import {
  BoutonRetourEC,
} from "../../components/espaceClient/BoutonAccueilEC";
import {
  StyledContentVueDetailsEC,
  StyledH1VuesEC,
  StyledTitleVueDetailsEC,
} from "../../styledComponents/StyledTextEspaceClient";
import { StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { Loading } from "../../components/Loading";
import { StyledPaperBodyEC } from "../../styledComponents/StyledPaper";
import {
  StyledDivSectionsRowRecap,
  StyledDivSectionVueDetailOrangeEC,
  StyledDivVueDetailsEC,
  StyledDivVueDetailsGeneBasicEC,
  StyledDivVueDetailsGeneBasicReverseEC,
} from "../../styledComponents/StyledDiv";
import { StyledSpanTitleVueDetailEC } from "../../styledComponents/StyledSpan";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllNC, getEtatNC } from "../../../model/slice/EspaceClientReducer";
import { useTranslations } from "../../components/hook/useTranslations";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

export const DetailsNC = ({ t, actions, state: { ncById } }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const lang = useTranslations();

  const [tableauOffres, setTableauOffres] = useState([]);

  const { etatNCList } = useSelector((state) => state.espaceClient);

  useEffect(() => {
    const encodedClientId = localStorage.getItem("clientId");

    if (encodedClientId) {
      const clientId = atob(encodedClientId);
      const obj = { clientId };

      dispatch(getEtatNC(obj));
    }
  }, [dispatch, params]);

  const triggerHandleRetour = () => {
    const url = new URL(window.location.href);

    actions.setIsNCByIdSet(false);

    url.searchParams.delete("nc");
    window.history.pushState({}, "", url);

    const encodedClientId = localStorage.getItem("clientId");

    if (encodedClientId) {
      const clientId = atob(encodedClientId);
      const obj = { clientId };

      dispatch(getAllNC(obj));
    }
  };

  const afficherBoutonRetour = (texte, action, backgroundColor) => {
    return (
      <BoutonRetourEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  useEffect(() => {
    if (ncById) {
      const offresArray = Object.values(ncById).filter(
        (value) => typeof value === "object" && value !== null
      );
      setTableauOffres(offresArray);
    }
  }, [ncById, params]);

  const displayEtatNC = (field) => {
    const etatItem = etatNCList.find((e) => e.etatFNCCode === field);
    const backgroundColor = etatItem ? etatItem.HEX : "#FFFFFF"; // Default to white if not found

    return (
      <StyledContentVueDetailsEC>
        <p
          style={{
            color: "white",
            wordBreak: "break-word",
            justify: "center",
            alignItems: "center",
            backgroundColor: backgroundColor,
            padding: "5px",
            borderRadius: "5px",
            margin: "0",
          }}
        >
          {lang.lang === "FR"
            ? etatItem?.FR ?? field
            : lang.lang === "GB"
            ? etatItem?.EN ?? field
            : lang.lang === "DE"
            ? etatItem?.DE ?? field
            : lang.lang === "IT"
            ? etatItem?.IT ?? field
            : field}
        </p>
      </StyledContentVueDetailsEC>
    );
  };

  const navigateToArticle = (articleId) => {
    if (articleId) {
      navigate(ClientRoutes.VUE_ARTICLE + "?art=" + articleId);
    }
  };

  return (
    <div style={{ padding: "2rem" }}>
      {afficherBoutonRetour(t.retourListeNC, triggerHandleRetour, "#F8F9FF")}
      {ncById ? (
        <div>
          <StyledH1VuesEC>
            {t.detailsNC} : {ncById[0]["FNCCode"]}
          </StyledH1VuesEC>
          <StyledHrEspaceClient></StyledHrEspaceClient>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC>
              <StyledSpanTitleVueDetailEC>
                {t.informationsGenerales}
              </StyledSpanTitleVueDetailEC>
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneBasicEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>{t.reference} : </span>

                      <StyledContentVueDetailsEC>
                        {ncById[0]["FNCLibelle"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {" "}
                        {t.dateDeclaration} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {ncById[0]["FNCDate"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>{t.statut} : </span>
                      {displayEtatNC(ncById[0]["etatFNC"])}
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneBasicEC>
                </StyledDivSectionVueDetailOrangeEC>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneBasicReverseEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.articleDesignation} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {ncById[0]["articleDesignation"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.votreCodeArticle} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {ncById[0]["articleReferenceClient"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.codeArticleRCMoov} :{" "}
                      </span>

                      <StyledContentVueDetailsEC
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigateToArticle(ncById[0]["articleId"])
                        }
                      >
                        {ncById[0]["articleCode"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneBasicReverseEC>
                </StyledDivSectionVueDetailOrangeEC>
              </StyledDivSectionsRowRecap>
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          {ncById[0]["FNCConstat"] !== "" ? (
            <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
              <StyledDivVueDetailsEC>
                <StyledSpanTitleVueDetailEC>
                  {t.constat}
                </StyledSpanTitleVueDetailEC>
                <br />
                <StyledContentVueDetailsEC
                  dangerouslySetInnerHTML={{ __html: ncById[0]["FNCConstat"] }}
                />
              </StyledDivVueDetailsEC>
            </StyledPaperBodyEC>
          ) : null}
          {ncById[0]["commentaireEspaceClient"] !== "" ? (
            <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
              <StyledDivVueDetailsEC>
                {/* Jansens : J'ai encapsulé les StyledSpanTitleVueDetailEC et StyledContentVueDetailsEC dans des div
                    pour résoudre rapidement le problème de retour à la ligne, et pour éviter de modifier les composants
                    concernés en risquant de changer d'autres comportements ailleurs.
                    La solution n'est pas idéale, il faudra la modifier un jour.
                */}
                <div>
                  <StyledSpanTitleVueDetailEC>
                    {t.decision}
                  </StyledSpanTitleVueDetailEC>
                </div>
                <div>
                  <StyledContentVueDetailsEC>
                    {ncById[0]["commentaireEspaceClient"]}
                  </StyledContentVueDetailsEC>
                </div>
              </StyledDivVueDetailsEC>
            </StyledPaperBodyEC>
          ) : null}
        </div>
      ) : (
        <Loading t={t} /> // ou tout autre composant pour indiquer qu'il n'y a pas d'offre
      )}
    </div>
  );
};

import { arrayProductFamilly } from "../../../constantes/symbols/SymbolsServicesCommon";

export const controllerMenuStep = (familly, t) => {
  // TODO mettre en cache le résultat au premier calcul puis réutiliser le cache
  switch (familly) {

    case arrayProductFamilly()[2].code:
      return stepNameMerger(stepNameS04(t), t);

    case arrayProductFamilly()[4].code:
      return stepNameMerger(stepNameS05(t), t);

    case arrayProductFamilly()[5].code:
      return stepNameMerger(stepNameS08(t), t);

    case arrayProductFamilly()[3].code:
      return stepNameMerger(stepNameS04_06(t), t);

    default:
      return stepNameMerger(stepNameS01(t), t);
  }
};


/**
 * Fusionne un tableau d'étapes avec les étapes communes du début et de la fin.
 * Renvoie le résultat sous forme d'un tableau dont les noms des étapes
 * sont préfixés du numéro d'étape (du genre [ '1. abc'], [2. def], [3. ghi] ])
 */
const stepNameMerger = (arrayOfNames, t) => {
  const mergedArray = stepCommonStart(t)
    .concat(arrayOfNames)
    .concat(stepCommonEnd(t));
  return mergedArray.map((title, index) => `${index + 1}. ${title}`);
};

// étapes communes au début
const stepCommonStart = (t) => [
  t.genConfigurateurH2FamilleProtectionsIndustrielles,
  t.genConfigurateurH2InformationsMachine,
  t.genConfigurateurH2Agressions,
];

// étapes communes à la fin
const stepCommonEnd = (t) => [
  t.genConfigurateurH2InformationsComplementaires,
  t.genConfigurateurH2Coordonnees,
  t.genConfigurateurH2Recapitulatif,
];

const stepNameS01 = (t) => [
  t.genConfigurateurH2DimensionsCorps,
  t.genConfigurateurH2DimensionsExtremites,
  t.genConfigurateurH2Accessoires,
];

const stepNameS04 = (t) => [
  t.genConfigurateurH2Construction,
  t.genConfigurateurH2DimensionsCorps,
  t.genConfigurateurH2Extremites,
  t.genConfigurateurH2Accessoires,
];

const stepNameS05 = (t) => [
  t.genConfigurateurH2Construction,
  t.genConfigurateurH2Dimensions,
  t.genConfigurateurH2Extremite,
];

const stepNameS08 = (t) => [
  t.genConfigurateurH2Matiere,
  t.genConfigurateurH2Forme,
  t.genConfigurateurH2Dimensions,
  t.genConfigurateurH2Extremites,
  t.genConfigurateurH2Accessoires,
];

const stepNameS04_06 = (t) => [
  t.genConfigurateurH2Construction,
  t.genConfigurateurH2DimensionsCorps,
  t.genConfigurateurH2Extremites,
  t.genConfigurateurH2Accessoires,
];

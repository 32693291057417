import { useSelector, useDispatch } from 'react-redux';
import { selectionnerContact as selectionnerContactThunk } from '../../../slice/ChoixContactReducer';

const selecteurChoixContact = (state) => ({
  contact: state.choixContact.contact,
  apiStatus : state.choixContact.apiStatus,
  apiError : state.choixContact.apiError
});

const useChoixContact = () => {
  const dispatch = useDispatch();
  // ATTENTION : laisser l'argument de useSelector sous forme de fonction nommée !
  // (pour que la référence reçue par useSelector ne change pas à chaque fois avec une fonction anonyme)
  const selectionnerContact = (contactId) => dispatch(selectionnerContactThunk({ contactId }));
  const selectionState = useSelector(selecteurChoixContact);
  return {
    ...selectionState,
    selectionnerContact
  };
};

export { useChoixContact };

import { useState } from "react";
import { Stack } from "@mui/material";

import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { importAllSpec } from "../../helper/ImportAll";
import { LangSwitcher } from "./components/LangSwitcher";
import { StyledHeaderHeader } from "./styledComponents/StyledHeader";
import { StyledImgHeader } from "./styledComponents/StyledImg";
import { StyledSpanHeaderEC, StyledSpanHeaderECActive } from "./styledComponents/StyledSpan";
import { HamburgerMenu } from "../../routes/component-HOC/HamburgerMenu";
import { StringToRouteInNavigate } from "../../helper/StringParser";
import { ClientRoutes } from "../../constantes/routes/ClientRoutes";

export const HeaderEspaceClient = ({ isMobileFormat, styles, t }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Centralisation de l'état du menu ici

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleConfigurer = () => {
    const url = StringToRouteInNavigate(GuestRoutes.CONFIGURATEUR);
    window.open(url, "_blank");
  };

  const handleEspaceClient = () => {
    const url = StringToRouteInNavigate(ClientRoutes.ACCUEIL_EC);
    window.open(url, "_blank");
  };

  const handleAccueilClickRC = () => {
    // TODO marque blanche : url site codée en dur
    const url = "https://www.rcmodeles.com/";
    window.open(url, "_blank");
  };

  const handleAccueilClickMP = () => {
    // TODO marque blanche : url site codée en dur
    const url = "https://moovprotect.com/";
    window.open(url, "_blank");
  }

  return (
    <>
      <StyledHeaderHeader style={{ padding: "0", overflow: "hidden" }}>
        {isMobileFormat && (
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledImgHeader
              src={importAllSpec(false)["Logo.png"]}
              className={styles.logo}
              onClick={handleAccueilClickRC}
            />
            <StyledImgHeader
              src={importAllSpec(true)["Logo.png"]}
              className={styles.logoMoovEC}
              onClick={handleAccueilClickMP}
              style={{ maxHeight: "3.5em!important" }}
            />
          </Stack>
        )}

        {!isMobileFormat && (
          <Stack
            style={{
              margin: "1rem",
              spacing: 1,
              alignItems: "center",
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <img
              src={importAllSpec(false)["LogoMobile.png"]}
              alt="Logo RC"
              style={{ cursor: "pointer" }}
              onClick={handleAccueilClickRC}
            />
            <img
              src={importAllSpec(true)["LogoMobile.png"]}
              alt="Logo Moov"
              style={{ cursor: "pointer" }}
              onClick={handleAccueilClickMP}
            />
          </Stack>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
            padding: "0 1rem",
            boxSizing: "border-box",
            flexWrap: "wrap",
          }}
        >

          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {isMobileFormat && (
              <>
                <StyledSpanHeaderECActive onClick={handleEspaceClient}>
                  {t.espaceClient}
                </StyledSpanHeaderECActive>
                <StyledSpanHeaderEC onClick={handleConfigurer}>
                  {t.configurateur}
                </StyledSpanHeaderEC>
              </>
            )}
            <LangSwitcher />
            <div style={{ marginLeft: "1rem" }}>
              {!isMobileFormat && (
                <HamburgerMenu
                  t={t}
                  toggleMenu={toggleMenu}
                  isMenuOpen={isMenuOpen}
                  isInHeader
                />
              )}
            </div>
          </div>
        </div>
      </StyledHeaderHeader>

      {/* Menu placé en dessous du header */}
      <HamburgerMenu t={t} toggleMenu={toggleMenu} isMenuOpen={!isMobileFormat ? isMenuOpen : false} />
    </>
  );
};

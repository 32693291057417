import { useState, useEffect, useRef } from 'react';

import { useTranslations } from './hook/useTranslations.js';

import '../../../css/Tableau.css';

// TODO masquage colonnes selon mode écran

const TEMPO = 300; // ms

const OrdreTri = {
  CROISSANT: 'croissant',
  DECROISSANT: 'decroissant'
};
Object.freeze(OrdreTri);


const FiltreColonne = ({valeur, setValeur}) => {

  const evChange = (ev) => {
    setValeur(ev.target.value);
  };

  return (
      <input type='text' onChange={evChange} value={valeur} />
  );
};

const EnteteColonne = ({ colonne, fonctionFiltrage, fonctionTri, tri }) => {
  const [valeur, setValeur] = useState('');
  const boutonTriCroissantRef = useRef(null);
  const boutonTriDecroissantRef = useRef(null);

  useEffect( () => {
    if (tri === OrdreTri.CROISSANT)
      boutonTriCroissantRef.current.click();
    else if (tri === OrdreTri.DECROISSANT)
      boutonTriDecroissantRef.current.click();
  }, []);

  useEffect( () => {
    if (typeof(fonctionFiltrage) !== 'function')
      return;
    fonctionFiltrage(colonne, valeur);
  }, [valeur]);

  const evBoutonTri = (ev) => {
    if (typeof(fonctionTri) !== 'function')
      return;
    const sens = ev.target.value;
    fonctionTri(colonne, sens);
  };

  const styleLargeur = colonne.width ?  { width: colonne.width } : {};

  return (
    <th style={ styleLargeur } >
      <div className='tableau-entete'>
        <span>{colonne.titre}</span>
        {
          fonctionTri && (<>
            <label>
              <input
                name='bouton-tri'
                type='radio'
                value={OrdreTri.DECROISSANT}
                checked={ tri!== null && tri === OrdreTri.DECROISSANT }
                onChange={evBoutonTri}
                ref={boutonTriDecroissantRef }
              />
              <span>▲</span>
            </label>
            <label>
              <input
                name='bouton-tri'
                type='radio'
                value={OrdreTri.CROISSANT}
                onChange={evBoutonTri}
                checked={ tri!== null && tri === OrdreTri.CROISSANT }
                ref={boutonTriCroissantRef }
              />
              <span>▼</span>
            </label>
            </>)
        }
        {
          fonctionFiltrage && (
              <FiltreColonne valeur={valeur} setValeur={setValeur} />
          )
        }
      </div>
    </th>
  );
};


const CelluleDonnees = ({ cellule, colonne }) => {
  // alignement des nombres à droite (colonne.type)
  const styleAlignement = colonne.type === 'number' ? { textAlign: 'right' } : {};
  return (
    <td style={styleAlignement}>
      {cellule}
    </td>
  );
};


const CelluleLien = ({ cellule, colonne }) => {

  const evLienClick = (ev) => {
    const cellule = ev.target.parentElement.parentElement.dataset?.cellule || null;
    if (colonne.fonction)
      colonne.fonction(cellule);
  };

  return (
    <td>
      <a href="#" onClick={evLienClick}>
        {cellule}
      </a>
    </td>
  );
};


const CelluleAction = ({ colonne }) => {

  const onActionClick = (ev) => {
    const cellule = ev.target.parentElement.parentElement.dataset?.cellule || null;
    const indexAction = ev.target.dataset?.action || null;
    if (indexAction)
      colonne.actions[indexAction].fonction(cellule);
  };

  return (
    <td>
      {
        colonne.actions.map( (action, indexAction) =>
          <button key={indexAction} data-action={indexAction} onClick={onActionClick}>{action.label}</button>
        )
      }
    </td>
  );
};

const CelluleTableau = ({ cellule, colonne }) => {
  switch (colonne.type) {
    case 'string':
    case 'number':
      return <CelluleDonnees cellule={cellule} colonne={colonne}/>;
    case 'link':
      return <CelluleLien cellule={cellule} colonne={colonne}/>;
    case 'actions':
      return <CelluleAction colonne={colonne}/>;
    default :
      return <td>?</td>;
  };
};

const Pagination = ({pagination, setPagination}) => {

  const evChoixTaille = (ev) => {
    pagination.taille = parseInt(ev.target.value);
    normaliserPagination(pagination);
    setPagination({ ...pagination });
  };

  const changerPage = (delta) => {
    switch (delta) {
      case -2:
        pagination.page = 1;
        break;
      case 2:
        pagination.page = pagination.nbrPages;
        break;
      default:
        pagination.page += delta;
    }
    normaliserPagination(pagination);
    setPagination({ ...pagination });
  };

  return (
    <div className='pagination'>
      <button onClick={() => {changerPage(-2)}}>⇤</button>
      <button onClick={() => {changerPage(-1)}}>←</button>
      &nbsp;
      <span>{pagination.page}</span>
      &nbsp;/&nbsp;
      <span>{pagination.nbrPages}</span>
      &nbsp;
      <button onClick={() => {changerPage(1)}}>→</button>
      <button onClick={() => {changerPage(2)}}>⇥</button>
      &nbsp;
      <select value={pagination.taille} onChange={evChoixTaille}>
      { pagination.tailles.sort((a,b) => a-b).map( taille=> (
          <option key={taille} value={taille}>{taille}</option>
        ))
      }
      </select> &nbsp;
    </div>
  );
};

const normaliserPagination = (pagination) => {
  pagination.nbrPages = Math.ceil(pagination.total / pagination.taille);
  if (pagination.page < 1)
    pagination.page = 1;
  if (pagination.page > pagination.nbrPages)
    pagination.page = pagination.nbrPages;
  return pagination;
};

const nouvellePagination = (pages, total) => {
  const tailles = pages.sort().map(taille => parseInt(taille));
  const taille = parseInt(tailles[0]); // taille par défaut en premier dans le tableau
  const pagination = {
    tailles, // tableau de choix du nombre de lignes par page
    taille, // nombre de lignes par page choisi
    total, // nombre total d'élément
    page:1, // numéro de la page affiché
    nbrPages: -1 // nombre total de page
  }
  return normaliserPagination(pagination);
};

const Tableau = ({lignes, colonnes, triInitial, pages, messages}) => {

  if (! (lignes instanceof Array))
    lignes = [];
  if (! (pages instanceof Array))
    pages = null;
  const { t } = useTranslations();
  const [pagination, setPagination] = useState(null);
  const [ idTempo, setIdTempo ] = useState(null);
  const [ lignesFiltrees, setLignesFiltrees ] = useState([]);
  const [ lignesAffichees, setLignesAffichees ] = useState([]);
  const [ filtres, setFiltres ] = useState([]);
  const [ tri, setTri] = useState(null);

  useEffect( () => {
    const tabFiltres = [];
    colonnes.forEach( (colonne, index) => {
      if ( colonne.sort === true)
        tabFiltres.push({
          index,
          valeur: ''
        });
    });
    setFiltres(tabFiltres);

    if (triInitial)
      setTri(triInitial);

    return () => {
      if (idTempo !== null)
        window.clearTimeout(idTempo);
    }
  }, []);

  useEffect( () => {
    filtrerLignes();
  }, [lignes]);

  useEffect( () => {
    filtrerLignes();
  }, [tri]);

  useEffect( () => {
    if (idTempo !== null) {
      window.clearTimeout(idTempo);
      setIdTempo(null);
    }
    setIdTempo( window.setTimeout( () => {
      filtrerLignes();
    }, TEMPO));
  }, [filtres]);

  let numColonneCle = colonnes.findIndex( colonne => colonne.key=== true);
  if (numColonneCle === -1)
    numColonneCle = null;

  const filtrerLignes = () => {
    if (lignes === null)
      return;
    const listeLignesFiltrees = [];
    let listeLignesTriees = null;
    if (tri === null) {
      listeLignesTriees = lignes; // pas de tri
    }
    else {
      const i = tri.colonne;
      const fonctionTri = tri.sens === OrdreTri.CROISSANT ?
        (a,b) => (a[i] > b[i]) :
        (a,b) => (a[i] < b[i]);
      listeLignesTriees = lignes.sort( fonctionTri );
    }

    for (const ligne of listeLignesTriees) {
      let selectionne = true;
      for (const filtre of filtres) {
        if (filtre.valeur === null || filtre.valeur.length === 0)
          continue;
        const valeurFiltre = filtre.valeur.toLowerCase();
        if (! ligne[filtre.index].toLowerCase().includes(valeurFiltre)) {
          selectionne = false;
          break;
        }
      }
      if (selectionne)
        listeLignesFiltrees.push(ligne);
    }
    setLignesFiltrees(listeLignesFiltrees);
    setPagination(nouvellePagination(pages, listeLignesFiltrees.length));
  }

  useEffect( () => {
    if (pagination === null)
      return;
    const ligneDebut = (pagination.page - 1) * pagination.taille;
    const ligneFin = Math.min(ligneDebut + pagination.taille, pagination.total);
    setLignesAffichees(lignesFiltrees.slice(ligneDebut, ligneFin));
  }, [ pagination ]);

  const fonctionFiltrage = (colonne, nouvelleValeur) => {
    nouvelleValeur = nouvelleValeur.trim();
    const listeFiltres = filtres.map( filtre =>  ({
        index: filtre.index,
        valeur: (filtre.index === colonne.index && nouvelleValeur !== null) ? nouvelleValeur : filtre.valeur
      })
    );
    setFiltres(listeFiltres);
  };

  const fonctionTri = (colonne, sens) => {
    setTri({
      colonne: colonne.index,
      sens
    });
  };

  colonnes.forEach( (colonne, index) => {
    colonne.index = index;
  });

  const listeColonnesAffichees = colonnes.filter(colonne => colonne.titre !== undefined);
  const nbrLignesAffichees = lignesAffichees.length;
  const nbrColonnesAffichees = colonnes.length;
  const filtrage = nbrLignesAffichees != lignesFiltrees.length;

  return (<>
      { pagination && (
        <Pagination pagination={pagination} setPagination={setPagination}/>
      )}
      <table className='tableau'>
        <thead>
          <tr>
            { listeColonnesAffichees.map( (colonne, index) =>
                <EnteteColonne
                  key={index}
                  colonne={colonne}
                  fonctionFiltrage={colonne.filter ? fonctionFiltrage : null}
                  fonctionTri={colonne.sort ? fonctionTri : null}
                  tri={ (tri && tri.colonne === colonne.index) ? tri.sens : null}
                />
              )
            }
          </tr>
        </thead>
        <tbody>
          { nbrLignesAffichees === 0 ? (
              <tr>
                <td colSpan={nbrColonnesAffichees-1} className='tableau-message'>
                  { filtrage ? messages.tableau_vide: messages.aucun_resultat}
                </td>
              </tr>
            ):(
                lignesAffichees.map( (ligne, numLigne) =>
                  <tr
                    key={(numColonneCle === null) ? numLigne : ligne[numColonneCle]}
                    data-cellule={(numColonneCle === null) ? numLigne : ligne[numColonneCle]}
                  >
                  { ligne.map( (cellule, index) =>
                      colonnes[index]?.titre ? (
                        <CelluleTableau key={index} cellule={cellule} colonne={colonnes[index]}/>
                      ) : null
                    )
                  }
                  </tr>
                )
            )
          }
        </tbody>
      </table>
      { pagination && (
        <Pagination pagination={pagination} setPagination={setPagination}/>
      )}
    </>);
};

export { Tableau, OrdreTri };


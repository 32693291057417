import {
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
  StyledDivSearchBar,
} from "./styledComponents/StyledDivBackoffice";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { StyledTextfieldSearchDdp } from "./styledComponents/StyledTextfieldBackoffice";
import {
  forceSendMailAndFilesByDDP,
  getAllInfoByRef,
  triggerForceSendMailAndFilesByDDP,
} from "../slice/FormulaireReducer";
import { useDispatch, useSelector } from "react-redux";
import { arrayProductFamilly } from "../../constantes/symbols/SymbolsServicesCommon";
import { useState } from "react";
import { useEffect } from "react";
import { EC_COLORS } from "./espaceClient/common/EcColor";

export const DdpItem = ({
  id,
  reference,
  marque,
  familleDeProtectionIndustrielle,
  societe,
  date,
  nom,
  prenom,
  odp,
  pub,
  action,
  state: { t },
  searchBarAction: {
    setSearchBarContentNDDP,
    setSearchBarContentMarque,
    setSearchBarContentFamille,
    setSearchBarContentSociete,
    setSearchBarContentDate,
    setSearchBarContentNom,
    setSearchBarContentPrenom,
    setSearchBarContentOdp,
    setSearchBarContentPub,
  },
  sortAction: {
    setSortedDate,
    setSortedMarque,
    setSortedFamille,
    setSortedSociete,
    setSortedNom,
    setSortedPrenom,
    setSortedOdp,
    setSortedPub,
  },
  modale: { handleOpen, setReferenceToDelete },
}) => {
  const [columnSize, setColumnSize] = useState(1);
  const [isForceSendEnded, setIsForceSendEnded] = useState(true);
  const { accessToken } = useSelector((state) => state.authentification);
  const { forceSend } = useSelector((state) => state.formulaire);
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  const betweenSmMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const dispatch = useDispatch();
  const noClassNameMobileAndTablette =
    "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  useEffect(() => {
    if (forceSend === undefined) {
      setIsForceSendEnded(true);
    } else {
      setIsForceSendEnded(forceSend);
    }
  }, [forceSend]);

  const handleChange = (event, setterSearchBar) => {
    setterSearchBar(event.target.value);
  };

  const forceSendMailAndFilesByDDPAction = () => {
    dispatch(triggerForceSendMailAndFilesByDDP());
    const obj = {
      reference: marque + reference,
    };
    dispatch(forceSendMailAndFilesByDDP(obj));
  };

  const showForm = (reference) => {
    // FIXME fonctionne sans useNavigate ?
    dispatch(getAllInfoByRef({ reference: reference }));
  };

  const DeleteProfil = (marque, reference) => {
    const obj = {
      marque: marque,
      reference: reference,
    };
    setReferenceToDelete(obj);
    handleOpen();
  };

  const DisplayAdornment = ({ md, style }) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={md ? md : columnSize}
        className={style + " itemDdp action"}
        xs={4}
        paddingLeft="0.5em!important"
      >
        <Grid
          container
          direction={"row"}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {!odp && isForceSendEnded && accessToken?.data.role === "4000" ? (
            <Grid item md={12} xl={3}>
              <IconButton onClick={() => forceSendMailAndFilesByDDPAction()}>
                <SendIcon
                  sx={
                    betweenSmMd
                      ? { color: EC_COLORS.DEEP_BLUE, fontSize: 15 }
                      : { color: EC_COLORS.DEEP_BLUE, fontSize: 20 }
                  }
                />
              </IconButton>
            </Grid>
          ) : null}
          {!isForceSendEnded && accessToken?.data.role === "4000" ? (
            <Grid item md={12} xl={3}>
              <IconButton>
                <HourglassTopIcon
                  sx={
                    betweenSmMd
                      ? { color: EC_COLORS.DEEP_BLUE, fontSize: 15 }
                      : { color: EC_COLORS.DEEP_BLUE, fontSize: 20 }
                  }
                />
              </IconButton>
            </Grid>
          ) : null}
          <Grid item md={12} xl={3}>
            <IconButton onClick={() => showForm(reference)}>
              <RemoveRedEyeOutlinedIcon
                sx={
                  betweenSmMd
                    ? { color: EC_COLORS.DEEP_BLUE, fontSize: 15 }
                    : { color: EC_COLORS.DEEP_BLUE, fontSize: 20 }
                }
              />
            </IconButton>
          </Grid>
          {accessToken?.data.role === "4000" ? (
            <Grid item md={12} xl={3}>
              <IconButton onClick={() => DeleteProfil(marque, reference)}>
                <DeleteIcon
                  sx={
                    betweenSmMd
                      ? { color: EC_COLORS.VIOLET, fontSize: 15 }
                      : { color: EC_COLORS.VIOLET, fontSize: 20 }
                  }
                />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const displaySearchBar = (setValue) => {
    if (setValue) {
      return (
        <div>
          <StyledDivSearchBar>
            <StyledTextfieldSearchDdp
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event, setValue)}
              InputProps={{
                endAdornment: (
                  <SearchIcon md={{ color: EC_COLORS.ORANGE, fontSize: 10 }} />
                ),
              }}
            />
          </StyledDivSearchBar>
        </div>
      );
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (id !== reference && setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " columnHeader"}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
            {displaySortArrows(sortStateSetter)}
          </Stack>
          <Stack>{displaySearchBar(searchBarStateSetter)}</Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </Grid>
    );
  };

  const displayItemMatch = (array, field, md, sm, style) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={0}
        className={style + " itemDdp"}
      >
        <div
          style={{
            wordBreak: "break-word",
            justify: "center",
            alignItems: "center",
          }}
        >
          {array.find((e) => e.code === field)?.name ?? field}
        </div>
      </Grid>
    );
  };

  const displayItemAction = (md, style) => {
    return <DisplayAdornment md={md} style={style} />;
  };

  return (
    <Grid container item direction="row" className={id === 0 ? "row0" : "row"}>
      {id === 0
        ? displayItemHeader(
            reference,
            setSearchBarContentNDDP,
            null,
            2,
            2,
            8,
            alwaysDisplayed
          )
        : displayItem(reference, 2, 2, alwaysDisplayed)}
      {id === 0
        ? displayItemHeader(
            marque,
            setSearchBarContentMarque,
            setSortedMarque,
            null,
            null,
            null,
            noClassNameMobile
          )
        : displayItem(marque, null, null, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            familleDeProtectionIndustrielle,
            setSearchBarContentFamille,
            setSortedFamille,
            1.5,
            2,
            null,
            noClassNameMobile
          )
        : displayItemMatch(
            arrayProductFamilly(t),
            familleDeProtectionIndustrielle,
            1.5,
            2,
            noClassNameMobile
          )}
      {id === 0
        ? displayItemHeader(
            societe,
            setSearchBarContentSociete,
            setSortedSociete,
            null,
            2,
            null,
            noClassNameMobile
          )
        : displayItem(societe, null, 2, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            nom,
            setSearchBarContentNom,
            setSortedNom,
            null,
            2,
            null,
            noClassNameMobile
          )
        : displayItem(nom, null, 2, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            prenom,
            setSearchBarContentPrenom,
            setSortedPrenom,
            null,
            null,
            null,
            noClassNameMobileAndTablette
          )
        : displayItem(prenom, null, null, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(
            date,
            setSearchBarContentDate,
            setSortedDate,
            1.5,
            2,
            null,
            noClassNameMobile
          )
        : displayItem(date, 1.5, 2, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            odp,
            setSearchBarContentOdp,
            setSortedOdp,
            null,
            null,
            null,
            noClassNameMobileAndTablette
          )
        : displayItem(odp, null, null, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(
            pub,
            setSearchBarContentPub,
            setSortedPub,
            null,
            null,
            null,
            noClassNameMobileAndTablette
          )
        : displayItem(pub, null, null, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(action, null, null, null, null, 4, "")
        : displayItemAction(null, "")}
    </Grid>
  );
};

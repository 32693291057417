import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useConfiguration } from "../../redux/vue/components/hook/useConfiguration";
import { useTranslations } from "../../redux/vue/components/hook/useTranslations";

import { FooterInternaute } from "../../redux/vue/FooterInternaute";
import { GetStyle } from "../../helper/Styles";
import { HeaderEspaceClient } from "../../redux/vue/HeaderEspaceClient";
import { useDispatch } from "react-redux";


const PublicClientWrapper = () => {
  const dispatch = useDispatch();

  const { loading: configLoading } = useConfiguration();
  const { loading: translationLoading, t } = useTranslations();

  const styles = GetStyle(false);

  const setIdentite = () => {
    // FIXME marque blanche : setIdentite
    const url = window.location.href;
    const regex = new RegExp("moovp*");
    const site = regex.test(url);
    return site;
  };

  const [booleanIdentite, _] = useState(setIdentite()); // TODO setter jamais utilisé

  // attendre que la configuration soit chargée
  if (configLoading || translationLoading)
    return <div>{t.status_pending}...</div>

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F9FF",
      }}
    >
      <div>
        <HeaderEspaceClient
          t={t}
          isMobileFormat={false}
          styles={styles}
        />
      </div>

      <div style={{ display: "flex" } }>
        <div
          style={{
            flex: 1,
            backgroundColor: "#F8F9FF",
          }}
        >
          <Outlet/>
        </div>
      </div>

      <div><FooterInternaute styles={styles} /></div>
    </div>
  );
};

export { PublicClientWrapper };

import { Grid } from "@mui/material";

import { LoadingErrorMessage } from "../../components/LoadingMessageComponents.jsx";
import { useConfiguration } from "../../components/hook/useConfiguration";
import { formulaireInformationsMachine } from "../../ChampsFormulaires/S01/etape2/ChampsInformationsMachine";
import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../../constantes/symbols/SymbolsCommon";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";

import { StyledButtonContinuer, StyledButtonPrec } from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
  StyledDivPhotoFamille,
} from "../../styledComponents/StyledDiv";


export const StepInfoMachine = ({
  state: {
    booleanIdentite,
    formik,
    styles,
    styles: { cssFontFamily, cssButtonPrec, activeStepTitle },
    t,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
  },
  action: { handleRetour },
  nom,
}) => {
  const { parameters } = useConfiguration();
  if (
    !upToDateTypeMachineList ||
    upToDateTypeMachineList.length === 0 ||
    !upToDateMarqueMachineList ||
    upToDateMarqueMachineList.length === 0 ||
    !formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description]
  ) {
    return <LoadingErrorMessage t={t} />;
  } else {
    return (
      <StyledDivFormAndImageContainer>
        <StyledDivFormContainer>
          <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
            <h2>{nom}</h2>
          </StyledDivActiveStepTitle>
          <StyledP className={cssFontFamily}>
            {t.genConfigurateurBodyComplétezLesInformations}
          </StyledP>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
              {genererFormulaire(
                formulaireInformationsMachine(
                  upToDateTypeMachineList,
                  upToDateMarqueMachineList,
                  styles,
                  t,
                  formik
                ),
                formik
              )}
              <Grid item>
                <StyledDivButton>
                  <StyledButtonPrec
                    className={cssButtonPrec}
                    onClick={handleRetour}
                    variant="outlined"
                    startIcon={<StyledArrowBackOutlinedIcon />}
                  />
                  <StyledButtonContinuer
                    className={'cssButton'}
                    variant="contained"
                    type="submit"
                  >
                    {t.bouttonContinuer}
                  </StyledButtonContinuer>
                </StyledDivButton>
              </Grid>
            </Grid>
          </form>
        </StyledDivFormContainer>

        <StyledSticky>
          <StyledDivPhotoFamille
            style={{
              backgroundImage: "url(" + parameters.HOME_BACKGROUND_IMAGE + ")"
            }}
          />
        </StyledSticky>
      </StyledDivFormAndImageContainer>
    );
  }
};

import { Grid } from "@mui/material";
import { importAllEnvironnement } from "../../../../helper/ImportAll";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import { StyledGridStickyZoneAgressions } from "../../styledComponents/StyledGrid";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
  StyledDivPhotoEnv,
} from "../../styledComponents/StyledDiv";

import {
  formulaireAgressions,
  formulaireAgressionsImages,
  formulaireEnvironnement,
  formulaireTemperatures,
} from "../../ChampsFormulaires/S01/etape3/ChampsEnvironnementMachine";
import { useState } from "react";
import { useEffect } from "react";
import {
  arrayEnvironnement,
  arrayProductFamilly0_1_3,
  arrayProductFamilly0_1_3_2_3,
} from "../../../../constantes/symbols/SymbolsServicesCommon";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  ENVIRONNEMENT,
  FAMILLE_PROTECTION_INDUSTRIELLE,
} from "../../../../constantes/symbols/SymbolsCommon";

export const StepEnvironnement = ({
  state: {
    booleanIdentite,
    formik,
    styles,
    styles: { cssFontFamily, cssButtonPrec, activeStepTitle },
    t,
    upToDateAgressionsList,
    upToDateAgressionsWithImgList,
  },
  action: { handleRetour },
  nom,
}) => {
  const [environnementCode, setEnvironnementCode] = useState();

  useEffect(() => {
    setEnvironnementCode(parseInt(formik.values[ENVIRONNEMENT.description]));
  }, [formik.values]);

  const afficherAgressions = () => {
    if (
      !arrayEnvironnement(t).find(
        (element) => {
          return element.code === environnementCode && element.code !== 3;
        } //Autre
      )
    ) {
      return genererFormulaire(
        formulaireAgressions(upToDateAgressionsList, styles, t),
        formik
      );
    }
  };

  const afficherSticky = () => {
    switch (environnementCode) {
      case 3:
        return (
          <StyledSticky bottomOffset={500} boundaryElement=".limitSticky">
            {/* ajouter className="limitSticky" dans element parent pour arreter sticky avant footer */}
            <StyledGridStickyZoneAgressions container spacing={2}>
              {genererFormulaire(
                formulaireAgressionsImages(
                  upToDateAgressionsWithImgList,
                  styles,
                  t
                ),
                formik
              )}
            </StyledGridStickyZoneAgressions>
          </StyledSticky>
        );
      default:
        return null;
    }
  };

  const afficherBackground = () => {
    switch (environnementCode) {
      case 0:
        return (
          <StyledSticky>
            <StyledDivPhotoEnv
              style={{
                backgroundImage:
                  "url(" +
                  importAllEnvironnement(
                    formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description]
                  )["alimentaire.jpg"] +
                  ")",
              }}
            />
          </StyledSticky>
        );
      case 1:
        return (
          <StyledSticky>
            <StyledDivPhotoEnv
              style={{
                backgroundImage:
                  "url(" +
                  importAllEnvironnement(
                    formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description]
                  )["nucleaire.jpg"] +
                  ")",
              }}
            />
          </StyledSticky>
        );
      case 2:
        return (
          <StyledSticky>
            <StyledDivPhotoEnv
              style={{
                backgroundImage:
                  "url(" +
                  importAllEnvironnement(
                    formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description]
                  )["labelPeinture.jpg"] +
                  ")",
              }}
            />
          </StyledSticky>
        );
      default:
        return null;
    }
  };

  const afficherFormulaireTemperatures = () => {
    if (
      arrayProductFamilly0_1_3_2_3(t).includes(
        formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description]
      )
    ) {
      return (
        <>{genererFormulaire(formulaireTemperatures(styles, t), formik)}</>
      );
    }
  };

  const afficherEnvironnement = () => {
    //Si RC
    if (!booleanIdentite) {
      //Si S01 ou S01_1
      if (
        arrayProductFamilly0_1_3(t).includes(
          formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description]
        )
      ) {
        return genererFormulaire(formulaireEnvironnement(styles, t), formik);
      }
    }
    return null;
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyDefinissezIciEnvironnement}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {afficherEnvironnement()}
            {afficherFormulaireTemperatures()}
            {afficherAgressions()}
            <Grid item>
              <StyledDivButton id='boutonContinuer'>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  className={'cssButton'}
                  variant="contained"
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>
      {afficherSticky()}
      {afficherBackground()}
    </StyledDivFormAndImageContainer>
  );
};

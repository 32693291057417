import React from "react";
import { Card, CardContent } from "@mui/material";

import SelecteurContact from '../../components/SelecteurContact.jsx';
import { useTranslations } from '../../components/hook/useTranslations.js';
import { StyledContentCard, StyledDivTitleCard } from "../../styledComponents/StyledTextEspaceClient";

const ChoixContactCard = ({ styles, isMobile }) => {
  const { t } = useTranslations();

  const specStyle = isMobile ? {} : { flex:1 };
  return (
    <Card
      id="whoIam-card"
      className={styles.whoIamCard}
      style={{
        ... specStyle,
        display: "flex",
        flexDirection: "column",
        border: "#E7EAFF solid",
        boxShadow: "none",
        backgroundColor: "transparent",
        borderRadius: "8px",
      }}
    >
      <CardContent style={{ flex: 1, padding: "24px" }}>
        <StyledDivTitleCard>{t.jePersonnalise}</StyledDivTitleCard>
        <SelecteurContact/>
        <StyledContentCard>... {t.descrJePersonnalise}</StyledContentCard>
      </CardContent>
    </Card>
  )
}

export default ChoixContactCard;

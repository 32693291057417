import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { DetailsOffre } from "./DetailsOffre";
import { selectOffreEC } from "../../../slice/EspaceClientReducer";
import { ApiStatus } from '../../../../constantes/ApiStatus';
import { LoadingMessage, LoadingErrorMessage } from "../../components/LoadingMessageComponents.jsx";
import { useTranslations } from "../../components/hook/useTranslations";
import { BoutonRetourEC } from "../../components/espaceClient/BoutonAccueilEC";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { StyledDivWrapperVues } from "../../styledComponents/StyledDivEspaceClient";

const VueVisuOffre = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { currentOffre } = useSelector((state) => state.espaceClient);

  const { clientId, offreId } = useParams();

  useEffect( () => {
    dispatch(selectOffreEC({ clientId, offreId }));
  }, [clientId, offreId]);

  const navigateToEC = () => {
    navigate(ClientRoutes.ACCUEIL_EC);
  };

  if (currentOffre.apiStatus === ApiStatus.IDLE)
    return <LoadingMessage t={t} />;

  if (currentOffre.apiStatus === ApiStatus.ERROR)
    // TODO améliorer le rendu
    return <>
        <LoadingErrorMessage t={t} errorMessage={currentOffre.apiError}/>;
      </>

  return (
    <StyledDivWrapperVues>
      <BoutonRetourEC texte={t.accesEspaceClient} action={navigateToEC} backgroundColor={"#F8F9FF"} />
      <DetailsOffre/>
    </StyledDivWrapperVues>
  );
};

export { VueVisuOffre };

import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { importAllSpec } from "../../helper/ImportAll";
import { LangSwitcher } from "../vue/components/LangSwitcher";
import { StyledHeaderHeader } from "./styledComponents/StyledHeader";
import { StyledImgHeader } from "./styledComponents/StyledImg";
import { Container, StyledSpanHeaderEC, StyledSpanHeaderECActive } from "./styledComponents/StyledSpan";

import { StringToRouteInNavigate } from "../../helper/StringParser";
import { ClientRoutes } from "../../constantes/routes/ClientRoutes";

export const HeaderInternaute = ({
  booleanIdentite,
  styles,
  isMobileFormat,
  t,
}) => {

  const navigate = useNavigate();

  const handleConfigurer = () => {
    const url = StringToRouteInNavigate(GuestRoutes.CONFIGURATEUR);
    window.open(url, "_blank");
  };

  const handleEspaceClient = () => {
    const url = StringToRouteInNavigate(ClientRoutes.ACCUEIL_EC);
    window.open(url, "_blank");
  };

  const handleAccueilClick = () => {
    navigate(GuestRoutes.ACCUEIL);
  };
  // TODO marque blanche : logos codés en dur ci-dessous
  return (
    <StyledHeaderHeader>
      {!isMobileFormat ? (
        <Stack
          style={{
            margin: "1rem",
            spacing: 1,
            alignItems: "center",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <img
            src={importAllSpec(false)["LogoMobile.png"]}
            alt="Logo RC"
            style={{ cursor: "pointer" }}
            onClick={handleAccueilClick}
          />
        </Stack>
      ) : (
        <StyledImgHeader
          src={importAllSpec(booleanIdentite)["Logo.png"]}
          style={{ left: "0" }}
          className={styles.logo}
          onClick={handleAccueilClick}
        />
      )}

      <div
        style={{
          right: "0",
          position: "absolute",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <div style={{ display: "flex", margin: "1em" }}>
          <Container>
            <StyledSpanHeaderEC onClick={handleEspaceClient}>
            {t.espaceClient}
            </StyledSpanHeaderEC>
            <StyledSpanHeaderECActive onClick={handleConfigurer}>
            {t.configurateur}
            </StyledSpanHeaderECActive>
            {/* <LangSwitcher /> */}
          </Container>
          <LangSwitcher />
        </div>

        {/* <StyledSpanHeader style={{ 'display':"flex"}}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography key="falseCase" style={{'textTransform':'uppercase'}}>RC</Typography>
                        <Switch
                            key={"SwitchStyle"}
                            name={"SwitchStyle"}
                            onChange={HandleChange}
                            checked={booleanIdentite}
                        />
                        <Typography key="trueCase" style={{'textTransform':'uppercase'}}>MOOV</Typography>
                    </Stack>
                </StyledSpanHeader> */}
      </div>
    </StyledHeaderHeader>
  );
};

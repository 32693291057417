export const ClientRoutes = Object.freeze({
    ACCUEIL_EC:        '/EspaceClient/',
    AUTHENTIFICATION:  'Authentification', // FIXME chemin incomplet
    VUE_ARTICLE:       '/EspaceClient/VueArticle/',
    VUE_OFFRE:         '/EspaceClient/VueOffre/',
    VUE_COMMANDE:      '/EspaceClient/VueCommande/',
    VUE_CONTACT:       '/EspaceClient/VueContact/',
    VUE_NC:            '/EspaceClient/VueNC/',
    VUE_SERVICE:       '/EspaceClient/VueService/',
    VUE_VISU_COMMANDE: '/EspaceClient/VisuCommande/',
    VUE_VISU_OFFRE:    '/EspaceClient/VisuOffre/'
})

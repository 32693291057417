import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { HeaderInternaute } from "../../redux/vue/HeaderInternaute";
import { FooterInternaute } from "../../redux/vue/FooterInternaute";
import { GetStyle } from "../../helper/Styles";
import { StyledCircularProgressLoading } from "../../redux/vue/styledComponents/StyledIcon";
import { useTranslations } from "../../redux/vue/components/hook/useTranslations";
import { useConfiguration } from "../../redux/vue/components/hook/useConfiguration";
import { StyledDivLoading } from "../../redux/vue/styledComponents/StyledDiv";

export const MainWrapper = () => {
  const { loading: configLoading } = useConfiguration();
  const { loading: translationLoading, t } = useTranslations();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const pdfHeaderRef = useRef(null);
  const pdfBodyRef = useRef(null);

  const setIdentite = () => {
    // TODO marque blanche : setIdentite
    const url = window.location.href;
    const regex = new RegExp("moovp*");
    const site = regex.test(url);
    return site;
  };

  const [booleanIdentite, _] = useState(setIdentite()); // setter jamais utilisé
  const [isPdfInCreation, setIsPdfInCreation] = useState(false);
  const [iframe, setIframe] = useState();
  // FIXME taille largeur écran mobile codée en dur
  const [isMobileFormat, setIsMobileFormat] = useState(window.innerWidth >= 768);

  useEffect(() => {
    setIframe(query.get("iframe"));
  }, []);

  // Update sidebar visibility on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileFormat(window.innerWidth >= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = GetStyle(booleanIdentite);
  const loadingDiv = styles.loadingDiv;
  const logoWaiting = styles.logoWaiting;

  // attendre que la configuration soit chargée
  if (configLoading || translationLoading)
    return <div>{t.status_pending}..</div>

  return (
    <div
    style={{ position: "relative" }}
      // style={{ display: "flex", flexDirection: "column", minHeight: "100vh", backgroundColor: "#F8F9FF" }}
    >
      <div ref={pdfHeaderRef}>
        {!iframe ? (
          <HeaderInternaute
            booleanIdentite={booleanIdentite}
            styles={styles}
            isMobileFormat={isMobileFormat}
            t={t}
          />
        ) : null}
      </div>

      {/* <div style={{ display: "flex", flexGrow: 1 }}>
        <Sidebar />

        <div
          style={{
            flexGrow: 1,
            padding: "20px",
            backgroundColor: "#F8F9FF",
          }}
        > */}
          <div ref={pdfBodyRef}>
            <Outlet
              context={[
                styles,
                t,
                booleanIdentite,
                pdfHeaderRef,
                pdfBodyRef,
                setIsPdfInCreation,
              ]}
            />
          </div>
          {isPdfInCreation ? (
            <StyledDivLoading className={loadingDiv}>
              <StyledCircularProgressLoading
                className={logoWaiting}
                size="1em"
              />
            </StyledDivLoading>
          ) : null}
        {/* </div> */}
      {/* // </div> */}

      <div>{!iframe ? <FooterInternaute styles={styles} /> : null}</div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useOutletContext, useParams, useSearchParams, useNavigate } from "react-router-dom";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { ListeArticle } from "./ListeArticle";
import { useDispatch, useSelector } from "react-redux";
import { ARTICLE } from "../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { selectArticleEC } from "../../../slice/EspaceClientReducer";
import ErrorBoundary from "../../components/ErrorBoundary";
import { controllerDisplayResumeArticleEC } from "./controllerResume/controllerDisplayResumeArticleEC";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

import { StyledH1VuesEC, StyledP } from "../../styledComponents/StyledTextEspaceClient";
import { StyledDivMsgEchec, StyledDivMsgSucces } from "../../styledComponents/StyledDivBackoffice";
import { StyledDivWrapperVues, StyledHrEspaceClientRose } from "../../styledComponents/StyledDivEspaceClient";

export const VueArticle = () => {
  const { clientId } = useAuthentification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { articleId:articleIdParam } = useParams();
  const articleIdUrlParam  = articleIdParam || null;

  const [isArticleByIdSet, setIsArticleByIdSet] = useState(false);
  const [articleId, setArticleId] = useState(null);
  const [referenceToDelete, setReferenceToDelete] = useState();
  const [upToDateAgressionsList, setUpToDateAgressionsList] = useState([]);
  const [upToDateSecteursList, setUpToDateSecteursList] = useState([]);
  const [upToDateFonctionsList, setUpToDateFonctionsList] = useState([]);
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [upToDateTypeMachineList, setUpToDateTypeMachineList] = useState([]);
  const [upToDateMarqueMachineList, setUpToDateMarqueMachineList] = useState([]);

  // TODO optimiser avec un seul useSelector
  const { message } = useSelector((state) => state.message);
  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);
  const { agressionsList } = useSelector((state) => state.agressions);
  const { typeMachineList, marqueMachineList } = useSelector( (state) => state.machines);
  const { currentArticle, familleArticleList } = useSelector((state) => state.espaceClient);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
    isMobileFormat,
  ] = useOutletContext();

  useEffect( () => {
    // compatibilité avec ancienne URL EspaceClient/VueArticle/?art=xxx
    const articleId = searchParams.get('art');
    if (articleId !== null) {
      navigate(ClientRoutes.VUE_ARTICLE + articleId, { replace: true});
    }
  }, [searchParams]);

  useEffect( () => {
    dispatch(selectArticleEC( { clientId, articleId: articleIdUrlParam }));
    setIsArticleByIdSet(articleIdUrlParam !== null);
  }, [ articleIdUrlParam ]);


  useEffect(() => {
    if (!agressionsList)
      return;
    setUpToDateAgressionsList(agressionsList);
  }, [agressionsList]);

  useEffect(() => {
    if (!typeMachineList)
      return;
    setUpToDateTypeMachineList(typeMachineList);
  }, [typeMachineList]);

  useEffect(() => {
    if (!marqueMachineList)
      return;
    setUpToDateMarqueMachineList(marqueMachineList);
  }, [marqueMachineList]);

  useEffect(() => {
    if (!secteursList)
      return;
    setUpToDateSecteursList(secteursList);
  }, [secteursList]);

  useEffect(() => {
    if (!fonctionsList)
      return;
    setUpToDateFonctionsList(fonctionsList);
  }, [fonctionsList]);

  useEffect(() => {
    if (!paysList)
      return;
    setUpToDatePaysList(paysList);
  }, [paysList]);

  const stateUsedForRender = {
    booleanIdentite: false,
    formik: currentArticle.item,
    styles,
    t,
    upToDateAgressionsList,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateFonctionsList,
    upToDateSecteursList,
    upToDatePaysList,
    upToDateArticleByIdList,
    articleId,
    familleArticleList,
    isMobileFormat,
    isArticleByIdSet,
    articleById: currentArticle.item,
    currentArticle
  };

  const actionUsedForRender = {
    dispatch,
    setArticleId,
    setUpToDateArticleByIdList,
    setReferenceToDelete,
    setIsArticleByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  return !isArticleByIdSet ? (
    <StyledDivWrapperVues>
      <div>
        <StyledH1VuesEC>{t.titleSideBarArticles}</StyledH1VuesEC>
        <StyledHrEspaceClientRose></StyledHrEspaceClientRose>
        <StyledP>{t.descrVueArticle}</StyledP>
      </div>
      <div>
        {displayError()}
        <ListeArticle
          state={stateUsedForRender}
          actions={actionUsedForRender}
        />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%" }}>
        {controllerDisplayResumeArticleEC(
          stateUsedForRender,
          actionUsedForRender,
          ARTICLE
        )}
      </div>
    </ErrorBoundary>
  );
};

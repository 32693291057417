/*
 * ArtByOffreItem :
 * Composant JSX affichant une ligne d'article dans une offre
 * Utilisé par : ListeArticleByOffre.js
 */

import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { useTranslations } from "../../components/hook/useTranslations";
import { getBgColorFromStatus } from "../common/EcColor";

const ArtByOffreItem = ({
  id,
  articleReferenceClient,
  articleCode,
  articleDesignation,
  familleCode,
  marque,
  quantite,
  prixUnitaireHT,
  totalHT,
  miseADisposition,
  ligne,
  reponseLigne,
  state: { offreById, familleArticleList },
}) => {
  const navigate = useNavigate();
  const { t, lang } = useTranslations();

  const [columnSize, setColumnSize] = useState(1);

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));

  const noClassNameMobileAndTablette =
    "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  const navigateToArticle = (articleId) => {
    if (articleId) {
      navigate(ClientRoutes.VUE_ARTICLE + articleId);
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={`${style} columnHeaderEC ${
          searchBarStateSetter ? "" : "hasSearch"
        }`}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
          // marginLeft={name === "Action" ? "-0.75rem" : "0"}
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style) => {
    let displayName = name;

    // Vérifier et remplacer les valeurs spécifiques
    if (name === "RC") {
      displayName = "RC Modèles";
    } else if (name === "MP") {
      displayName = "Moovprotect";
    }

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: displayName }}
        />
      </Grid>
    );
  };

  const displayItemArray = (array, md, sm, style, texte = "") => {
    if (array.length === 1) {
      return displayItem(array[0] + " " + texte, md, sm, style);
    }

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>
          {array.map((item, index) => (
            <div key={index + item}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.toString() + " " + texte,
                }}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
              />
              {index < array.length - 1 && (
                <hr className={"hrDdp"} />
              )}
            </div>
          ))}
        </div>
      </Grid>
    );
  };

  const displayBadgeItemArray = (array, md, sm, xs, style, texte = "") => {
    if (array.length === 1) {
      return displayBadgeResponse(array[0], md, sm, xs, style);
    }

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>
          {array.map((item, index) => (
            <Fragment key={index + item}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
              >
                <Badge
                  style={{
                    wordBreak: "break-all",
                    backgroundColor: getBgColorFromStatus(item.toString()),
                    color: "white",
                    marginTop: "0.25rem",
                    marginBottom: "0.25rem",
                    paddingLeft: "0.4rem",
                    paddingRight: "0.4rem",
                    borderRadius: "0.25rem",
                    fontSize: "0.75rem",
                  }}
                >
                  {item.toString() + " " + texte}
                </Badge>
              </div>
              {index < array.length - 1 && <hr className={"hrDdp"} />}
            </Fragment>
          ))}
        </div>
      </Grid>
    );
  };

  const displayArticleCode = (articleId, name, md, sm, xs, style) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {articleId ? (
          // Si articleId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={() => navigateToArticle(articleId) } // Ne pas invoquer directement la fonction
          />
        ) : (
          // Si articleId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  const displayBadgeResponse = (response, md, sm, xs, style) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Badge
          style={{
            wordBreak: "break-all",
            backgroundColor: getBgColorFromStatus(response),
            color: "white",
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
            paddingLeft: "0.4rem",
            paddingRight: "0.4rem",
            borderRadius: "0.25rem",
            fontSize: "0.75rem",
          }}
        >
          {response}
        </Badge>
      </Grid>
    );
  };

  const displayFamillArticle = (array, field, md, sm, style) => {

    const getTranslation = (code) => {
      const entry = array.find((e) => e.code === code);
      return entry?.[lang] ?? code;
    };

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={0}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{
            wordBreak: "break-word",
            justify: "center",
            alignItems: "center",
          }}
        >
          { getTranslation(field) }
        </div>
      </Grid>
    );
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : null}
    >
      {/* colonne n°1 - Numéro ligne*/}
      {id === 0
        ? displayItemHeader(ligne, null, null, 0.5, 0.5, 4, noClassNameMobile)
        : displayItem(ligne, 0.5, 0.5, noClassNameMobile)}

      {/* colonne n°2 - Code article */}
      {id === 0
        ? displayItemHeader(
            articleCode,
            null,
            null,
            1.25,
            1.25,
            8,
            alwaysDisplayed
          )
        : displayArticleCode(
            offreById[0].articleId,
            articleCode,
            1.25,
            1.25,
            8,
            alwaysDisplayed
          )}

      {/* colonne n°3 - Code article */}
      {id === 0
        ? displayItemHeader(
            articleReferenceClient,
            null,
            null,
            1,
            1,
            4,
            noClassNameMobile
          )
        : displayItem(articleReferenceClient, 1, 1, noClassNameMobile)}

      {/* colonne n°4 - Désignation */}
      {id === 0
        ? displayItemHeader(
            articleDesignation,
            null,
            null,
            1.75,
            1.75,
            4,
            noClassNameMobile
          )
        : displayItem(articleDesignation, 1.75, 1.75, noClassNameMobile)}

      {/* colonne n°5 - Famille */}
      {id === 0
        ? displayItemHeader(
            familleCode,
            null,
            null,
            1.5,
            1.5,
            null,
            noClassNameMobile
          )
        : displayFamillArticle(
            familleArticleList,
            familleCode,
            1.5,
            1.5,
            noClassNameMobile
          )}

      {/* colonne n°6 - Marque */}
      {id === 0
        ? displayItemHeader(
            marque,
            null,
            null,
            1,
            1,
            8,
            noClassNameMobileAndTablette
          )
        : displayItem(marque, 1, 1, noClassNameMobileAndTablette)}

      {/* colonne n°7 - Quantité */}
      {id === 0
        ? displayItemHeader(
            quantite,
            null,
            null,
            1,
            1,
            8,
            noClassNameMobileAndTablette
          )
        : displayItemArray(quantite, 1, 1, noClassNameMobileAndTablette)}

      {/* colonne n°8 - Prix unitaire */}
      {id === 0
        ? displayItemHeader(
            prixUnitaireHT,
            null,
            null,
            1,
            1,
            8,
            noClassNameMobileAndTablette
          )
        : displayItemArray(
            prixUnitaireHT,
            1,
            1,
            noClassNameMobileAndTablette,
            "€"
          )}

      {/* colonne n°9 - Prix total */}
      {id === 0
        ? displayItemHeader(
            totalHT,
            null,
            null,
            1,
            1,
            8,
            noClassNameMobileAndTablette
          )
        : displayItemArray(totalHT, 1, 1, noClassNameMobileAndTablette, "€")}

      {/* colonne n°10 - Délais */}
      {id === 0
        ? displayItemHeader(
            miseADisposition,
            null,
            null,
            1,
            1,
            4,
            noClassNameMobileAndTablette
          )
        : displayItemArray(
            miseADisposition,
            1,
            1,
            noClassNameMobileAndTablette,
            t.semaineAvantDispo
          )}

      {/* colonne n°11 - Réponse */}
      {id === 0
        ? displayItemHeader(reponseLigne, null, null, 1, 1, 4, "")
        : displayBadgeItemArray(reponseLigne, 1, 1, 4, "")}
    </Grid>
  );
};

export { ArtByOffreItem };

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { BoutonRetourEC } from "../../components/espaceClient/BoutonAccueilEC";
import {
  StyledContentVueDetailsEC,
  StyledH1VuesEC,
  StyledTitleVueDetailsEC,
} from "../../styledComponents/StyledTextEspaceClient";
import { StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { LoadingMessage, LoadingErrorMessage } from "../../components/LoadingMessageComponents.jsx";
import { StyledPaperBodyEC } from "../../styledComponents/StyledPaper";
import {
  StyledDivSectionsRowRecap,
  StyledDivSectionVueDetailOrangeEC,
  StyledDivVueDetailsEC,
  StyledDivVueDetailsGeneBasicEC,
  StyledDivVueDetailsGeneBasicReverseEC,
} from "../../styledComponents/StyledDiv";
import { StyledSpanTitleVueDetailEC } from "../../styledComponents/StyledSpan";
import { useTranslations } from "../../components/hook/useTranslations";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { ApiStatus } from '../../../../constantes/ApiStatus';

export const DetailsNC = ({ t, state: { currentNC} }) => {
  const ncById = currentNC.item;
  const navigate = useNavigate();

  const translation = useTranslations();
  const lang = translation.lang;

  const { etatNCList } = useSelector((state) => state.espaceClient);

  const triggerHandleRetour = () => {
    navigate(ClientRoutes.VUE_NC);
  };

  const navigateToArticle = (articleId) => {
    if (articleId) {
      navigate(ClientRoutes.VUE_ARTICLE + articleId);
    }
  };

  const displayEtatNC = (field) => {
    const etatItem = etatNCList.list.find((e) => e.etatFNCCode === field);
    const backgroundColor = etatItem ? etatItem.HEX : "#FFFFFF"; // Default to white if not found

    return (
      <StyledContentVueDetailsEC>
        <p
          style={{
            color: "white",
            wordBreak: "break-word",
            justify: "center",
            alignItems: "center",
            backgroundColor: backgroundColor,
            padding: "5px",
            borderRadius: "5px",
            margin: "0",
          }}
        >
          { etatItem?.[lang] ?? field }
        </p>
      </StyledContentVueDetailsEC>
    );
  };

  let nc = null;
  if (ncById instanceof Array && ncById.length > 0)
    nc = ncById[0];

  if (currentNC.apiStatus === ApiStatus.IDLE)
    return <LoadingMessage t={t} />;

  if (currentNC.apiStatus === ApiStatus.ERROR)
    // TODO améliorer le rendu
    return <>
      <LoadingErrorMessage t={t} errorMessage={currentNC.apiError}/>
      <BoutonRetourEC texte={t.retourListeNC} action={triggerHandleRetour} backgroundColor={"#F8F9FF"} />
    </>;


  return (
    <div style={{ padding: "2rem" }}>
      <BoutonRetourEC texte={t.retourListeNC} action={triggerHandleRetour} backgroundColor={"#F8F9FF"} />
      {nc ? (
        <div>
          <StyledH1VuesEC>
            {t.detailsNC} : {nc["FNCCode"]}
          </StyledH1VuesEC>
          <StyledHrEspaceClient></StyledHrEspaceClient>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC>
              <StyledSpanTitleVueDetailEC>
                {t.informationsGenerales}
              </StyledSpanTitleVueDetailEC>
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneBasicEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>{t.reference} : </span>

                      <StyledContentVueDetailsEC>
                        {nc["FNCLibelle"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {" "}
                        {t.dateDeclaration} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {nc["FNCDate"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>{t.statut} : </span>
                      {displayEtatNC(nc["etatFNC"])}
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneBasicEC>
                </StyledDivSectionVueDetailOrangeEC>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneBasicReverseEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.articleDesignation} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {nc["articleDesignation"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.votreCodeArticle} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {nc["articleReferenceClient"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.codeArticleRCMoov} :{" "}
                      </span>

                      <StyledContentVueDetailsEC
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigateToArticle(nc["articleId"])
                        }
                      >
                        {nc["articleCode"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneBasicReverseEC>
                </StyledDivSectionVueDetailOrangeEC>
              </StyledDivSectionsRowRecap>
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          {nc["FNCConstat"] !== "" ? (
            <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
              <StyledDivVueDetailsEC>
                <StyledSpanTitleVueDetailEC>
                  {t.constat}
                </StyledSpanTitleVueDetailEC>
                <br />
                <StyledContentVueDetailsEC
                  dangerouslySetInnerHTML={{ __html: nc["FNCConstat"] }}
                />
              </StyledDivVueDetailsEC>
            </StyledPaperBodyEC>
          ) : null}
          {nc["commentaireEspaceClient"] !== "" ? (
            <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
              <StyledDivVueDetailsEC>
                {/* Jansens : J'ai encapsulé les StyledSpanTitleVueDetailEC et StyledContentVueDetailsEC dans des div
                    pour résoudre rapidement le problème de retour à la ligne, et pour éviter de modifier les composants
                    concernés en risquant de changer d'autres comportements ailleurs.
                    La solution n'est pas idéale, il faudra la modifier un jour.
                */}
                <div>
                  <StyledSpanTitleVueDetailEC>
                    {t.decision}
                  </StyledSpanTitleVueDetailEC>
                </div>
                <div>
                  <StyledContentVueDetailsEC>
                    {nc["commentaireEspaceClient"]}
                  </StyledContentVueDetailsEC>
                </div>
              </StyledDivVueDetailsEC>
            </StyledPaperBodyEC>
          ) : null}
        </div>
      ) : (
        <LoadingMessage t={t} />
      )}
    </div>
  );
};

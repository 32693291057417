import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from "../redux/vue/components/hook/useTranslations";
import { useConfiguration } from "../redux/vue/components/hook/useConfiguration";

import { loadConfiguration } from "../redux/slice/ConfigReducer";
import { initializeTranslation } from "../redux/slice/i18nReducer";

// Vues
import { Accueil } from "../redux/vue/Accueil";
import { Profil } from "../redux/vue/Profil";
import Developpement from '../Developpement';

import { AjouterUtilisateur } from "../redux/vue/controllerFormulaires/AjouterUtilisateur";
import { ModifierCollaborateur } from "../redux/vue/controllerFormulaires/ModifierCollaborateur";
import { ModifierSonProfil } from "../redux/vue/controllerFormulaires/ModifierSonProfil";
import EcranErreurCritique from "../redux/vue/EcranErreurCritique";

// Logique d'authentification
import { AuthentificationForm } from "../redux/vue/AuthentificationForm";
import { AuthentificationRoute } from "./component-HOC/AuthentificationRoute";
import { AuthentificationFormEC } from "../redux/vue/espaceClient/AuthentificationFormEC";
import { AuthentificationECRoute } from "./component-HOC/AuthentificationECRoute";

// Constantes
import { CollaborateurRoutes } from "../constantes/routes/CollaborateurRoutes";
import { AdminRoutes } from "../constantes/routes/AdminRoutes";
import { Permissions } from "../constantes/routes/Permissions";
import { GestionDesComptes } from "../redux/vue/GestionDesComptes";
import { Contact } from "../redux/vue/Contact";
import { Rechercher } from "../redux/vue/Rechercher";
import { Configurateur } from "../redux/vue/formulaires/Configurateur";
import { PolitiqueDeConfidentialite } from "../redux/vue/PolitiqueDeConfidentialite";
import { MentionsLegales } from "../redux/vue/MentionsLegales";

// composants de haut niveau
import MasterWrapper from "./component-HOC/MasterWrapper";
import { MainWrapper } from "./component-HOC/MainWrapper";
import { EmployeeWrapper } from "./component-HOC/EmployeeWrapper";
import { ClientWrapper } from "./component-HOC/ClientWrapper";
import { PublicClientWrapper } from "./component-HOC/PublicClientWrapper";

import { DemandeDePrix } from "../redux/vue/DemandeDePrix";
import { OffreDePrix } from "../redux/vue/OffreDePrix";
import { Article } from "../redux/vue/Article";
import { ContactSOS } from "../redux/vue/ContactSOS";

import BilanTraductions from '../redux/vue/BilanTraductions.jsx';

import { GuestRoutes } from "../constantes/routes/GuestRoutes";
import { ClientRoutes } from "../constantes/routes/ClientRoutes";

import { AccueilEC } from "../redux/vue/espaceClient/AccueilEC";
import { VueArticle } from "../redux/vue/espaceClient/vueArticle/VueArticle";
import { VueOffre } from "../redux/vue/espaceClient/vueOffre/VueOffre";
import { VueCommande } from "../redux/vue/espaceClient/vueCommande/VueCommande";
import { VueContact } from "../redux/vue/espaceClient/vueContact/VueContact";
import { VueNC } from "../redux/vue/espaceClient/vueNC/VueNC";
import { VueService } from "../redux/vue/espaceClient/vueService/VueService";

import { VueVisuCommande } from "../redux/vue/espaceClient/vueCommande/VueVisuCommande.jsx";
import { VueVisuOffre } from "../redux/vue/espaceClient/vueOffre/VueVisuOffre.jsx";

const App = () => {
  const location = useLocation();
  const { loading: configLoadingProcessing, error: configLoadError, parameters } = useConfiguration();
  const { loading: i18nLoadingProcessing, error: i18nLoadingError, setLang } = useTranslations();

  const [trackingId, setTrackingId] = useState(); // pour Google Analytics
  const [urlHistory, setUrlHistory] = useState([]);

  const dispatch = useDispatch();

  // 1ère étape : chargement de la configuration
  useEffect( () => {
    dispatch(loadConfiguration());
  }, [dispatch]);

  // 2ème étape : chargement des traductions
  useEffect( () => {
    if (configLoadingProcessing)
      return;
    if (configLoadError !== null)
      return;
    dispatch(initializeTranslation());
  }, [dispatch, configLoadingProcessing, parameters]);


  useEffect(() => {
    setUrlHistory([...urlHistory, location]);
  }, [location]);

  useEffect(() => {
    if (!trackingId)
      return;

    const env = process.env.NODE_ENV;
    if (env !== 'production') {
      console.log(`Google Analytics désactivé (${env})`);
      return;
    }
    console.log("Google Analytics tracking Id :", trackingId);
    ReactGA.initialize([
      {
        trackingId: trackingId,
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      }
    ]);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [trackingId, location]);

  // Détecter les connexions à l'espace client pour changer la langue en fonction du profile utilisateur
  const { detailsClient } = useSelector((state) => state.espaceClient );
  useEffect( () => {
    let lang = detailsClient?.codeLangue;
    if (! lang)
      return;
    lang = lang.toUpperCase();
    setLang(lang);
  }, [detailsClient]);


  // afficher un message d'erreur en cas d'erreur de chargement de config.json
  if ( configLoadingProcessing === false && configLoadError != null) {
    return (<EcranErreurCritique>
        <div>Erreur de chargement du paramétrage</div>
        <div>{configLoadError}</div>
      </EcranErreurCritique>);
  }

  // afficher un message d'erreur en cas d'erreur de chargement des traductions
  if ( i18nLoadingProcessing === false && i18nLoadingError != null) {
    return (<EcranErreurCritique>
        <div>Erreur de chargement des traductions</div>
        <div>{i18nLoadingError}</div>
      </EcranErreurCritique>);
  }

  return (
    <MasterWrapper setTrackingId={setTrackingId}>
      <Routes>
        <Route path={'/developpement'} element={<Developpement/>} />

        <Route path="/*" element={<MainWrapper/>}>
          {/* public routes */}
          <Route index element={<Accueil />} />
          <Route path={GuestRoutes.MENTIONS_LEGALES} element={<MentionsLegales />} />
          <Route path={GuestRoutes.NOUS_CONTACTER} element={<Contact />} />
          <Route path={GuestRoutes.SOS} element={<ContactSOS />} />
          <Route path={GuestRoutes.RECHERCHER} element={<Rechercher />} />
          <Route path={GuestRoutes.CONFIGURATEUR} element={<Configurateur />} />
          <Route path={GuestRoutes.CONFIGURATEUR + "/:famille"} element={<Configurateur />} />
          <Route path={GuestRoutes.CONFIGURATEUR + "/:value"} element={<Configurateur />} />
          <Route path={GuestRoutes.POLITIQUE_DE_CONFIDENTIALITE} element={<PolitiqueDeConfidentialite />} />
          <Route path={GuestRoutes.VUE_ARTICLE} element={<Article />} />
          <Route path={GuestRoutes.BILAN_TRADUCTION} element={<BilanTraductions/>} />
        </Route>

        <Route path="/*" element={<EmployeeWrapper />}>
          {/* protected routes collaborateur minimum level */}
          <Route element={<AuthentificationRoute permissions={[Permissions.COLLABORATEUR, Permissions.ADMIN]} />}>
            <Route path={CollaborateurRoutes.PROFIL} element={<Profil />} />
            <Route path={CollaborateurRoutes.MODIFIER_SON_PROFIL} element={<ModifierSonProfil />} />
            <Route path={CollaborateurRoutes.DDP} element={<DemandeDePrix />} />
            <Route path={CollaborateurRoutes.ODP} element={<OffreDePrix />} />
            <Route path={CollaborateurRoutes.ART} element={<Article />} />
          </Route>

          {/* protected routes admin only */}
          <Route element={<AuthentificationRoute permissions={[Permissions.ADMIN]} />} >
            <Route path={AdminRoutes.INSCRIPTION} element={<AjouterUtilisateur />} />
            <Route path={AdminRoutes.GESTION_COMPTES} element={<GestionDesComptes />} />
            <Route path={AdminRoutes.MODIFIER_COLLABORATEUR + "/:id"} element={<ModifierCollaborateur />} />
          </Route>
        </Route>

        <Route path="/*">
          <Route path={GuestRoutes.AUTHENTIFICATION} element={<AuthentificationForm history={urlHistory} />} />
        </Route>

        <Route path={`${ClientRoutes.ACCUEIL_EC}*`}>
          <Route path={ClientRoutes.AUTHENTIFICATION} element={<AuthentificationFormEC history={urlHistory} />} />
          <Route path={ClientRoutes.AUTHENTIFICATION + "/:login"} element={<AuthentificationFormEC history={urlHistory} />}/>
        </Route>

        <Route element={<ClientWrapper/>}>
          {/* protected routes minimum level */}
          <Route element={<AuthentificationECRoute/>}>
            <Route path={ClientRoutes.ACCUEIL_EC}   element={<AccueilEC/>}   />
            <Route path={ClientRoutes.VUE_ARTICLE}                  element={<VueArticle/>}  />
            <Route path={ClientRoutes.VUE_ARTICLE  + ":articleId"}  element={<VueArticle/>}  />
            <Route path={ClientRoutes.VUE_OFFRE}                    element={<VueOffre/>}    />
            <Route path={ClientRoutes.VUE_OFFRE    + ":offreId"}    element={<VueOffre/>}    />
            <Route path={ClientRoutes.VUE_COMMANDE}                 element={<VueCommande/>} />
            <Route path={ClientRoutes.VUE_COMMANDE + ":commandeId"} element={<VueCommande/>} />
            <Route path={ClientRoutes.VUE_NC}                       element={<VueNC/>}       />
            <Route path={ClientRoutes.VUE_NC       + ':ncId'}       element={<VueNC/>}       />
            <Route path={ClientRoutes.VUE_CONTACT}                  element={<VueContact/>}  />
            <Route path={ClientRoutes.VUE_CONTACT  + ':contactId'}  element={<VueContact/>}  />
            <Route path={ClientRoutes.VUE_SERVICE}  element={<VueService/>}  />
          </Route>
        </Route>
        {/* routes publiques de l'espace client (sans authentification) */}
        <Route element={<PublicClientWrapper/>}>
          <Route path={ClientRoutes.VUE_VISU_COMMANDE + ':clientId/:commandeId'} element={<VueVisuCommande/>} />
          <Route path={ClientRoutes.VUE_VISU_OFFRE    + ':clientId/:offreId'} element={<VueVisuOffre/>} />
        </Route>

      </Routes>
    </MasterWrapper>
  );
};

export { App };

import axios from "axios";

import { Serveur } from "../../constantes/services/Serveur";
import { Authentification } from "../../constantes/services/Authentification";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";
import { jsonPost } from '../../helper/fetchUtil';


const connexionEC = async (clientCode, clientPassword) => {
  try {
    const LOGIN_ENDPOINT = Serveur.URL_API + Authentification.CONNEXION_EC;
    const data = {
      clientCode,
      clientPassword,
    };
    const json = await jsonPost(LOGIN_ENDPOINT, data);

    if (json.login === false )
      throw new Error("Identifiant ou mot de passe invalide");

    for (const prop of [ 'code', 'accesEC', 'clientId', 'accessTokenEC' ]) {
      if (json[prop] === undefined)
        throw new Error(`Réponse API invalide (propriété «${prop}» introuvable)`);
    }

    if (json.code !== 200)
      throw new Error(`Retour en erreur de l'API : code=${json.code}`);

    if (json.accesEC !== 'Vrai')
      throw new Error("Accès non autorisé par l'API");

    const clientId = json.clientId;
    localStorage.setItem(LocalStorage.CLIENT_ID, clientId);

    const rawToken = json.accessTokenEC;
    localStorage.setItem(LocalStorage.ACCESS_TOKEN_EC, rawToken);

    /* FIXME décodage inutile à supprimer :
      import { jwtDecode } from "jwt-decode";
      const token = jwtDecode(rawToken);
     */
    return { clientId, token: rawToken };
  }
  catch (error) {
    localStorage.removeItem(LocalStorage.CLIENT_ID);
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN_EC);
    throw error;
  }
};


const rafraichissementEC = async () => {
  try {
    let clientId = localStorage.getItem(LocalStorage.CLIENT_ID);
    let rawToken = localStorage.getItem(LocalStorage.ACCESS_TOKEN_EC);

    if (clientId === null || rawToken === null)
      throw new Error('Stockage authentification incohérent');

    const data = {
      accessTokenEC: rawToken
    };

    const REFRESH_ENDPOINT = Serveur.URL_API + Authentification.RAFRAICHISSEMENT_EC;
    const json = await jsonPost(REFRESH_ENDPOINT, data);

    for (const prop of [ 'code', 'accessTokenEC' ]) {
      if (json[prop] === undefined)
        throw new Error(`Réponse API invalide (propriété «${prop}» introuvable)`);
    }

    if (json.code !== 200)
      throw new Error(`Retour en erreur de l'API : code=${json.code}`);

    rawToken = json.accessTokenEC;
    localStorage.setItem(LocalStorage.ACCESS_TOKEN_EC, rawToken);

    return { token: rawToken };
  }
  catch (error) {
    localStorage.removeItem(LocalStorage.CLIENT_ID);
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN_EC);
    throw error;
  }
};

const deconnexionEC = () => {
  localStorage.removeItem(LocalStorage.ACCESS_TOKEN_EC);
  localStorage.removeItem(LocalStorage.CLIENT_ID);
};

const updateTokenEC = (rawToken) => {
  if (rawToken)
    localStorage.setItem(LocalStorage.ACCESS_TOKEN_EC, rawToken);
  else
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN_EC);
};

const getAccessTokenEC = () => {
  return localStorage.getItem(LocalStorage.ACCESS_TOKEN_EC);
};

const getClientId = () => {
  return localStorage.getItem(LocalStorage.CLIENT_ID);
};


const connexion = async (email, motDePasse) => {
  const LOGIN_ENDPOINT = Serveur.URL_API + Authentification.CONNEXION;
  const data = {
    email,
    motDePasse,
  };
  const reponse = await axios.post(LOGIN_ENDPOINT, data);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  }
  return reponse;
};

const rafraichissement = async (data) => {
  const REFRESH_ENDPOINT = Serveur.URL_API + Authentification.RAFRAICHISSEMENT;
  const reponse = await axios.post(REFRESH_ENDPOINT, data);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
  return reponse;
};


const deconnexion = () => {
  localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
};


const getAccessToken = () => {
  localStorage.getItem(LocalStorage.ACCESS_TOKEN);
};


const authentificationService = {
  connexion,
  rafraichissement,
  deconnexion,
  getAccessToken,
  connexionEC,
  rafraichissementEC,
  deconnexionEC,
  updateTokenEC,
  getAccessTokenEC,
  getClientId
};

export default authentificationService;

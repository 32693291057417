import { Grid } from "@mui/material";
import { importAllSpec } from "../../../../helper/ImportAll";

import { DessinTechnique } from "../../components/DessinTechnique";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  DIMENSIONS_PLIS,
  NOMBRE_FACE,
  S06_LONGUEUR_A_EXT,
  S06_LONGUEUR_A_INT,
  S06_LONGUEUR_B_EXT,
  S06_LONGUEUR_B_INT,
  S06_LONGUEUR_C_EXT,
  S06_LONGUEUR_C_INT,
} from "../../../../constantes/symbols/SymbolsS06";
import { S06_STEP5_arrayDimensionsPlis, S06_STEP5_arrayNombreFaces } from "../../../../constantes/symbols/SymbolsServicesS06";
import { equalsCodeOrLabel } from "../../../../constantes/symbols/HelperSymbolsServices";
import {
  formulaireDimensionsPart1S06,
  formulaireDimensionsPart2S06,
  formulaireLongueurCExt,
  formulaireLongueurCInt,
} from "../../ChampsFormulaires/S04_06/etape5/ChampsDimensionsCorpsS06";
import { useEffect, useState } from "react";
import { anchorCheck } from "../../../../helper/Anchors";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";
import { nativeLang } from '../../../../config/i18nConfig.js';

export const StepS06BodyDim = ({
  state: {
    booleanIdentite,
    etape,
    styles,
    styles: {
      cssFontFamily,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const translation = useTranslations();
  const lang = translation.lang;


  const longueurAInt = formik.values[S06_LONGUEUR_A_INT.description];
  const longueurBInt = formik.values[S06_LONGUEUR_B_INT.description];
  const longueurCInt = formik.values[S06_LONGUEUR_C_INT.description];
  const code = formik.values[DIMENSIONS_PLIS.description];
  const dimensionsPlis = parseInt(S06_STEP5_arrayDimensionsPlis().find(item => item.code === code)?.label) || "";
  const nombreFace = formik.values[NOMBRE_FACE.description];

  useEffect(() => {
    let calculatedLengthAExt;
    if (nombreFace === S06_STEP5_arrayNombreFaces(t)[1].code) {
      calculatedLengthAExt = longueurAInt + 2 * (dimensionsPlis || 0);
    } else {
      calculatedLengthAExt = longueurAInt + 1 * (dimensionsPlis || 0);
    }

    formik.setFieldValue(S06_LONGUEUR_A_EXT.description, calculatedLengthAExt);
  }, [longueurAInt, formik.values[DIMENSIONS_PLIS.description], nombreFace]);

  useEffect(() => {
    let calculatedLengthBExt;

    calculatedLengthBExt = longueurBInt + 2 * (dimensionsPlis || 0);

    formik.setFieldValue(S06_LONGUEUR_B_EXT.description, calculatedLengthBExt);
  }, [longueurBInt, formik.values[DIMENSIONS_PLIS.description]]);

  useEffect(() => {
    let calculatedLengthCExt;

    calculatedLengthCExt = longueurCInt + 1 * (dimensionsPlis || 0);

    formik.setFieldValue(S06_LONGUEUR_C_EXT.description, calculatedLengthCExt);
  }, [longueurCInt, formik.values[DIMENSIONS_PLIS.description]]);

  // useEffect(() => {
  //   if (longueurAInt > 1000) {
  //     const updatedDimensionsPlis = S06_STEP5_arrayDimensionsPlis(t).filter(option => option.code !== 0);
  //     // formik.setFieldValue(DIMENSIONS_PLIS.description, updatedDimensionsPlis);
  //   }
  // }, [longueurAInt, formik.values[NOMBRE_FACE.description], formik.values[DIMENSIONS_PLIS.description]]);



  useEffect(() => {
    anchorCheck(formik.errors);
  }, [formik.isSubmitting]);

  const afficherLesChampsDimensions = () => {
    if (
      equalsCodeOrLabel(
        formik.values[NOMBRE_FACE.description],
        S06_STEP5_arrayNombreFaces(),
        0
      )
    ) {
      return (
        <>
          {genererFormulaire(formulaireDimensionsPart1S06(styles, t), formik)}
          {genererFormulaire(formulaireLongueurCInt(styles, t), formik)}
          {genererFormulaire(
            formulaireDimensionsPart2S06(styles, t, formik),
            formik
          )}
          {genererFormulaire(formulaireLongueurCExt(styles, t, formik), formik)}
        </>
      );
    } else {
      return (
        <>
          {genererFormulaire(formulaireDimensionsPart1S06(styles, t), formik)}
          {genererFormulaire(
            formulaireDimensionsPart2S06(styles, t, formik),
            formik
          )}
        </>
      );
    }
  };

  const afficherDessinTechnique = () => {
    return (
      <DessinTechnique
        formik={formik.values}
        styles={styles}
        etape={etape}
        t={t}
        isFrom={"Configurateur"}
      />
    );
  };

  const afficherSOSetForm = () => {
    if (lang !== nativeLang)
      return <></>;
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyRenseignezToutesLesDimensionsSouffletPlis}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {afficherLesChampsDimensions()}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={'cssButton'}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Pagination } from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import { CommandeItem } from "./CommandeItem";
import { apiStatusMessageForList } from "../../../../helper/apiStatusHelper.js";

import { StyledDivPagination } from "../../styledComponents/StyledDivBackoffice";
import { StyledGridLignesTabRoseEC } from "../../styledComponents/StyledGridBackoffice";

export const ListeCommande = ({ actions, state }) => {
  const { t } = useTranslations();

  const [listMessage, setListMessage] = useState(null);
  const [searchBarContentCodeOffre, setSearchBarContentCodeOffre] = useState();
  const [searchBarContentReference, setSearchBarContentReference] = useState();
  const [searchBarContentDateCreation, setSearchBarContentDateCreation] = useState();
  const [searchBarContentLigne, setSearchBarContentLigne] = useState();
  const [searchBarContentContact, setSearchBarContentContact] = useState();
  const [searchBarContentCodeCommande, setSearchBarContentCodeCommande] = useState();
  const [searchBarContentTotalHT, setSearchBarContentTotalHT] = useState();
  const [searchBarContentEtatLivr, setSearchBarContentEtatLivr] = useState();
  const [searchBarContentEtatFact, setSearchBarContentEtatFact] = useState();

  const [sortedCodeOffre, setSortedCodeOffre] = useState();
  const [sortedReference, setSortedReference] = useState();
  const [sortedDateCreation, setSortedDateCreation] = useState("down");
  const [sortedLigne, setSortedLigne] = useState();
  const [sortedCodeCommande, setSortedCodeCommande] = useState();
  const [sortedContact, setSortedContact] = useState();
  const [sortedTotalHT, setSortedTotalHT] = useState();
  const [sortedTypeCommande, setSortedTypeCommande] = useState();
  const [sortedEtatLivr, setSortedEtatLivr] = useState();
  const [sortedEtatFact, setSortedEtatFact] = useState();

  const { commandeList } = useSelector((state) => state.espaceClient);
  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  const defineFilteredList = (searchBarContent, key) => {
    return commandeList.list.filter((item) => {
      if (searchBarContent === undefined) {
        return item;
      } else {
        if (item[key].toLowerCase().includes(searchBarContent.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      }
    });
  };

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...commandeList.list].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...commandeList.list].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    setListMessage(apiStatusMessageForList(commandeList));
  }, [commandeList.list, commandeList.apiStatus]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentCodeOffre, "offrePrixCode")
    );
  }, [searchBarContentCodeOffre, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentReference, "reference"));
  }, [searchBarContentReference, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentDateCreation, "dateCreation")
    );
  }, [searchBarContentDateCreation, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentLigne, "ligne"));
  }, [searchBarContentLigne, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentContact, "contact"));
  }, [searchBarContentContact, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentCodeCommande, "commandeCode")
    );
  }, [searchBarContentCodeCommande, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentTotalHT, "totalHT"));
  }, [searchBarContentTotalHT, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentEtatLivr, "livr"));
  }, [searchBarContentEtatLivr, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentEtatFact, "fact"));
  }, [searchBarContentEtatFact, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }

    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split("/");
      return new Date(year, month - 1, day);
    };

    if (sortedDateCreation === "up") {
      setFilteredList(
        [...commandeList.list].sort(
          (a, b) => parseDate(a.dateCreation) - parseDate(b.dateCreation)
        )
      );
    }
    if (sortedDateCreation === "down") {
      setFilteredList(
        [...commandeList.list].sort(
          (a, b) => parseDate(b.dateCreation) - parseDate(a.dateCreation)
        )
      );
    }
  }, [sortedDateCreation, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedCodeOffre, "offrePrixCode");
  }, [sortedCodeOffre, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedReference, "reference");
  }, [sortedReference, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedLigne, "ligne");
  }, [sortedLigne, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedContact, "contact");
  }, [sortedContact, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedCodeCommande, "commandeCode");
  }, [sortedCodeCommande, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedTotalHT, "totalHT");
  }, [sortedTotalHT, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedTypeCommande, "typeCommandeCode");
  }, [sortedTypeCommande, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedEtatLivr, "livr");
  }, [sortedEtatLivr, commandeList.list]);

  useEffect(() => {
    if (!commandeList.list) {
      return;
    }
    defineSortedList(sortedEtatFact, "fact");
  }, [sortedEtatFact, commandeList.list]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (_, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeCommande = pagination.currentData.map((item) => {
    return (
      <CommandeItem
        key={item.commandeId}
        id={item.commandeId}
        state={state}
        {...item}
        searchBarAction={{
          setSearchBarContentCodeOffre,
          setSearchBarContentReference,
          setSearchBarContentDateCreation,
          setSearchBarContentLigne,
          setSearchBarContentContact,
          setSearchBarContentCodeCommande,
          setSearchBarContentTotalHT,
          setSearchBarContentEtatLivr,
          setSearchBarContentEtatFact,
        }}
        sortAction={{
          setSortedCodeOffre,
          setSortedReference,
          setSortedDateCreation,
          setSortedLigne,
          setSortedCodeCommande,
          setSortedContact,
          setSortedTotalHT,
          setSortedTypeCommande,
          setSortedEtatLivr,
          setSortedEtatFact,
        }}
        modale={actions}
      />
    );
  });

  return (
    <div>
      <StyledGridLignesTabRoseEC
        container
        style={{
          boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        }}
      >
        <CommandeItem
          key={0}
          id={0}
          action={t.titleAction}
          reference={t.titleTabReference}
          offrePrixCode={t.titleTabCodeOffre}
          dateCreation={t.titleTabDateCreation}
          ligne={t.titleTabNombreLigne}
          contact={t.titleTabContact}
          rep={t.titleTabReponse}
          commandeCode={t.titleTabCodeCommande}
          livr={t.titleTabLivraison}
          fact={t.titleTabFacturation}
          totalHT={t.titleTabTotalHT}
          commandeId={"commandeId"}
          articleEtatValidation={"articleEtatValidation"}
          state={state}
          searchBarAction={{
            setSearchBarContentCodeOffre,
            setSearchBarContentReference,
            setSearchBarContentDateCreation,
            setSearchBarContentContact,
            setSearchBarContentCodeCommande,
            setSearchBarContentTotalHT,
            setSearchBarContentEtatLivr,
            setSearchBarContentEtatFact,
          }}
          sortAction={{
            setSortedCodeOffre,
            setSortedReference,
            setSortedDateCreation,
            setSortedLigne,
            setSortedCodeCommande,
            setSortedContact,
            setSortedTotalHT,
            setSortedTypeCommande,
            setSortedEtatLivr,
            setSortedEtatFact,
          }}
          modale={actions}
        />
        { listMessage !== null ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1rem",
            }}
          >
            <Grid container item direction="row" className={"row"}>
              {listMessage}
            </Grid>
          </div>
        ) : (
          afficherListeCommande
        )}
      </StyledGridLignesTabRoseEC>
      <StyledDivPagination>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPagination>
    </div>
  );
};

export const EspaceClientActionType = Object.freeze({
    END_POINT: 'espaceClient',
    LOAD_CURRENT_CLIENT: 'espaceClient/loadCurrentClient',
    GET_ALL_ARTICLES: 'espaceClient/getAllArticlesEC',
    SELECT_ARTICLE: 'espaceClient/selectArticleEC',
    GET_ALL_COMMANDES: 'espaceClient/getAllCommandesEC',
    GET_ALL_COMMANDES_BY_ARTICLE_ID: 'espaceClient/getAllCommandesByArticleIdEC',
    GET_ALL_OFFRES: 'espaceClient/getAllOffresEC',
    GET_ALL_OFFRES_BY_ARTICLE_ID: 'espaceClient/getAllOffresByArticleIdEC',
    SELECT_OFFRE: 'espaceClient/selectOffreEC',
    GET_TYPES_COMMANDE: 'espaceClient/getTypesCommandeEC',
    SELECT_COMMANDE: 'espaceClient/selectCommandeEC',
    GET_ALL_NC: 'espaceClient/getAllNC',
    GET_ETAT_NC: 'espaceClient/getEtatNC',
    SELECT_NC: 'espaceClient/selectNC',
    GET_ALL_CLIENTS: 'espaceClient/getAllContacts',
    GET_DETAILS_CLIENT: 'espaceClient/getDetailsClient',
    SELECT_CONTACT: 'espaceClient/selectContact',
    SEND_MAIL_PROGRAMMER_VISITE: 'espaceClient/sendMailProgrammerVisite',
    SEND_MAIL_ETRE_RAPPELER: 'espaceClient/sendMailEtreRappeler',
    SEND_MAIL_MODIF_CONTACT: 'espaceClient/sendMailModifContact',
    GET_ALL_FAMILLE_ARTICLE: 'espaceClient/getAllFamilleArticle',
    SEND_STATS_TO_FPS: 'espaceClient/sendStatsToFPS',
})

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { StringToRouteInNavigate } from "../../../helper/StringParser";
import { GuestRoutes } from "../../../constantes/routes/GuestRoutes";
import {
  StyledContentActus,
  StyledContentCard,
  StyledContentSubCardReverse,
  StyledDivBoutonCialProximite,
  StyledDivTitleCard,
  StyledDivTitleCardReverse,
  StyledDivTitleSubCard,
  StyledDivTitleSubCardReverse,
  StyledH1VuesEC,
  StyledH2VuesEC,
  StyledTitleActus,
} from "../styledComponents/StyledTextEspaceClient";
import {
  getAllArticlesEC,
  getAllCommandesEC,
  getAllContacts,
  getAllOffresEC,
  getDetailsClient,
  getOneContact,
  sendMailEtreRappeler,
  sendMailProgrammerVisite,
} from "../../model/slice/EspaceClientReducer";
import { importAll, importAllSpec } from "../../../helper/ImportAll";
import {
  StyledDivCardWrapper,
  StyledDivCardWrapperRow,
  StyledHrEspaceClient,
} from "../styledComponents/StyledDivEspaceClient";
import { NCIconCard } from "../components/espaceClient/NCIconCard";
import { ServiceIconCard } from "../components/espaceClient/ServiceIconCard";
import { IconContactCard } from "../components/espaceClient/IconContactCard";
import { ClientRoutes } from "../../../constantes/routes/ClientRoutes";
import { SeeMoreIconEC } from "../components/SeeMoreIconEC";
import { BoutonAccueilEC, BoutonCialProx, BoutonWithoutArrow, } from "../components/espaceClient/BoutonAccueilEC";
import { LocalStorage } from "../../../constantes/globalName/LocalStorage";
import { FONCTION, NOM, PAYS, PRENOM, SECTEUR, SOCIETE, } from "../enum/champs/ChampsCommunFormulairesEnum";
import { ADRESSE_MAIL, CODE_POSTAL, TELEPHONE, } from "../../../constantes/symbols/SymbolsCommon";
import { PersonIcon } from "../components/espaceClient/PersonIcon";
import { getAllSecteurs } from "../../model/slice/SecteurReducer";
import { getAllFonctions } from "../../model/slice/FonctionReducer";
import { getAllPays } from "../../model/slice/PaysReducer";
import { StyledButtonContinuer } from "../styledComponents/StyledButton";
import { StyledCheckRoundedIcon } from "../styledComponents/StyledIcon";
import { StyledCardContainer } from "../styledComponents/StyledCardContainer";
import CloseIcon from "@mui/icons-material/Close";
import { AccueilMobileEC } from "./AccueilMobileEC";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export const AccueilEC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();

  const setIdentite = () => {
    const url = window.location.href;
    const regex = new RegExp("moovp*");
    const site = regex.test(url);
    const title = site === true ? "Moovprotect" : "RC Modèles";
    document.title = title + " | Demande de prix";
    return site;
  };

  const [booleanIdentite, setBooleanIdentite] = useState(setIdentite());
  const [upToDateArticlesList, setUpToDateArticlesList] = useState([]);
  const [upToDateCommandesList, setUpToDateCommandesList] = useState([]);
  const [upToDateOffresList, setUpToDateOffresList] = useState([]);
  const [upToDateClientDetails, setUpToDateClientDetails] = useState({});

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openWaitingModal, setOpenWaitingModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [resultTitle, setResultTitle] = useState("");
  const [actionToConfirm, setActionToConfirm] = useState(null);
  const [messageToConfirm, setMessageToConfirm] = useState("");
  const [prenomContact, setPrenomContact] = useState("");
  const [raisonSociale, setRaisonSociale] = useState("");
  const [isMobileFormatAccueil, setIsMobileFormatAccueil] = useState(
    window.innerWidth >= 1366
  );

  const [imageSrc, setImageSrc] = useState("");

  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue") || "all"
  );

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);

  const {
    articleList,
    commandeList,
    offreList,
    detailsClient,
    contactList,
    detailsContact,
  } = useSelector((state) => state.espaceClient);

  const [
    styles,
    t,
    setUpToDateReferenceByIdList,
    upToDateReferenceByIdList,
    setSelectedPage,
  ] = useOutletContext();
  useEffect(() => {
    const encodedClientId = localStorage.getItem("clientId");

    if (encodedClientId) {
      const clientId = atob(encodedClientId);
      const contactId =
        localStorage.getItem("selectedValue") !== "all"
          ? localStorage.getItem("selectedValue")
          : "%25";

      const obj = { clientId };

      dispatch(getAllSecteurs());
      dispatch(getAllFonctions());
      dispatch(getAllPays());

      dispatch(getAllArticlesEC(obj));
      dispatch(getAllCommandesEC({ clientId, contactId }));
      dispatch(getAllOffresEC({ clientId, contactId }));
      dispatch(getDetailsClient({ clientId }));
      dispatch(getAllContacts(obj));
      dispatch(getOneContact({ clientId, contactId }));
    }
  }, [dispatch, selectedValue]);

  useEffect(() => {
    if (articleList && articleList.length > 0) {
      setUpToDateArticlesList(articleList.slice(0, 3)); // Prendre les 3 premiers articles
    }
  }, [articleList]);

  useEffect(() => {
    if (commandeList && commandeList.length > 0) {
      setUpToDateCommandesList(commandeList.slice(0, 2)); // Prendre les 2 premières commandes
    }
  }, [commandeList]);

  useEffect(() => {
    if (offreList && offreList.length > 0) {
      setUpToDateOffresList(offreList.slice(0, 2)); // Prendre les 2 premières offres
    }
  }, [offreList]);

  useEffect(() => {
    if (detailsClient) {
      setUpToDateClientDetails(detailsClient);
    }
  }, [detailsClient]);

  useEffect(() => {
    if (contactList) {
      const contact = contactList.find(
        (item) => item.contactId === selectedValue
      );
      const raisonSociale = contactList.find(
        (item) => item.clientRaisonSociale
      );

      if (contact && contact.prenom !== "" && selectedValue !== "all") {
        setPrenomContact(contact.prenom + ",");
      } else {
        setPrenomContact("");
      }

      if (raisonSociale) {
        setRaisonSociale(contactList[ 0 ].clientRaisonSociale);
      } else {
        setRaisonSociale("");
      }
    }
  }, [contactList, selectedValue]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileFormatAccueil(window.innerWidth >= 1366);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fonction de gestion du changement de sélection
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    localStorage.setItem("selectedValue", event.target.value);
  };

  const handleConfigurer = () => {
    if (detailsContact[ 0 ] && selectedValue !== "all") {
      // Fonction pour mapper sur fonctionList et rechercher la valeur correspondante
      const fonction = fonctionsList.find(
        (item) => item.name === detailsContact[ 0 ].fonction
      );

      const secteur = secteursList.find(
        (item) =>
          item.reference === detailsContact[ "infosClient" ].codeSecteurActivite
      );

      const pays = paysList.find(
        (item) => item.reference === detailsContact[ "infosClient" ].codePays
      );

      localStorage.setItem(
        LocalStorage.INFORMATIONS_CLIENT,
        btoa(
          JSON.stringify({
            [ SOCIETE.description ]: detailsContact[ "infosClient" ].raisonSociale,
            [ PAYS.description ]: pays,
            [ CODE_POSTAL.description ]: detailsContact[ "infosClient" ].codePostal,
            [ SECTEUR.description ]: secteur,
            [ NOM.description ]: detailsContact[ 0 ].nom,
            [ PRENOM.description ]: detailsContact[ 0 ].prenom,
            [ FONCTION.description ]: fonction,
            [ ADRESSE_MAIL.description ]: detailsContact[ 0 ].email,
            [ TELEPHONE.description ]: detailsContact[ 0 ].telephone,
          })
        )
      );
    }

    const url = StringToRouteInNavigate(GuestRoutes.CONFIGURATEUR);
    window.open(url, "_blank");
  };

  const handleConfirmationModal = (action) => {
    setActionToConfirm(() => {
      if (action === "visite") {
        setMessageToConfirm(t.messageToConfirmVisite);
        return handleProgrammerVisite;
      }
      if (action === "rappel") {
        setMessageToConfirm(t.messageToConfirmRappel);
        return handleEtreRappele;
      }
      return null;
    });
    setOpenConfirmationModal(true);
  };

  const handleProgrammerVisite = () => {
    const obj = {
      contactById: detailsContact,
    };

    setOpenWaitingModal(true);

    dispatch(sendMailProgrammerVisite(obj))
      .unwrap()
      .then((response) => {
        setOpenWaitingModal(false);
        setResultMessage(t.messageResultatEnvoiMailVisite);
        setResultTitle(t.titleMessageResultat);
        setOpenResultModal(true);
      })
      .catch((error) => {
        setOpenWaitingModal(false);
        setResultMessage(t.erreurEnvoiMail);
        setOpenResultModal(true);
      });
  };

  const handleEtreRappele = () => {
    const obj = {
      contactById: detailsContact,
    };

    setOpenWaitingModal(true);

    dispatch(sendMailEtreRappeler(obj))
      .unwrap()
      .then((response) => {
        setOpenWaitingModal(false);
        setResultMessage(t.messageResultatEtreRappele);
        setResultTitle(t.titleMessageResultatEtreRappele);
        setOpenResultModal(true);
      })
      .catch((error) => {
        setOpenWaitingModal(false);
        setResultMessage(t.erreurEnvoiMail);
        setOpenResultModal(true);
      });
  };

  useEffect(() => {
    if (
      detailsClient &&
      detailsClient.commercialPrenom &&
      detailsClient.commercialNom
    ) {
      const prenom = detailsClient.commercialPrenom;
      const nom = detailsClient.commercialNom;

      // On prend les initiales du prénom et du nom pour générer le nom de fichier
      const initials = prenom.charAt(0) + nom.charAt(0);

      // Générer le nom de l'image en fonction du format (Desktop ou Mobile)
      const format = window.innerWidth > 768 ? "Desktop" : "Mobile";
      const imageName = `${initials}_${format}.png`;

      // Importer toutes les images
      const images = importAll();

      // Vérifier si l'image spécifique existe, sinon utiliser l'image par défaut
      if (images[ imageName ]) {
        setImageSrc(images[ imageName ]);
      } else {
        const defaultImage = `DEFAULT_${format}.png`;
        setImageSrc(images[ defaultImage ]);
      }
    }
  }, [detailsClient, window.innerWidth]);

  const handleArticles = () => {
    navigate(ClientRoutes.VUE_ARTICLE);
  };

  const handleNC = () => {
    navigate(ClientRoutes.VUE_NC);
  };

  const handleEquipe = () => {
    navigate(ClientRoutes.VUE_CONTACT);
  };

  const handleCommandes = () => {
    navigate(ClientRoutes.VUE_COMMANDE);
  };

  const handleOffres = () => {
    navigate(ClientRoutes.VUE_OFFRE);
  };

  const handleOneCommande = (commandeId) => () => {
    navigate(ClientRoutes.VUE_COMMANDE + "?commande=" + commandeId);
  };

  const handleOneOffre = (offreId) => () => {
    navigate(ClientRoutes.VUE_OFFRE + "?offre=" + offreId);
  };

  const handleOneArticle = (articleId) => () => {
    navigate(ClientRoutes.VUE_ARTICLE + "?art=" + articleId);
  };

  const afficherBouton = (texte, action, backgroundColor) => {
    return (
      <BoutonAccueilEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  const afficherBoutonWithoutArrow = (texte, action, isFirstButton) => {
    return (
      <BoutonCialProx
        texte={texte}
        action={action}
        isFirstButton={isFirstButton}
      />
    );
  };

  const stateUsedForRender = {
    upToDateArticlesList,
    upToDateCommandesList,
    upToDateOffresList,
    selectedValue,
    contactList,
    isMobileFormatAccueil,
    detailsClient,
    detailsContact,
    articleList,
    commandeList,
    offreList,
    secteursList,
    fonctionsList,
    paysList,
  };

  const actionsUsedForRender = {
    handleSelectChange,
    handleConfigurer,
    handleConfirmationModal,
    handleProgrammerVisite,
    handleEtreRappele,
    handleArticles,
    handleNC,
    handleEquipe,
    handleCommandes,
    handleOffres,
    handleOneCommande,
    handleOneOffre,
    handleOneArticle,
    afficherBouton,
    afficherBoutonWithoutArrow,
    setRaisonSociale,
    setPrenomContact,
    setSelectedValue,
    setUpToDateArticlesList,
    setUpToDateCommandesList,
    setUpToDateOffresList,
    setUpToDateClientDetails,
  };
  return (
    <div style={{ padding: "16px" }}>
      <div>
        <StyledH1VuesEC>
          {prenomContact} {t.genH1BienvenueEspaceClient}
        </StyledH1VuesEC>
        <StyledHrEspaceClient></StyledHrEspaceClient>
      </div>
      {/* raison sociale placée a droite de la page */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <StyledH2VuesEC>{raisonSociale}</StyledH2VuesEC>
      </div>

      {!isMobileFormatAccueil ? (
        <AccueilMobileEC
          state={stateUsedForRender}
          actions={actionsUsedForRender}
          t={t}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
          }}
        >
          <StyledCardContainer
            id="card-container"
            className={styles.cardContainer}
            style={{
              flex: 1,
              display: "flex",
              gap: "1.5rem",
              flexWrap: "wrap",
            }}
          >
            <StyledDivCardWrapper>
              <Card
                id="commercial-card"
                className={`${styles.cardContainer}`}
                style={{
                  boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                  borderRadius: "8px",
                }}
              >
                {/* Contenu textuel */}
                <CardContent
                  className={styles.textContainer}
                  style={{ padding: "24px" }}
                >
                  <StyledDivTitleCard
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t.genTitleCardCialProximp}
                  </StyledDivTitleCard>
                  <StyledDivTitleSubCard>
                    {detailsClient.commercialPrenom +
                      " " +
                      detailsClient.commercialNom}
                  </StyledDivTitleSubCard>
                  <StyledContentCard>
                    {t.telCommercial} : {detailsClient.commercialTelephone}
                  </StyledContentCard>
                  <StyledContentCard
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t.emailCommercial} : {detailsClient.commercialEmail}
                  </StyledContentCard>
                  <StyledDivBoutonCialProximite
                    style={{
                      display: "flex", // Flexbox pour gérer la mise en page
                      flexDirection: "column", // Les boutons sont empilés verticalement
                      width: "100%", // Le conteneur prend toute la largeur
                      gap: "10px", // Un espace entre les boutons (facultatif)
                    }}
                  >
                    {afficherBoutonWithoutArrow(
                      t.programmerVisite,
                      () => handleConfirmationModal("visite"),
                      true
                    )}
                    {afficherBoutonWithoutArrow(
                      t.etreRappele,
                      () => handleConfirmationModal("rappel"),
                      false
                    )}
                  </StyledDivBoutonCialProximite>
                </CardContent>

                {/* Image pour Desktop */}
                <CardMedia
                  component="img"
                  alt={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
                  src={imageSrc}
                  title={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
                  className={styles.desktopImage}
                />

                {/* Image pour Mobile */}
                <CardMedia
                  component="img"
                  alt={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
                  src={imageSrc}
                  title={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
                  className={styles.mobileImage}
                />
              </Card>

              <Card
                id="commande-card"
                className={styles.commandeCard}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#CC007B",
                  boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                  borderRadius: "8px",
                }}
              >
                <CardContent style={{ flex: 1, padding: "24px" }}>
                  <StyledDivTitleCardReverse>
                    {t.genTitleCardCommande}
                  </StyledDivTitleCardReverse>
                  <div
                    className={styles.commandeContainer}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "1rem",
                      // Assurez-vous que les cartes utilisent toute la largeur disponible
                      width: "100%",
                    }}
                  >
                    {upToDateCommandesList.length === 0 ? (
                      <StyledContentCard className={styles.aucuneCommande}>{t.aucuneCommande}</StyledContentCard>
                    ) : (
                      upToDateCommandesList.map((commande, index) => (
                        <Card
                          key={index}
                          className={styles.singleCommandeCard}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "calc(50% - 1rem)",
                            borderRadius: "8px",
                            background:
                              "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.25) 100%)",
                            boxShadow:
                              "0px 0px 4px 2px rgba(255, 255, 255, 0.15)",
                          }}
                        >
                          <CardContent style={{ flex: 1 }}>
                            <StyledDivTitleSubCardReverse>
                              {commande.commandeCode}
                            </StyledDivTitleSubCardReverse>
                            <StyledContentSubCardReverse>
                              {commande.reference}
                            </StyledContentSubCardReverse>
                            <StyledContentSubCardReverse>
                              {commande.datePub}
                            </StyledContentSubCardReverse>
                            <StyledContentSubCardReverse>
                              {commande.totalHT} €
                            </StyledContentSubCardReverse>

                            <Badge
                              style={{
                                position: "initial",
                                backgroundColor: "white",
                                color: "#1C243C",
                                paddingTop: "0.1rem",
                                paddingBottom: "0.1rem",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                                marginTop: "0.5rem",
                                borderRadius: "5px",
                              }}
                            >
                              <StyledContentCard style={{ margin: "0" }}>
                                {commande.livr}
                              </StyledContentCard>
                            </Badge>
                          </CardContent>
                          <SeeMoreIconEC
                            dark={true}
                            method={handleOneCommande(commande.commandeId)}
                          />
                        </Card>
                      ))
                    )}
                  </div>
                  {afficherBouton(
                    t.genAffToutesCommandes,
                    handleCommandes,
                    "dark"
                  )}
                </CardContent>
              </Card>

              <Card
                id="offre-card"
                className={styles.offreCard}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#EC8812",
                  boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                  borderRadius: "8px",
                }}
              >
                <CardContent style={{ flex: 1, padding: "24px" }}>
                  <StyledDivTitleCard>{t.genTitleCardOffre}</StyledDivTitleCard>

                  <div className={styles.offreContainer}>
                    {upToDateOffresList.length === 0 ? (
                      <StyledContentCard className={styles.aucuneOffre}>{t.aucuneOffre}</StyledContentCard>
                    ) : (
                      upToDateOffresList.map((offre, index) => (
                        <Card
                          key={index}
                          className={styles.singleOffreCard}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "calc(50% - 1rem)",
                            borderRadius: "8px",
                            background:
                              "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.25) 100%)",
                            boxShadow:
                              "0px 0px 4px 2px rgba(255, 255, 255, 0.15)",
                          }}
                        >
                          <CardContent style={{ flex: 1 }}>
                            <StyledDivTitleSubCard>
                              {offre.offrePrixCode}
                            </StyledDivTitleSubCard>
                            <StyledContentCard>
                              {offre.offreRefClient}
                            </StyledContentCard>
                            <StyledContentCard>
                              {offre.dateCreation}
                            </StyledContentCard>
                            {offre.commandeCode !== "" ? (
                              <Badge
                                style={{
                                  position: "initial",
                                  backgroundColor: "white",
                                  color: "#1C243C",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  paddingLeft: "0.5rem",
                                  paddingRight: "0.5rem",
                                  marginTop: "0.5rem",
                                  borderRadius: "5px",
                                }}
                              >
                                <StyledContentCard style={{ margin: "0" }}>
                                  {t.commandee}
                                </StyledContentCard>
                              </Badge>
                            ) : (
                              <Badge
                                style={{
                                  position: "initial",
                                  backgroundColor: "white",
                                  color: "#1C243C",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                  paddingLeft: "0.5rem",
                                  paddingRight: "0.5rem",
                                  marginTop: "0.5rem",
                                  borderRadius: "5px",
                                }}
                              >
                                <StyledContentCard style={{ margin: "0" }}>
                                  {t.nonCommandee}
                                </StyledContentCard>
                              </Badge>
                            )}
                          </CardContent>
                          <SeeMoreIconEC
                            dark={true}
                            method={handleOneOffre(offre.offrePrixId)}
                          />
                        </Card>
                      ))
                    )}
                  </div>
                  {afficherBouton(t.genAffToutesOffres, handleOffres)}
                </CardContent>
              </Card>
            </StyledDivCardWrapper>

            <StyledDivCardWrapper style={{ flex: "1.75" }}>
              <StyledDivCardWrapperRow>
                <Card
                  id="publicite-card"
                  className={styles.publiciteCard}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1.5,
                    boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                    background:
                      "linear-gradient(270deg, rgba(42, 55, 92, 0.20) 0.01%, #2A375C 93.17%), url(" +
                      importAllSpec(false)[ "LandingPageImage.jpg" ] +
                      ") lightgray 50% / cover no-repeat",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: "none", padding: "24px" }}>
                    <StyledDivTitleCardReverse>
                      {t.genTitleCardActus}
                    </StyledDivTitleCardReverse>

                    <StyledTitleActus>{t.ESPACE_CLIENT_PUB_2}</StyledTitleActus>

                    <StyledContentActus>
                      {t.ESPACE_CLIENT_PUB_3}
                    </StyledContentActus>
                  </CardContent>
                </Card>
                <Card
                  id="whoIam-card"
                  className={styles.whoIamCard}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    border: "#E7EAFF solid",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: 1, padding: "24px" }}>
                    <StyledDivTitleCard>{t.jePersonnalise}</StyledDivTitleCard>

                    <FormControl fullWidth style={{ marginBottom: "0.875rem" }}>
                      <InputLabel id="avatar-select-label">
                        {t.jeSuis}
                      </InputLabel>
                      <Select
                        labelId="avatar-select-label"
                        id="avatar-select"
                        label={t.jeSuis}
                        value={selectedValue}
                        onChange={handleSelectChange}
                        startAdornment={<PersonIcon/>}
                      >
                        <MenuItem value="all">{t.toutLeMonde}</MenuItem>
                        {contactList
                          ? contactList.map((contact, index) => (
                            <MenuItem key={index} value={contact.contactId}>
                              {contact.contact}
                            </MenuItem>
                          ))
                          : null}
                      </Select>
                    </FormControl>

                    <StyledContentCard>
                      ... {t.descrJePersonnalise}
                    </StyledContentCard>
                  </CardContent>
                </Card>
              </StyledDivCardWrapperRow>
              <Card
                id="article-card"
                className={styles.articleCard}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                  borderRadius: "8px",
                }}
              >
                <CardContent style={{ flex: 1, padding: "24px" }}>
                  <StyledDivTitleCard>
                    {t.genTitleCardArticle}
                  </StyledDivTitleCard>
                  <div
                    className="articles-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "1rem",
                    }}
                  >
                    {upToDateArticlesList.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <CircularProgress style={{ color: "#1C243C" }}/>
                      </div>
                    ) : (
                      upToDateArticlesList.map((article, index) => (
                        <Card
                          key={index}
                          className={styles.singleArticleCard}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "calc(33.333% - 1rem)",
                            borderRadius: "8px",
                            boxShadow:
                              "0px 0px 4px 2px rgba(87, 92, 130, 0.10)",
                            background:
                              "linear-gradient(180deg, rgba(231, 234, 255, 0.10) 0%, rgba(231, 234, 255, 0.50) 100%)",
                          }}
                        >
                          <CardContent style={{ flex: 1 }}>
                            {article.articleReferenceClient ? (
                              <StyledContentCard>
                                {article.articleReferenceClient}
                              </StyledContentCard>
                            ) : null}
                            <StyledContentCard>
                              {article.articleCode}
                            </StyledContentCard>
                            <StyledDivTitleSubCard>
                              {article.articleDesignation}
                            </StyledDivTitleSubCard>
                            <StyledContentCard>
                              {article.modeleMachine}
                            </StyledContentCard>
                            <StyledContentCard>
                              {article.emplacementPiece}
                            </StyledContentCard>
                          </CardContent>
                          {article.articleEtatValidation !== "100" ||
                          article.articleEtatValidation !== "900" ? (
                            <>
                              <SeeMoreIconEC
                                dark={false}
                                method={handleOneArticle(article.articleId)}
                              />
                            </>
                          ) : null}
                        </Card>
                      ))
                    )}
                  </div>
                  {afficherBouton(t.genAffTousArticles, handleArticles)}
                </CardContent>
              </Card>
              <StyledDivCardWrapperRow className={styles.cardContainer}>
                <Card
                  id="nc-card"
                  className={styles.ncCard}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: 1, padding: "24px" }}>
                    <div
                      style={{
                        height: 0,
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <NCIconCard/>
                    </div>
                    <StyledDivTitleCard>{t.genTitleCardNC}</StyledDivTitleCard>

                    <StyledContentCard>{t.genDescrNC}</StyledContentCard>

                    {afficherBouton(t.genAffNC, handleNC)}
                  </CardContent>
                </Card>
                <Card
                  id="contact-card"
                  className={styles.equipeCard}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: 1, padding: "24px" }}>
                    <div
                      style={{
                        height: 0,
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <IconContactCard/>
                    </div>
                    <StyledDivTitleCard>
                      {t.genTitleCardEquipe}
                    </StyledDivTitleCard>
                    <StyledContentCard>{t.genDescrEquipe}</StyledContentCard>
                    {afficherBouton(t.genAffEquipe, handleEquipe)}
                  </CardContent>
                </Card>
                <Card
                  id="service-card"
                  className={styles.serviceCard}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: 1, padding: "24px" }}>
                    <div
                      style={{
                        height: 0,
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <ServiceIconCard/>
                    </div>
                    <StyledDivTitleCard>
                      {t.genTitleCardService}
                    </StyledDivTitleCard>
                    <StyledContentCard>{t.genDescrServices}</StyledContentCard>
                  </CardContent>
                </Card>
              </StyledDivCardWrapperRow>
              <Card
                id="configurateur-card"
                className={styles.jeConfigureCard}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                  backgroundColor: "#2A375C",
                  flexWrap: "wrap",
                  borderRadius: "8px",
                }}
              >
                <img
                  className={styles.mobileImageConfigure}
                  src={importAll()[ "RCModeles-Configurateur.png" ]}
                  alt="RC Modèles Configurateur"
                />
                <CardContent
                  style={{ flex: "1", minWidth: "300px", padding: "24px" }}
                >
                  <StyledDivTitleCardReverse>
                    {t.genTitleJeConfigure}
                  </StyledDivTitleCardReverse>

                  <StyledContentActus
                    style={{ fontSize: "1.25rem", color: "#fff" }}
                  >
                    {t.genDescrJeConfigure}
                  </StyledContentActus>

                  {afficherBouton(
                    t.genAffJeConfigure,
                    handleConfigurer,
                    "dark"
                  )}
                </CardContent>
              </Card>
            </StyledDivCardWrapper>
          </StyledCardContainer>
        </div>
      )}

      {/* Modale de confirmation d'envoi de mail */}
      <Modal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box sx={style}>
          <StyledDivTitleCard
            id="confirm-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            {t.descrConfirmationEnvoi}
          </StyledDivTitleCard>
          <StyledContentCard
            id="confirm-modal-description"
            sx={{ mt: 2 }}
            style={{ textAlign: "center" }}
          >
            {messageToConfirm}
          </StyledContentCard>
          <Box mt={3} display="flex" justifyContent="space-between">
            <IconButton
              onClick={() => setOpenConfirmationModal(false)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
            >
              <CloseIcon/>
            </IconButton>

            <BoutonWithoutArrow
              texte={"Annuler"}
              action={() => setOpenConfirmationModal(false)}
            />
            <StyledButtonContinuer
              variant="contained"
              style={{ backgroundColor: "#1C243C" }}
              onClick={() => {
                if (actionToConfirm) actionToConfirm(); // Appelle la méthode stockée
                setOpenConfirmationModal(false);
              }}
            >
              {t.comfirmer}
            </StyledButtonContinuer>
          </Box>
        </Box>
      </Modal>
      {/* Modale d'attente */}
      <Modal
        open={openWaitingModal}
        onClose={() => setOpenWaitingModal(false)}
        aria-labelledby="waiting-modal-title"
        aria-describedby="waiting-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={() => setOpenWaitingModal(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>
          <StyledDivTitleCard
            id="waiting-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            {t.envoiEnCours}...
          </StyledDivTitleCard>
          <Typography
            id="waiting-modal-description"
            sx={{ mt: 2 }}
            style={{ textAlign: "center" }}
          >
            <CircularProgress/>
          </Typography>
        </Box>
      </Modal>

      {/* Modale de résultat */}
      <Modal
        open={openResultModal}
        onClose={() => setOpenResultModal(false)}
        aria-labelledby="result-modal-title"
        aria-describedby="result-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={() => setOpenResultModal(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>

          <StyledDivTitleCard
            id="waiting-modal-title"
            variant="h6"
            component="h2"
          >
            {resultTitle}
          </StyledDivTitleCard>
          <StyledContentCard id="result-modal-description">
            <div style={{ textAlign: "center", marginBottom: "1.25rem" }}>
              <StyledCheckRoundedIcon
                sx={{
                  backgroundColor: "rgb(95, 163, 110, 0.33)",
                  borderRadius: "50%",
                  width: "5.375rem",
                  height: "5.375rem",
                }}
              />
            </div>
            {resultMessage}
          </StyledContentCard>
        </Box>
      </Modal>
    </div>
  );
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";

import {
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
  StyledDivSearchBar,
} from "../../styledComponents/StyledDivBackoffice";

import {
  StyledTextfieldSearchDdp,
} from "../../styledComponents/StyledTextfieldBackoffice";

export const NCItem = ({
  id,
  action,
  FNCLibelle,
  etatFNC,
  FNCDate,
  FNCCode,
  articleReferenceClient,
  articleCode,
  articleDesignation,
  commandeCode,
  commandeId,
  articleId,
  FNCId,
  searchBarAction: {
    setSearchBarContentStatutNC,
    setSearchBarContentReference,
    setSearchBarContentDateDeclaration,
    setSearchBarContentCodeNC,
    setSearchBarContentCodeClient,
    setSearchBarContentCodeFPS,
    setSearchBarContentArticleDesignation,
    setSearchBarContentCodeCommande,
  },
  sortAction: {
    setSortedStatutNC,
    setSortedReference,
    setSortedDateDeclaration,
    setSortedCodeNC,
    setSortedCodeFPS,
    setSortedCodeClient,
    setSortedArticleDesignation,
    setSortedCodeCommande,
  },
}) => {
  const { t, lang } = useTranslations();
  const navigate = useNavigate();
  const { clientId } = useAuthentification();

  const [columnSize, setColumnSize] = useState(1);
  const { etatNCList } = useSelector((state) => state.espaceClient);

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const noClassNameMobileAndTablette = "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  const handleChange = (event, setterSearchBar) => {
    setterSearchBar(event.target.value);
  };

  const navigateToNC = (ncId) => {
    if (ncId)
      navigate(ClientRoutes.VUE_NC + ncId);
  };

  const navigateToArticle = (articleId) => {
    if (articleId) {
      navigate(ClientRoutes.VUE_ARTICLE + articleId);
    }
  };

  const navigateToCommande = (commandeId) => {
    if (commandeId) {
      navigate(ClientRoutes.VUE_COMMANDE + commandeId);
    }
  };

  const DisplayAdornment = ({ md, style }) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={md ? md : columnSize}
        className={style + " itemDdp action"}
        xs={4}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        <IconButton style={{ padding: 0 }} onClick={() => navigateToNC(FNCId)} >
          <div
            style={{
              padding: 6,
              borderRadius: 4,
              overflow: "hidden",
              border: "2px #1C243C solid",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                color: "#1C243C",
                fontSize: 18,
                fontFamily: "Dosis",
                fontWeight: "600",
              }}
            >
              {t.voirPlus}
            </div>
          </div>
        </IconButton>
      </Grid>
    );
  };

  const displaySearchBar = (setValue) => {
    if (setValue) {
      return (
        <div>
          <StyledDivSearchBar>
            <StyledTextfieldSearchDdp
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event, setValue)}
              InputProps={{
                endAdornment: (
                  <SearchIcon md={{ color: "#EC8812", fontSize: 10 }} />
                ),
              }}
            />
          </StyledDivSearchBar>
        </div>
      );
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={`${style} columnHeaderEC ${
          searchBarStateSetter ? "" : "hasSearch"
        }`}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
          // marginLeft={name === "Action" ? "-0.75rem" : "0"}
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
            {displaySortArrows(sortStateSetter)}
          </Stack>
          <Stack>{displaySearchBar(searchBarStateSetter)}</Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </Grid>
    );
  };

  const displayEtatNC = (field, md, sm, style) => {
    const etatItem = etatNCList.list.find((e) => e.etatFNCCode === field);
    const backgroundColor = etatItem ? etatItem.HEX : "#FFFFFF"; // Default to white if not found

    const getTranslation = (code) => {
        return etatItem?.[lang] ?? code;
    }

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={0}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{
            wordBreak: "break-word",
            justify: "center",
            alignItems: "center",
            color: "white",
            backgroundColor: backgroundColor, // Apply background color
            padding: "10px", // Optional: add padding for better visibility
            borderRadius: "5px", // Optional: add border radius for rounded corners
          }}
        >
          { getTranslation(field) }
        </div>
      </Grid>
    );
  };

  const displayItemAction = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return <DisplayAdornment md={md} style={style} />;
  };

  const displayCommandeCode = (commandeId, name, md, sm, style) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {commandeId ? (
          // Si commandeId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={() => navigateToCommande(commandeId)}
          />
        ) : (
          // Si commandeId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  const displayArticleCode = (articleId, name, md, sm, style) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {articleId ? (
          // Si articleId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={() => navigateToArticle(articleId)} // Ne pas invoquer directement la fonction
          />
        ) : (
          // Si articleId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  const displayNCCode = (ncId, name, md, sm, style) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {ncId ? (
          // Si ncId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={ () => navigateToNC(ncId) }
          />
        ) : (
          // Si ncId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : { height: "100px" }}
    >
      {id === 0
        ? displayItemHeader(
            FNCCode,
            setSearchBarContentCodeNC,
            setSortedCodeNC,
            1,
            1,
            8,
            alwaysDisplayed
          )
        : displayNCCode(FNCId, FNCCode, 1, 1, alwaysDisplayed)}

      {id === 0
        ? displayItemHeader(
            FNCDate,
            setSearchBarContentDateDeclaration,
            setSortedDateDeclaration,
            1,
            1,
            4,
            noClassNameMobile
          )
        : displayItem(FNCDate, 1, 1, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            FNCLibelle,
            setSearchBarContentReference,
            setSortedReference,
            2,
            2,
            4,
            noClassNameMobileAndTablette
          )
        : displayItem(FNCLibelle, 2, 2, noClassNameMobileAndTablette)}

      {id === 0
        ? displayItemHeader(
            articleCode,
            setSearchBarContentCodeFPS,
            setSortedCodeFPS,
            1.5,
            1.5,
            8,
            noClassNameMobile
          )
        : displayArticleCode(
            articleId,
            articleCode,
            1.5,
            1.5,
            noClassNameMobile
          )}

      {id === 0
        ? displayItemHeader(
            articleReferenceClient,
            setSearchBarContentCodeClient,
            setSortedCodeClient,
            1,
            1,
            8,
            noClassNameMobile
          )
        : displayItem(articleReferenceClient, 1, 1, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            articleDesignation,
            setSearchBarContentArticleDesignation,
            setSortedArticleDesignation,
            1.5,
            1.5,
            null,
            noClassNameMobileAndTablette
          )
        : displayItem(
            articleDesignation,
            1.5,
            1.5,
            noClassNameMobileAndTablette
          )}
      {id === 0
        ? displayItemHeader(
            commandeCode,
            setSearchBarContentCodeCommande,
            setSortedCodeCommande,
            1,
            1,
            4,
            noClassNameMobile
          )
        : displayCommandeCode(
            commandeId,
            commandeCode,
            1,
            1,
            noClassNameMobile
          )}
      {id === 0
        ? displayItemHeader(
            etatFNC,
            setSearchBarContentStatutNC,
            setSortedStatutNC,
            2,
            2,
            4,
            noClassNameMobile
          )
        : displayEtatNC(etatFNC, 2, 2, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(action, null, null, 1, 1, 4, "")
        : displayItemAction(action, null, null, 1, 1, 0.5, "")}
    </Grid>
  );
};

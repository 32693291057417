export const ODPIcon = ({ currentColor }) => {


  return (
    <svg width="40" height="48" x="0" y="0" viewBox="0 0 512 512">
      <g>
        <path
          d="M356 10.9V80c0 5.523 4.477 10 10 10h69.1"
          style={{
            fill: "none",
            stroke: currentColor,
            strokeWidth: "20",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
        ></path>
        <path
          d="M436 242.009V94.142c0-2.652-1.054-5.196-2.929-7.071l-74.142-74.142A10.001 10.001 0 0 0 351.858 10H86c-5.523 0-10 4.477-10 10v472c0 5.523 4.477 10 10 10h340c5.523 0 10-4.477 10-10V322.009M435.999 282h0"
          style={{
            fill: "none",
            stroke: currentColor,
            strokeWidth: "20",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
        ></path>
        <circle
          cx="256"
          cy="186"
          r="96"
          style={{
            fill: "none",
            stroke: currentColor,
            strokeWidth: "20",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
        ></circle>
        <path
          d="M274.898 150.454c-4.097-6.06-11.032-10.042-18.898-10.042-12.503 0-22.794 10.285-22.794 22.794.698 24.46 45.506 21.521 45.588 45.588 0 12.503-10.291 22.794-22.794 22.794-7.865 0-14.801-3.981-18.898-10.042M256 130v10.412M256 231.588V242M386 382H126M306 342H206M306 422H206M386 422h-40M166 422h-40"
          style={{
            fill: "none",
            stroke: currentColor,
            strokeWidth: "20",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
        ></path>
      </g>
    </svg>
  );
};

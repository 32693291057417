import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { DashboardIcon } from "../../redux/vue/components/espaceClient/DashboardIcon";

import { useTranslations } from "../../redux/vue/components/hook/useTranslations";
import { ArticleIcon } from "../../redux/vue/components/espaceClient/ArticleIcon";
import { ODPIcon } from "../../redux/vue/components/espaceClient/ODPIcon";
import { CommandeIcon } from "../../redux/vue/components/espaceClient/CommandeIcon";
import { NCIcon } from "../../redux/vue/components/espaceClient/NCIcon";
import { ContactIcon } from "../../redux/vue/components/espaceClient/ContactIcon";
import { ServiceIcon } from "../../redux/vue/components/espaceClient/ServiceIcon";
import { LogoutIcon } from "../../redux/vue/components/espaceClient/LogoutIcon";

import { useDispatch } from "react-redux";
import { deconnexionEC } from "../../redux/slice/AuthentificationReducer";
import { ClientRoutes } from "../../constantes/routes/ClientRoutes";

const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const [activePage, setActivePage] = useState("/");

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location.pathname]);

  const listItemStyle = {
    fontFamily: "Dosis",
    fontSize: "1rem",
    fontWeight: "600",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    listStyleType: "none",
    marginBottom: "16px",
    width: "100%",
    color: "#2A375C",
    lineHeight: "120%",
  };

  const activeListItemStyle = {
    ...listItemStyle,
    boxShadow: "0 -5px 5px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.1)",
    paddingTop: "0.5rem",
    color: "#1C243C",
    fontWeight: "800",
  };

  const disabledListItemStyle = {
    ...listItemStyle,
    // backgroundColor: "#e0e0e0",
    cursor: "not-allowed",
    fontWeight: "0",
  };

  const spanStyle = {
    marginTop: "8px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  };

  const disabledLinkStyle = {
    ...linkStyle,
    pointerEvents: "none",
    color: "#a0a0a0",
  };

  const handleLogout = () => {
    dispatch(deconnexionEC());
  };


  const renderLink = (to, IconComponent, text, isActive) => {
    const currentColor = isActive ? "#EC8812" : "#2a375c";

    // Condition to render the "Service" link as disabled
    if (to === ClientRoutes.VUE_SERVICE) {
      return (
        <li key={to} style={disabledListItemStyle}>
          <div style={disabledLinkStyle}>
            <ServiceIcon currentColor={'gray'}/>
            <span style={spanStyle}>{text}</span>
          </div>
        </li>
      );
    }

    if (to === "/Logout") {
      return (
        <li
          key={to}
          style={{
            ...listItemStyle,
            fontWeight: "600",
          }}
        >
          <div style={linkStyle} onClick={handleLogout}>
            <IconComponent currentColor={currentColor} />
            <span style={spanStyle}>{text}</span>
          </div>
        </li>
      );
    }

    return (
      <li
        key={to}
        style={isActive ? activeListItemStyle : listItemStyle}
      >
        <Link to={to} style={linkStyle}>
          <IconComponent currentColor={currentColor} />
          <span
            style={{
              fontWeight: isActive ? "800" : "600",
              fontSize: "1rem",
              margin: "8px",
            }}
          >
            {text}
          </span>
        </Link>
      </li>
    );
  };

  const links = [
    { to: ClientRoutes.ACCUEIL_EC,   icon: DashboardIcon,text: t.titleSideBarDashboard, },
    { to: ClientRoutes.VUE_ARTICLE,  icon: ArticleIcon,  text: t.titleSideBarArticles, },
    { to: ClientRoutes.VUE_OFFRE,    icon: ODPIcon,      text: t.titleSideBarOffres, },
    { to: ClientRoutes.VUE_COMMANDE, icon: CommandeIcon, text: t.titleSideBarCommandes, },
    { to: ClientRoutes.VUE_NC,       icon: NCIcon,       text: t.titleSideBarNC },
    { to: ClientRoutes.VUE_SERVICE,  icon: ServiceIcon,  text: t.titleSideBarServices, },
    { to: ClientRoutes.VUE_CONTACT,  icon: ContactIcon,  text: t.titleSideBarContact, },
    { to: "/Logout",                 icon: LogoutIcon,   text: t.titleSideBarLogout },
  ];

  // trouver le lien dont la route correspond à l'URL
  // (prendre le dernier trouvé à cause de la route de l'accueil)
  const activeLink = links.findLast( link => activePage.startsWith(link.to));

  return (
    <aside id="default-sidebar" style={{ backgroundColor: "white", width: "112px" }} >
      <div>
        <ul style={{ padding: "0", marginTop: "2rem" }}>
          {links.map((link) => renderLink(link.to, link.icon, link.text, (link === activeLink)))}
        </ul>
      </div>
    </aside>
  );
};

export default SideBar;

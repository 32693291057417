import { useNavigate } from "react-router-dom";

import { Card, CardContent } from "@mui/material";
import { IconContactCard } from "./IconContactCard";
import { BoutonAccueilEC } from "./BoutonAccueilEC";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

import { useWindowWidth, ScreenType } from '../../../../context/WindowWidthContext';
import { useTranslations } from '../../components/hook/useTranslations.js';
import { StyledContentCard, StyledDivTitleCard } from "../../styledComponents/StyledTextEspaceClient";


const ContactCard = ({ styles }) => {
  const { screenType } = useWindowWidth();
  const { t } = useTranslations();
  const navigate = useNavigate();

  const specStyle = (screenType === ScreenType.DESKTOP) ? { flex: 1 } : {};

  const handleEquipe = () => {
    navigate(ClientRoutes.VUE_CONTACT);
  };

  return (
    <Card
      id="contact-card"
      className={styles.equipeCard}
      style={{
        ... specStyle,
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        borderRadius: "8px"
      }}
    >
      <CardContent style={{ flex: 1, padding: "24px" }}>
        <div
          style={{
            height: 0,
            display: "flex",
            justifyContent: "end"
          }}
        >
          <IconContactCard/>
        </div>
        <StyledDivTitleCard>{t.genTitleCardEquipe}</StyledDivTitleCard>
        <StyledContentCard>{t.genDescrEquipe}</StyledContentCard>

        <BoutonAccueilEC
          texte={t.genAffEquipe}
          action={handleEquipe}
        />

      </CardContent>
    </Card>
  )
}

export default ContactCard;

import { importAllSpec } from "../../../../helper/ImportAll";
import { Card, CardContent } from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import {
  StyledContentActus,
  StyledDivTitleCardReverse,
  StyledTitleActus,
} from "../../styledComponents/StyledTextEspaceClient";

const PubliciteCard = ({ styles, isMobile }) => {
  const { t } = useTranslations();

  return (
    <Card
      id='publicite-card'
      className={styles.publiciteCard}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1.5,
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        background:
          "linear-gradient(270deg, rgba(42, 55, 92, 0.20) 0.01%, #2A375C 93.17%), url(" +
          importAllSpec(false)[ "LandingPageImage.jpg" ] +
          ") lightgray 50% / cover no-repeat",
        borderRadius: "8px",
      }}
    >
      <CardContent style={{ flex: "none", padding: "24px" }}>
        <StyledDivTitleCardReverse>
          {t.genTitleCardActus}
        </StyledDivTitleCardReverse>

        <StyledTitleActus>{t.ESPACE_CLIENT_PUB_2}</StyledTitleActus>

        <StyledContentActus>{t.ESPACE_CLIENT_PUB_3}</StyledContentActus>
      </CardContent>
    </Card>
  )
}

export default PubliciteCard;

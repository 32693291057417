const FOCUSED = Symbol("focused");

//Étape n°1
const FAMILLE_PROTECTION_INDUSTRIELLE = Symbol(
  "familleDeProtectionIndustrielle"
);

//Étape n°2
const TYPE_DE_MACHINE = Symbol("SQ0001_Q00030");
const MARQUE_MACHINE = Symbol("SQ0001_Q00040");
const AUTRE = Symbol("autre");
const MODELE_MACHINE = Symbol("SQ0001_Q00051");
const AXE_EMPLACEMENT = Symbol("SQ0001_Q00061");
const POSITION_DE_TRAVAIL = Symbol("SQ0001_Q00070");
const NUMERO_DE_PLAN = Symbol("SQ0001_Q00090");
const CODE_ARTICLE = Symbol("codeArticle");
const VITESSE_DEPLACEMENT = Symbol("SQ0001_Q00071");

//Étape n°3
const TEMPERATURE = Symbol("SQ0013_Q00015");
const AGRESSIONS = Symbol("agressions");
const ENVIRONNEMENT = Symbol("SQ0014_Q00010");

//Étape n°7
const RENSEIGNEZ_QTE = Symbol("renseignezQte");
const QUANTITEE_SOUHAITEE = Symbol("champQuantite");
const COMMENTAIRE = Symbol("SQ0063_Q00010");
const FICHIERS_SUPP = Symbol("fichierSupp");
const SERVICES = Symbol("services");
const DELAI = Symbol("delai");

//Étape n°8
const SOCIETE = Symbol("societe");
const PAYS = Symbol("pays");
const CODE_POSTAL = Symbol("codePostal");
const SECTEUR = Symbol("secteur");
const NOM = Symbol("nom");
const PRENOM = Symbol("prenom");
const FONCTION = Symbol("fonction");
const ADRESSE_MAIL = Symbol("adresseMail");
const TELEPHONE = Symbol("telephone");

export {
  FOCUSED,

  //Étape n°1
  FAMILLE_PROTECTION_INDUSTRIELLE,

  //Étape n°2
  TYPE_DE_MACHINE,
  MARQUE_MACHINE,
  AUTRE,
  MODELE_MACHINE,
  AXE_EMPLACEMENT,
  POSITION_DE_TRAVAIL,
  VITESSE_DEPLACEMENT,
  NUMERO_DE_PLAN,
  CODE_ARTICLE,

  //Étape n°3
  TEMPERATURE,
  AGRESSIONS,
  ENVIRONNEMENT,

  //Étape n°7
  RENSEIGNEZ_QTE,
  QUANTITEE_SOUHAITEE,
  COMMENTAIRE,
  FICHIERS_SUPP,
  SERVICES,
  DELAI,

  //Étape n°8
  SOCIETE,
  PAYS,
  CODE_POSTAL,
  SECTEUR,
  NOM,
  PRENOM,
  FONCTION,
  ADRESSE_MAIL,
  TELEPHONE,
};

import { CONFIGURATEUR } from "../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { WaitingDbInsert } from "../components/WaitingDbInsert";

//Common
import { StepFamilly } from "./commonStep/StepFamilly";
import { StepInfoMachine } from "./commonStep/StepInfoMachine";
import { StepEnvironnement } from "./commonStep/StepEnvironnement";
import { StepAdditionalInfo } from "./commonStep/StepAdditionalInfo";
import { StepContactInfo } from "./commonStep/StepContactInfo";

//S01
import { StepS01BodyDim } from "./S01/StepS01BodyDim";
import { StepS01BodyEnds } from "./S01/StepS01BodyEnds";
import { StepS01Accessories } from "./S01/StepS01Accessories";
import { ResumeS01 } from "./S01/ResumeS01";

//S04
import { StepS04Build } from "./S04/StepS04Build";
import { StepS04BodyDim } from "./S04/StepS04BodyDim";
import { StepS04BodyEnds } from "./S04/StepS04BodyEnds";
import { StepS04Accessories } from "./S04/StepS04Accessories";
import { ResumeS04 } from "./S04/ResumeS04";

//S05
import { StepS05Build } from "./S05/StepS05Build";
import { StepS05BodyDim } from "./S05/StepS05BodyDim";
import { StepS05BodyEnds } from "./S05/StepS05BodyEnds";
import { ResumeS05 } from "./S05/ResumeS05";

//S08
import { StepS08Matter } from "./S08/StepS08Matter";
import { StepS08Shape } from "./S08/StepS08Shape";
import { StepS08BodyDim } from "./S08/StepS08BodyDim";
import { StepS08BodyEnds } from "./S08/StepS08BodyEnds";
import { ResumeS08 } from "./S08/ResumeS08";

//End
import { ErrorDbInsert } from "./ErrorDbInsert";
import { Confirmation } from "./Confirmation";

//Symbol
import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../../../constantes/symbols/SymbolsServicesCommon";
import { StepS08Accessories } from "./S08/StepS08Accessories";
import { StepS06Build } from "./S04_06/StepS06Build";
import { StepS06BodyDim } from "./S04_06/StepS06BodyDim";
import { StepS06BodyEnds } from "./S04_06/StepS06BodyEnds";
import { StepS06Accessories } from "./S04_06/StepS06Accessories";
import { ResumeS06 } from "./S04_06/ResumeS06";

export const ControllerStep = ({ nom, state, action }) => {
  // choix en fonction du type de famille choisi à la première étape
  switch (state.formik.values[FAMILLE_PROTECTION_INDUSTRIELLE.description]) {
    case arrayProductFamilly()[2].code:
      return renderS04(state, action, nom);
    case arrayProductFamilly()[4].code:
      return renderS05(state, action, nom);
    case arrayProductFamilly()[5].code:
      return renderS08(state, action, nom);
    case arrayProductFamilly()[3].code:
      return renderS06(state, action, nom);
    default:
      return renderS01(state, action, nom);
  }
};

export const renderErrorDb = (state, action) => {
  return <ErrorDbInsert state={state} action={action} />;
};

export const renderConfirmation = (state, action) => {
  return <Confirmation state={state} action={action} from={CONFIGURATEUR} />;
};

const renderS01 = (state, action, nom) => {
  switch (state.etape) {
    case 1:
      return <StepFamilly state={state} action={action} nom={nom} />;
    case 2:
      return <StepInfoMachine state={state} action={action} nom={nom} />;
    case 3:
      return <StepEnvironnement state={state} action={action} nom={nom} />;
    case 4:
      return <StepS01BodyDim state={state} action={action} nom={nom} />;
    case 5:
      return <StepS01BodyEnds state={state} action={action} nom={nom} />;
    case 6:
      return <StepS01Accessories state={state} action={action} nom={nom} />;
    case 7:
      return <StepAdditionalInfo state={state} action={action} nom={nom} />;
    case 8:
      return <StepContactInfo state={state} action={action} nom={nom} />;
    case 9:
      return (
        <ResumeS01
          state={state}
          action={action}
          isFrom={CONFIGURATEUR}
          nom={nom}
        />
      );
    case 10:
      return (
        <WaitingDbInsert state={state} action={action} from={CONFIGURATEUR} />
      );
    default:
      return null;
  }
};

const renderS04 = (state, action, nom) => {
  switch (state.etape) {
    case 1:
      return <StepFamilly state={state} action={action} nom={nom} />;
    case 2:
      return <StepInfoMachine state={state} action={action} nom={nom} />;
    case 3:
      return <StepEnvironnement state={state} action={action} nom={nom} />;
    case 4:
      return <StepS04Build state={state} action={action} nom={nom} />;
    case 5:
      return <StepS04BodyDim state={state} action={action} nom={nom} />;
    case 6:
      return <StepS04BodyEnds state={state} action={action} nom={nom} />;
    case 7:
      return <StepS04Accessories state={state} action={action} nom={nom} />;
    case 8:
      return <StepAdditionalInfo state={state} action={action} nom={nom} />;
    case 9:
      return <StepContactInfo state={state} action={action} nom={nom} />;
    case 10:
      return (
        <ResumeS04
          state={state}
          action={action}
          isFrom={CONFIGURATEUR}
          nom={nom}
        />
      );
    case 11:
      return (
        <WaitingDbInsert state={state} action={action} from={CONFIGURATEUR} />
      );
    default:
      return null;
  }
};

const renderS05 = (state, action, nom) => {
  switch (state.etape) {
    case 1:
      return <StepFamilly state={state} action={action} nom={nom} />;
    case 2:
      return <StepInfoMachine state={state} action={action} nom={nom} />;
    case 3:
      return <StepEnvironnement state={state} action={action} nom={nom} />;
    case 4:
      return <StepS05Build state={state} action={action} nom={nom} />;
    case 5:
      return <StepS05BodyDim state={state} action={action} nom={nom} />;
    case 6:
      return <StepS05BodyEnds state={state} action={action} nom={nom} />;
    case 7:
      return <StepAdditionalInfo state={state} action={action} nom={nom} />;
    case 8:
      return <StepContactInfo state={state} action={action} nom={nom} />;
    case 9:
      return (
        <ResumeS05
          state={state}
          action={action}
          isFrom={CONFIGURATEUR}
          nom={nom}
        />
      );
    case 10:
      return (
        <WaitingDbInsert state={state} action={action} from={CONFIGURATEUR} />
      );
    default:
      return null;
  }
};

const renderS08 = (state, action, nom) => {
  switch (state.etape) {
    case 1:
      return <StepFamilly state={state} action={action} nom={nom} />;
    case 2:
      return <StepInfoMachine state={state} action={action} nom={nom} />;
    case 3:
      return <StepEnvironnement state={state} action={action} nom={nom} />;
    case 4:
      return <StepS08Matter state={state} action={action} nom={nom} />;
    case 5:
      return <StepS08Shape state={state} action={action} nom={nom} />;
    case 6:
      return <StepS08BodyDim state={state} action={action} nom={nom} />;
    case 7:
      return <StepS08BodyEnds state={state} action={action} nom={nom} />;
    case 8:
      return <StepS08Accessories state={state} action={action} nom={nom} />;
    case 9:
      return <StepAdditionalInfo state={state} action={action} nom={nom} />;
    case 10:
      return <StepContactInfo state={state} action={action} nom={nom} />;
    case 11:
      return (
        <ResumeS08
          state={state}
          action={action}
          isFrom={CONFIGURATEUR}
          nom={nom}
        />
      );
    case 12:
      return (
        <WaitingDbInsert state={state} action={action} from={CONFIGURATEUR} />
      );
    default:
      return null;
  }
};

const renderS06 = (state, action, nom) => {

  switch (state.etape) {
    case 1:
      return <StepFamilly state={state} action={action} nom={nom} />;
    case 2:
      return <StepInfoMachine state={state} action={action} nom={nom} />;
    case 3:
      return <StepEnvironnement state={state} action={action} nom={nom} />;
    case 4:
      return <StepS06Build state={state} action={action} nom={nom} />;
    case 5:
      return <StepS06BodyDim state={state} action={action} nom={nom} />;
    case 6:
      return <StepS06BodyEnds state={state} action={action} nom={nom} />;
    case 7:
      return <StepS06Accessories state={state} action={action} nom={nom} />;
    case 8:
      return <StepAdditionalInfo state={state} action={action} nom={nom} />;
    case 9:
      return <StepContactInfo state={state} action={action} nom={nom} />;
    case 10:
      return (
        <ResumeS06
          state={state}
          action={action}
          isFrom={CONFIGURATEUR}
          nom={nom}
        />
      );
    case 11:
      return (
        <WaitingDbInsert state={state} action={action} from={CONFIGURATEUR} />
      );
    default:
      return null;
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../../helper/ErrorHandlerMessageProvider";
import { LocalStorage } from "../../../constantes/globalName/LocalStorage";
import { AuthentificationActionType } from "../../../constantes/actionType/AuthentificationActionType";
import authentificationService from "../../service/AuthentificationService";

const accessToken = localStorage.getItem(LocalStorage.ACCESS_TOKEN);
const accessTokenEC = localStorage.getItem(LocalStorage.ACCESS_TOKEN_EC);

/* TODO JBR Cette méthode est l'ancienne méthode utilisé qui ne fonctionne pas dans le cas d'une erreur renvoyé.
  Il faudra revoir la logique, mais en attendant, j'ai créé une autre méthode connexionEC (elle ne gère pas les erreur, mais évite l'effet page blanche)
*/
export const connexionECBackup = createAsyncThunk(
  AuthentificationActionType.CONNEXION_EC,
  async ({ clientCode, clientPassword }, thunkAPI) => {
    try {

      if (localStorage.getItem("accessTokenEC")) {
        localStorage.removeItem("accessTokenEC");
      }
      if (localStorage.getItem("clientId")) {
        localStorage.removeItem("clientId");
      }

      const reponse = await authentificationService.connexionEC(
        clientCode,
        clientPassword
      );

      if (reponse.data.clientId) {
        localStorage.setItem("clientId", btoa(reponse.data.clientId));
      }
      let token = null;
      if (reponse.data.accesEC && reponse.data.accesEC === "Vrai") {
        token = JSON.parse(atob(reponse.data.accessTokenEC.split(".")[1]));
      } else {
        thunkAPI.dispatch(
          setMessage({ message: "Accès non autorisé", type: "error" })
        );
      }

      thunkAPI.dispatch(setMessage(null));
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

/* TODO JBR Voir le commentaire sur connexionEcBackup
*   Il faudra choisir une méthode entre les deux, mais pour l'heure, aucune ne fonctionne correctement.
*/
export const connexionEC= createAsyncThunk(
  AuthentificationActionType.CONNEXION_EC,
  async ({clientCode, clientPassword}, thunkAPI) => {
    localStorage.removeItem("accessTokenEC");
    localStorage.removeItem("clientId");

    try {
      const response = await authentificationService.connexionEC(
        clientCode,
        clientPassword
      )
      if (response.data.clientId) {
        localStorage.setItem("clientId", btoa(response.data.clientId));
      }

      let token = null;
      if (response.data.accesEC && response.data.accesEC === "Vrai") {
        token = JSON.parse(atob(response.data.accessTokenEC.split(".")[ 1 ]));
      }
      thunkAPI.dispatch(setMessage(null));
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const connexion = createAsyncThunk(
  AuthentificationActionType.CONNEXION,
  async ({ email, motDePasse }, thunkAPI) => {
    try {
      const reponse = await authentificationService.connexion(
        email,
        motDePasse
      );
      const token = JSON.parse(atob(reponse.data.accessToken.split(".")[1]));
      thunkAPI.dispatch(setMessage(null));
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const rafraichissement = createAsyncThunk(
  AuthentificationActionType.RAFRAICHISSEMENT,
  async (thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = await authentificationService.rafraichissement(
        accessToken
      );
      const token = JSON.parse(atob(reponse.data.accessToken.split(".")[1]));

      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const rafraichissementEC = createAsyncThunk(
  AuthentificationActionType.RAFRAICHISSEMENT_EC,
  async (thunkAPI) => {
    try {
      const accessTokenEC = {
        [LocalStorage.ACCESS_TOKEN_EC]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN_EC
        ),
      };
      const reponse = await authentificationService.rafraichissementEC(
        accessTokenEC
      );
      const token = JSON.parse(atob(reponse.data.accessTokenEC.split(".")[1]));

      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const deconnexion = createAsyncThunk(
  AuthentificationActionType.DECONNEXION,
  async () => {
    authentificationService.deconnexion();
  }
);

export const deconnexionEC = createAsyncThunk(
  AuthentificationActionType.DECONNEXION_EC,
  async () => {
    authentificationService.deconnexionEC();
  }
);

const initialState = {
  isLoggedIn: !!(accessToken || accessTokenEC),
  accessToken: accessToken ? JSON.parse(atob(accessToken.split(".")[1])) : null,
  accessTokenEC: accessTokenEC
    ? JSON.parse(atob(accessTokenEC.split(".")[1]))
    : null,
};

const authentificationSlice = createSlice({
  name: AuthentificationActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(connexion.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessToken = action.payload.token;
      })
      .addCase(connexion.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.accessToken = null;
      })
      .addCase(connexionEC.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessTokenEC = action.payload.token;
      })
      .addCase(connexionEC.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.accessTokenEC = null;
      })
      .addCase(rafraichissement.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessToken = action.payload.token;
      })
      .addCase(rafraichissement.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.accessToken = null;
      })
      .addCase(rafraichissementEC.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessTokenEC = action.payload.token;
      })
      .addCase(rafraichissementEC.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.accessTokenEC = null;
      })
      .addCase(deconnexion.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.accessToken = null;
      })
      .addCase(deconnexionEC.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.accessTokenEC = null;
      });
  },
});

const { reducer } = authentificationSlice;
export default reducer;

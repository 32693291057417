import { Serveur } from "../../constantes/services/Serveur";
import { EspaceClient } from "../../constantes/services/EspaceClient";
import { jsonPost } from '../../helper/fetchUtil.js';
import { serviceCleaner } from "../../helper/ServiceCleaner";

const getAllArticles = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_ARTICLES_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_ARTICLES;
  const json = await jsonPost(GET_ALL_ARTICLES_ENDPOINT, {
    type: 'ListeArticle',
    clientId: params.clientId,
    accessTokenEC: params.accessTokenEC
  });
  if (! (json instanceof Array))
    throw new Error("Tableau d'articles non récupéré");
  return json;
};

const getOneArticleEC = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.articleId)
    throw new Error('articleId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_ARTICLES_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_ARTICLES;
  const result = await jsonPost(GET_ALL_ARTICLES_ENDPOINT, {
    clientId: params.clientId,
    articleId: params.articleId,
    accessTokenEC: params.accessTokenEC
  });
  // FIXME générer une erreur si article non trouvé :
  // Pour l'instant l'API renvoie une erreur 500 si l'article n'est pas trouvé au lieu de renvoyer un résultat vide
  const json = serviceCleaner(result);
  return json;
};

const getAllCommandes = async (params) => {
  if (params.clientId === undefined)
    throw new Error('clientId attendu');
  if (params.contactId === undefined)
    throw new Error('contactId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_COMMANDES_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_COMMANDES;
  const json = await jsonPost(GET_ALL_COMMANDES_ENDPOINT, {
    type: "ListeCommande",
      clientId: params.clientId,
      contactId: params.contactId,
      accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getAllCommandesByArticleId = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.articleId)
    throw new Error('articleId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_COMMANDES;
  const json = await jsonPost(GET_ALL_OFFRES_ENDPOINT, {
    clientId: params.clientId,
    articleId: params.articleId,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getTypesCommande = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_TYPES_COMMANDE_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_TYPES_COMMANDE;
  const json = await jsonPost(GET_TYPES_COMMANDE_ENDPOINT, {
    clientId: params.clientId,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getOneCommande = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.commandeId)
    throw new Error('commandeId attendu');

  // le token peut être nul pour une visu de commande sans authentification
  if (params.accessTokenEC === undefined)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_COMMANDES;
  const json = await jsonPost(GET_ALL_OFFRES_ENDPOINT, {
    clientId: params.clientId,
    commandeId: params.commandeId,
    accessTokenEC: params.accessTokenEC
  });
  if (json === null)
    throw new Error('Commande introuvable'); // FIXME traduction
  return json;
};

const getAllOffres = async (params) => {
  if (params.clientId === undefined)
    throw new Error('clientId attendu');
  if (params.contactId === undefined)
    throw new Error('contactId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_OFFRES;
  const json = await jsonPost(GET_ALL_OFFRES_ENDPOINT, {
    type: "ListeOffre",
    clientId: params.clientId,
    contactId: params.contactId,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getAllOffresByArticleId = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.articleId)
    throw new Error('articleId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_OFFRES;
  const json = await jsonPost(GET_ALL_OFFRES_ENDPOINT, {
    clientId: params.clientId,
    articleId: params.articleId,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getOneOffreId = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.offreId)
    throw new Error('offreId attendu');

  // le token peut être nul pour une visu de commande sans authentification
  if (params.accessTokenEC === undefined)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_OFFRES_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_OFFRES;
  // TODO erreur offreId attendu json/getOffresEC
  const json = await jsonPost(GET_ALL_OFFRES_ENDPOINT, {
    clientId: params.clientId,
    offreId: params.offreId,
    accessTokenEC: params.accessTokenEC
  });
  if (json.commandeCode === undefined)
    throw new Error('Élément introuvable'); // FIXME traduction
  return json;
};

const getAllContacts = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_CLIENTS_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_CLIENTS; // getContactsEC
  const json = await jsonPost(GET_ALL_CLIENTS_ENDPOINT, {
    type: 'ListeContact',
    clientId: params.clientId,
    accessTokenEC: params.accessTokenEC
  });
  if (! (json instanceof Array))
    throw new Error("Tableau des contacts non récupéré");
  return json;
};

const getDetailsClient = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_DETAILS_CLIENT_ENDPOINT = Serveur.URL_API + EspaceClient.GET_DETAILS_CLIENT; // getContactEC
  const json = await jsonPost( GET_DETAILS_CLIENT_ENDPOINT, {
    type: 'DetailsClient',
    clientId: params.clientId,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getOneContact = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.contactId)
    throw new Error('contactId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_DETAILS_CONTACT_ENDPOINT = Serveur.URL_API + EspaceClient.GET_DETAILS_CLIENT; // getContactEC
  //TODO erreur "Pas de numéro de client transmis"
  const json = await jsonPost(GET_DETAILS_CONTACT_ENDPOINT, {
    clientId: params.clientId,
    contactId: params.contactId,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getAllNC = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_NCL_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_NCL;
  const json = await jsonPost(GET_ALL_NCL_ENDPOINT, {
    clientId: params.clientId,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getOneNC = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.ncId)
    throw new Error('ncId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_NCL_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_NCL;
  const json = await jsonPost(GET_ALL_NCL_ENDPOINT, {
    clientId: params.clientId,
    ncId: params.ncId,
    accessTokenEC: params.accessTokenEC
  });
  // FIXME pourquoi devoir tester json.length ?
  if (json === null || json.length === 0)
    throw new Error('Élément introuvable'); // FIXME traduction
  return json;
};

const getEtatNC = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ETAT_NC_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ETAT_NC;
  const json = await jsonPost(GET_ETAT_NC_ENDPOINT, {
        clientId: params.clientId,
        accessTokenEC: params.accessTokenEC
  });
  return json;
};

const getAllFamilleArticle = async (params) => {
  if (! params.clientId)
    throw new Error('clientId attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const GET_ALL_FAMILLE_ARTICLE_ENDPOINT = Serveur.URL_API + EspaceClient.GET_ALL_FAMILLE_ARTICLE;
  const json = await jsonPost(GET_ALL_FAMILLE_ARTICLE_ENDPOINT, {
      clientId: params.clientId,
      accessTokenEC: params.accessTokenEC
  });
  return json;
};

const sendMailProgrammerVisite = async (params) => {
  if (! params.contactById)
    throw new Error('contactById attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const SEND_MAIL_PROGRAMMER_VISITE_ENDPOINT = Serveur.URL_API + EspaceClient.SEND_MAIL_EC;
  const json = await jsonPost(SEND_MAIL_PROGRAMMER_VISITE_ENDPOINT, {
    type: 'programmerVisite',
    contactById: params.contactById,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const sendMailEtreRappeler = async (params) => {
  if (! params.contactById)
    throw new Error('contactById attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const SEND_MAIL_ETRE_RAPPELER_ENDPOINT = Serveur.URL_API + EspaceClient.SEND_MAIL_EC;
  const json = await jsonPost(SEND_MAIL_ETRE_RAPPELER_ENDPOINT, {
    type: 'etreRappele',
    contactById: params.contactById,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const sendMailModifContact = async (params) => {
  if (! params.anciennesInfos)
    throw new Error('anciennesInfos attendu');
  if (! params.nouvellesInfos)
    throw new Error('nouvellesInfos attendu');
  if (! params.accessTokenEC)
    throw new Error('accessTokenEC attendu');

  const SEND_MAIL_MODIF_CONTACT_ENDPOINT = Serveur.URL_API + EspaceClient.SEND_MAIL_EC;
  const json = await jsonPost(SEND_MAIL_MODIF_CONTACT_ENDPOINT, {
    type: "modificationContact",
    anciennesInfos: params.anciennesInfos,
    nouvellesInfos: params.nouvellesInfos,
    accessTokenEC: params.accessTokenEC
  });
  return json;
};

const sendStatsToFPS = async () => {
  const SEND_STATS_TO_FPS_ENDPOINT = Serveur.URL_API + EspaceClient.SEND_STATS_TO_FPS;
  const json = await jsonPost(SEND_STATS_TO_FPS_ENDPOINT);
  return json;
};

const espaceClientService = {
  getAllArticles,
  getOneArticleEC,
  getAllCommandes,
  getTypesCommande,
  getAllOffres,
  getDetailsClient,
  getAllOffresByArticleId,
  getOneOffreId,
  getAllCommandesByArticleId,
  getOneCommande,
  getAllNC,
  getEtatNC,
  getOneNC,
  getAllContacts,
  getOneContact,
  sendMailProgrammerVisite,
  sendMailEtreRappeler,
  sendMailModifContact,
  getAllFamilleArticle,
  sendStatsToFPS,
};

export default espaceClientService;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { PaysActionType } from "../../constantes/actionType/PaysActionType";
import paysService from "../service/PaysService";
import { langJsonFormatter } from "../../helper/ServiceCleaner";
import { ApiStatus } from '../../constantes/ApiStatus';

export const getAllPays = createAsyncThunk(
  PaysActionType.GET_ALL_PAYS,
  async (lang, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const paysList = state.pays.paysList;
      // optimisation : ne pas renvoyer de requête si liste déjà récupérée
      if (paysList.length > 0)
        return paysList;
      const reponse = await paysService.getAllPays();
      const listPaysAllValues = langJsonFormatter(lang, thunkAPI, reponse);
      const listPaysSelected = listPaysAllValues.filter((elt) =>
        paysSelected.includes(elt.reference)
      );
      const listPaysSelected_sorted = listPaysSelected.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      const listAttenduPaysSelected = listPaysAllValues.filter(
        (elt) => !paysSelected.includes(elt.reference)
      );
      const paysAttendus = listPaysSelected_sorted.concat(
        divider,
        listAttenduPaysSelected
      );
      return paysAttendus;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {
  paysList: [],
  apiStatus: ApiStatus.IDLE,
  apiError: null
};

const paysSelected = ["FR", "BE", "DE"];
const divider = {
  code: "",
  name: "",
  isDivider: true,
};

const paysSlice = createSlice({
  name: PaysActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllPays.pending, (state, _) => {
        state.apiStatus = ApiStatus.IDLE;
        state.apiError = null;
      })
      .addCase(getAllPays.fulfilled, (state, action) => {
        state.apiStatus = ApiStatus.SUCCESS;
        state.apiError = null;

        state.paysList = action.payload;
      })
      .addCase(getAllPays.rejected, (state, action) => {
        state.apiStatus = ApiStatus.ERROR;
        state.apiError = action.payload;
        state.paysList = [];
      });
  },
});

const { reducer } = paysSlice;
export default reducer;

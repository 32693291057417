//Librairies de fonctionnement
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import {
  ARTICLES,
  CAP,
  formulaireRechercherP1,
  formulaireRechercherP2,
  QUANTITEE_SOUHAITEE_CODE_ARTICLE,
} from "./ChampsFormulaires/RechercherChamps";
import { initialValuesRechercher } from "./ChampsFormulaires/Initialisation/RechercherInitialValues";
import { ValidRecherche } from "./ChampsFormulaires/validationsFormulaires/ValidRecherche";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { useDispatch, useSelector } from "react-redux";
import { importAllSpec } from "../../helper/ImportAll";
import {
  StyledDivButton,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
  StyledDivFormSection,
  StyledDivH1ContactRecherche,
  StyledDivLineRechercher,
  StyledDivQuantiteEtCode,
  StyledDivSticky,
} from "./styledComponents/StyledDiv";
import { LoadingErrorMessage } from "./components/LoadingMessageComponents.jsx";
import { mergeEtape, resetIsFormularySent, sendFormulaireRechercher, } from "../slice/FormulaireReducer";
import { useTranslations } from "./components/hook/useTranslations";
import { getAllSecteurs } from "../slice/SecteurReducer";
import { getAllFonctions } from "../slice/FonctionReducer";
import { getAllPays } from "../slice/PaysReducer";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";
import { WaitingDbInsert } from "./components/WaitingDbInsert";
import { Confirmation } from "./formulaires/Confirmation";
import {
  StyledButtonAjouterQuantite,
  StyledButtonContinuer,
  StyledButtonPrec,
  StyledButtonRetirerQuantite,
} from "./styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "./styledComponents/StyledIcon";
import { StyledPContact } from "./styledComponents/StyledP";
import { genererFormulaire } from "./components/generateFormulary/CasesGeneration";
import { TEXTFIELD_RETURN_ARRAY_MULTI } from "./components/generateFormulary/types/TypeFieldsFormulary";
import { ErrorDbInsert } from "./formulaires/ErrorDbInsert";
import { RECHERCHER } from "../../constantes/symbols/SymbolsResumeDisplayedFrom";
import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  FONCTION,
  NOM,
  PAYS,
  PRENOM,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
} from "../../constantes/symbols/SymbolsCommon";

/**
 * It generates a form with the fields of the form.
 * @returns The form is being returned.
 */
export const Rechercher = () => {
  const [styles, t, booleanIdentite] = useOutletContext();
  const [step, setStep] = useState(1);
  const [upToDateSecteursList, setUpToDateSecteursList] = useState([]);
  const [upToDateFonctionsList, setUpToDateFonctionsList] = useState([]);
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [formulaire, setFormulaire] = useState("");
  const [nbChamps, setNbChamps] = useState(1);
  const [bouttonsList, setBouttonList] = useState([]);
  const [isAllowedToQuit, setIsAllowedToQuit] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useTranslations();
  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);
  const { formikList } = useSelector((state) => state.formulaire);
  const { isFormularySent } = useSelector((state) => state.formulaire);

  const cssButtonPrec = styles.cssButtonPrec;
  const cssButton = styles.cssButton;
  const cssPContact = styles.cssPContact;
  const cssFontFamily = styles.cssFontFamily;
  const sectionFormSansBg = styles.sectionFormSansBg;
  const sectionFormMarginRechercher = styles.sectionFormMarginRechercher;
  const maxChamp = 100;


  useEffect(() => {
    // FIXME appels dispatch à faire en amont
    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());
  }, [dispatch]);

  useEffect(() => {
    if (!secteursList)
      return;
    setUpToDateSecteursList(secteursList);
  }, [secteursList]);

  useEffect(() => {
    if (!fonctionsList)
      return;
    setUpToDateFonctionsList(fonctionsList);
  }, [fonctionsList]);

  useEffect(() => {
    if (!paysList)
      return;
    setUpToDatePaysList(paysList);
  }, [paysList]);

  useEffect(() => {
    if (!formikList)
      return;
    setFormulaire(formikList);
  }, [formikList]);

  useEffect(() => {
    const formulaireFPS = localStorage.getItem(LocalStorage.FORMULAIRE_FPS)
      ? JSON.parse(atob(localStorage.getItem(LocalStorage.FORMULAIRE_FPS)))
      : null;
    const informationsClient = localStorage.getItem(
      LocalStorage.INFORMATIONS_CLIENT
    )
      ? JSON.parse(atob(localStorage.getItem(LocalStorage.INFORMATIONS_CLIENT)))
      : {};

    dispatch(mergeEtape(Object.assign({}, formulaire, informationsClient)));
  }, []);

  useEffect(() => {
    const informationsClient = localStorage.getItem(
      LocalStorage.INFORMATIONS_CLIENT
    )
      ? JSON.parse(atob(localStorage.getItem(LocalStorage.INFORMATIONS_CLIENT)))
      : {};

    localStorage.setItem(
      LocalStorage.INFORMATIONS_CLIENT,
      btoa(
        JSON.stringify({
          [ SOCIETE.description ]: informationsClient.societe,
          [ PAYS.description ]: informationsClient.pays,
          [ CODE_POSTAL.description ]: informationsClient.codePostal,
          [ SECTEUR.description ]: informationsClient.secteur,
          [ NOM.description ]: informationsClient.nom,
          [ PRENOM.description ]: informationsClient.prenom,
          [ FONCTION.description ]: informationsClient.fonction,
          [ ADRESSE_MAIL.description ]: informationsClient.adresseMail,
          [ TELEPHONE.description ]: informationsClient.telephone,
        })
      )
    );

    dispatch(mergeEtape(Object.assign({}, formulaire, informationsClient)));
  }, [localStorage.getItem(LocalStorage.INFORMATIONS_CLIENT)]);

  useEffect(() => {
    if (nbChamps <= maxChamp) {
      const newButtonList = [...Array(nbChamps)]
        .map((value, index) => {
          return [
            {
              nom: QUANTITEE_SOUHAITEE_CODE_ARTICLE,
              label: t.quantiteSouhaitee,
              type: TEXTFIELD_RETURN_ARRAY_MULTI,
              value: ARTICLES,
              positionInArray: index,
              cssTextfield: styles.cssTextfieldQuantiEtCode,
            },
            {
              nom: CAP,
              label: t.codeArticlePrecedent,
              type: TEXTFIELD_RETURN_ARRAY_MULTI,
              value: ARTICLES,
              positionInArray: index,
              cssTextfield: styles.cssTextfieldQuantiEtCode,
            },
          ];
        })
        .flat(1);
      setBouttonList(newButtonList);
      formik.setFieldValue("nbFieldToKeep", nbChamps);
    }
  }, [nbChamps, t]);

  const handleSubmit = (form) => {
    form.langue = lang;
    form.marque = booleanIdentite ? "MP" : "RC";
    form.timestamp = Date.now();
    dispatch(sendFormulaireRechercher(form));
    setStep(step + 1);
  };

  const handleRetour = () => {
    navigate(GuestRoutes.ACCUEIL);
  };

  const formik = useFormik({
    initialValues: { ...initialValuesRechercher(formulaire, maxChamp) },
    validationSchema: ValidRecherche(
      upToDateSecteursList,
      upToDateFonctionsList,
      upToDatePaysList,
      t
    ),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const informationsClient = localStorage.getItem(LocalStorage.INFORMATIONS_CLIENT)
      ? JSON.parse(atob(localStorage.getItem(LocalStorage.INFORMATIONS_CLIENT)))
      : {};
    const numeroOffre = localStorage.getItem(LocalStorage.OFFRE_CLIENT)
    if (numeroOffre) {
      console.log(formik)
      formik.setFieldValue("numeroOffreTransmise", numeroOffre);
    }

    if (informationsClient) {
      // Associe les valeurs de informationsClient aux valeurs de formik
      formik.setFieldValue('societe', informationsClient.societe);
      formik.setFieldValue('pays', informationsClient.pays);
      formik.setFieldValue('codePostal', informationsClient.codePostal);
      formik.setFieldValue('secteur', informationsClient.secteur);
      formik.setFieldValue('nom', informationsClient.nom);
      formik.setFieldValue('prenom', informationsClient.prenom);
      formik.setFieldValue('fonction', informationsClient.fonction);
      formik.setFieldValue('adresseMail', informationsClient.adresseMail);
      formik.setFieldValue('telephone', informationsClient.telephone);
    }

    // Dispatch de l'objet combiné (formulaire et informationsClient)
    dispatch(mergeEtape(Object.assign({}, formulaire, informationsClient)));
  }, []);


  const ajouterUnChampBouton = () => {
    if (nbChamps < maxChamp) {
      setNbChamps(nbChamps + 1);
    }
  };

  const retirerUnChampBouton = () => {
    if (nbChamps > 1) {
      setNbChamps(nbChamps - 1);
      setBouttonList(
        bouttonsList.filter((element, index) => index < bouttonsList.length - 2)
      );
    }
  };

  const afficherBoutonRetirer = () => {
    if (nbChamps > 1) {
      return (
        <StyledButtonRetirerQuantite
          className={cssFontFamily}
          variant="contained"
          onClick={retirerUnChampBouton}
          startIcon={<RemoveIcon/>}
        >
          {t.retirerDerniereQuantite}
        </StyledButtonRetirerQuantite>
      );
    }
  };

  const afficherBoutonAjouter = () => {
    return (
      <StyledButtonAjouterQuantite
        className={cssFontFamily}
        variant="contained"
        onClick={ajouterUnChampBouton}
        startIcon={<AddIcon/>}
      >
        {t.descrBouttonAjouter}
      </StyledButtonAjouterQuantite>
    );
  };

  const afficherBoutonList = (bouttonsList, formik, styles) => {
    if (bouttonsList !== []) {
      return genererFormulaire(bouttonsList, formik, styles);
    }
  };

  const resetFormularySentState = () => {
    dispatch(resetIsFormularySent());
  };

  const resetFormulary = () => {
    setFormulaire({});
  };

  const state = {
    styles,
    t,
    booleanIdentite,
    formik,
  };

  const action = {
    setEtape: setStep,
    resetFormularySentState,
    resetFormulary,
    setIsAllowedToQuit,
  };

  if (!upToDateSecteursList || !upToDateFonctionsList || !upToDatePaysList)
    return <LoadingErrorMessage t={t}/>;

  if (isFormularySent.status !== 200) {
    return (
      <ErrorDbInsert
        etape={step}
        setEtape={setStep}
        t={t}
        styles={styles}
        resetFormularySentState={resetFormularySentState}
        resetFormulary={resetFormulary}
      />
    );
  }
  if (isFormularySent.isBackendOk)
    return <Confirmation state={state} action={action} from={RECHERCHER} />;

  if (step === 1) {
    return (
      <StyledDivFormAndImageContainer>
        <StyledDivFormContainer>
          <StyledDivH1ContactRecherche>
            <h1>{t.titrePageRechercher}</h1>
          </StyledDivH1ContactRecherche>
          <StyledPContact className={cssPContact}>
            {t.descrPageRechercher}
          </StyledPContact>
          <StyledDivLineRechercher/>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
              <StyledDivFormSection className={sectionFormSansBg}>
                {genererFormulaire(formulaireRechercherP1(styles, t), formik)}
                <div className={sectionFormMarginRechercher}>
                  <StyledDivQuantiteEtCode>
                    {afficherBoutonList(bouttonsList, formik, styles)}
                  </StyledDivQuantiteEtCode>
                  <StyledDivQuantiteEtCode style={{ marginBottom: "2.5em" }}>
                    {afficherBoutonAjouter()}
                    {afficherBoutonRetirer()}
                  </StyledDivQuantiteEtCode>
                </div>
                {genererFormulaire(
                  formulaireRechercherP2(
                    upToDatePaysList,
                    upToDateSecteursList,
                    upToDateFonctionsList,
                    styles,
                    t
                  ),
                  formik
                )}
              </StyledDivFormSection>
              <Grid item>
                <StyledDivButton>
                  <StyledButtonPrec
                    className={cssButtonPrec}
                    variant="outlined"
                    onClick={handleRetour}
                    startIcon={<StyledArrowBackOutlinedIcon/>}
                  />
                  <StyledButtonContinuer
                    className={cssButton}
                    variant="contained"
                    type="submit"
                  >
                    {t.bouttonEnvoyer}
                  </StyledButtonContinuer>
                </StyledDivButton>
              </Grid>
            </Grid>
          </form>
        </StyledDivFormContainer>

        <StyledDivSticky>
          <img
            className="alone"
            src={importAllSpec(booleanIdentite)["LandingPageImage.jpg"]}
            src={importAllSpec(booleanIdentite)[ "LandingPageImage.jpg" ]}
            alt="TODO: define alt"
          />
        </StyledDivSticky>
      </StyledDivFormAndImageContainer>
    );
  } else {
    return <WaitingDbInsert state={state} from={RECHERCHER}/>;
  }
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, Card, CardContent } from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { EC_COLORS } from "../../espaceClient/common/EcColor";
import { SeeMoreIconEC } from "../../components/SeeMoreIconEC";
import { BoutonAccueilEC } from "../../components/espaceClient/BoutonAccueilEC";
import { ApiStatus } from "../../../../constantes/ApiStatus";
import { apiStatusMessageForList } from "../../../../helper/apiStatusHelper.js";

import {
  StyledContentCard,
  StyledContentSubCardReverse,
  StyledDivTitleCardReverse,
  StyledDivTitleSubCardReverse,
} from "../../styledComponents/StyledTextEspaceClient";

const CommandeCard = ({ styles }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  const [upToDateCommandesList, setUpToDateCommandesList] = useState([]);
  const [listMessage, setListMessage] = useState(null);

  const { commandeList } = useSelector((state) => state.espaceClient);

  useEffect(() => {
    setListMessage(apiStatusMessageForList(commandeList));

    if (commandeList.apiStatus === ApiStatus.IDLE)
      return;

    // Prendre les 2 premières commandes
    setUpToDateCommandesList(commandeList.list.slice(0, 2));

  }, [commandeList]);


  const handleOneCommande = (commandeId) => () => {
    navigate(ClientRoutes.VUE_COMMANDE + commandeId);
  };

  const handleCommandes = () => {
    navigate(ClientRoutes.VUE_COMMANDE);
  };

  const divStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%", // Assure que les cartes utilisent toute la largeur disponible
  };

  return (
      <Card
        id="commande-card"
        className={styles.commandeCard}
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: EC_COLORS.VIOLET,
          boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
          borderRadius: "8px",
        }}
      >
        <CardContent style={{ flex: 1, padding: "24px" }}>
          <StyledDivTitleCardReverse>
            {t.genTitleCardCommande}
          </StyledDivTitleCardReverse>
          <div className={styles.commandeContainer} style={divStyle} >
            {listMessage !== null ? (
              <StyledContentCard className={styles.aucuneCommande}>{listMessage}</StyledContentCard>
            ) : (
              upToDateCommandesList.map((commande, index) => (
                <Card
                  key={index}
                  className={styles.singleCommandeCard}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(50% - 1rem)",
                    borderRadius: "8px",
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.25) 100%)",
                    boxShadow: "0px 0px 4px 2px rgba(255, 255, 255, 0.15)",
                  }}
                >
                  <CardContent style={{ flex: 1 }}>
                    <StyledDivTitleSubCardReverse>
                      {commande.commandeCode}
                    </StyledDivTitleSubCardReverse>
                    <StyledContentSubCardReverse>
                      {commande.reference}
                    </StyledContentSubCardReverse>
                    <StyledContentSubCardReverse>
                      {commande.datePub}
                    </StyledContentSubCardReverse>
                    <StyledContentSubCardReverse>
                      {commande.totalHT} €
                    </StyledContentSubCardReverse>

                    <Badge
                      style={{
                        position: "initial",
                        backgroundColor: "white",
                        color: EC_COLORS.DARK_BLUE,
                        paddingTop: "0.1rem",
                        paddingBottom: "0.1rem",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        marginTop: "0.5rem",
                        borderRadius: "5px",
                      }}
                    >
                      <StyledContentCard style={{ margin: "0" }}>
                        {commande.livr}
                      </StyledContentCard>
                    </Badge>
                  </CardContent>
                  <SeeMoreIconEC
                    dark={true}
                    method={handleOneCommande(commande.commandeId)}
                  />
                </Card>
              ))
            )}
          </div>
          <BoutonAccueilEC
            texte={t.genAffToutesCommandes}
            action={handleCommandes}
            backgroundColor={"dark"}
          />

        </CardContent>
      </Card>
  );
}

export default CommandeCard;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { DetailsCommande } from "./DetailsCommande";
import { selectCommandeEC } from "../../../slice/EspaceClientReducer";
import { ApiStatus } from '../../../../constantes/ApiStatus';
import { LoadingMessage, LoadingErrorMessage } from "../../components/LoadingMessageComponents.jsx";
import { useTranslations } from "../../components/hook/useTranslations";
import { BoutonRetourEC } from "../../components/espaceClient/BoutonAccueilEC";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { StyledDivWrapperVues } from "../../styledComponents/StyledDivEspaceClient";

const VueVisuCommande = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { currentCommande } = useSelector((state) => state.espaceClient);

  const { clientId, commandeId } = useParams();

  useEffect( () => {
    dispatch(selectCommandeEC({ clientId, commandeId }));
  }, [clientId, commandeId]);

  const navigateToEC = () => {
    navigate(ClientRoutes.ACCUEIL_EC);
  };

  if (currentCommande.apiStatus === ApiStatus.IDLE)
    return <LoadingMessage t={t} />;

  if (currentCommande.apiStatus === ApiStatus.ERROR)
    // TODO améliorer le rendu
    return <>
        <LoadingErrorMessage t={t} errorMessage={currentCommande.apiError}/>;
      </>

  return (
    <StyledDivWrapperVues>
      <BoutonRetourEC texte={t.accesEspaceClient} action={navigateToEC} backgroundColor={"#F8F9FF"} />
      <DetailsCommande/>
    </StyledDivWrapperVues>
  );
};

export { VueVisuCommande };

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  societe: "",
  pays: "",
  codePostal: "",
  secteur: "",
  nom: "",
  prenom: "",
  fonction: "",
  adresseMail: "",
  telephone: ""
};

const LOCAL_STORAGE_KEY = 'infos-client';

// Restaurer l'état depuis le localStorage s'il existe
const savedState = localStorage.getItem(LOCAL_STORAGE_KEY );
const actualState = savedState ? JSON.parse(savedState) : initialState;

const infosClientSlice = createSlice({
  name: 'infosClient',
  initialState: actualState,
  reducers: {
    setInfosClient: (state, action) => {
      // Valider et mettre à jour uniquement les propriétés définies dans initialState
      Object.keys(initialState).forEach(key => {
        if (key in action.payload) {
          state[key] = action.payload[key];
        }
      });
      // Sauvegarder l'état dans le localStorage après chaque mise à jour
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }
  }
});

const { reducer, actions } = infosClientSlice;

export const { setInfosClient } = actions;
export default reducer;

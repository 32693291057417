import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { ChoixContactActionType } from "../../constantes/actionType/ChoixContactActionType";
import choixContactService from "../service/ChoixContactService";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { ApiStatus } from '../../constantes/ApiStatus';
import { getAllCommandesEC, getAllOffresEC, selectContact } from "./EspaceClientReducer";

const selectionnerContact = createAsyncThunk(
  ChoixContactActionType.SELECTIONNER_CONTACT,
  async ({contactId, forcage}, thunkAPI) => {
    const { choixContact } = thunkAPI.getState();

    if (forcage === undefined)
      forcage = false;

    if (contactId === undefined) {
      // l'ID du contact n'est pas précisé, reprendre l'ID actuel
      const currentContact = choixContactService.recupererContact();
      contactId = currentContact?.contactId ?? null;
      // si on n'est pas en mode forcage, essayer d'éviter un rafraîchissement inutile
      if (forcage === false) {
        if ( choixContact.apiStatus === ApiStatus.SUCCESS ) {
          const contactActuelId = choixContact.contact?.contactId || null;
          if (contactId === contactActuelId)
            return;
        }
      }
    }

    if (contactId === 'all')
      contactId = null;

    try {
      const { authentification } = thunkAPI.getState();
      const clientId = authentification.clientId;

      // 1ère étape : faire la connexion et attendre la mise à jour du state authentification
      const dispatch = thunkAPI.dispatch;
      const contact = await dispatch(selectionnerContactInterne({ clientId, contactId } )).unwrap();

      // 2ème étape : déclencher les mises à jour dépendantes
      // FIXME déclencher les dispatch dépendant dans le thunk selectContact
      dispatch(getAllCommandesEC({ clientId, contactId }));
      dispatch(getAllOffresEC({ clientId, contactId }));
      dispatch(selectContact({ clientId, contactId }));

      return contact ;
    } catch (error) {
      console.log(error);
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const selectionnerContactInterne = createAsyncThunk(
  'choixContact/_selectionnerInterne',
  async ({ clientId, contactId }, thunkAPI) => {
    if (clientId === null)
      return null;
    try {
      return await choixContactService.selectionnerContact({ contactId });
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);


const initialState = {
  contact: choixContactService.recupererContact(),
  apiStatus: ApiStatus.IDLE,
  apiError: null
};

const choixContactSlice = createSlice({
  name: ChoixContactActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      // thunk selectionnerContactInterne
      .addCase(selectionnerContactInterne.pending, (state, _) => {
        state.apiStatus = ApiStatus.IDLE;
        state.apiError = null;
      })
      .addCase(selectionnerContactInterne.fulfilled, (state, action) => {
        state.apiStatus = ApiStatus.SUCCESS;
        state.apiError = null;
        state.contact = action.payload;
      })
      .addCase(selectionnerContactInterne.rejected, (state, action) => {
        state.apiStatus = ApiStatus.ERROR;
        state.apiError = action.payload;
      })
  }
});

const { reducer } = choixContactSlice;
export { selectionnerContact };
export default reducer;

import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import { GuestRoutes } from "../../../../constantes/routes/GuestRoutes";
import { useConfiguration } from "../../components/hook/useConfiguration";
import { formulaireFamilleProtectionIndustrielle } from "../../ChampsFormulaires/S01/etape1/ChampsFamilleProtectionIndustrielle";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";

import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
  StyledDivPhotoFamille,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";


/*
 * Première étape commune permettant de choisir une famille de produits
 */
export const StepFamilly = ({
  state: {
    booleanIdentite,
    formik,
    formulary,
    styles,
    styles: { cssFontFamily, cssButtonPrec, activeStepTitle },
    t,
  },
  action: { setFormulary, setMaxReachedEtape, setFamilleProt },
  nom,
}) => {
  const { parameters } = useConfiguration();
  const navigate = useNavigate();

  const handleRetour = () => {
    navigate(GuestRoutes.ACCUEIL);
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodySelectionnezLaFamille}
        </StyledP>
        <form onSubmit={formik.handleSubmit} id="step-family-form">
          <Grid container direction="column">
            {genererFormulaire(
              formulaireFamilleProtectionIndustrielle(styles, t),
              formik,
              t,
              booleanIdentite,
              false,
              { formulary, setFormulary, setMaxReachedEtape, setFamilleProt }
            )}
            {/* barre de bouton du dessous avec boutons précédent / suivant */}
            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  className={'cssButton'}
                  variant="contained"
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivPhotoFamille
          style={{
            backgroundImage: "url(" + parameters.HOME_BACKGROUND_IMAGE + ")",
          }}
        />
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};

/*
 * CommandesArticle :
 * Composant JSX - onglet «Commandes» d'un article
 * Utilisé par : controllerResume/S06/ArticleS06.js
 */

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { ListeCommandeByArticle } from "./ListeCommandeByArticle";
import { getAllCommandesByArticleIdEC } from "../../../../slice/EspaceClientReducer";

import { StyledDivRecapitulatifEC } from "../../../styledComponents/StyledDiv";
import { StyledPaperBodyEC, } from "../../../styledComponents/StyledPaper";
import { StyledDivMsgEchec, StyledDivMsgSucces } from "../../../styledComponents/StyledDivBackoffice";

export const CommandesArticle = ({ clientId, articleId }) => {
  const dispatch = useDispatch();

  const { commandesByArtIdList } = useSelector((state) => state.espaceClient);
  const { message } = useSelector((state) => state.message);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
  ] = useOutletContext();

  useEffect(() => {
    if (clientId !== null)
      dispatch(getAllCommandesByArticleIdEC({ clientId, articleId }));
  }, [dispatch]);

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  const actionUsedForRender = {
    dispatch,
    setUpToDateArticleByIdList,
  };

  return (
    <div>
      <StyledPaperBodyEC elevation={0}>
        <StyledDivRecapitulatifEC>
          <div>
            {displayError()}
            <ListeCommandeByArticle actions={actionUsedForRender} />
          </div>
        </StyledDivRecapitulatifEC>
      </StyledPaperBodyEC>
    </div>
  );
};

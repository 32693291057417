import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { errorHandlerMessageProvider } from '../../helper/ErrorHandlerMessageProvider';
import { fetchJsonFromServer } from '../../helper/fetchUtil.js';

// Ne pas enlever le «/» en début de chemin (pour l'espace client)
const CONFIG_DIR= '/config/';

const PARAM_LIST = {
  ID : { type: 'string' },
  URL_BACKEND : { type: 'string' },
  URL_TRANSLATION: { type: 'string' },
  SITE_TITLE: { type: 'string' },
  TRACKING_ID: { type: 'string' }, // rc=G-YP15RLFR0V, // mp=G-HMK14DHSCQ
  CSS : { type: 'string' }, // fichier style dans public/css
  HOME_BACKGROUND_IMAGE : { type: 'string' }, // image de fond de la page d'accueil
};

const loadConfiguration = createAsyncThunk(
  'configuration/loadConfiguration',
  async (_, thunkAPI) => {
    try {
      const configPath = CONFIG_DIR + window.location.hostname.replaceAll('.', '-') + '.json';
      console.log('Chargement configuration', configPath);
      const parameters = await fetchJsonFromServer(configPath);

      // contrôler les paramètres
      for (const [paramName, paramConfig] of Object.entries(PARAM_LIST)) {
        const paramValue = parameters[paramName];
        if (paramValue === undefined)
          throw new Error(`Paramètre «${paramName}» absent du fichier «${configPath}»`);
        if (typeof(paramValue) != paramConfig.type)
          throw new Error(`Le paramètre «${paramName}» n'est pas de type ${paramConfig.type}`);
      }
      return parameters;
    } catch (error) {
      console.log('Load config error :', error);
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {
  parameters: {},
  loading: true,
  error: null
};


const configReducer = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadConfiguration.pending, (state, _) => {
        state.loading = true;
        state.error = null;
        state.parameters = null;
      })
      .addCase(loadConfiguration.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.parameters = action.payload;
      })
      .addCase(loadConfiguration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.parameters = null;
      })
  }
});

export { configReducer, loadConfiguration  };
export default configReducer.reducer;

import { Card, CardContent } from "@mui/material";
import { ServiceIconCard } from "./ServiceIconCard";

import { useWindowWidth, ScreenType } from '../../../../context/WindowWidthContext';
import { useTranslations } from '../../components/hook/useTranslations.js';
import { StyledContentCard, StyledDivTitleCard } from "../../styledComponents/StyledTextEspaceClient";

const ServiceCard = ({ styles }) => {
  const { screenType } = useWindowWidth();
  const { t } = useTranslations();

  const specStyle = (screenType === ScreenType.DESKTOP) ? { flex: 1 } : {};

  return (
    <Card
      id="service-card"
      className={styles.serviceCard}
      style={{
        ...specStyle,
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        borderRadius: "8px",
      }}
    >
      <CardContent style={{ flex: 1, padding: "24px" }}>
        <div
          style={{
            height: 0,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <ServiceIconCard/>
        </div>
        <StyledDivTitleCard>{t.genTitleCardService}</StyledDivTitleCard>
        <StyledContentCard>{t.genDescrServices}</StyledContentCard>
      </CardContent>
    </Card>
  )
}

export default ServiceCard;

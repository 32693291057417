import { useState } from "react";
import { useLocation } from "react-router-dom";

import { ClientRoutes } from "../../constantes/routes/ClientRoutes";

export const HamburgerMenu = ({ t, toggleMenu, isMenuOpen, isInHeader }) => {
  const location = useLocation();
  const [ activePage ] = useState(location.pathname);

  const links = [
    { to: ClientRoutes.ACCUEIL_EC, text: t.titleSideBarDashboard },
    { to: ClientRoutes.VUE_ARTICLE, text: t.titleSideBarArticles },
    { to: ClientRoutes.VUE_OFFRE, text: t.titleSideBarOffres },
    { to: ClientRoutes.VUE_COMMANDE, text: t.titleSideBarCommandes },
    { to: ClientRoutes.VUE_NC, text: t.titleSideBarNC },
    { to: ClientRoutes.VUE_SERVICE, text: t.titleSideBarServices, disabled: true, }, // Désactiver ce lien
    { to: ClientRoutes.VUE_CONTACT, text: t.titleSideBarContact },
    { to: "/Logout", text: t.titleSideBarLogout },
  ];

  if (isInHeader) {
    return (
      <div onClick={toggleMenu} style={styles.hamburgerIcon}>
        &#9776; {/* Icône hamburger */}
      </div>
    );
  }

  return (
    isMenuOpen && (
      <div style={styles.menuContainer}>
        <ul style={styles.menuList}>
          {links.map((link) => (
            <li
              key={link.to}
              id={'menu' + link.to.replaceAll('/','')}
              style={{
                ...styles.listItem,
                ...(link.disabled ? styles.disabledListItem : {}), // Appliquer le style désactivé si le lien est marqué comme tel
              }}
            >
              <a
                href={link.disabled ? "#" : link.to}
                style={{
                  ...styles.link,
                  pointerEvents: link.disabled ? "none" : "auto",
                  color: "#2A375C",
                  fontWeight:
                    activePage === link.to && !link.disabled
                      ? "bold"
                      : "normal",
                }}
                onClick={link.disabled ? (e) => e.preventDefault() : undefined}
              >
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

const styles = {
  hamburgerIcon: {
    fontSize: "1.5rem",
    cursor: "pointer",
  },
  menuContainer: {
    position: "relative",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
  },
  menuList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  listItem: {
    fontFamily: "Dosis",
    fontWeight: "600",
    textAlign: "center",
    padding: "1rem",
    borderBottom: "1px solid #ddd",
  },
  link: {
    textDecoration: "none",
    color: "#2A375C",
    lineHeight: "120%",
    display: "block",
  },
  disabledListItem: {
    color: "#888",
    cursor: "not-allowed",
    backgroundColor: "#f9f9f9",
  },
};

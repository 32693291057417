import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import BoutonRetourListeArticle from "../BoutonRetourListeArticle";
import { ARTICLE, DEMANDE_DE_PRIX, OFFRE_DE_PRIX } from "../../../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { setAllInfoByRefNull } from "../../../../../slice/FormulaireReducer";
import { DessinTechnique } from "../../../../components/DessinTechnique";
import { LoadingErrorMessage } from "../../../../components/LoadingMessageComponents.jsx";
import { importAllSpec } from "../../../../../../helper/ImportAll";
import { arrayProductFamilly } from "../../../../../../constantes/symbols/SymbolsServicesCommon";
import { genererFormulaire } from "../../../../components/generateFormulary/CasesGeneration";
import { genererChampRecapitulatifHorsConfigurateur } from "../../../../components/generateFormulary/methods/ResumeGenerate";
import { dataCleanerS08 } from "../../../../../../helper/DataCleanerS08";
import { FormeFaceS08 } from "../../../../DessinTechnique/S08/FormeFaceS08";
import {
  formulaireAggressions,
  formulaireCommentaire,
  formulaireInformationsMachine,
  formulaireQuantités,
} from "../../../../ChampsFormulaires/S01/recapitulatif/ChampsRecapitulatif";
import {
  formulaireAccessoiresS08,
  formulaireDimensionsS08,
  formulaireExtremitesRecapS08,
  formulaireFormeS08,
  formulaireRecapitulatifMatiere,
} from "../../../../ChampsFormulaires/S08/recapitulatifS08/ChampsRecapitulatifS08";
import {
  afficherOnglets,
  afficherOngletsMobile,
} from "../../../../components/espaceClient/OngletsArticle";


import {
  StyledDivDessinRecap,
  StyledDivDessinRecapIframe,
  StyledDivDessinRecapIframeScaleS08,
  StyledDivFiligranneRecapEC,
  StyledDivRecapGeneEC,
  StyledDivRecapInt,
  StyledDivRecapitulatifEC,
  StyledDivSectionRecapEC,
  StyledDivSectionsRowRecap,
  StyledDivSectionsRowReverseRecap,
  StyledDivSubtitlesRecap,
} from "../../../../styledComponents/StyledDiv";
import { StyledH1VuesEC } from "../../../../styledComponents/StyledTextEspaceClient";
import { StyledPaperBodyEC } from "../../../../styledComponents/StyledPaper";
import { StyledSpanSubtitlesRecap } from "../../../../styledComponents/StyledSpan";
import { StyledHrEspaceClient } from "../../../../styledComponents/StyledDivEspaceClient";

export const ArticleS08 = ({
  state: {
    booleanIdentite,
    etape,
    formik,
    styles,
    styles: {
      sousTitreRecap,
      iconeRecap,
    },
    t,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateAgressionsList,
    isArticleByIdSet,
  },
  action: {
    dispatch,
    setArticleId,
    handleRetour,
    setIsArticleByIdSet,
  },
  isFrom,
  nom,
}) => {
  const [params] = useSearchParams();
  const from = [DEMANDE_DE_PRIX, OFFRE_DE_PRIX, ARTICLE].includes(isFrom);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const [iframe, setIframe] = useState();
  const [formulaire, setFormulaire] = useState(
    dataCleanerS08(formik.values ? formik.values : formik)
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [isOngletDesktopFormat, setIsOngletDesktopFormat] = useState(
    window.innerWidth >= 768
  );

  useEffect(() => {
    setIframe(parseInt(query.get("iframe")) === 1);
  }, []);

  useEffect(() => {
    setIsOngletDesktopFormat(window.innerWidth >= 768);
  }, [window.innerWidth]);

  useEffect(() => {
    setArticleId(params.get("art"));
  }, [booleanIdentite, setArticleId]);

  if (!isArticleByIdSet)
    return <LoadingErrorMessage t={t} />;

  const afficherDessinTechnique = () => {
    return iframe ? (
      <>
        <StyledDivDessinRecapIframe>
          <StyledDivDessinRecapIframeScaleS08>
            <DessinTechnique
              formik={formulaire}
              formulaire={formulaire}
              styles={styles}
              etape={etape}
              t={t}
            />
          </StyledDivDessinRecapIframeScaleS08>
        </StyledDivDessinRecapIframe>
        <StyledDivDessinRecapIframe>
          <StyledDivDessinRecapIframeScaleS08>
            <FormeFaceS08
              formik={formulaire}
              formulaire={formulaire}
              styles={styles}
              etape={etape}
              t={t}
            />
          </StyledDivDessinRecapIframeScaleS08>
        </StyledDivDessinRecapIframe>
      </>
    ) : (
      <StyledDivSectionsRowRecap>
        <StyledDivDessinRecap>
          <DessinTechnique
            formik={formulaire}
            formulaire={formulaire}
            styles={styles}
            etape={etape}
            t={t}
          />
        </StyledDivDessinRecap>
        <StyledDivDessinRecap>
          <FormeFaceS08
            formik={formulaire}
            formulaire={formulaire}
            styles={styles}
            etape={etape}
            t={t}
          />
        </StyledDivDessinRecap>
      </StyledDivSectionsRowRecap>
    );
  };

  const afficherRecap = () => {
    return (
      <>
        {iframe ? afficherDessinTechnique() : null}
        <StyledPaperBodyEC elevation={0}>
          <StyledDivRecapitulatifEC>
            <StyledDivRecapInt>
              <StyledDivFiligranneRecapEC
                style={{
                  backgroundImage:
                    "url(" +
                    importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                    ")",
                  zIndex: "0",
                }}
                className={styles.logoFiligraneEC}
              />
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                {!iframe ? afficherDessinTechnique() : null}
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titleReferences}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {formulaire?.["infosArticleEC"]?.[0][
                      "articleReferenceClient"
                    ] === "" ? null : (
                      <div>
                        {t.titleCodeClient} :{" "}
                        <span>
                          {
                            formulaire?.["infosArticleEC"]?.[0][
                              "articleReferenceClient"
                            ]
                          }
                        </span>
                      </div>
                    )}
                    <div>
                      {t.codeRCMP} :{" "}
                      <span>
                        {formulaire?.["infosArticleEC"]?.[0]["articleCode"]}
                      </span>
                    </div>

                    <div>
                      {t.titleNomArticle} :{" "}
                      <span>
                        {
                          formulaire?.["infosArticleEC"]?.[0][
                            "articleDesignation"
                          ]
                        }
                      </span>
                    </div>

                    <div>
                      {t.titleFamille} :{" "}
                      <span>
                        {arrayProductFamilly(t).find(
                          (e) =>
                            e.code ===
                            formulaire?.["infosArticleEC"]?.[0][
                              "familleCode"
                            ].substring(0, 3)
                        )?.name ??
                          formulaire?.["infosArticleEC"]?.[0][
                            "familleCode"
                          ].substring(0, 3)}
                      </span>
                    </div>
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.descrArticle}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC
                    dangerouslySetInnerHTML={{
                      __html:
                        formulaire?.["infosArticleEC"]?.[0][
                          "articleDescription"
                        ],
                    }}
                  />
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowRecap>

              <StyledDivSectionsRowReverseRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.genConfigurateurH2DimensionsS08}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireDimensionsS08(t),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(
                      formulaire,
                      7,
                      t
                    )}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.genConfigurateurH2FormeS08}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireFormeS08(t, formulaire),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(
                      formulaire,
                      10,
                      t
                    )}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowReverseRecap>

              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreMatiereRecap}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireRecapitulatifMatiere(t),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(
                      formulaire,
                      9,
                      t
                    )}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.genConfigurateurH2ExtremitesS08}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireExtremitesRecapS08(t, formulaire),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(
                      formulaire,
                      6,
                      t
                    )}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowRecap>

              <StyledDivSectionsRowReverseRecap>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreInformationsMachine}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireInformationsMachine(
                        t,
                        upToDateTypeMachineList,
                        upToDateMarqueMachineList
                      ),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(
                      formulaire,
                      1,
                      t
                    )}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>

                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreAccessoires}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  {genererFormulaire(
                    formulaireAccessoiresS08(t),
                    formulaire,
                    t
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(
                    formulaire,
                    11,
                    t
                  )}
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowReverseRecap>

              <StyledDivSectionsRowRecap>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreEnvironnement}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    {genererFormulaire(
                      formulaireAggressions(t, upToDateAgressionsList),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(
                      formulaire,
                      2,
                      t
                    )}
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>

                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreInfosComp}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  {genererFormulaire(formulaireQuantités(t), formulaire, t)}
                  {genererFormulaire(
                    formulaireCommentaire(t, booleanIdentite),
                    formulaire,
                    t,
                    booleanIdentite
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(
                    formulaire,
                    12,
                    t
                  )}
                </StyledDivSectionRecapEC>
              </StyledDivSectionsRowRecap>
            </StyledDivRecapInt>
          </StyledDivRecapitulatifEC>
        </StyledPaperBodyEC>
      </>
    );
  };

  return (
    <div name={nom} style={{ padding: "1rem" }}>
      <BoutonRetourListeArticle/>
      <div>
        <StyledH1VuesEC>
          {t.detailsArticle} :{" "}
          {arrayProductFamilly(t).find(
            (e) =>
              e.code ===
              formulaire?.["infosArticleEC"]?.[0]["familleCode"].substring(0, 3)
          )?.name ?? formulaire["designation"]}
        </StyledH1VuesEC>
        <StyledHrEspaceClient></StyledHrEspaceClient>
      </div>


      {isOngletDesktopFormat
        ? afficherOnglets(
            t,
            formulaire,
            selectedTab,
            setSelectedTab,
            afficherRecap()
          )
        : afficherOngletsMobile(
            t,
            formulaire,
            selectedTab,
            setSelectedTab,
            afficherRecap()
          )}
    </div>
  );
};

import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StyledSelectHeader } from "../styledComponents/StyledSelect";
import { useLocation } from "react-router-dom";
import { useTranslations } from "./hook/useTranslations";
import { StyledSpanHeader } from "../styledComponents/StyledSpan";

export const LangSwitcher = () => {

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const dispatch = useDispatch();
  const { lang, supportedLangs, setLang, loading: i18nLoading} = useTranslations();

  useEffect(() => {
    if (!supportedLangs || !query.get('langue'))
      return;
    let langParam = query.get('langue');
    urlLangHandler(langParam);
  }, [supportedLangs]);

  if (i18nLoading)
    return <></>;

  // TODO gérer le paramètre d'URL «lang» ailleurs que dans LangSwitcher
  // change la langue si le paramètre «?lang=xx» est défini dans l'URL
  const urlLangHandler = (langParam) => {
    const value = supportedLangs.filter(
      (el) => el.code.toUpperCase() === langParam.toUpperCase()
    )[0]?.code;
    if (!value) {
      return;
    }
    setLang({ target: { value } });
  };

  const getIconUrl = (code, size = 1) => {
    code = code.toLowerCase();
    if (code === 'en')
      code = 'gb';
    const dir = (size === 2) ? 'w40' : 'w20';
    let url = `https://flagcdn.com/${dir}/${code}.png`;
    return url;
  };

  const langChangeHandler = (ev) => {
    setLang(ev.target.value);
  }

  // ci-dessous, ajout ID «lang-switcher» et classe CSS «lang-switcher» pour auto-tests
  return (
    <StyledSpanHeader id='lang-switcher'>
      <StyledSelectHeader
        value={lang}
        onChange={ langChangeHandler }
        size="small"
      >
        {supportedLangs.map(({ code, name }) => (
          <MenuItem className='lang-switcher' value={code} key={code}>
            <ListItemIcon style={{ minWidth: "30px" }}>
              <img
                loading="lazy"
                width="20"
                src={getIconUrl(code, 1)}
                srcSet={getIconUrl(code, 2)}
                alt={`Flag of ${code.label}`}
              />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </MenuItem>
        ))}
      </StyledSelectHeader>
    </StyledSpanHeader>
  );
};

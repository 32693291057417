import React, { createContext, useContext, useState, useEffect } from 'react';

const LIMITE_LARGEUR_ECRAN_MOBILE = 768;
const LIMITE_LARGEUR_ECRAN_DESKTOP = 1366;

const ScreenType = {
  MOBILE: 'mobile',
  PORTABLE: 'portable',
  DESKTOP: 'desktop',
};
Object.freeze(ScreenType);

const WindowWidthContext = createContext();

const calculateScreenType = () => {
  if (window.innerWidth < LIMITE_LARGEUR_ECRAN_MOBILE)
    return ScreenType.MOBILE;
  else if (window.innerWidth >= LIMITE_LARGEUR_ECRAN_DESKTOP)
    return ScreenType.DESKTOP;
  else
    return ScreenType.PORTABLE;
};

const WindowWidthProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [screenType, setScreenType] = useState(calculateScreenType);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      setScreenType(calculateScreenType());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowWidthContext.Provider value={{ windowWidth, screenType }}>
      {children}
    </WindowWidthContext.Provider>
  );
};

const useWindowWidth = () => {
  return useContext(WindowWidthContext);
};

export { WindowWidthProvider, useWindowWidth, ScreenType };

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useWindowWidth, ScreenType } from '../../../context/WindowWidthContext';
import { useChoixContact } from '../../../redux/vue/components/hook/useChoixContact.js';
import { StyledH1VuesEC, StyledH2VuesEC } from "../styledComponents/StyledTextEspaceClient";
import {
  StyledDivCardWrapper,
  StyledDivCardWrapperRow,
  StyledHrEspaceClient,
} from "../styledComponents/StyledDivEspaceClient";

import { StyledCardContainer } from "../styledComponents/StyledCardContainer";
import CommandeCard from '../components/espaceClient/CommandeCard';
import OffreCard from '../components/espaceClient/OffreCard';
import ArticleCard from '../components/espaceClient/ArticleCard';
import NcCard from '../components/espaceClient/NcCard';
import CommercialCard from '../components/espaceClient/CommercialCard';
import ContactCard from '../components/espaceClient/ContactCard';
import ServiceCard from '../components/espaceClient/ServiceCard';
import PubliciteCard from '../components/espaceClient/PubliciteCard';
import ChoixContactCard from '../components/espaceClient/ChoixContactCard';
import ConfigurateurCard from '../components/espaceClient/ConfigurateurCard';
import { useTranslations } from '../components/hook/useTranslations.js';
import { GetStyle } from "../../../helper/Styles";


const AccueilMobileEC = () => {
  const styles = GetStyle(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <StyledCardContainer
        id="card-container"
        className={styles.cardContainer}
        style={{ flex: 1, display: "flex", gap: "1.5rem", flexWrap: "wrap" }}
      >
        <StyledDivCardWrapper>

          <CommercialCard styles={styles}/>
          <CommandeCard styles={styles}/>
          <OffreCard styles={styles}/>
          <PubliciteCard styles={styles}/>
          <ChoixContactCard styles={styles}/>
          <ArticleCard styles={styles} />
          <NcCard styles={styles}/>
          <ContactCard styles={styles}/>
          <ServiceCard styles={styles}/>
          <ConfigurateurCard styles={styles}/>

        </StyledDivCardWrapper>
      </StyledCardContainer>
    </div>
  );
};

const AccueilDesktopEC = () => {
  const styles = GetStyle(false);
  return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        <StyledCardContainer
          id="card-container"
          className={styles.cardContainer}
          style={{
            flex: 1,
            display: "flex",
            gap: "1.5rem",
            flexWrap: "wrap",
          }}
        >
          <StyledDivCardWrapper>

            <CommercialCard styles={styles}/>
            <CommandeCard styles={styles}/>
            <OffreCard styles={styles}/>

          </StyledDivCardWrapper>

          <StyledDivCardWrapper style={{ flex: "1.75" }}>
            <StyledDivCardWrapperRow>

              <PubliciteCard styles={styles}/>
              <ChoixContactCard styles={styles}/>

            </StyledDivCardWrapperRow>

            <ArticleCard styles={styles}/>

            <StyledDivCardWrapperRow className={styles.cardContainer}>

              <NcCard styles={styles}/>
              <ContactCard styles={styles}/>
              <ServiceCard styles={styles}/>

            </StyledDivCardWrapperRow>

            <ConfigurateurCard styles={styles}/>

          </StyledDivCardWrapper>
        </StyledCardContainer>
      </div>
  );
};

const AccueilEC = () => {
  const styles = GetStyle(false);
  const { screenType } = useWindowWidth();
  const { t } = useTranslations();

  const { contact: choixContact } = useChoixContact();
  const [prenomContact, setPrenomContact] = useState("");
  const [raisonSociale, setRaisonSociale] = useState("");

  const { contactList } = useSelector((state) => state.espaceClient);

  useEffect( () => {
    let prenom = choixContact?.prenom;
    if (prenom === undefined || prenom.length === 0)
      prenom = null;
    setPrenomContact(prenom);
  }, [ choixContact ]);

  useEffect(() => {
    let raisonSociale = null;
    if (choixContact === null) {
      const selContact = contactList.find( item => item.clientRaisonSociale);
      raisonSociale = selContact?.clientRaisonSociale || null;
    }
    else {
      raisonSociale = choixContact?.clientRaisonSociale || null;
    }
    setRaisonSociale(raisonSociale);
  }, [contactList, choixContact]);

  return (
    <div style={{ padding: "16px" }}>
      <div>
        <StyledH1VuesEC>
          {prenomContact && prenomContact + ','} {t.genH1BienvenueEspaceClient}
        </StyledH1VuesEC>
        <StyledHrEspaceClient></StyledHrEspaceClient>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <StyledH2VuesEC>{raisonSociale}</StyledH2VuesEC>
      </div>

      { (screenType === ScreenType.DESKTOP) ? (
        <AccueilDesktopEC styles={styles}/>
      ) : (
        <AccueilMobileEC styles={styles}/>
      )}

    </div>
  );
};

export { AccueilEC };

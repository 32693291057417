export const EC_COLORS = {
  GREY: '#7F7F7F',
  GREEN: '#4EA72E',
  DEEP_GREEN: '#5FA36E54',
  YELLOW: '#FFC000',
  ORANGE: '#EC8812',
  DEEP_ORANGE: '#E97132',
  DEEP_BLUE: '#2A375C',
  DARK_BLUE: '#1C243C',
  VIOLET: '#CC007B',
};

export function getBgColorFromStatus(name) {

  switch(name) {
    case "Non commencée":
    case "Non commencé":
    case "Attente":
      return EC_COLORS.GREY;
    case "Terminée":
    case "Terminé":
    case "Commandée":
      return EC_COLORS.GREEN;
    case "Partielle":
    case "Autre offre":
    case "Commencé":
      return EC_COLORS.YELLOW;
    case "Refusée":
      return EC_COLORS.DEEP_ORANGE;
    default:
      return EC_COLORS.GREY;
  }
}

/*
 * ListeArticle :
 * Composant JSX - tableau des articles
 * Utilisé par : VueArticle.js
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Tableau, OrdreTri } from '../../components/Tableau.jsx';
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { ApiStatus } from '../../../../constantes/ApiStatus';
import { LoadingMessage, LoadingErrorMessage } from "../../components/LoadingMessageComponents.jsx";

import { useTranslations } from '../../components/hook/useTranslations.js';

const ListeArticle = () => {
  const { t, lang } = useTranslations();
  const navigate = useNavigate();
  const [ lignes, setLignes ] = useState(null);
  const { articleList, familleArticleList } = useSelector((state) => state.espaceClient);

  const actionVoirArticle = (cellule) => {
    navigate(ClientRoutes.VUE_ARTICLE + cellule);
  };


  const colonnes = [
    { // colonne cachée
      key: true,
      type: 'string',
    },
    { // colonne n°1 - Référence FPS
      titre: t.titleCodeFPS,
      type: 'link',
      filter: true,
      sort: true,
      width: '9rem',
      fonction: actionVoirArticle,
    },
    { // colonne n°2 - Référence client
      titre: t.titleCodeClient,
      type: 'string',
      filter: true,
      sort: true,
      width: '9rem',
    },
    { // colonne n°3 - Désignation
      titre: t.titleNomArticle,
      type: 'string',
      filter: true,
      sort: true,
      width: '10rem'
    },
    { // colonne n°4 - Famille
      titre: t.titleFamille,
      type: 'string',
      filter: true,
      sort: true,
      width: '10rem'
    },
    { // colonne n°5 - Modèle
      titre: t.titleModeleMachine,
      type: 'string',
      filter: true,
      sort: true,
      width: '10rem'
    },
    { // colonne n°6 - Emplacement
      titre: t.emplacementPiece,
      type: 'string',
      filter: true,
      sort: true,
      width: '12rem'
    },
    { // colonne n°7 - nombre d'offres
      titre: t.titleOffrePrix,
      type: 'number',
      width: '4rem'
    },
    { // colonne n°8 - nombre de commandes
      titre: t.titleCommande,
      type: 'number',
      width: '4rem'
    },
    { // colonne n°9 - actions
      titre: t.titleAction,
      type: 'actions',
      width: '5rem',
      actions : [
        {
          label: t.voirPlus,
          fonction: actionVoirArticle
        },
      ]
    },
  ];

  useEffect( () => {
    // FIXME le tableau familleArticleList peut être vide si le chargement n'est pas terminé
    if (familleArticleList.length === 0)
      return;
    const tabLignes = articleList.list.map( article => {
      const objFamille = familleArticleList.find((famille) => famille.code === article['familleCode'])
      let nomFamille = '?';
      for (const selLang of [ lang, 'EN', 'FR' ]) { // FIXME langues en dur
        if (objFamille[selLang]) {
          nomFamille = objFamille[selLang];
          break;
        }
      }
      return [
        article['articleId'],              // colonne cachée
        article['articleCode'],            // colonne n°1 - Référence FPS
        article['articleReferenceClient'], // colonne n°2 - Référence client
        article['articleDesignation'],     // colonne n°3 - Désignation
        nomFamille,                        // colonne n°4 - Famille
        article['modeleMachine'],          // colonne n°5 - Modèle
        article['emplacementPiece'],       // colonne n°6 - Emplacement
        article['nbOffrePrix'],            // colonne n°7 - nombre d'offres
        article['nbCommande'],             // colonne n°8 - nombre de commandes
        null,                              // colonne n°9 - actions
      ];
    });
    setLignes(tabLignes, lang);
  }, [articleList, familleArticleList]);

  if (articleList.apiStatus === ApiStatus.IDLE)
    return <LoadingMessage t={t} />;
  // TODO gérer le cas currentNC.apiStatus === ApiStatus.ERROR


  return <Tableau
      lignes={lignes}
      colonnes={colonnes}
      triInitial={ {colonne:1, sens: OrdreTri.CROISSANT }}
      pages ={ [ 25, 10, 50, 100 ]}
      messages={{
        aucun_resultat: t.aucun_resultat,
        tableau_vide: t.tableau_vide
      }}
    />;
};

export { ListeArticle };

import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { useFormik } from "formik";

import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import {
  SOCIETE,
  PAYS,
  CODE_POSTAL,
  SECTEUR,
  NOM,
  PRENOM,
  FONCTION,
  ADRESSE_MAIL,
  TELEPHONE,
} from "../../constantes/symbols/SymbolsCommon";

import {
  formulaireRechercherP1,
  formulaireRechercherP2,
  ARTICLES,
  CAP,
  NDDP,
  NUM_OFFRE,
  QUANTITEE_SOUHAITEE_CODE_ARTICLE,
} from "./ChampsFormulaires/RechercherChamps";
import { initialValuesRechercher } from "./ChampsFormulaires/Initialisation/RechercherInitialValues";
import { ValidRecherche } from "./ChampsFormulaires/validationsFormulaires/ValidRecherche";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledDivButton,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
  StyledDivFormSection,
  StyledDivH1ContactRecherche,
  StyledDivLineRechercher,
  StyledDivQuantiteEtCode,
  StyledDivSticky,
} from "./styledComponents/StyledDiv";
import { LoadingErrorMessage } from "./components/LoadingMessageComponents.jsx";
import { mergeEtape, resetIsFormularySent, sendFormulaireRechercher, } from "../slice/FormulaireReducer";
import { useTranslations } from "./components/hook/useTranslations";
import { useConfiguration } from "./components/hook/useConfiguration";
import useInfosClient from './components/hook/useInfosClient.js';
import { LocalStorage } from "../../constantes/globalName/LocalStorage";
import { WaitingDbInsert } from "./components/WaitingDbInsert";
import { Confirmation } from "./formulaires/Confirmation";
import {
  StyledButtonAjouterQuantite,
  StyledButtonContinuer,
  StyledButtonPrec,
  StyledButtonRetirerQuantite,
} from "./styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "./styledComponents/StyledIcon";
import { StyledPContact } from "./styledComponents/StyledP";
import { genererFormulaire } from "./components/generateFormulary/CasesGeneration";
import { TEXTFIELD_RETURN_ARRAY_MULTI } from "./components/generateFormulary/types/TypeFieldsFormulary";
import { ErrorDbInsert } from "./formulaires/ErrorDbInsert";
import { RECHERCHER } from "../../constantes/symbols/SymbolsResumeDisplayedFrom";

/**
 * It generates a form with the fields of the form.
 * @returns The form is being returned.
 */
export const Rechercher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useTranslations();
  const { parameters } = useConfiguration();
  const { infosClient, updateInfosClient } = useInfosClient();

  const [searchParams] = useSearchParams();
  const refOffre = searchParams.get('offre');
  const refCommande = searchParams.get('commande');

  const [styles, t, booleanIdentite] = useOutletContext();
  const [step, setStep] = useState(1);
  const referenceRef = useRef(null);
  const [upToDateSecteursList, setUpToDateSecteursList] = useState([]);
  const [upToDateFonctionsList, setUpToDateFonctionsList] = useState([]);
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [formulaire, setFormulaire] = useState("");
  const [nbChamps, setNbChamps] = useState(1);
  const [bouttonsList, setBouttonList] = useState([]);
  const [_isAllowedToQuit, setIsAllowedToQuit] = useState(false);

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);
  const { isFormularySent } = useSelector((state) => state.formulaire);

  const cssButtonPrec = styles.cssButtonPrec;
  const cssPContact = styles.cssPContact;
  const cssFontFamily = styles.cssFontFamily;
  const sectionFormSansBg = styles.sectionFormSansBg;
  const sectionFormMarginRechercher = styles.sectionFormMarginRechercher;
  const maxChamp = 100;


  useEffect(() => {
    if (!secteursList)
      return;
    setUpToDateSecteursList(secteursList);
  }, [secteursList]);

  useEffect(() => {
    if (!fonctionsList)
      return;
    setUpToDateFonctionsList(fonctionsList);
  }, [fonctionsList]);

  useEffect(() => {
    if (!paysList)
      return;
    setUpToDatePaysList(paysList);
  }, [paysList]);

  useEffect(() => {
    // FIXME est-il utile d'appeler ici mergeEtape ?
    dispatch(mergeEtape(Object.assign({}, formulaire, infosClient)));
  }, [infosClient]);

  // initialiser le champ «N° d'offre» avec le paramètre reçu dans l'URL
  useEffect(() => {
    if (refOffre !== null)
      formik.setFieldValue(NUM_OFFRE.description, refOffre);
  }, [refOffre]);

  // initialiser le champ «Référence commande» avec le paramètre reçu dans l'URL
  useEffect(() => {
    if (refCommande !== null)
      formik.setFieldValue(NDDP.description, refCommande);
  }, [refCommande ]);

  useEffect(() => {
    if (nbChamps <= maxChamp) {
      const newButtonList = [...Array(nbChamps)]
        .map((_value, index) => {
          return [
            {
              nom: QUANTITEE_SOUHAITEE_CODE_ARTICLE,
              label: t.quantiteSouhaitee,
              type: TEXTFIELD_RETURN_ARRAY_MULTI,
              value: ARTICLES,
              positionInArray: index,
              cssTextfield: styles.cssTextfieldQuantiEtCode,
            },
            {
              nom: CAP,
              label: t.codeArticlePrecedent,
              type: TEXTFIELD_RETURN_ARRAY_MULTI,
              value: ARTICLES,
              positionInArray: index,
              cssTextfield: styles.cssTextfieldQuantiEtCode,
            },
          ];
        })
        .flat(1);
      setBouttonList(newButtonList);
      formik.setFieldValue("nbFieldToKeep", nbChamps);
    }
  }, [nbChamps, t]);

  const handleSubmit = (form) => {

    console.log("Traitement formulaire");
    // mettre à jour infosClient avec les données saisies
    updateInfosClient({
      societe:     form.societe,
      codePostal:  form.codePostal,
      nom:         form.nom,
      prenom:      form.prenom,
      adresseMail: form.adresseMail,
      telephone:   form.telephone,
      pays:        { ...form.pays},
      secteur:     {...form.secteur},
      fonction:    {...form.fonction},
    });

    form.langue = lang;
    form.marque = booleanIdentite ? "MP" : "RC";
    form.timestamp = Date.now();
    dispatch(sendFormulaireRechercher(form));
    setStep(step + 1);
  };

  const handleRetour = () => {
    navigate(GuestRoutes.ACCUEIL);
  };

  const formik = useFormik({
    initialValues: { ...initialValuesRechercher(formulaire, maxChamp) },
    validationSchema: ValidRecherche(
      upToDateSecteursList,
      upToDateFonctionsList,
      upToDatePaysList,
      t
    ),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const numeroOffre = localStorage.getItem(LocalStorage.OFFRE_CLIENT)
    if (numeroOffre) {
      console.log(formik)
      formik.setFieldValue("numeroOffreTransmise", numeroOffre);
    }

    if (infosClient) {
      // Associe les valeurs de infosClient aux valeurs de formik
      formik.setFieldValue(SOCIETE.description,      infosClient.societe);
      formik.setFieldValue(PAYS.description,         infosClient.pays);
      formik.setFieldValue(CODE_POSTAL.description,  infosClient.codePostal);
      formik.setFieldValue(SECTEUR.description,      infosClient.secteur);
      formik.setFieldValue(NOM.description,          infosClient.nom);
      formik.setFieldValue(PRENOM.description,       infosClient.prenom);
      formik.setFieldValue(FONCTION.description,     infosClient.fonction);
      formik.setFieldValue(ADRESSE_MAIL.description, infosClient.adresseMail);
      formik.setFieldValue(TELEPHONE.description,    infosClient.telephone);
    }

    // Dispatch de l'objet combiné (formulaire et infosClient)
    dispatch(mergeEtape(Object.assign({}, formulaire, infosClient)));
  }, []);


  const ajouterUnChampBouton = () => {
    if (nbChamps < maxChamp) {
      setNbChamps(nbChamps + 1);
    }
  };

  const retirerUnChampBouton = () => {
    if (nbChamps > 1) {
      setNbChamps(nbChamps - 1);
      setBouttonList(
        bouttonsList.filter((_element, index) => index < bouttonsList.length - 2)
      );
    }
  };

  const afficherBoutonRetirer = () => {
    if (nbChamps > 1) {
      return (
        <StyledButtonRetirerQuantite
          className={cssFontFamily}
          variant="contained"
          onClick={retirerUnChampBouton}
          startIcon={<RemoveIcon/>}
        >
          {t.retirerDerniereQuantite}
        </StyledButtonRetirerQuantite>
      );
    }
  };

  const afficherBoutonAjouter = () => {
    return (
      <StyledButtonAjouterQuantite
        id='bouton-ajouter-quantite'
        className={cssFontFamily}
        variant="contained"
        onClick={ajouterUnChampBouton}
        startIcon={<AddIcon/>}
      >
        {t.descrBouttonAjouter}
      </StyledButtonAjouterQuantite>
    );
  };

  const afficherBoutonList = (bouttonsList, formik, styles) => {
    if (bouttonsList.length !== 0) {
      return genererFormulaire(bouttonsList, formik, styles);
    }
  };

  const resetFormularySentState = () => {
    dispatch(resetIsFormularySent());
  };

  const resetFormulary = () => {
    setFormulaire({});
  };

  const state = {
    styles,
    t,
    booleanIdentite,
    formik,
    reference: referenceRef.current
  };

  const action = {
    setEtape: setStep,
    resetFormularySentState,
    resetFormulary,
    setIsAllowedToQuit,
  };

  if (!upToDateSecteursList || !upToDateFonctionsList || !upToDatePaysList)
    return <LoadingErrorMessage t={t}/>;

  if (isFormularySent.status !== 200) {
    return (
      <ErrorDbInsert
        etape={step}
        setEtape={setStep}
        t={t}
        styles={styles}
        resetFormularySentState={resetFormularySentState}
        resetFormulary={resetFormulary}
      />
    );
  }
  if (isFormularySent.isBackendOk) {
    referenceRef.current = isFormularySent.isBackendOk.ref;
    return <Confirmation state={state} action={action} from={RECHERCHER} />;
  }

  if (step === 1) {
    return (
      <StyledDivFormAndImageContainer>
        <StyledDivFormContainer>
          <StyledDivH1ContactRecherche>
            <h1>{t.titrePageRechercher}</h1>
          </StyledDivH1ContactRecherche>
          <StyledPContact className={cssPContact}>
            {t.descrPageRechercher}
          </StyledPContact>
          <StyledDivLineRechercher/>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
              <StyledDivFormSection className={sectionFormSansBg}>
                {genererFormulaire(formulaireRechercherP1(styles, t), formik)}
                <div className={sectionFormMarginRechercher}>
                  <StyledDivQuantiteEtCode>
                    {afficherBoutonList(bouttonsList, formik, styles)}
                  </StyledDivQuantiteEtCode>
                  <StyledDivQuantiteEtCode style={{ marginBottom: "2.5em" }}>
                    {afficherBoutonAjouter()}
                    {afficherBoutonRetirer()}
                  </StyledDivQuantiteEtCode>
                </div>
                {genererFormulaire(
                  formulaireRechercherP2(
                    upToDatePaysList,
                    upToDateSecteursList,
                    upToDateFonctionsList,
                    styles,
                    t
                  ),
                  formik
                )}
              </StyledDivFormSection>
              <Grid item>
                <StyledDivButton>
                  <StyledButtonPrec
                    className={cssButtonPrec}
                    variant="outlined"
                    onClick={handleRetour}
                    startIcon={<StyledArrowBackOutlinedIcon/>}
                  />
                  <StyledButtonContinuer
                    className={'cssButton'}
                    variant="contained"
                    type="submit"
                  >
                    {t.bouttonEnvoyer}
                  </StyledButtonContinuer>
                </StyledDivButton>
              </Grid>
            </Grid>
          </form>
        </StyledDivFormContainer>

        <StyledDivSticky>
          <img
            className="alone"
            src={parameters.HOME_BACKGROUND_IMAGE}
            alt="TODO: define alt"
          />
        </StyledDivSticky>
      </StyledDivFormAndImageContainer>
    );
  } else {
    return <WaitingDbInsert state={state} from={RECHERCHER}/>;
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";

import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { selectNcEC } from "../../../slice/EspaceClientReducer";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ListeNC } from "./ListeNC";
import { DetailsNC } from "./DetailsNC";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

import { StyledH1VuesEC, StyledP } from "../../styledComponents/StyledTextEspaceClient";
import { StyledDivWrapperVues, StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { StyledDivMsgEchec, StyledDivMsgSucces } from "../../styledComponents/StyledDivBackoffice";

export const VueNC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { clientId } = useAuthentification();
  const { ncId:ncIdParam } = useParams();
  const ncIdUrlParam  = ncIdParam || null;

  const { message } = useSelector((state) => state.message);
  const [isNCByIdSet, setIsNCByIdSet] = useState(false);

  const { currentNC } = useSelector((state) => state.espaceClient);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
  ] = useOutletContext();

  useEffect( () => {
    // compatibilité avec ancienne URL EspaceClient/VueNC/?offre=xxx
    const ncId = searchParams.get('nc');
    if (ncId !== null) {
      navigate(ClientRoutes.VUE_NC + ncId, { replace: true});
    }
  }, [searchParams]);


  useEffect( () => {
    dispatch(selectNcEC( { clientId, ncId: ncIdUrlParam }));
    setIsNCByIdSet(ncIdUrlParam !== null);
  }, [ ncIdUrlParam ]);


  const stateUsedForRender = {
    booleanIdentite: false,
    formik: upToDateOffreByIdList,
    styles,
    t,
    currentNC,
    isNCByIdSet,
  };

  const actionUsedForRender = {
    dispatch,
    setUpToDateOffreByIdList,
    setIsNCByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  return !isNCByIdSet ? (
    <StyledDivWrapperVues>
      <StyledH1VuesEC>{t.titleSideBarNC}</StyledH1VuesEC>
      <StyledHrEspaceClient></StyledHrEspaceClient>
      <StyledP>{t.descrVueNC}</StyledP>

      <div>
        {displayError()}
        <ListeNC actions={actionUsedForRender} />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%" }}>
        <DetailsNC
          t={t}
          state={stateUsedForRender}
          actions={actionUsedForRender}
        />
      </div>
    </ErrorBoundary>
  );
};

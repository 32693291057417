import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Pagination } from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import { OffreItem } from "./OffreItem";
import { apiStatusMessageForList } from "../../../../helper/apiStatusHelper.js";

import { StyledGridLignesTabOrangeEC } from "../../styledComponents/StyledGridBackoffice";
import { StyledDivPaginationOrange } from "../../styledComponents/StyledDivBackoffice";

export const ListeOffre = ({ actions }) => {
  const { t } = useTranslations();

  const [listMessage, setListMessage] = useState(null);
  const [searchBarContentCodeOffre, setSearchBarContentCodeOffre] = useState();
  const [searchBarContentReference, setSearchBarContentReference] = useState();
  const [searchBarContentDateCreation, setSearchBarContentDateCreation] = useState();
  const [searchBarContentLigne, setSearchBarContentLigne] = useState();
  const [searchBarContentContact, setSearchBarContentContact] = useState();
  const [searchBarContentCodeCommande, setSearchBarContentCodeCommande] = useState();
  const [searchBarContentReponse, setSearchBarContentReponse] = useState();
  const [searchBarContentEtat, setSearchBarContentEtat] = useState();

  const [sortedCodeOffre, setSortedCodeOffre] = useState();
  const [sortedReference, setSortedReference] = useState();
  const [sortedDateCreation, setSortedDateCreation] = useState();
  const [sortedLigne, setSortedLigne] = useState();
  const [sortedCodeCommande, setSortedCodeCommande] = useState();
  const [sortedContact, setSortedContact] = useState();
  const [sortedReponse, setSortedReponse] = useState();
  const [sortedEtat, setSortedEtat] = useState();

  const { offreList } = useSelector((state) => state.espaceClient);
  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  const defineFilteredList = (searchBarContent, key) => {
    return offreList.list.filter((item) => {
      if (searchBarContent === undefined) {
        return item;
      } else {
        if (item[key].toLowerCase().includes(searchBarContent.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      }
    });
  };

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...offreList.list].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...offreList.list].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    setListMessage(apiStatusMessageForList(offreList));
  }, [offreList.list, offreList.apiStatus]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentCodeOffre, "offrePrixCode")
    );
  }, [searchBarContentCodeOffre, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentReference, "offreRefClient"));
  }, [searchBarContentReference, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentDateCreation, "dateCreation")
    );
  }, [searchBarContentDateCreation, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentLigne, "ligne"));
  }, [searchBarContentLigne, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentContact, "contact"));
  }, [searchBarContentContact, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentCodeCommande, "commandeCode")
    );
  }, [searchBarContentCodeCommande, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentReponse, "reponse"));
  }, [searchBarContentReponse, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentEtat, "publ"));
  }, [searchBarContentEtat, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    defineSortedList(sortedCodeOffre, "offrePrixCode");
  }, [sortedCodeOffre, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    defineSortedList(sortedReference, "offreRefClient");
  }, [sortedReference, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    defineSortedList(sortedLigne, "ligne");
  }, [sortedLigne, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    defineSortedList(sortedEtat, "publ");
  }, [sortedEtat, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    defineSortedList(sortedReponse, "reponse");
  }, [sortedReponse, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    defineSortedList(sortedContact, "contact");
  }, [sortedContact, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }

    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split("/");
      return new Date(year, month - 1, day);
    };

    if (sortedDateCreation === "up") {
      setFilteredList(
        [...offreList.list].sort(
          (a, b) => parseDate(a.dateCreation) - parseDate(b.dateCreation)
        )
      );
    }
    if (sortedDateCreation === "down") {
      setFilteredList(
        [...offreList.list].sort(
          (a, b) => parseDate(b.dateCreation) - parseDate(a.dateCreation)
        )
      );
    }
  }, [sortedDateCreation, offreList.list]);

  useEffect(() => {
    if (!offreList.list) {
      return;
    }
    defineSortedList(sortedCodeCommande, "commandeCode");
  }, [sortedCodeCommande, offreList.list]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (event, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeOffre = pagination.currentData.map((item) => {
    return (
      <OffreItem
        key={item.offrePrixId}
        id={item.offrePrixId}
        {...item}
        searchBarAction={{
          setSearchBarContentCodeOffre,
          setSearchBarContentReference,
          setSearchBarContentDateCreation,
          setSearchBarContentLigne,
          setSearchBarContentContact,
          setSearchBarContentCodeCommande,
          setSearchBarContentReponse,
          setSearchBarContentEtat,
        }}
        sortAction={{
          setSortedCodeOffre,
          setSortedReference,
          setSortedDateCreation,
          setSortedLigne,
          setSortedCodeCommande,
          setSortedContact,
          setSortedReponse,
          setSortedEtat,
        }}
        modale={actions}
      />
    );
  });

  return (
    <div>
      <StyledGridLignesTabOrangeEC container
      style={{
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)"
      }}
      >
        <OffreItem
          key={0}
          id={0}
          action={t.titleAction}
          offreRefClient={t.titleTabReference}
          offrePrixCode={t.titleTabCodeOffre}
          dateCreation={t.titleTabDateCreation}
          ligne={t.titleTabNombreLigne}
          contact={t.titleTabContact}
          reponse={t.titleTabReponse}
          publ={t.titleTabEtat}
          commandeCode={t.titleTabCodeCommande}
          offrePrixId={"offrePrixId"}
          articleEtatValidation={"articleEtatValidation"}
          commandeId={"commandeId"}
          searchBarAction={{
            setSearchBarContentCodeOffre,
            setSearchBarContentReference,
            setSearchBarContentDateCreation,
            setSearchBarContentContact,
            setSearchBarContentCodeCommande,
            setSearchBarContentReponse,
            setSearchBarContentEtat,
          }}
          sortAction={{
            setSortedCodeOffre,
            setSortedReference,
            setSortedDateCreation,
            setSortedLigne,
            setSortedCodeCommande,
            setSortedContact,
            setSortedReponse,
            setSortedEtat,
          }}
          modale={actions}
        />
        { listMessage !== null ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1rem",
            }}
          >
            <Grid container item direction="row" className={"row"}>
              {listMessage}
            </Grid>
          </div>
        ) : (
          afficherListeOffre
        )}
      </StyledGridLignesTabOrangeEC>
      <StyledDivPaginationOrange>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPaginationOrange>
    </div>
  );
};

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { BoutonRetourEC } from "../../../components/espaceClient/BoutonAccueilEC";
import { useTranslations } from '../../../components/hook/useTranslations.js';
import { ClientRoutes } from "../../../../../constantes/routes/ClientRoutes";
import { setAllInfoByRefNull } from "../../../../slice/FormulaireReducer";

const BoutonRetourListeArticle = () => {

  const { t } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToList = () => {
    dispatch(setAllInfoByRefNull());
    navigate(ClientRoutes.VUE_ARTICLE);
  }

  return (
    <BoutonRetourEC
      texte={t.retourListeArticles}
      action={navigateToList}
      backgroundColor={"#F8F9FF"}
    />
  );
};

export default BoutonRetourListeArticle;

import { useSelector } from "react-redux";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { useTranslations } from './hook/useTranslations.js';
import { useChoixContact } from './hook/useChoixContact.js';
import { PersonIcon } from "../components/espaceClient/PersonIcon";
import { ApiStatus } from '../../../constantes/ApiStatus';

const SelecteurContact = () => {
  const { t } = useTranslations();
  const { contact: choixContact, selectionnerContact } = useChoixContact();

  const { contactList, state } = useSelector((state) => state.espaceClient);

  const handleSelectChange = (event) => {
    const contactId = event.target.value;
    selectionnerContact(contactId);
  };

  const selectValue = choixContact?.contactId || 'all';

  if ( state !== ApiStatus.SUCCESS )
    return <>{t.status_pending}...</>

  return (
    <FormControl fullWidth style={{ marginBottom: "0.875rem" }}>
      <InputLabel id="avatar-select-label">{t.jeSuis}</InputLabel>
      <Select
        labelId="avatar-select-label"
        id="avatar-select"
        label={t.jeSuis}
        value={selectValue}
        onChange={handleSelectChange}
        startAdornment={<PersonIcon/>}
      >
        <MenuItem value="all">{t.toutLeMonde}</MenuItem>
        { contactList.map((contact, index) => <MenuItem key={index} value={contact.contactId}>{contact.contact}</MenuItem>) }
      </Select>
    </FormControl>
  );
};

export default SelecteurContact;

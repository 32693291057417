import { getStore } from '../app/storeProvider';
import { ApiStatus } from "../constantes/ApiStatus";

const _internalGetTranslations = () => {
  const store = getStore();
  const state = store.getState();
  return state.i18n.translations;
}

const _internalStatusMessage = (apiResult, t, nullIfSuccess = true) => {
  if (apiResult.apiStatus === undefined)
      throw new Error(`Status non défini`);
  switch (apiResult.apiStatus) {
    case ApiStatus.IDLE:
      return `${t.status_pending} ...`;
    case ApiStatus.ERROR:
      return `${t.status_error} : ${apiResult.apiError}`;
    case ApiStatus.SUCCESS:
      return (nullIfSuccess) ? null : t.status_success;
    default:
      throw new Error(`Status invalide «${apiResult.apiStatus}»`);
  }
};

const apiStatusMessage = (apiResult) => {
  const t = _internalGetTranslations();
  return _internalStatusMessage(apiResult, t, false);
};

const apiStatusMessageForList = (apiResult) => {
  const t = _internalGetTranslations();
  let message = _internalStatusMessage(apiResult, t, true);
  if (message === null) { // success
    if (! (apiResult.list instanceof Array)) {
      console.log("ApiResult : ", apiResult);
      throw new Error('Propriété «List» non définie');
    }
    if (apiResult.list.length === 0)
      message = t.aucun;
  }
  return message;
};

export { apiStatusMessage, apiStatusMessageForList };

import { useSelector } from "react-redux";

import { Card, CardContent } from "@mui/material";
import { EC_COLORS } from "../../espaceClient/common/EcColor";

import { useChoixContact } from '../hook/useChoixContact.js';
import { useTranslations } from '../hook/useTranslations.js';
import { StyledDivTitleCardReverse, StyledContentActus } from "../../styledComponents/StyledTextEspaceClient";
import { LocalStorage } from "../../../../constantes/globalName/LocalStorage";
import { FONCTION, NOM, PAYS, PRENOM, SECTEUR, SOCIETE, } from "../../enum/champs/ChampsCommunFormulairesEnum";
import { ADRESSE_MAIL, CODE_POSTAL, TELEPHONE, } from "../../../../constantes/symbols/SymbolsCommon";
import { StringToRouteInNavigate } from "../../../../helper/StringParser";
import { GuestRoutes } from "../../../../constantes/routes/GuestRoutes";
import { BoutonAccueilEC } from "../../components/espaceClient/BoutonAccueilEC";
import { importAll } from "../../../../helper/ImportAll";

const ConfigurateurCard = ({ styles, isMobile }) => {
  const { t } = useTranslations();
  const { contact: choixContact } = useChoixContact();
  const { currentContact } = useSelector((state) => state.espaceClient);

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);

  const handleConfigurer = () => {
    if (currentContact.item[ 0 ] && choixContact === null) {
      // Fonction pour mapper sur fonctionList et rechercher la valeur correspondante
      const fonction = fonctionsList.find(
        (item) => item.name === currentContact.item[ 0 ].fonction
      );

      const secteur = secteursList.find(
        (item) =>
          item.reference === currentContact.item[ "infosClient" ].codeSecteurActivite
      );

      const pays = paysList.find(
        (item) => item.reference === currentContact.item[ "infosClient" ].codePays
      );

      // TODO à mettre dans un slice
      localStorage.setItem(
        LocalStorage.INFORMATIONS_CLIENT,
        btoa(
          JSON.stringify({
            [ SOCIETE.description ]: currentContact.item[ "infosClient" ].raisonSociale,
            [ PAYS.description ]: pays,
            [ CODE_POSTAL.description ]: currentContact.item[ "infosClient" ].codePostal,
            [ SECTEUR.description ]: secteur,
            [ NOM.description ]: currentContact.item[ 0 ].nom,
            [ PRENOM.description ]: currentContact.item[ 0 ].prenom,
            [ FONCTION.description ]: fonction,
            [ ADRESSE_MAIL.description ]: currentContact.item[ 0 ].email,
            [ TELEPHONE.description ]: currentContact.item[ 0 ].telephone,
          })
        )
      );
    }

    const url = StringToRouteInNavigate(GuestRoutes.CONFIGURATEUR);
    window.open(url, "_blank");
  };


  return (
    <Card
      id="configurateur-card"
      className={styles.jeConfigureCard}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        backgroundColor: EC_COLORS.DEEP_BLUE,
        flexWrap: "wrap",
        borderRadius: "8px",
      }}
    >
      <img
        className={styles.mobileImageConfigure}
        src={importAll()[ "RCModeles-Configurateur.png" ]}
        alt="RC Modèles Configurateur"
      />
      <CardContent
        style={{ flex: "1", minWidth: "300px", padding: "24px" }}
      >
        <StyledDivTitleCardReverse>
          {t.genTitleJeConfigure}
        </StyledDivTitleCardReverse>

        <StyledContentActus
          style={{ fontSize: "1.25rem", color: "white" }}
        >
          {t.genDescrJeConfigure}
        </StyledContentActus>

        <BoutonAccueilEC
          texte={t.genAffJeConfigure}
          action={handleConfigurer}
          backgroundColor={'dark'}
        />

      </CardContent>
    </Card>
  )
}

export default ConfigurateurCard;

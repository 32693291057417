import React, { useEffect, useState, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useConfiguration } from "../../redux/vue/components/hook/useConfiguration";
import { useTranslations } from "../../redux/vue/components/hook/useTranslations";

import { FooterInternaute } from "../../redux/vue/FooterInternaute";
import { GetStyle } from "../../helper/Styles";
import SideBar from "./SideBar";
import { HeaderEspaceClient } from "../../redux/vue/HeaderEspaceClient";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../redux/slice/MessageReducer";
import { rafraichissementEC } from "../../redux/slice/AuthentificationReducer";
import { ClientRoutes } from "../../constantes/routes/ClientRoutes";
import { loadCurrentClient } from "../../redux/slice/EspaceClientReducer";

const REFRESH_TOKEN_DELAY = 10 * 60000; // 10 minutes

export const ClientWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading: configLoading } = useConfiguration();
  const { loading: translationLoading, t } = useTranslations();

  const [upToDateArticleList, setUpToDateArticleList] = useState([]);

  const calculateIsMobile = () => {
    // FIXME logique inversée isMobileFormat vaut false pour les mobiles
    // FIXME déjà calculé dans AccueilEC.js
    const isMobile = window.innerWidth >= 768;
    return isMobile;
  };

  const [isMobileFormat, setIsMobileFormat] = useState(calculateIsMobile());
  const timerId = useRef(null);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [iframe, setIframe] = useState();

  const styles = GetStyle(false);
  const { accessTokenEC } = useSelector((state) => state.authentification);
  const [upToDateArticleByIdList, setUpToDateArticleByIdList] = useState();
  const [upToDateOffreByIdList, setUpToDateOffreByIdList] = useState();

  const setIdentite = () => {
    // FIXME marque blanche : setIdentite
    const url = window.location.href;
    const regex = new RegExp("moovp*");
    const site = regex.test(url);
    return site;
  };

  const [booleanIdentite, _] = useState(setIdentite()); // setter jamais utilisé

  useEffect( async () => {
    if (configLoading || translationLoading)
      return;
    if ( accessTokenEC === null ) {
      // FIXME le message d'expiration se voit-il si on déclenche une naviguation aussitôt après ?
      dispatch(setMessage(t.expiredSession));
      navigate("EspaceClient/" + ClientRoutes.AUTHENTIFICATION);
    }
    // forçage à faux car chargement client déjà fait si on vient de s'authentifier
    dispatch(loadCurrentClient({forcage:false}));
  }, [ configLoading, translationLoading ]);

  useEffect(() => {
    if (configLoading || translationLoading)
      return;
    setIframe(query.get("iframe"));
  }, [navigate]);


  useEffect(() => {
    setIframe(query.get("iframe"));

    timerId.current = window.setInterval(renowToken, REFRESH_TOKEN_DELAY);

    return () => {
      if (timerId.current !== null)
        window.clearInterval(timerId.current);
    }
  }, []);

  const renowToken = () => {
    dispatch(rafraichissementEC());
  }

  const handleResize = () => {
    setIsMobileFormat(calculateIsMobile());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const stateUsedForRender = {
    booleanIdentite,
    styles,
    t,
    upToDateArticleList,
    upToDateArticleByIdList,
    upToDateOffreByIdList,
    isMobileFormat,
  };

  // attendre que la configuration soit chargée
  if (configLoading || translationLoading)
    return <div>{t.status_pending}..</div>

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F9FF",
      }}
    >
      <div>
        {!iframe ? (
          <HeaderEspaceClient
            t={t}
            isMobileFormat={isMobileFormat}
            styles={styles}
          />
        ) : null}
      </div>

      <div style={{ display: "flex" } }>
        {/* menu latéral */}
        {isMobileFormat && (
          <SideBar />
        )}

        <div
          style={{
            flex: 1,
            backgroundColor: "#F8F9FF",
          }}
        >
          <Outlet
            context={[
              styles,
              t,
              booleanIdentite,
              upToDateArticleByIdList,
              setUpToDateArticleByIdList,
              upToDateOffreByIdList,
              setUpToDateOffreByIdList,
              isMobileFormat,
            ]}
            state={stateUsedForRender}
          />
        </div>
      </div>

      <div>{!iframe ? <FooterInternaute styles={styles} /> : null}</div>
    </div>
  );
};

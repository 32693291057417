import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import { useTranslations } from "../components/hook/useTranslations";
import { useStyles } from "../css/General";
import { connexionEC } from "../../slice/AuthentificationReducer";
import { setMessage } from "../../slice/MessageReducer";
import { ClientRoutes } from "../../../constantes/routes/ClientRoutes";
import { importAll } from "../../../helper/ImportAll";
import { ValidAuthentificationEC } from "../ChampsFormulaires/validationsFormulaires/ValidAuthentificationEC";

import { StyledImgLogoMoov, StyledImgLogoRC } from "../styledComponents/StyledImgBackoffice";
import { StyledH1Connexion } from "../styledComponents/StyledTextBackoffice";
import { StyledButtonConnexion } from "../styledComponents/StyledButtonBackoffice";
import {
  StyledDivButtonConnexionEC,
  StyledDivConnexion,
  StyledDivConnexionParent,
  StyledDivLogos,
} from "../styledComponents/StyledDivBackoffice";

export const AuthentificationFormEC = ({ history }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login } = useParams(); // Extraction du login de l'URL
  const { t } = useTranslations();

  const [ isMounted, setMounted ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.authentification);
  const { message } = useSelector((state) => state.message);

  const css = useStyles();

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      let url = null;
      const previousPage = history.at(-2);
      // TODO route codée en dur
      if ( previousPage !== undefined && previousPage.pathname !== "/EspaceClient" + ClientRoutes.AUTHENTIFICATION )
        url = previousPage.pathname + previousPage.search;
      else
        url = ClientRoutes.ACCUEIL_EC;
      navigate(url);
    }
  }, [isLoggedIn]);


  const handleConnexion = async (formData) => {
    setLoading(true);
    try {
      dispatch(setMessage(null)); // FIXME faut-il effacer le message dans connexionEC ?
      await dispatch(connexionEC(formData)).unwrap();
    }
    catch (error) {
      // ne pas supprimer ce bloc
    }
    finally {
      // éviter les erreurs «Can't perform a React state update on an unmounted component»
      if (isMounted) setLoading(false);
    }
  };

  const TogglePassword = () => {
    setShowPassword(!showPassword);
  };

  //Initialisation des champs formulaire
  const valeursInitiales = {
    clientCode: login || '', // Initialisation avec le login de l'URL si présent
    clientPassword: ''
  };

  //Initialisation du formulaire
  const formik = useFormik({
    initialValues: { ...valeursInitiales },
    validationSchema: ValidAuthentificationEC(t),
    onSubmit: (values) => {
      handleConnexion(values);
    },
  });

  return (
    <StyledDivConnexionParent
      style={{
        background:
          "linear-gradient(0deg, rgba(72 92 150 / 50%), rgba(72 92 150 / 50%)), url(" +
          importAll()["LandingPageImage.jpg"] +
          ")",
      }}
    >
      <StyledDivConnexion>
        <StyledDivLogos>
          {/* TODO logo codé en cur */}
          <StyledImgLogoRC src={importAll()["LogoRCCouleur.png"]} />
          <StyledImgLogoMoov src={importAll()["LogoMoovCouleur.png"]} />
        </StyledDivLogos>
        <StyledH1Connexion>Connexion</StyledH1Connexion>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            <Grid item>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  className={css.textfield}
                  id="clientCode"
                  name="clientCode"
                  label={t.authLblClientCode}
                  value={formik.values.clientCode}
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  error={Boolean(formik.errors.clientCode)}
                  helperText={formik.errors.clientCode}
                  InputProps={{
                    className: css.textfieldProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlinedIcon
                          className={css.iconConnexion}
                        />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ className: css.textfieldLabelProps }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  className={css.textfield}
                  id="clientPassword"
                  name="clientPassword"
                  label={t.authLblPassword}
                  value={formik.values.clientPassword}
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type={showPassword ? "text" : "password"}
                  error={Boolean(formik.errors.clientPassword)}
                  helperText={formik.errors.clientPassword}
                  InputProps={{
                    className: css.textfieldProps,
                    endAdornment: (
                      <IconButton onClick={TogglePassword}>
                        {showPassword ? (
                          <RemoveRedEyeOutlinedIcon sx={{ fontSize: 24 }} />
                        ) : (
                          <VisibilityOffOutlinedIcon sx={{ fontSize: 24 }} />
                        )}
                      </IconButton>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon className={css.iconConnexion} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ className: css.textfieldLabelProps }}
                />
              </Box>
            </Grid>
            {/* <Grid item>
                <StyledFormControlLabelSouvenir control={<Checkbox size="small" />} label='Se souvenir de moi'/>
                </Grid> */}
          </Grid>
          <StyledDivButtonConnexionEC>
            {/* <StyledButtonMdp variant="text" onClick={handleForgetPassword}>
                Mot de passe oublié ?
                </StyledButtonMdp> */}

            <StyledButtonConnexion
              type="submit"
              disabled={loading}
              style={{ marginTop: "1rem" }}
            >
              {t.authBtConnexion}
            </StyledButtonConnexion>
          </StyledDivButtonConnexionEC>
        </form>
        <div>{message}</div>
      </StyledDivConnexion>
    </StyledDivConnexionParent>
  );
};

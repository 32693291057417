import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Paper } from '@mui/material';

import { StringToRouteInNavigate } from '../../helper/StringParser';
import { CollaborateurRoutes } from '../../constantes/routes/CollaborateurRoutes';
import { StyledH1Profil, StyledPProfil } from './styledComponents/StyledTextBackoffice';
import { getOwnUtilisateur } from "../slice/UtilisateurReducer";

import { useConfiguration } from "./components/hook/useConfiguration";

import { StyledButtonOrange } from './styledComponents/StyledButtonBackoffice';
import { StyledDivImgProfil, 
  StyledDivProfilParent, 
  StyledDivProfil, 
  StyledDivOverlayProfil, 
  StyledDivProfilContent, 
  StyledDivButtonValiderAnnuler } from './styledComponents/StyledDivBackoffice';


export const Profil = () => {
    const { parameters } = useConfiguration();
    const { accessToken } = useSelector((state) => state.authentification);
    const { currentUtilisateur } = useSelector((state) => state.utilisateur);
    const [utdCurrentUtilisateur, setUtdCurrentUtilisateur] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [styles, t, setRecapitulatif, recapitulatif, setSelectedPage] = useOutletContext();

    useEffect(()=>{
        if(!accessToken){
            return;
        }
        dispatch(getOwnUtilisateur(accessToken?.data.id))
            .unwrap()
            .then((e) => {
            }).catch((e) => {
            });
    }, [])

    useEffect(() => {
        setSelectedPage(3)
    },[])

    useEffect(()=>{
        if(!currentUtilisateur){
            return;
        }
        setUtdCurrentUtilisateur(currentUtilisateur);
    }, [currentUtilisateur])

    const handleModifierProfil = () => {
        navigate(StringToRouteInNavigate(CollaborateurRoutes.MODIFIER_SON_PROFIL));
    }

    if(!utdCurrentUtilisateur){
        return null;
    }
    
    return (
        <Paper>
            <StyledDivProfilParent>
                <StyledDivProfil>
                    <StyledDivProfilContent>
                        <StyledH1Profil>Bienvenue {utdCurrentUtilisateur?.prenom}</StyledH1Profil>
                        <StyledPProfil>Prénom : {utdCurrentUtilisateur?.prenom}</StyledPProfil>
                        <StyledPProfil>Nom : {utdCurrentUtilisateur?.nom}</StyledPProfil>
                        <StyledPProfil>Adresse mail : {utdCurrentUtilisateur?.email}</StyledPProfil>
                        <StyledDivButtonValiderAnnuler>
                            <StyledButtonOrange onClick={ handleModifierProfil }>Modifier mon profil</StyledButtonOrange>
                        </StyledDivButtonValiderAnnuler>
                    </StyledDivProfilContent>
                </StyledDivProfil>
                <StyledDivImgProfil style={{'backgroundImage': 'url(' + parameters.HOME_BACKGROUND_IMAGE + ')'}}/>
                <StyledDivOverlayProfil/>
            </StyledDivProfilParent>
        </Paper>
    )

}

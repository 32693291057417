import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge } from "@mui/material";

import { useTranslations } from "../../components/hook/useTranslations";
import { LoadingMessage, LoadingErrorMessage } from "../../components/LoadingMessageComponents.jsx";
import { ListeArticleByCommande } from "./ListeArticleByCommande";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { ApiStatus } from '../../../../constantes/ApiStatus';
import { getBgColorFromStatus } from "../../espaceClient/common/EcColor";

import { StyledHrEspaceClientRose } from "../../styledComponents/StyledDivEspaceClient";
import { StyledPaperBodyEC } from "../../styledComponents/StyledPaper";
import { StyledSpanTitleVueDetailEC } from "../../styledComponents/StyledSpan";
import {
  StyledContentVueDetailsEC,
  StyledH1VuesEC,
  StyledTitleVueDetailsEC,
} from "../../styledComponents/StyledTextEspaceClient";
import {
  StyledDivSectionsRowRecap,
  StyledDivSectionVueDetailRoseEC,
  StyledDivVueDetailsEC,
  StyledDivVueDetailsGeneRoseEC,
  StyledDivVueDetailsGeneRoseReverseEC,
} from "../../styledComponents/StyledDiv";


const DetailsCommande = () => {
  const navigate = useNavigate();
  const { t, lang } = useTranslations();
  // FIXME pourquoi useSearchParams est utilisé ?
  const [params] = useSearchParams();

  const { familleArticleList, currentCommande, typeCommandeList } = useSelector((state) => state.espaceClient);
  const commandeById = currentCommande.item;

  const [tableauArtByCommande, setTableauArtByCommande] = useState([]);

  useEffect(() => {
    if (commandeById) {
      const artArray = Object.values(commandeById)
        .filter((value) => typeof value === "object" && value !== null)
        .sort((a, b) => a.ligne - b.ligne);
      setTableauArtByCommande(artArray);
    }
  }, [commandeById, params]);

  const stateUsedForRender = {
    booleanIdentite: false,
    commandeById,
    tableauArtByCommande,
    familleArticleList,
  };

  const actionUsedForRender = {
    setTableauArtByCommande,
  };

  const displayTypeCommande = () => {
    let field = commandeById["typeCommande"];

    const getTranslation = (code) => {
      const entry = typeCommandeList.find((e) => e.typeCommandeCode === code);
      return entry?.[lang] ?? code;
    };

    return (
      <StyledTitleVueDetailsEC>
        <span style={{ width: "200px" }}>{t.typeCommande} : </span>

        <StyledContentVueDetailsEC>
          {getTranslation(field)}
        </StyledContentVueDetailsEC>
      </StyledTitleVueDetailsEC>
    );
  };

  const displayEtat = (etat) => {
    // TODO : Vérifier si le mot name est "Attente", "Commandée" ou "Refusée"
    const reponse =
      etat === "Non commencée"
        ? t.nonCommencee
        : etat === "Terminé"
        ? t.terminee
        : etat === "Partielle"
        ? t.partielle
        : etat;

    return (
      <Badge
        style={{
          wordBreak: "break-all",
          backgroundColor: getBgColorFromStatus(etat),
          color: "white",
          padding: "0.2rem",
          borderRadius: "0.2rem",
        }}
      >
        {reponse}
      </Badge>
    );
  };

  const navigateToOffre = (offreId) => {
    navigate(ClientRoutes.VUE_OFFRE + offreId);
  };

  if (currentCommande.apiStatus === ApiStatus.IDLE)
    return <LoadingMessage t={t} />;

  if (currentCommande.apiStatus === ApiStatus.ERROR)
    // TODO améliorer le rendu
    return <>
      <LoadingErrorMessage t={t} errorMessage={currentCommande.apiError}/>
    </>;

  return (
    <>
      {commandeById && Object.keys(commandeById).length > 0 ? (
        <>
          <StyledH1VuesEC>
            {t.detailsCommande} : {commandeById["commandeCode"]}
          </StyledH1VuesEC>
          <StyledHrEspaceClientRose></StyledHrEspaceClientRose>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC style={{ padding: "24px" }}>
              <StyledSpanTitleVueDetailEC>
                {t.informationsGenerales}
              </StyledSpanTitleVueDetailEC>
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionVueDetailRoseEC>
                  <StyledDivVueDetailsGeneRoseEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.reference} : </span>
                      <StyledContentVueDetailsEC>
                        {commandeById["reference"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    {displayTypeCommande()}
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.dateCreation} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {commandeById["dateCreation"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.contact} : </span>

                      <StyledContentVueDetailsEC>
                        {commandeById["contact"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneRoseEC>
                </StyledDivSectionVueDetailRoseEC>
                <StyledDivSectionVueDetailRoseEC>
                  <StyledDivVueDetailsGeneRoseReverseEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.offreDePrix} : </span>

                      {commandeById["offrePrixCode"] !== "" ? (
                        <StyledContentVueDetailsEC
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigateToOffre(commandeById["offrePrixId"])
                          }
                        >
                          {commandeById["offrePrixCode"]}
                        </StyledContentVueDetailsEC>
                      ) : (
                        <StyledContentVueDetailsEC>
                          {t.aucuneOffre}
                        </StyledContentVueDetailsEC>
                      )}
                    </StyledTitleVueDetailsEC>

                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.titleTabTotalHT} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {commandeById["totalHT"]} €
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.statutLivraison} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {displayEtat(commandeById["livr"])}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}> {t.facture} : </span>

                      <StyledContentVueDetailsEC>
                        {displayEtat(commandeById["fact"])}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneRoseReverseEC>
                </StyledDivSectionVueDetailRoseEC>
              </StyledDivSectionsRowRecap>
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC style={{ padding: "24px" }}>
              <StyledSpanTitleVueDetailEC>
                {t.titleTableauDetailsCommande}
              </StyledSpanTitleVueDetailEC>

              <ListeArticleByCommande
                state={stateUsedForRender}
                actions={actionUsedForRender}
                style={{ marginTop: "1rem" }}
              />
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
        </>
      ) : (
        <LoadingErrorMessage t={t}/>
      )}
    </>
  );
};

export { DetailsCommande };

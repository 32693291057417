import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { EspaceClientActionType } from "../../constantes/actionType/EspaceClientActionType";
import espaceClientService from "../service/EspaceClientService";
import { selectionnerContact } from "./ChoixContactReducer";
import { setInfosClient } from "./InfosClientReducer";
import { ApiStatus } from '../../constantes/ApiStatus';
import { rafraichissementEC } from './AuthentificationReducer';

const loadCurrentClient = createAsyncThunk(
  EspaceClientActionType.LOAD_CURRENT_CLIENT,
  async ({ forcage } , thunkAPI) => {
    try {
      const dispatch = thunkAPI.dispatch;
      let state = thunkAPI.getState();

      const clientId = state.authentification.clientId || null;

      //────── 1ère étape : renouveller le jeton si le client est connecté
      if (clientId !== null) {
        try {
          await dispatch(rafraichissementEC()).unwrap();
        }
        catch (error) {
          throw new Error("Session expirée");
        }
      }

      //────── 2ème étape : lancer les mises à jour en parallèle
      await Promise.all([
        dispatch(getDetailsClient({ clientId })),
        dispatch(getAllFamilleArticle({ clientId })),
        dispatch(getAllArticlesEC({ clientId })),
        dispatch(getTypesCommandeEC({ clientId })),
        dispatch(getEtatNC({ clientId })),
        dispatch(getAllNC({ clientId })),
        dispatch(getAllContacts({ clientId })),
      ]);

      //────── 3ème étape : lancer les mises à jour dépendantes
      await dispatch(selectionnerContact({forcage})).unwrap();

      // mettre à jour les infos client de la partie espace public du site
      if (clientId !== null) {
        const majInfosClient = {};
        state = thunkAPI.getState(); // mise à jour nécessaire
        const contact = state.espaceClient.contactList.reduce( (acc, contact) =>
          contact.clientId === clientId ? contact : acc
        , null);
        if (contact !== null) {
            majInfosClient.nom = contact.nom;
            majInfosClient.prenom = contact.prenom;
            majInfosClient.adresseMail = contact.email;
            majInfosClient.telephone = contact.telephone;

            // retrouver la fonction sous forme d'objet
            const fonctionContact = contact.fonction;
            const objetFonction = state.fonctions.fonctionsList.reduce( (acc, fonction) =>
              fonction.name === fonctionContact ? fonction: acc
            , null);
            majInfosClient.fonction = objetFonction;
        }

        // mettre à jour les coordonnées de l'entreprise
        const detailsClient = state.espaceClient.detailsClient;
        if (detailsClient) {
          majInfosClient.societe = detailsClient.raisonSociale;
          majInfosClient.codePostal = detailsClient.codePostal;

          // retrouver le pays sous forme d'objet
          const paysClient = detailsClient.codePays;
          const objetPays = state.pays.paysList.reduce( (acc, pays) =>
            pays.reference === paysClient ? pays: acc
          , null);
          majInfosClient.pays = objetPays;

          // retrouver le secteur sous forme d'objet
          const secteurClient = detailsClient.codeSecteurActivite;
          const objetSecteur = state.secteurs.secteursList.reduce( (acc, secteur) =>
            secteur.reference === secteurClient ? secteur: acc
          , null);
          majInfosClient.secteur = objetSecteur;
        }

        // mettre à jour les infos client de la partie publique du site
        dispatch(setInfosClient(majInfosClient));
      }

    } catch (error) {
      console.log("Erreur", error);
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getAllArticlesEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_ARTICLES,
  async ({ clientId }, thunkAPI) => {
    try {
      if (clientId === null)
        return [];
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllArticles({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const selectArticleEC = createAsyncThunk(
  EspaceClientActionType.SELECT_ARTICLE,
  async ({ clientId, articleId }, thunkAPI) => {
    if (clientId === null || articleId === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getOneArticleEC({
        clientId,
        articleId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getAllCommandesEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_COMMANDES,
  async ({ clientId, contactId }, thunkAPI) => {
    // contactId peut être null pour «Tout le monde»
    if (clientId === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllCommandes({
        clientId,
        contactId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getAllCommandesByArticleIdEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_COMMANDES_BY_ARTICLE_ID,
  async ({ clientId, articleId }, thunkAPI) => {
    // FIXME articleId peut-il être null ?
    if (clientId === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllCommandesByArticleId({
        clientId,
        articleId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getTypesCommandeEC = createAsyncThunk(
  EspaceClientActionType.GET_TYPES_COMMANDE,
  async ({ clientId }, thunkAPI) => {
    if (clientId === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getTypesCommande({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const selectCommandeEC = createAsyncThunk(
  EspaceClientActionType.SELECT_COMMANDE,
  async ({ clientId, commandeId }, thunkAPI) => {
    // FIXME faut-il contrôler si commandeId est null ?
    if (commandeId === null)
      return null;
    try {
      const { authentification, espaceClient } = thunkAPI.getState();

      // Pas de contrôle de token pour pouvoir visualiser une commande sans authentification
      const accessTokenEC = authentification.accessTokenEC;

      // optimisation si commande déjà sélectionnée
      const currentCommande = espaceClient.currentCommande?.item || null;
      if (currentCommande !== null) {
        const currentCommandeId = currentCommande.commandeId;
        if (currentCommandeId === commandeId )
          return currentCommande;
      }

      const json = await espaceClientService.getOneCommande({
        clientId,
        commandeId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getAllOffresEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_OFFRES,
  async ({ clientId, contactId }, thunkAPI) => {
    // contactId peut être null pour «Tout le monde»
    if (clientId === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllOffres({
        clientId,
        contactId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getAllOffresByArticleIdEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_OFFRES_BY_ARTICLE_ID,
  async ({ clientId, articleId }, thunkAPI) => {
    // FIXME faut-il contrôler si articleId est null ?
    if (clientId === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllOffresByArticleId({
        clientId,
        articleId ,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const selectOffreEC = createAsyncThunk(
  EspaceClientActionType.SELECT_OFFRE,
  async ({ clientId, offreId }, thunkAPI) => {
    if (clientId === null || offreId === null)
      return null;
    try {
      const { authentification, espaceClient } = thunkAPI.getState();

      // Pas de contrôle de token pour pouvoir visualiser une offre sans authentification
      const accessTokenEC = authentification.accessTokenEC;

      // optimisation si offre déjà sélectionnée
      const currentOffre = espaceClient.currentOffre?.item || null;
      if (currentOffre !== null) {
        // FIXME pourquoi la offre est-elle dans un tableau contenant un seul élément
        const currentOffreId = currentOffre[0].offreId;
        if (currentOffreId === offreId )
          return currentOffre;
      }

      const json = await espaceClientService.getOneOffreId({
        clientId,
        offreId,
        accessTokenEC
      });
      return json
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getAllContacts = createAsyncThunk(
  EspaceClientActionType.GET_ALL_CLIENTS,
  async ({ clientId }, thunkAPI) => {
    if (clientId === null)
      return [];
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllContacts({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getDetailsClient = createAsyncThunk(
  EspaceClientActionType.GET_DETAILS_CLIENT,
  async ({ clientId }, thunkAPI) => {
    if (clientId === null)
      return {};
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      // FIXME : renvoie message «Pas de numéro client»
      const json = await espaceClientService.getDetailsClient({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const selectContact = createAsyncThunk(
  EspaceClientActionType.SELECT_CONTACT,
  async ({ clientId, contactId }, thunkAPI) => {
    if (clientId === null || contactId === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getOneContact({
        clientId,
        contactId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getAllNC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_NC,
  async ({ clientId }, thunkAPI) => {
    if (clientId === null)
      return [];
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllNC({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const selectNcEC = createAsyncThunk(
  EspaceClientActionType.SELECT_NC,
  async ({ clientId, ncId }, thunkAPI) => {
    if (clientId === null || ncId === null)
      return null;
    try {
      const { authentification, espaceClient } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');

      // optimisation si nc déjà sélectionnée
      const currentNC = espaceClient.currentNC?.item || null;
      if (currentNC !== null) {
        // FIXME pourquoi la NC est-elle dans un tableau contenant un seul élément
        const currentNcId = currentNC[0].FNCId;
        if (currentNcId === ncId )
          return currentNC;
      }

      const json = await espaceClientService.getOneNC({
        clientId,
        ncId,
        accessTokenEC
      });

      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getEtatNC = createAsyncThunk(
  EspaceClientActionType.GET_ETAT_NC,
  async ({ clientId }, thunkAPI) => {
    if (clientId === null)
      return [];
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getEtatNC({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const getAllFamilleArticle = createAsyncThunk(
  EspaceClientActionType.GET_ALL_FAMILLE_ARTICLE,
  async ({ clientId }, thunkAPI) => {
    if (clientId === null)
      return [];
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllFamilleArticle({
        clientId: clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const sendMailProgrammerVisite = createAsyncThunk(
  EspaceClientActionType.SEND_MAIL_PROGRAMMER_VISITE,
  async ({ contactById }, thunkAPI) => {
    if (contactById === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.sendMailProgrammerVisite({
        contactById,
        accessTokenEC
      });
      if (json.code != 200)
        throw new Error("Erreur d'envoi de mail");
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const sendMailEtreRappeler = createAsyncThunk(
  EspaceClientActionType.SEND_MAIL_ETRE_RAPPELER,
  async ({ contactById }, thunkAPI) => {
    if (contactById === null)
      return null;
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.sendMailEtreRappeler({
        contactById,
        accessTokenEC
      });
      if (json.code != 200)
        throw new Error("Erreur d'envoi de mail");
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const sendModifToFPS = createAsyncThunk(
  EspaceClientActionType.SEND_MAIL_MODIF_CONTACT,
  async ({ anciennesInfos, nouvellesInfos }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.sendMailModifContact({
        anciennesInfos,
        nouvellesInfos,
        accessTokenEC
      });
      if (json.code != 200)
        throw new Error("Erreur d'envoi de mail");
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const sendStatsToFPS = createAsyncThunk(
  EspaceClientActionType.SEND_STATS_TO_FPS,
  async (_, thunkAPI) => {
    try {
      const json = await espaceClientService.sendStatsToFPS();
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);


const initialState = {
  state: ApiStatus.IDLE,
  articleList: {
    list: [],
    apiStatus: ApiStatus.IDLE,
    apiError: null
  },
  currentArticle: {
    item: null,
    apiStatus: ApiStatus.SUCCESS, // (pas IDLE !)
    apiError: null
  },
  commandeList: {
    list: null,
    apiStatus: ApiStatus.IDLE,
    apiError: null
  },
  commandesByArtIdList: {
    list: [],
    apiStatus: ApiStatus.IDLE,
    apiError: null
  },
  offreList: {
    list: null,
    apiStatus: ApiStatus.IDLE,
    apiError: null
  },
  currentOffre: {
    item: null,
    apiStatus: ApiStatus.SUCCESS, // (pas IDLE !)
    apiError: null
  },
  offreByArtIdList: {
    list: [],
    apiStatus: ApiStatus.IDLE,
    apiError: null
  },
  contactList: [], // FIXME gérer apiStatus/apiError
  currentContact: { // anciennement detailsContact
    item: null,
    apiStatus: ApiStatus.SUCCESS, // (pas IDLE !)
    apiError: null
  },
  detailsClient: {},
  typeCommandeList: [], // FIXME gérer apiStatus/apiError
  currentCommande: {
    item: null,
    apiStatus: ApiStatus.SUCCESS, // (pas IDLE !)
    apiError: null
  },
  ncList: {
    list: [],
    apiStatus: ApiStatus.IDLE,
    apiError: null
  },
  etatNCList: {
    list: [],
    apiStatus: ApiStatus.IDLE,
    apiError: null
  },
  currentNC: {
    item: null,
    apiStatus: ApiStatus.SUCCESS, // (pas IDLE !)
    apiError: null
  },
  familleArticleList: [], // FIXME gérer apiStatus/apiError
};

const espaceClientSlice = createSlice({
  name: EspaceClientActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadCurrentClient.pending, (state) => {
        state.state = ApiStatus.IDLE;
      })
      .addCase(loadCurrentClient.fulfilled, (state) => {
        state.state = ApiStatus.SUCCESS;
      })
      .addCase(loadCurrentClient.rejected, (state) => {
        state.state = ApiStatus.ERROR;
      })
      // thunk getAllArticlesEC
      .addCase(getAllArticlesEC.pending, (state) => {
        state.articleList.list = [];
        state.articleList.apiStatus = ApiStatus.IDLE;
        state.articleList.apiError = null;
      })
      .addCase(getAllArticlesEC.fulfilled, (state, action) => {
        const list = action.payload;
        if (list !== null && ! (list instanceof Array))
          console.error('Tableau attendu getAllArticlesEC');
        state.articleList.list = list;
        state.articleList.apiStatus = ApiStatus.SUCCESS;
        state.articleList.apiError = null;
      })
      .addCase(getAllArticlesEC.rejected, (state, action) => {
        state.articleList.list = [];
        state.articleList.apiStatus = ApiStatus.ERROR;
        state.articleList.apiError = action.payload;
      })
      // thunk selectArticleEC
      .addCase(selectArticleEC.pending, (state) => {
        state.currentArticle.item = null;
        state.currentArticle.apiStatus = ApiStatus.IDLE;
        state.currentArticle.apiError = null;
      })
      .addCase(selectArticleEC.fulfilled, (state, action) => {
        state.currentArticle.item = action.payload;
        state.currentArticle.apiStatus = ApiStatus.SUCCESS;
        state.currentArticle.apiError = null;
      })
      .addCase(selectArticleEC.rejected, (state, action) => {
        state.currentArticle.item = null;
        state.currentArticle.apiStatus = ApiStatus.ERROR;
        state.currentArticle.apiError = action.payload;
      })
      // thunk getAllCommandesEC
      .addCase(getAllCommandesEC.pending, (state) => {
        state.commandeList.apiStatus = ApiStatus.IDLE;
        state.commandeList.apiError = null;
      })
      .addCase(getAllCommandesEC.fulfilled, (state, action) => {
        const list = action.payload;
        if (list !== null  && ! (list instanceof Array))
          console.error('Tableau attendu getAllCommandesEC');
        state.commandeList.list = list;
        state.commandeList.apiStatus = (list) ? ApiStatus.SUCCESS : ApiStatus.IDLE;
        state.commandeList.apiError = null;
      })
      .addCase(getAllCommandesEC.rejected, (state, action) => {
        state.commandeList.list = null;
        state.commandeList.apiStatus = ApiStatus.ERROR;
        state.commandeList.apiError = action.payload;
      })
      // thunk getAllCommandesByArticleIdEC
      .addCase(getAllCommandesByArticleIdEC.pending, (state) => {
        state.commandesByArtIdList.list = [];
        state.commandesByArtIdList.apiStatus = ApiStatus.IDLE;
        state.commandesByArtIdList.apiError = null;
      })
      .addCase(getAllCommandesByArticleIdEC.fulfilled, (state, action) => {
        const list = action.payload;
        if (list !== null && ! (list instanceof Array))
          console.error('Tableau attendu getAllCommandesByArticleIdEC');
        state.commandesByArtIdList.list = list;
        state.commandesByArtIdList.apiStatus = ApiStatus.SUCCESS;
        state.commandesByArtIdList.apiError = null;
      })
      .addCase(getAllCommandesByArticleIdEC.rejected, (state, action) => {
        state.commandesByArtIdList.list = [];
        state.commandesByArtIdList.apiStatus = ApiStatus.ERROR;
        state.commandesByArtIdList.apiError = action.payload;
      })
      // thunk getTypesCommandeEC
      .addCase(getTypesCommandeEC.fulfilled, (state, action) => {
        state.typeCommandeList = action.payload;
      })
      .addCase(getTypesCommandeEC.rejected, (state, _) => {
        state.typeCommandeList = [];
      })
      // thunk selectCommandeEC
      .addCase(selectCommandeEC.pending, (state) => {
        state.currentCommande.apiStatus = ApiStatus.IDLE;
        state.currentCommande.apiError = null;
      })
      .addCase(selectCommandeEC.fulfilled, (state, action) => {
        const item = action.payload;
        // optimisation : garder en cache la dernière commande en ne la déselectionnant pas
        if (item !== null)
          state.currentCommande.item = item;
        state.currentCommande.apiStatus = ApiStatus.SUCCESS;
        state.currentCommande.apiError = null;
      })
      .addCase(selectCommandeEC.rejected, (state, action) => {
        state.currentCommande.item = null;
        state.currentCommande.apiStatus = ApiStatus.ERROR;
        state.currentCommande.apiError = action.payload;
      })
      // thunk getAllOffresEC
      .addCase(getAllOffresEC.pending, (state) => {
        state.offreList.apiStatus = ApiStatus.IDLE;
        state.offreList.apiError = null;
      })
      .addCase(getAllOffresEC.fulfilled, (state, action) => {
        const list = action.payload;
        if (list !== null && ! (list instanceof Array))
          console.error('Tableau attendu getAllOffresEC');
        state.offreList.list = list;
        state.offreList.apiStatus = (list) ? ApiStatus.SUCCESS : ApiStatus.IDLE;
        state.offreList.apiError = null;
      })
      .addCase(getAllOffresEC.rejected, (state, action) => {
        state.offreList.list = null;
        state.offreList.apiStatus = ApiStatus.ERROR;
        state.offreList.apiError = action.payload;
      })
      // thunk selectOffreEC
      .addCase(selectOffreEC.pending, (state) => {
        state.currentOffre.apiStatus = ApiStatus.IDLE;
        state.currentOffre.apiError = null;
      })
      .addCase(selectOffreEC.fulfilled, (state, action) => {
        const item = action.payload;
        // optimisation : garder en cache la dernière offre en ne la déselectionnant pas
        if (item !== null)
          state.currentOffre.item = item;
        state.currentOffre.apiStatus = ApiStatus.SUCCESS;
        state.currentOffre.apiError = null;
      })
      .addCase(selectOffreEC.rejected, (state, action) => {
        state.currentOffre.item = null;
        state.currentOffre.apiStatus = ApiStatus.ERROR;
        state.currentOffre.apiError = action.payload;
      })
      // thunk getAllOffresByArticleIdEC
      .addCase(getAllOffresByArticleIdEC.pending, (state) => {
        state.offreByArtIdList.list = [];
        state.offreByArtIdList.apiStatus = ApiStatus.IDLE;
        state.offreByArtIdList.apiError = null;
      })
      .addCase(getAllOffresByArticleIdEC.fulfilled, (state, action) => {
        const list = action.payload;
        if (list !== null && ! (list instanceof Array))
          console.error('Tableau attendu getAllOffresByArticleIdEC');
        state.offreByArtIdList.list = list;
        state.offreByArtIdList.apiStatus = ApiStatus.SUCCESS;
        state.offreByArtIdList.apiError = null;
      })
      .addCase(getAllOffresByArticleIdEC.rejected, (state, action) => {
        state.offreByArtIdList.list = [];
        state.offreByArtIdList.apiStatus = ApiStatus.ERROR;
        state.offreByArtIdList.apiError = action.payload;
      })
      // thunk getAllContacts
      .addCase(getAllContacts.fulfilled, (state, action) => {
        state.contactList = action.payload;
      })
      .addCase(getAllContacts.rejected, (state, _) => {
        state.contactList = [];
      })
      // thunk getDetailsClient
      .addCase(getDetailsClient.fulfilled, (state, action) => {
        state.detailsClient = action.payload;
      })
      .addCase(getDetailsClient.rejected, (state, _) => {
        state.detailsClient = {};
      })
      // thunk selectContact
      .addCase(selectContact.pending, (state) => {
        state.currentContact.apiStatus = ApiStatus.IDLE;
        state.currentContact.apiError = null;
      })
      .addCase(selectContact.fulfilled, (state, action) => {
        const item = action.payload;
        // optimisation : garder en cache le dernier contact en ne le déselectionnant pas
        if (item !== null)
          state.currentContact.item = item;
        state.currentContact.apiStatus = ApiStatus.SUCCESS;
        state.currentContact.apiError = null;
      })
      .addCase(selectContact.rejected, (state, action) => {
        state.currentContact.item = null;
        state.currentContact.apiStatus = ApiStatus.ERROR;
        state.currentContact.apiError = action.payload;
      })
      // thunk getAllNC
      .addCase(getAllNC.pending, (state) => {
        state.ncList.list = [];
        state.ncList.apiStatus = ApiStatus.IDLE;
        state.ncList.apiError = null;
      })
      .addCase(getAllNC.fulfilled, (state, action) => {
        const list = action.payload;
        if (list !== null && ! (list instanceof Array))
          console.error('Tableau attendu getAllNC');
        state.ncList.list = list;
        state.ncList.apiStatus = ApiStatus.SUCCESS;
        state.ncList.apiError = null;
      })
      .addCase(getAllNC.rejected, (state, action) => {
        state.ncList.list = [];
        state.ncList.apiStatus = ApiStatus.ERROR;
        state.ncList.apiError = action.payload;
      })
      // thunk getEtatNC
      .addCase(getEtatNC.pending, (state) => {
        state.etatNCList.list = [];
        state.etatNCList.apiStatus = ApiStatus.IDLE;
        state.etatNCList.apiError = null;
      })
      .addCase(getEtatNC.fulfilled, (state, action) => {
        const list = action.payload;
        if (list !== null && ! (list instanceof Array))
          console.error('Tableau attendu getEtatNC');
        state.etatNCList.list = list;
        state.etatNCList.apiStatus = ApiStatus.SUCCESS;
        state.etatNCList.apiError = null;
      })
      .addCase(getEtatNC.rejected, (state, action) => {
        state.etatNCList.list = [];
        state.etatNCList.apiStatus = ApiStatus.ERROR;
        state.etatNCList.apiError = action.payload;
      })
      // thunk getAllFamilleArticle
      .addCase(getAllFamilleArticle.fulfilled, (state, action) => {
        const list = action.payload;
        if (list !== null && ! (list instanceof Array))
          console.error('Tableau attendu getAllFamilleArticle');
        state.familleArticleList = list;
      })
      .addCase(getAllFamilleArticle.rejected, (state, _) => {
        state.familleArticleList = [];
      })
      // thunk selectNcEC
      .addCase(selectNcEC.pending, (state) => {
        state.currentNC.apiStatus = ApiStatus.IDLE;
        state.currentNC.apiError = null;
      })
      .addCase(selectNcEC.fulfilled, (state, action) => {
        const item = action.payload;
        // optimisation : garder en cache la dernière NC en ne la déselectionnant pas
        if (item !== null)
          state.currentNC.item = item;
        state.currentNC.apiStatus = ApiStatus.SUCCESS;
        state.currentNC.apiError = null;
      })
      .addCase(selectNcEC.rejected, (state, action) => {
        state.currentNC.item = null;
        state.currentNC.apiStatus = ApiStatus.ERROR;
        state.currentNC.apiError = action.payload;
      })
      // thunk sendMailProgrammerVisite
      .addCase(sendMailProgrammerVisite.fulfilled, (_, action) => {
        setMessage(action.payload);
      })
      .addCase(sendMailProgrammerVisite.rejected, (_, action) => {
        setMessage(action.payload);
      })
      // thunk sendMailEtreRappeler
      .addCase(sendMailEtreRappeler.fulfilled, (_, action) => {
        setMessage(action.payload);
      })
      .addCase(sendMailEtreRappeler.rejected, (_, action) => {
        setMessage(action.payload);
      })
      // thunk sendModifToFPS
      .addCase(sendModifToFPS.fulfilled, (_, action) => {
        setMessage(action.payload);
      })
      .addCase(sendModifToFPS.rejected, (_, action) => {
        setMessage(action.payload);
      })
  }
});

const { reducer } = espaceClientSlice;

export {
  loadCurrentClient,
  getAllArticlesEC,
  selectArticleEC,
  getAllCommandesEC,
  getAllCommandesByArticleIdEC,
  getTypesCommandeEC,
  selectCommandeEC,
  getAllOffresEC,
  getAllOffresByArticleIdEC,
  selectOffreEC,
  getAllContacts,
  getDetailsClient,
  selectContact,
  getAllNC ,
  selectNcEC ,
  getEtatNC ,
  getAllFamilleArticle ,
  sendMailProgrammerVisite,
  sendMailEtreRappeler,
  sendModifToFPS,
  sendStatsToFPS
};
export default reducer;

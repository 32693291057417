import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { SecteursActionType } from "../../constantes/actionType/SecteursActionType";
import secteursService from "../service/SecteursService";
import { langJsonFormatter } from "../../helper/ServiceCleaner";
import { ApiStatus } from '../../constantes/ApiStatus';

export const getAllSecteurs = createAsyncThunk(
  SecteursActionType.GET_ALL_SECTEURS,
  async (lang, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const secteursList = state.secteurs.secteursList;
      // optimisation : ne pas renvoyer de requête si liste déjà récupérée
      if (secteursList.length > 0)
        return secteursList;
      const reponse = await secteursService.getAllSecteurs();
      return langJsonFormatter(lang, thunkAPI, reponse);
    } catch (error) {
      console.log(error);
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {
  secteursList: [],
  apiStatus: ApiStatus.IDLE,
  apiError: null
};

const secteursSlice = createSlice({
  name: SecteursActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllSecteurs.pending, (state, _) => {
        state.apiStatus = ApiStatus.IDLE;
        state.apiError = null;
      })
      .addCase(getAllSecteurs.fulfilled, (state, action) => {
        state.apiStatus = ApiStatus.SUCCESS;
        state.apiError = null;
        state.secteursList = action.payload;
      })
      .addCase(getAllSecteurs.rejected, (state, action) => {
        state.apiStatus = ApiStatus.ERROR;
        state.apiError = action.payload;
      });
  },
});

const { reducer } = secteursSlice;
export default reducer;

import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { selectContact } from "../../../slice/EspaceClientReducer";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ListeContact } from "./ListeContact";
import { DetailsContact } from "./DetailsContact";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

import { StyledH1VuesEC, StyledP } from "../../styledComponents/StyledTextEspaceClient";
import { StyledDivWrapperVues, StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { StyledDivMsgEchec, StyledDivMsgSucces } from "../../styledComponents/StyledDivBackoffice";

export const VueContact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { clientId } = useAuthentification();
  const { contactId:contactIdParam } = useParams();
  const contactIdUrlParam  = contactIdParam || null;

  const { message } = useSelector((state) => state.message);

  const [isContactByIdSet, setIsContactByIdSet] = useState(false);

  const { currentContact } = useSelector((state) => state.espaceClient);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
  ] = useOutletContext();

  useEffect( () => {
    // compatibilité avec ancienne URL EspaceClient/VueCommande/?commande=xxx
    const contactId = searchParams.get('contact');
    if (contactId !== null) {
      navigate(ClientRoutes.VUE_CONTACT + contactId, { replace: true});
    }
  }, [searchParams]);


  useEffect( () => {
    dispatch(selectContact( { clientId, contactId: contactIdUrlParam }));
    setIsContactByIdSet(contactIdUrlParam !== null);
  }, [ contactIdUrlParam ]);


  const stateUsedForRender = {
    booleanIdentite: false,
    formik: upToDateOffreByIdList,
    styles,
    t,
    detailsContact: currentContact.item,
    isContactByIdSet,
  };

  const actionUsedForRender = {
    dispatch,
    setUpToDateOffreByIdList,
    setIsContactByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  return !isContactByIdSet ? (
    <StyledDivWrapperVues>
      <StyledH1VuesEC>{t.titleSideBarContact}</StyledH1VuesEC>
      <StyledHrEspaceClient></StyledHrEspaceClient>
      <StyledP>{t.descrVueContact}</StyledP>

      <div>
        {displayError()}
        <ListeContact state={stateUsedForRender} actions={actionUsedForRender} />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%" }}>
        <DetailsContact
          t={t}
          state={stateUsedForRender}
          actions={actionUsedForRender}
        />
      </div>
    </ErrorBoundary>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { selectOffreEC } from '../../../slice/EspaceClientReducer.js';
import { useAuthentification } from '../../components/hook/useAuthentification.js';
import SelecteurContact from '../../components/SelecteurContact.jsx';
import ErrorBoundary from "../../components/ErrorBoundary";
import { ListeOffre } from "./ListeOffre";
import { DetailsOffre } from "./DetailsOffre";
import { TooltipEC } from "../../components/espaceClient/TooltipEC";
import { BoutonRetourEC } from "../../components/espaceClient/BoutonAccueilEC";
import { ReactualisationCard } from "../../components/espaceClient/ReactualisationCard.jsx";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

import { StyledH1VuesEC, StyledP } from "../../styledComponents/StyledTextEspaceClient";
import { StyledDivWrapperVues, StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { StyledDivMsgEchec, StyledDivMsgSucces } from "../../styledComponents/StyledDivBackoffice";

const VueOffre = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { clientId } = useAuthentification();

  const { offreId:offreIdParam } = useParams();
  const offreIdUrlParam  = offreIdParam || null;

  const [isOffreByIdSet, setIsOffreByIdSet] = useState(false);

  // TODO optimiser avec un seul useSelector
  const { message } = useSelector((state) => state.message);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
  ] = useOutletContext();

  useEffect( () => {
    // compatibilité avec ancienne URL EspaceClient/VueOffre/?offre=xxx
    const offreId = searchParams.get('offre');
    if (offreId !== null) {
      navigate(ClientRoutes.VUE_OFFRE + offreId, { replace: true});
    }
  }, [searchParams]);


  useEffect( () => {
    dispatch(selectOffreEC( { clientId, offreId: offreIdUrlParam }));
    setIsOffreByIdSet(offreIdUrlParam !== null);
  }, [ offreIdUrlParam ]);


  const actionUsedForRender = {
    dispatch,
    setUpToDateOffreByIdList,
    setIsOffreByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  const triggerHandleRetour = () => {
    navigate(ClientRoutes.VUE_OFFRE);
  };

  return !isOffreByIdSet ? (
    <StyledDivWrapperVues>
      <StyledH1VuesEC>{t.titleSideBarOffres}</StyledH1VuesEC>
      <StyledHrEspaceClient></StyledHrEspaceClient>
      <StyledP>{t.descrVueOffre}</StyledP>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }} >
        <SelecteurContact/>
      </div>

      <div>
        {displayError()}
        <ListeOffre
          actions={actionUsedForRender}
          style={{
            boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
          }}
        />
        <TooltipEC texte={t.infoBulleOffre} />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%", padding: "1rem" }}>
        <BoutonRetourEC texte={t.retourListeOffres} action={triggerHandleRetour} backgroundColor={"#F8F9FF"} />
        <DetailsOffre />
        <ReactualisationCard
          title={t.titleReactualiserOffre}
          content={t.contentReactualiserOffre}
          buttonLabel={t.reactualiserOffre}
        />
      </div>
    </ErrorBoundary>
  );
};

export { VueOffre };

import {
  BoutonAccueilEC,
  BoutonRetourEC,
} from "../../components/espaceClient/BoutonAccueilEC";
import {
  StyledContentActus,
  StyledContentVueDetailsEC,
  StyledDivTitleCardReverse,
  StyledH1VuesEC,
  StyledTitleVueDetailsEC,
} from "../../styledComponents/StyledTextEspaceClient";
import { StyledHrEspaceClientRose } from "../../styledComponents/StyledDivEspaceClient";
import { Loading } from "../../components/Loading";
import { StyledPaperBodyEC } from "../../styledComponents/StyledPaper";
import {
  StyledDivSectionsRowRecap,
  StyledDivSectionVueDetailRoseEC,
  StyledDivVueDetailsEC,
  StyledDivVueDetailsGeneRoseEC,
  StyledDivVueDetailsGeneRoseReverseEC,
} from "../../styledComponents/StyledDiv";
import { StyledSpanTitleVueDetailEC } from "../../styledComponents/StyledSpan";
import { importAll } from "../../../../helper/ImportAll";
import { Badge, Card, CardContent } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StringToRouteInNavigate } from "../../../../helper/StringParser";
import { GuestRoutes } from "../../../../constantes/routes/GuestRoutes";
import { useEffect, useState } from "react";
import {
  getAllFamilleArticle,
  getTypesCommandeEC,
} from "../../../model/slice/EspaceClientReducer";
import { useDispatch, useSelector } from "react-redux";
import { ListeArticleByCommande } from "./ListeArticleByCommande";
import { useTranslations } from "../../components/hook/useTranslations";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { LocalStorage } from "../../../../constantes/globalName/LocalStorage";
import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  TELEPHONE,
  FONCTION,
  NOM,
  PAYS,
  PRENOM,
  SECTEUR,
  SOCIETE,
} from "../../../../constantes/symbols/SymbolsCommon";
import { getAllSecteurs } from "../../../model/slice/SecteurReducer";
import { getAllFonctions } from "../../../model/slice/FonctionReducer";
import { getAllPays } from "../../../model/slice/PaysReducer";
import { EC_COLORS, getBgColorFromStatus } from "../common/EcColor";

export const DetailsCommande = ({
  t,
  actions,
  state: {
    isOrderByIdSet,
    commandeById,
    fonctionsList,
    secteursList,
    paysList,
    detailsContact,
  },
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useTranslations();
  const [params] = useSearchParams();

  const { typeCommandeList, familleArticleList } = useSelector(
    (state) => state.espaceClient
  );

  const [tableauArtByCommande, setTableauArtByCommande] = useState([]);

  useEffect(() => {
    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());
  }, []);

  const triggerHandleRetour = () => {
    const url = new URL(window.location.href);

    actions.setIsOrderByIdSet(false);

    url.searchParams.delete("commande");
    window.history.pushState({}, "", url);
  };

  const afficherBoutonRetour = (texte, action, backgroundColor) => {
    return (
      <BoutonRetourEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  useEffect(() => {
    const encodedClientId = localStorage.getItem("clientId");

    if (encodedClientId) {
      const clientId = atob(encodedClientId);
      const obj = { clientId };

      dispatch(getTypesCommandeEC(obj));
      dispatch(getAllFamilleArticle(obj));
    }
  }, [dispatch]);

  useEffect(() => {
    if (commandeById) {
      const artArray = Object.values(commandeById)
        .filter((value) => typeof value === "object" && value !== null)
        .sort((a, b) => a.ligne - b.ligne);

      setTableauArtByCommande(artArray);
    }
  }, [commandeById, params]);

  const stateUsedForRender = {
    booleanIdentite: false,
    t,
    commandeById,
    isOrderByIdSet,
    tableauArtByCommande,
    familleArticleList,
  };

  const actionUsedForRender = {
    triggerHandleRetour,
    setTableauArtByCommande,
  };

  const displayTypeCommande = () => {
    let field = commandeById["typeCommande"];

    return (
      <StyledTitleVueDetailsEC>
        <span style={{ width: "200px" }}>{t.typeCommande} : </span>

        <StyledContentVueDetailsEC>
          {lang.lang === "FR"
            ? typeCommandeList.find((e) => e.typeCommandeCode === field)?.FR ??
              field
            : lang.lang === "GB"
            ? typeCommandeList.find((e) => e.typeCommandeCode === field)?.EN ??
              field
            : lang.lang === "DE"
            ? typeCommandeList.find((e) => e.typeCommandeCode === field)?.DE ??
              field
            : lang.lang === "IT"
            ? typeCommandeList.find((e) => e.typeCommandeCode === field)?.IT ??
              field
            : field}
        </StyledContentVueDetailsEC>
      </StyledTitleVueDetailsEC>
    );
  };

  const displayEtat = (etat) => {
    // Vérifier si le mot name est "Attente", "Commandée" ou "Refusée"
    const reponse =
      etat === "Non commencée"
        ? t.nonCommencee
        : etat === "Terminé"
        ? t.terminee
        : etat === "Partielle"
        ? t.partielle
        : etat;

    return (
      <Badge
        style={{
          wordBreak: "break-all",
          backgroundColor: getBgColorFromStatus(etat),
          color: "white",
          padding: "0.2rem",
          borderRadius: "0.2rem",
        }}
      >
        {reponse}
      </Badge>
    );
  };

  const handleActualiser = () => {
    const selectedValue = localStorage.getItem("selectedValue");

    if (detailsContact[0] && selectedValue !== "all") {
      // Fonction pour mapper sur fonctionList et rechercher la valeur correspondante
      const fonction = fonctionsList.find(
        (item) => item.name === detailsContact[0].fonction
      );

      const secteur = secteursList.find(
        (item) =>
          item.reference === detailsContact["infosClient"].codeSecteurActivite
      );

      const pays = paysList.find(
        (item) => item.reference === detailsContact["infosClient"].codePays
      );

      localStorage.setItem(
        LocalStorage.INFORMATIONS_CLIENT,
        btoa(
          JSON.stringify({
            [SOCIETE.description]: detailsContact["infosClient"].raisonSociale,
            [PAYS.description]: pays,
            [CODE_POSTAL.description]: detailsContact["infosClient"].codePostal,
            [SECTEUR.description]: secteur,
            [NOM.description]: detailsContact[0].nom,
            [PRENOM.description]: detailsContact[0].prenom,
            [FONCTION.description]: fonction,
            [ADRESSE_MAIL.description]: detailsContact[0].email,
            [TELEPHONE.description]: detailsContact[0].telephone,
          })
        )
      );
    }

    const url = StringToRouteInNavigate(GuestRoutes.RECHERCHER);
    window.open(url, "_blank");
  };

  const afficherBouton = (texte, action, backgroundColor) => {
    return (
      <BoutonAccueilEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  const navigateToODP = (offreId) => {
    navigate(ClientRoutes.VUE_OFFRE + "?offre=" + offreId);
  };

  return (
    <div style={{ padding: "1rem" }}>
      {afficherBoutonRetour(
        t.retourListeCommandes,
        triggerHandleRetour,
        "#F8F9FF"
      )}
      {commandeById && Object.keys(commandeById).length > 0 ? (
        <div>
          <StyledH1VuesEC>
            {t.detailsCommande} : {commandeById["commandeCode"]}
          </StyledH1VuesEC>
          <StyledHrEspaceClientRose></StyledHrEspaceClientRose>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC style={{ padding: "24px" }}>
              <StyledSpanTitleVueDetailEC>
                {t.informationsGenerales}
              </StyledSpanTitleVueDetailEC>
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionVueDetailRoseEC>
                  <StyledDivVueDetailsGeneRoseEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.reference} : </span>
                      <StyledContentVueDetailsEC>
                        {commandeById["reference"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    {displayTypeCommande()}
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.dateCreation} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {commandeById["dateCreation"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.contact} : </span>

                      <StyledContentVueDetailsEC>
                        {commandeById["contact"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneRoseEC>
                </StyledDivSectionVueDetailRoseEC>
                <StyledDivSectionVueDetailRoseEC>
                  <StyledDivVueDetailsGeneRoseReverseEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.offreDePrix} : </span>

                      {commandeById["offrePrixCode"] !== "" ? (
                        <StyledContentVueDetailsEC
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigateToODP(commandeById["offrePrixId"])
                          }
                        >
                          {commandeById["offrePrixCode"]}
                        </StyledContentVueDetailsEC>
                      ) : (
                        <StyledContentVueDetailsEC>
                          {t.aucuneOffre}
                        </StyledContentVueDetailsEC>
                      )}
                    </StyledTitleVueDetailsEC>

                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.titleTabTotalHT} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {commandeById["totalHT"]} €
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.statutLivraison} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {displayEtat(commandeById["livr"])}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}> {t.facture} : </span>

                      <StyledContentVueDetailsEC>
                        {displayEtat(commandeById["fact"])}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneRoseReverseEC>
                </StyledDivSectionVueDetailRoseEC>
              </StyledDivSectionsRowRecap>
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC style={{ padding: "24px" }}>
              <StyledSpanTitleVueDetailEC>
                {t.titleTableauDetailsCommande}
              </StyledSpanTitleVueDetailEC>

              <ListeArticleByCommande
                state={stateUsedForRender}
                actions={actionUsedForRender}
                style={{ marginTop: "1rem" }}
              />
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          <Card
            id="configurateur-card"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
              backgroundColor: EC_COLORS.DEEP_BLUE,
              flexWrap: "wrap",
              marginTop: "2rem",
            }}
          >
            <img
              src={importAll()["RCModeles-Configurateur.png"]}
              alt="RC Modèles Configurateur"
              style={{
                height: "100%",
                width: "100%",
                maxWidth: "225px",
                objectFit: "fill",
              }}
            />
            <CardContent style={{ flex: "1", minWidth: "300px" }}>
              <StyledDivTitleCardReverse>
                {t.titleReactualiserCommande}
              </StyledDivTitleCardReverse>

              <StyledContentActus
                style={{ fontSize: "calc(8px + 1vw)", color: "#fff" }}
              >
                {t.contentReactualiserCommande}
              </StyledContentActus>

              {afficherBouton(t.reactualiserCommande, handleActualiser, "dark")}
            </CardContent>
          </Card>
        </div>
      ) : (
        <Loading t={t} /> // ou tout autre composant pour indiquer qu'il n'y a pas de commande
      )}
    </div>
  );
};

import {
  Badge,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
  StyledDivSearchBar,
} from "../../../styledComponents/StyledDivBackoffice";
import { StyledTextfieldSearchDdp } from "../../../styledComponents/StyledTextfieldBackoffice";
import { ClientRoutes } from "../../../../../constantes/routes/ClientRoutes";

export const OffreByArtItem = ({
  id,
  offreCode,
  ligne,
  marque,
  quantite,
  prixUnitaireHT,
  totalHT,
  miseADisposition,
  articleDesignation,
  reponseLigne,
  offreId,
  state: { t },
  searchBarAction: {
    setSearchBarContentCodeOffre,
    setSearchBarContentNumeroLigne,
    setSearchBarContentMarque,
    setSearchBarContentQuantite,
    setSearchBarContentTotalHT,
    setSearchBarContentPrixUnitaire,
    setSearchBarContentDelais,
    setSearchBarContentDesignation,
    setSearchBarContentReponse,
  },
  sortAction: {
    setSortedCodeOffre,
    setSortedNumeroLigne,
    setSortedMarque,
    setSortedQuantite,
    setSortedTotalHT,
    setSortedPrixUnitaire,
    setSortedDelais,
    setSortedDesignation,
    setSortedReponse,
  },
  modale: { handleOpen, setReferenceToDelete, setUpToDateArticleByIdList },
}) => {
  const navigate = useNavigate();
  const [columnSize, setColumnSize] = useState(1);
  const [isForceSendEnded, setIsForceSendEnded] = useState(true);
  const { forceSend } = useSelector((state) => state.formulaire);

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));

  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  useEffect(() => {
    if (forceSend === undefined) {
      setIsForceSendEnded(true);
    } else {
      setIsForceSendEnded(forceSend);
    }
  }, [forceSend]);

  const handleChange = (event, setterSearchBar) => {
    setterSearchBar(event.target.value);
  };

  const displaySearchBar = (setValue) => {
    if (setValue) {
      return (
        <div>
          <StyledDivSearchBar>
            <StyledTextfieldSearchDdp
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event, setValue)}
              InputProps={{
                endAdornment: (
                  <SearchIcon md={{ color: "#EC8812", fontSize: 10 }} />
                ),
              }}
            />
          </StyledDivSearchBar>
        </div>
      );
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " columnHeaderEC"}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
          marginLeft={name === "Action" ? "-0.75rem" : "0"}
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
            {displaySortArrows(sortStateSetter)}
          </Stack>
          <Stack>{displaySearchBar(searchBarStateSetter)}</Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style) => {
    let displayName = name;

    // Vérifier et remplacer les valeurs spécifiques
    if (name === "RC") {
      displayName = "RC Modèles";
    } else if (name === "MP") {
      displayName = "Moovprotect";
    }

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: displayName }}
        />
      </Grid>
    );
  };

  const displayItemArray = (array, md, sm, style, texte = "") => {
    if (array.length === 1) {
      return displayItem(array[0] + " " + texte, md, sm, style);
    }

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          {array.map((item, index) => (
            <div id="item-container">
              <div
                key={index + item}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.toString() + " " + texte,
                  }}
                />
              </div>
              <div>
                {index < array.length - 1 && <hr className={"hrDdp"} />}
              </div>
            </div>
          ))}
        </div>
      </Grid>
    );
  };

  const getBgColor = (item) => {
    const bgcolorReponse =
      item === "Attente"
        ? "#7f7f7f"
        : item === "Commandée"
        ? "#4ea72e"
        : item === "Refusée"
        ? "#e97132"
        : item === "Autre offre"
        ? "#ffc000"
        : "#7f7f7f";

    return bgcolorReponse;
  };

  const displayBadgeItemArray = (array, md, sm, xs, style, texte = "") => {
    if (array.length === 1) {
      return displayBadgeReponse(array[0], md, sm, xs, style);
    }

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " itemDdp"}
      >
        <div style={{ width: "100%" }}>
          {array.map((item, index) => (
            <Fragment key={index + item}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
              >
                <Badge
                  style={{
                    wordBreak: "break-all",
                    backgroundColor: getBgColor(item.toString()),
                    color: "white",
                    marginTop: "0.25rem",
                    marginBottom: "0.25rem",
                    paddingLeft: "0.4rem",
                    paddingRight: "0.4rem",
                    borderRadius: "0.25rem",
                    fontSize: "0.75rem",
                  }}
                >
                  {item.toString() + " " + texte}
                </Badge>
              </div>
              {index < array.length - 1 && <hr className={"hrDdp"} />}
            </Fragment>
          ))}
        </div>
      </Grid>
    );
  };

  const displayBadgeReponse = (reponse, md, sm, xs, style) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Badge
          style={{
            wordBreak: "break-all",
            backgroundColor: getBgColor(reponse),
            color: "white",
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
            paddingLeft: "0.3rem",
            paddingRight: "0.3rem",
            borderRadius: "0.25rem",
            fontSize: "0.75rem",
          }}
        >
          {reponse}
        </Badge>
      </Grid>
    );
  };

  const displayOffreCode = (offreId, name, md, sm, style) => {
    // Définir l'URL de redirection pour la commande
    const navigateToOffre = () => {
      if (offreId) {
        navigate(ClientRoutes.VUE_OFFRE + "?offre=" + offreId);
      }
    };

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {offreId ? (
          // Si offreId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={navigateToOffre} // Ne pas invoquer directement la fonction
          />
        ) : (
          // Si offreId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : null}
    >
      {id === 0
        ? displayItemHeader(
            ligne,
            setSearchBarContentNumeroLigne,
            setSortedNumeroLigne,
            0.6,
            0.6,
            4,
            noClassNameMobile
          )
        : displayItem(ligne, 0.6, 0.6, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            offreCode,
            setSearchBarContentCodeOffre,
            setSortedCodeOffre,
            1.25,
            1.25,
            8,
            alwaysDisplayed
          )
        : displayOffreCode(offreId, offreCode, 1.25, 1.25, alwaysDisplayed)}
      {id === 0
        ? displayItemHeader(
            articleDesignation,
            setSearchBarContentDesignation,
            setSortedDesignation,
            2,
            2,
            4,
            noClassNameMobile
          )
        : displayItem(articleDesignation, 2, 2, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            marque,
            setSearchBarContentMarque,
            setSortedMarque,
            1.5,
            1.5,
            4,
            noClassNameMobile
          )
        : displayItem(marque, 1.5, 1.5, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            quantite,
            setSearchBarContentQuantite,
            setSortedQuantite,
            1,
            1,
            null,
            noClassNameMobile
          )
        : displayItemArray(quantite, 1, 1, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            prixUnitaireHT,
            setSearchBarContentPrixUnitaire,
            setSortedPrixUnitaire,
            1,
            1,
            8,
            noClassNameMobile
          )
        : displayItemArray(prixUnitaireHT, 1, 1, noClassNameMobile, "€")}
      {id === 0
        ? displayItemHeader(
            totalHT,
            setSearchBarContentTotalHT,
            setSortedTotalHT,
            1,
            1,
            4,
            noClassNameMobile
          )
        : displayItemArray(totalHT, 1, 1, noClassNameMobile, "€")}
      {id === 0
        ? displayItemHeader(
            miseADisposition,
            setSearchBarContentDelais,
            setSortedDelais,
            2,
            2,
            4,
            noClassNameMobile
          )
        : displayItemArray(
            miseADisposition,
            2,
            2,
            noClassNameMobile,
            t.semaineAvantDispo
          )}
      {/* {id === 0
        ? displayItemHeader(
            statut,
            setSearchBarContentStatut,
            setSortedStatut,
            1.75,
            1.75,
            4,
            alwaysDisplayed
          )
        : displayItem(statut, 1.75, 1.75, alwaysDisplayed)} */}
      {id === 0
        ? displayItemHeader(
            reponseLigne,
            setSearchBarContentReponse,
            setSortedReponse,
            1.5,
            1.5,
            4,
            ""
          )
        : displayBadgeItemArray(reponseLigne, 1.5, 1.5, 4, "")}
    </Grid>
  );
};

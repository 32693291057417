import { getStore } from '../../app/storeProvider';

const CONTACT_KEY = 'contact';

const selectionnerContact = async (params) => {
  let contactId = params.contactId;
  if (contactId === undefined)
    throw new Error('contactattendu');
  if (contactId === 'all')
    contactId = null;

  let contact = null;

  if (contactId !== null) {
    const store = getStore();
    const state = store.getState();
    const espaceClient = state.espaceClient;
    const contactList = espaceClient.contactList;
    if (contactList.length === 0)
      throw new Error("Liste des contacts non initialisée");

    const selContact = contactList.find( (item) => item.contactId === contactId);
    if (selContact === undefined) {
      contact = null;
    }
    else {
      contact = {
        ...selContact, // copie car object freeze
        id: contactId
      };
    }
  }

  if (contact === null) {
    localStorage.removeItem(CONTACT_KEY);
    localStorage.removeItem('selectedValue'); // TODO à supprimer après migration
  }
  else {
    localStorage.setItem(CONTACT_KEY, JSON.stringify(contact));
    localStorage.setItem('selectedValue', contactId); // TODO à supprimer après migration
  }
  return contact;
};

const recupererContact = () => {
  let contact = localStorage.getItem(CONTACT_KEY);
  if (contact !== null) {
    try {
      contact = JSON.parse(contact);
    }
    catch (_) {
      contact = null;
    }
  }
  return contact;
};

const choixContactService = {
  selectionnerContact,
  recupererContact
};

export default choixContactService;

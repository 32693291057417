import { BoutonAccueilEC, BoutonRetourEC, } from "../../components/espaceClient/BoutonAccueilEC";
import {
  StyledContentActus,
  StyledContentVueDetailsEC,
  StyledDivTitleCardReverse,
  StyledH1VuesEC,
  StyledTitleVueDetailsEC,
} from "../../styledComponents/StyledTextEspaceClient";
import { StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { Loading } from "../../components/Loading";
import { StyledPaperBodyEC } from "../../styledComponents/StyledPaper";
import {
  StyledDivSectionsRowRecap,
  StyledDivSectionVueDetailOrangeEC,
  StyledDivVueDetailsEC,
  StyledDivVueDetailsGeneOrangeEC,
} from "../../styledComponents/StyledDiv";
import { StyledSpanTitleVueDetailEC } from "../../styledComponents/StyledSpan";
import { importAll } from "../../../../helper/ImportAll";
import { Badge, Card, CardContent } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { StringToRouteInNavigate } from "../../../../helper/StringParser";
import { GuestRoutes } from "../../../../constantes/routes/GuestRoutes";
import { ListeArticleByOffre } from "./ListeArticleByOffre";
import { useEffect, useState } from "react";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { LocalStorage } from "../../../../constantes/globalName/LocalStorage";
import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  FONCTION,
  NOM,
  PAYS,
  PRENOM,
  SECTEUR,
  SOCIETE,
  TELEPHONE,
} from "../../../../constantes/symbols/SymbolsCommon";
import { useDispatch } from "react-redux";
import { getAllSecteurs } from "../../../model/slice/SecteurReducer";
import { getAllFonctions } from "../../../model/slice/FonctionReducer";
import { getAllPays } from "../../../model/slice/PaysReducer";

export const DetailsOffre = ({
  t,
  actions,
  state: {
    isOffreByIdSet,
    offreById,
    familleArticleList,
    fonctionsList,
    secteursList,
    paysList,
    detailsContact,
  },
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [tableauOffres, setTableauOffres] = useState([]);

  useEffect(() => {
    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());
  }, []);

  const triggerHandleRetour = () => {
    const url = new URL(window.location.href);

    actions.setIsOffreByIdSet(false);

    url.searchParams.delete("offre");
    window.history.pushState({}, "", url);
  };

  const handleActualiser = () => {
    if(offreById && offreById[0]){
      localStorage.setItem(LocalStorage.OFFRE_CLIENT, offreById[0]['offreCode']);
    }
    const selectedValue = localStorage.getItem("selectedValue");
    if (detailsContact[0] && selectedValue !== "all") {
      // Fonction pour mapper sur fonctionList et rechercher la valeur correspondante
      const fonction = fonctionsList.find(
        (item) => item.name === detailsContact[0].fonction
      );

      const secteur = secteursList.find(
        (item) =>
          item.reference === detailsContact["infosClient"].codeSecteurActivite
      );

      const pays = paysList.find(
        (item) => item.reference === detailsContact["infosClient"].codePays
      );

      localStorage.setItem(
        LocalStorage.INFORMATIONS_CLIENT,
        btoa(
          JSON.stringify({
            [SOCIETE.description]: detailsContact["infosClient"].raisonSociale,
            [PAYS.description]: pays,
            [CODE_POSTAL.description]: detailsContact["infosClient"].codePostal,
            [SECTEUR.description]: secteur,
            [NOM.description]: detailsContact[0].nom,
            [PRENOM.description]: detailsContact[0].prenom,
            [FONCTION.description]: fonction,
            [ADRESSE_MAIL.description]: detailsContact[0].email,
            [TELEPHONE.description]: detailsContact[0].telephone,
          })
        )
      );
    }
    const url = StringToRouteInNavigate(GuestRoutes.RECHERCHER);
    window.open(url, "_blank");
  };

  const afficherBoutonRetour = (texte, action, backgroundColor) => {
    return (
      <BoutonRetourEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  const afficherBouton = (texte, action, backgroundColor) => {
    return (
      <BoutonAccueilEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  const navigateToCommande = (commandeId) => {
    if (commandeId) {
      navigate(ClientRoutes.VUE_COMMANDE + "?commande=" + commandeId);
    }
  };

  const getBadgeColor = (reponse) => {
    switch (reponse) {
      case "Attente":
        return { backgroundColor: "#7f7f7f" }; // Orange pour "Attente"
      case "Commandée":
        return { backgroundColor: "#4ea72e" }; // Vert pour "Commandée"
      case "Refusée":
        return { backgroundColor: "#e97132" }; // Rouge pour "Refusée"
      default:
        return { backgroundColor: "#7f7f7f" }; // Gris par défaut
    }
  };

  const getBadgeText = (reponse, t) => {
    switch (reponse) {
      case "Attente":
        return t.enAttente;
      case "Commandée":
        return t.commandee;
      case "Refusée":
        return t.refusee;
      default:
        return reponse;
    }
  };

  const DisplayBadge = ({ offreById, t }) => (
    <Badge
      style={{
        wordBreak: "break-all",
        color: "white",
        padding: "0.2rem",
        borderRadius: "0.2rem",
        ...getBadgeColor(offreById["reponse"]),
      }}
    >
      {getBadgeText(offreById["reponse"], t)}
    </Badge>
  );

  useEffect(() => {
    if (offreById) {
      const offresArray = Object.values(offreById)
        .filter((value) => typeof value === "object" && value !== null)
        .sort((a, b) => a.ligne - b.ligne);

      setTableauOffres(offresArray);
    }
  }, [offreById, params]);

  const stateUsedForRender = {
    booleanIdentite: false,
    t,
    offreById,
    isOffreByIdSet,
    tableauOffres,
    familleArticleList,
  };

  const actionUsedForRender = {
    triggerHandleRetour,
    handleActualiser,
    setTableauOffres,
  };

  return (
    <div style={{ padding: "1rem" }}>
      {afficherBoutonRetour(
        t.retourListeOffres,
        triggerHandleRetour,
        "#F8F9FF"
      )}
      {offreById && offreById[0] !== undefined ? (
        <div>
          <StyledH1VuesEC>
            {t.detailsOffre} : {offreById[0]["offreCode"]}
          </StyledH1VuesEC>
          <StyledHrEspaceClient></StyledHrEspaceClient>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC>
              <StyledSpanTitleVueDetailEC>
                {t.informationsGenerales}
              </StyledSpanTitleVueDetailEC>
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneOrangeEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.reference} : </span>

                      <StyledContentVueDetailsEC>
                        {offreById["reference"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.dateCreation} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {offreById["dateCreation"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.contact} : </span>

                      <StyledContentVueDetailsEC>
                        {offreById["contact"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneOrangeEC>
                </StyledDivSectionVueDetailOrangeEC>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneOrangeEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.commande} : </span>

                      {offreById["commandeCode"] !== "" ? (
                        <StyledContentVueDetailsEC
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            navigateToCommande(offreById["commandeId"]);
                          }}
                        >
                          {offreById["commandeCode"]}
                        </StyledContentVueDetailsEC>
                      ) : (
                        <StyledContentVueDetailsEC>
                          {t.pasDeCommande}
                        </StyledContentVueDetailsEC>
                      )}
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.statutPublication} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {offreById["publ"].includes("Vrai") ? (
                          <Badge
                            style={{
                              wordBreak: "break-all",
                              backgroundColor: "#4ea72e",
                              color: "white",
                              padding: "0.2rem",
                              borderRadius: "0.2rem",
                            }}
                          >
                            {t.envoyee}
                          </Badge>
                        ) : (
                          <Badge
                            style={{
                              wordBreak: "break-all",
                              backgroundColor: "#7f7f7f",
                              color: "white",
                              padding: "0.2rem",
                              borderRadius: "0.2rem",
                            }}
                          >
                            {t.enCours}
                          </Badge>
                        )}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.reponse} : </span>

                      <StyledContentVueDetailsEC>
                        <DisplayBadge offreById={offreById} t={t} />
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneOrangeEC>
                </StyledDivSectionVueDetailOrangeEC>
              </StyledDivSectionsRowRecap>
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC>
              <StyledSpanTitleVueDetailEC>
                {t.titleTableauDetailsOffre}
              </StyledSpanTitleVueDetailEC>

              <ListeArticleByOffre
                state={stateUsedForRender}
                actions={actionUsedForRender}
                style={{ marginTop: "1rem" }}
              />
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          <Card
            id="configurateur-card"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
              backgroundColor: "#2A375C",
              flexWrap: "wrap",
              marginTop: "2rem",
            }}
          >
            <img
              src={importAll()["RCModeles-Configurateur.png"]}
              alt="RC Modèles Configurateur"
              style={{
                height: "100%",
                width: "100%",
                maxWidth: "225px",
                objectFit: "fill",
              }}
            />
            <CardContent style={{ flex: "1", minWidth: "300px" }}>
              <StyledDivTitleCardReverse>
                {t.titleReactualiserOffre}
              </StyledDivTitleCardReverse>

              <StyledContentActus
                style={{ fontSize: "calc(8px + 1vw)", color: "#fff" }}
              >
                {t.contentReactualiserOffre}
              </StyledContentActus>

              {afficherBouton(t.reactualiserOffre, handleActualiser, "dark")}
            </CardContent>
          </Card>
        </div>
      ) : (
        <Loading t={t} /> // ou tout autre composant pour indiquer qu'il n'y a pas d'offre
      )}
    </div>
  );
};

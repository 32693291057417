import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";

import { useWindowWidth, ScreenType } from '../../../../context/WindowWidthContext';
import { useTranslations } from '../../components/hook/useTranslations.js';
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { StyledDivTitleCard, StyledContentCard } from "../../styledComponents/StyledTextEspaceClient";
import { BoutonAccueilEC } from "../../components/espaceClient/BoutonAccueilEC";
import { NCIconCard } from "./NCIconCard";

const NcCard = ({ styles }) => {
  const { screenType } = useWindowWidth();
  const { t } = useTranslations();
  const navigate = useNavigate();

  const specStyle = (screenType === ScreenType.DESKTOP) ? { flex: 1 } : {};

  const handleNC = () => {
    navigate(ClientRoutes.VUE_NC);
  };

  return (
    <Card
      id="nc-card"
      className={styles.ncCard}
      style={{
        ...specStyle,
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        borderRadius: "8px"
      }}
    >
      <CardContent style={{ flex: 1, padding: "24px" }}>
        <div
          style={{
            height: 0,
            display: "flex",
            justifyContent: "end"
          }}
        >
          <NCIconCard/>
        </div>
        <StyledDivTitleCard>{t.genTitleCardNC}</StyledDivTitleCard>

        <StyledContentCard>{t.genDescrNC}</StyledContentCard>

          <BoutonAccueilEC texte={t.genAffNC} action={handleNC} />

      </CardContent>
    </Card>
  )
};

export default NcCard;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Badge, Card, CardContent } from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { EC_COLORS } from "../../espaceClient/common/EcColor";
import { SeeMoreIconEC } from "../../components/SeeMoreIconEC";
import { BoutonAccueilEC } from "../../components/espaceClient/BoutonAccueilEC";
import { ApiStatus } from "../../../../constantes/ApiStatus";
import { apiStatusMessageForList } from "../../../../helper/apiStatusHelper.js";

import {
  StyledDivTitleCard,
  StyledDivTitleSubCard,
  StyledContentCard
} from "../../styledComponents/StyledTextEspaceClient";


const OffreCard = ({ styles } ) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { offreList } = useSelector((state) => state.espaceClient);

  const [upToDateOffresList, setUpToDateOffresList] = useState([]);
  const [listMessage, setListMessage] = useState(null);

  const divStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%", // Assure que les cartes utilisent toute la largeur disponible
  };


  useEffect(() => {
    setListMessage(apiStatusMessageForList(offreList));

    if (offreList.apiStatus === ApiStatus.IDLE)
      return;

    // Prendre les 2 premières offres
    setUpToDateOffresList(offreList.list.slice(0, 2));

  }, [offreList.list, offreList.apiStatus]);

  const handleOffres = () => {
    navigate(ClientRoutes.VUE_OFFRE);
  };

  const handleOneOffre = (offreId) => () => {
    navigate(ClientRoutes.VUE_OFFRE + offreId);
  };

  return (
    <Card
      id="offre-card"
      className={styles.offreCard}
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: EC_COLORS.ORANGE,
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        borderRadius: "8px",
      }}
    >
      <CardContent style={{ flex: 1, padding: "24px" }}>
        <StyledDivTitleCard>{t.genTitleCardOffre}</StyledDivTitleCard>

        <div className={styles.offreContainer} style={divStyle}>
          { listMessage !== null ? (
            <StyledContentCard className={styles.aucuneOffre}>{listMessage}</StyledContentCard>
          ) : (
            upToDateOffresList.map((offre, index) => (
              <Card
                key={index}
                className={styles.singleOffreCard}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(50% - 1rem)",
                  borderRadius: "8px",
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.25) 100%)",
                  boxShadow: "0px 0px 4px 2px rgba(255, 255, 255, 0.15)",
                }}
              >
                <CardContent style={{ flex: 1 }}>
                  <StyledDivTitleSubCard>
                    {offre.offrePrixCode}
                  </StyledDivTitleSubCard>
                  <StyledContentCard>
                    {offre.offreRefClient}
                  </StyledContentCard>
                  <StyledContentCard>
                    {offre.dateCreation}
                  </StyledContentCard>
                  {offre.commandeCode !== "" ? (
                    <Badge
                      style={{
                        position: "initial",
                        backgroundColor: "white",
                        color: EC_COLORS.DARK_BLUE,
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        marginTop: "0.5rem",
                        borderRadius: "5px",
                      }}
                    >
                      <StyledContentCard style={{ margin: "0" }}>
                        {t.commandee}
                      </StyledContentCard>
                    </Badge>
                  ) : (
                    <Badge
                      style={{
                        position: "initial",
                        backgroundColor: "white",
                        color: EC_COLORS.DARK_BLUE,
                        paddingTop: "0.2rem",
                        paddingBottom: "0.2rem",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        marginTop: "0.5rem",
                        borderRadius: "5px",
                      }}
                    >
                      <StyledContentCard style={{ margin: "0" }}>
                        {t.nonCommandee}
                      </StyledContentCard>
                    </Badge>
                  )}
                </CardContent>
                <SeeMoreIconEC
                  dark={true}
                  method={handleOneOffre(offre.offrePrixId)}
                />
              </Card>
            ))
          )}
        </div>
        <BoutonAccueilEC
          texte={t.genAffToutesOffres}
          action={handleOffres}
          backgroundColor={"black"}
        />

      </CardContent>
    </Card>
  )
}

export default OffreCard;

import { Box, Tab, Tabs } from "@mui/material";
import { StyledTitleTab } from "../../styledComponents/StyledTextEspaceClient";
import { OffresArticle } from "../../espaceClient/vueArticle/controllerResume/OffresArticle";
import { CommandesArticle } from "../../espaceClient/vueArticle/controllerResume/CommandesArticle";

export const afficherOnglets = (t, formulaire, selectedTab, setSelectedTab, recap ) => {
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
    const nbOffrePrix = parseInt(formulaire?.['infosArticleEC']?.[0]?.['nbOffrePrix']);
    const nbCommande = parseInt(formulaire?.['infosArticleEC']?.[0]?.['nbCommande']);

    return (
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          style={{ marginTop: "2rem" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#2A375C",
            },
          }}
        >
          <Tab
            style={{
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: selectedTab === 0 ? "white" : "#E7EAFF",
              padding: "1.5rem",
              marginRight: "1.5rem",
              opacity: selectedTab === 0 ? "1" : "0.8",
            }}
            label={
              <StyledTitleTab>{t.titleDetailArtCaracteristique}</StyledTitleTab>
            }
          />
          <Tab disabled={nbOffrePrix === 0}
            style={{
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: selectedTab === 1 ? "white" : nbOffrePrix === 0 ? "lightgrey" : "#EC88124D",
              padding: "1.5rem",
              marginRight: "1.5rem",
              opacity: selectedTab === 1 ? "1" : "0.8",
            }}
            label={<StyledTitleTab disabled={nbOffrePrix === 0}>{t.titleDetailArtODP} {nbOffrePrix > 0 && '(' + nbOffrePrix + ')'}</StyledTitleTab>}
          />
          <Tab disabled={nbCommande === 0}
            style={{
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: selectedTab === 2 ? "white" : nbCommande === 0 ? "lightgrey" : "#CC007B40",
              padding: "1.5rem",
              opacity: selectedTab === 2 ? "1" : "0.8",
            }}
            label={<StyledTitleTab disabled={nbCommande === 0}>{t.titleDetailArtCommande} {nbCommande > 0 && '(' + nbCommande + ')'}</StyledTitleTab>}
          />
        </Tabs>
        {selectedTab === 0 && <Box>{recap}</Box>}
        {selectedTab === 1 && (
          <Box>
            {
              <OffresArticle
                clientId={formulaire?.["infosArticleEC"]?.[0]["clientId"]}
                articleId={formulaire?.["infosArticleEC"]?.[0]["articleId"]}
              />
            }
          </Box>
        )}
        {selectedTab === 2 && (
          <Box>
            {
              <CommandesArticle
                clientId={formulaire?.["infosArticleEC"]?.[0]["clientId"]}
                articleId={formulaire?.["infosArticleEC"]?.[0]["articleId"]}
              />
            }
          </Box>
        )}
      </Box>
    );
  };

export const afficherOngletsMobile = (t, formulaire, selectedTab, setSelectedTab, recap) => {
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const nbOffrePrix = parseInt(formulaire?.['infosArticleEC']?.[0]?.['nbOffrePrix']);
  const nbCommande = parseInt(formulaire?.['infosArticleEC']?.[0]?.['nbCommande']);
  return (
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          orientation="vertical" // Pour que les onglets soient disposés verticalement
          style={{ marginTop: "2rem" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#2A375C",
            },
          }}
        >
          <Tab
            style={{
              borderRadius: "8px 8px 8px 8px",
              backgroundColor: selectedTab === 0 ? "white" : "#E7EAFF",
              padding: "1.5rem",
              marginBottom: "1.5rem", // Remplacer marginRight par marginBottom
              width: "100%", // Assurer que chaque onglet prend toute la largeur
              opacity: selectedTab === 0 ? "1" : "0.8",
            }}
            label={
              <StyledTitleTab>{t.titleDetailArtCaracteristique}</StyledTitleTab>
            }
          />
          <Tab disabled={nbOffrePrix === 0}
            style={{
              borderRadius: "8px 8px 8px 8px",
              backgroundColor: selectedTab === 1 ? "white" : nbOffrePrix === 0 ? "lightgrey" : "#EC88124D",
              padding: "1.5rem",
              marginBottom: "1.5rem", // Remplacer marginRight par marginBottom
              width: "100%", // Assurer que chaque onglet prend toute la largeur
              opacity: selectedTab === 1 ? "1" : "0.8",
            }}
            label={<StyledTitleTab disabled={nbOffrePrix === 0}>{t.titleDetailArtODP} {nbOffrePrix > 0 && '(' + nbOffrePrix + ')'}</StyledTitleTab>}
          />
          <Tab disabled={nbCommande === 0}
            style={{
              borderRadius: "8px 8px 8px 8px",
              backgroundColor: selectedTab === 2 ? "white" : nbCommande === 0 ? "lightgrey" : "#CC007B40",
              padding: "1.5rem",
              width: "100%", // Assurer que chaque onglet prend toute la largeur
              marginBottom: "1.5rem",
              opacity: selectedTab === 2 ? "1" : "0.8",
            }}
            label={<StyledTitleTab disabled={nbCommande === 0}>{t.titleDetailArtCommande} {nbCommande > 0 && '(' + nbCommande + ')'}</StyledTitleTab>}
          />
        </Tabs>
        {selectedTab === 0 && <Box>{recap}</Box>}
        {selectedTab === 1 && (
          <Box>
            <OffresArticle
              clientId={formulaire?.["infosArticleEC"]?.[0]["clientId"]}
              articleId={formulaire?.["infosArticleEC"]?.[0]["articleId"]}
            />
          </Box>
        )}
        {selectedTab === 2 && (
          <Box>
            <CommandesArticle
              clientId={formulaire?.["infosArticleEC"]?.[0]["clientId"]}
              articleId={formulaire?.["infosArticleEC"]?.[0]["articleId"]}
            />
          </Box>
        )}
      </Box>
    );
  };

import { useSelector, useDispatch } from 'react-redux';
import { setInfosClient } from '../../../slice/InfosClientReducer';

const useInfosClient = () => {
  const dispatch = useDispatch();

  // Sélectionner l'état infosClient depuis le store Redux
  const infosClient = useSelector((state) => state.infosClient);

  // Fonction pour mettre à jour les infosClient
  const updateInfosClient = (newInfos) => {
    dispatch(setInfosClient(newInfos));
  };

  return {
    infosClient,
    updateInfosClient,
  };
};

export default useInfosClient;


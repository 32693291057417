import {
  CONFIGURATEUR,
  CONTACT,
  RECHERCHER,
  SOS,
} from "../../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { arrayProductFamilly } from "../../../../../constantes/symbols/SymbolsServicesCommon";
import { TEXT } from "../../../components/generateFormulary/types/TypeFieldsFormulary";

const TITRE_CONFIRMATION = Symbol("titreConfirmation");
const TITRE_MESSAGE = Symbol("titreMessage");
const TITRE_NEW_DEMANDE = Symbol("titreNewDemande");
const TITRE_QUESTIONNAIRE = Symbol("titreQuestionnaire");

const afficherTitreMessageConfirmation = (from, t) => {
  if (from === CONFIGURATEUR) {
    return t.validationDemandeDp;
  }
  if (from === RECHERCHER || from === CONTACT || from === SOS) {
    return t.validationDemande;
  }
  return "!!!ERROR:ChampsConfirmation!!!";
};

export const formulaireMessageConfirmation = (styles, from, t) => [
  {
    nom: TITRE_CONFIRMATION,
    label: afficherTitreMessageConfirmation(from, t),
    type: TEXT,
    csslabelQuestionConfirmation: styles.labelQuestionConfirmation,
    cssMarginFormSection: styles.noSectionForm,
  },
];

export const formulaireContactConfirmation = (
  styles,
  reference,
  booleanIdentite,
  t
) => [
  {
    nom: TITRE_MESSAGE,
    label:
      t.messageConfPart1 +
      (booleanIdentite ? " " + t.moovprotect : " " + t.rcmodeles) +
      t.point +
      "<br>" +
      t.messageConfPart2 +
      " <b>" +
      reference +
      "</b>" +
      t.point +
      "<br><br>" +
      t.messageConfPart3,
    type: TEXT,
    cssMsgConfirmation: styles.messageConfirmation,
    cssMarginFormSection: styles.noSectionForm,
  },
];

export const formulaireContactConfirmationRechercherEtContactCorps = (
  styles,
  reference,
  booleanIdentite,
  t
) => [
  {
    nom: TITRE_MESSAGE,
    label:
      t.messageConfPart1 +
      (booleanIdentite ? " " + t.moovprotect : " " + t.rcmodeles) +
      "<br>" +
      t.messageConfPart2 +
      " <b id='refddp'>" +
      reference +
      "</b>" +
      t.point,
    type: TEXT,
    cssMsgConfirmation: styles.messageConfirmation,
    cssMarginFormSection: styles.noSectionForm,
  },
];

export const formulairePubliciteFps = (styles, booleanIdentite, t, familleProt) => [
  {
    nom: TITRE_MESSAGE,
    label:
      [
        t.publicitePart1,
        booleanIdentite ? " " + t.moovprotect : " " + t.rcmodeles,
        t.publicitePart2,
        arrayProductFamilly(t)
          .filter(
            (el) => familleProt !== el.code
          )
          .map((el) => el.name)
          .join(", "),
      ].join(" ") + t.publicitePart3,
    type: TEXT,
    cssMsgConfirmation: styles.messageConfirmation,
    cssMarginFormSection: styles.noSectionForm,
  },
];

export const formulaireMessageConfirmationP2 = (styles, t) => [
  {
    nom: TITRE_NEW_DEMANDE,
    label: t.confNewDemande,
    type: TEXT,
    cssMsgConfirmation: styles.messageConfirmation,
    cssMarginFormSection: styles.noSectionForm,
  },
];

export const formulaireQuestionnaire = (styles, t) => [
  {
    nom: TITRE_QUESTIONNAIRE,
    label: t.messageQuestionnaireP1 + "<br>" + t.messageQuestionnaireP2,
    type: TEXT,
    cssMsgConfirmation: styles.messageCssQuestionnaire,
    cssMarginFormSection: styles.noSectionForm,
  },
];

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import SelecteurContact from '../../components/SelecteurContact.jsx';
import { TooltipEC } from "../../components/espaceClient/TooltipEC";
import { selectCommandeEC } from "../../../slice/EspaceClientReducer";
import { ListeCommande } from "./ListeCommande";
import ErrorBoundary from "../../components/ErrorBoundary";
import { DetailsCommande } from "./DetailsCommande";
import { EC_COLORS } from "../common/EcColor";
import { BoutonRetourEC, BoutonStereo } from "../../components/espaceClient/BoutonAccueilEC";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { ReactualisationCard } from "../../components/espaceClient/ReactualisationCard.jsx";
import { useStyles } from "./../../css/General";

import { StyledH1VuesEC, StyledP } from "../../styledComponents/StyledTextEspaceClient";
import { StyledDivWrapperVues, StyledHrEspaceClientRose } from "../../styledComponents/StyledDivEspaceClient";
import { StyledDivMsgEchec, StyledDivMsgSucces } from "../../styledComponents/StyledDivBackoffice";

const VueCommande = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const css = useStyles();
  const [searchParams] = useSearchParams();
  const { clientId } = useAuthentification();
  const { commandeId:commandeIdParam } = useParams();
  const commandeIdUrlParam  = commandeIdParam || null;

  const { message } = useSelector((state) => state.message);

  const [isCommandeByIdSet, setIsCommandeByIdSet] = useState(false);

  const { currentCommande, familleArticleList, currentContact } = useSelector((state) => state.espaceClient);
  const refCommande = (currentCommande.item !== null) ? currentCommande.item['commandeCode'] : null;

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
  ] = useOutletContext();

  useEffect( () => {
    // compatibilité avec ancienne URL EspaceClient/VueCommande/?commande=xxx
    const commandeId = searchParams.get('commande');
    if (commandeId !== null) {
      navigate(ClientRoutes.VUE_COMMANDE + commandeId, { replace: true});
    }
  }, [searchParams]);


  useEffect( () => {
    dispatch(selectCommandeEC( { clientId, commandeId: commandeIdUrlParam }));
    setIsCommandeByIdSet(commandeIdUrlParam !== null);
  }, [ commandeIdUrlParam ]);


  const stateUsedForRender = {
    booleanIdentite: false,
    formik: upToDateOffreByIdList,
    styles,
    t,
    currentCommande,
    //isCommandeByIdSet,
    familleArticleList,
    secteursList,
    fonctionsList,
    paysList,
    detailsContact: currentContact.item,
  };

  const actionUsedForRender = {
    dispatch,
    setUpToDateOffreByIdList,
    setIsCommandeByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  const triggerHandleRetour = () => {
    navigate(ClientRoutes.VUE_COMMANDE);
  };

  const triggerHandleAccesReactualiser = () => {
    const elCard = document.getElementById('configurateur-card');
    if (elCard)
      elCard.scrollIntoView({ behavior: 'smooth' });
      elCard.classList.add(css.highlightBorder);
      elCard.classList.add(css.highlight);
      setTimeout(function() {
            elCard.classList.remove(css.highlight);
      }, 5000);
  }


  return !isCommandeByIdSet ? (
    <StyledDivWrapperVues>
      <StyledH1VuesEC>{t.titleSideBarCommandes}</StyledH1VuesEC>
      <StyledHrEspaceClientRose></StyledHrEspaceClientRose>
      <StyledP>{t.descrVueCommande}</StyledP>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }} >
        <SelecteurContact/>
      </div>
      <div>
        {displayError()}
        <ListeCommande state={stateUsedForRender} actions={actionUsedForRender} />
        <TooltipEC texte={t.infoBulleCommande} color={EC_COLORS.VIOLET} />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%", padding: "1rem" }}>
        <div style={{ display:'flex', flexDirection:'row', gap: '1rem' }}>
          <BoutonRetourEC texte={t.retourListeCommandes} action={triggerHandleRetour} backgroundColor={"#F8F9FF"} />
          <BoutonStereo texte={t.titleReactualiserCommande} action={triggerHandleAccesReactualiser} backgroundColor={'dark'} />
        </div>
        <DetailsCommande/>
        <ReactualisationCard
          title={t.titleReactualiserCommande}
          content={t.contentReactualiserCommande}
          buttonLabel={t.reactualiserCommande}
          refCommande={refCommande}
        />
      </div>
    </ErrorBoundary>
  );
};

export { VueCommande };

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CardMedia, Modal, Box,
  CircularProgress, IconButton, Typography, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { importAll } from "../../../../helper/ImportAll";
import { useTranslations } from '../../components/hook/useTranslations.js';
import { sendMailEtreRappeler, sendMailProgrammerVisite } from "../../../slice/EspaceClientReducer";
import { BoutonCialProx, } from "./BoutonAccueilEC";
import { BoutonWithoutArrow, } from "./BoutonAccueilEC";
import { EC_COLORS } from "../../espaceClient/common/EcColor";

import { StyledButtonContinuer } from "../../styledComponents/StyledButton";
import { StyledCheckRoundedIcon, StyledClearSharpIconError } from "../../styledComponents/StyledIcon";
import {
  StyledContentCard,
  StyledDivTitleCard,
  StyledDivTitleSubCard,
  StyledDivBoutonCialProximite
} from "../../styledComponents/StyledTextEspaceClient";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const DialogueConfirmation = ({
  openConfirmationModal, setOpenConfirmationModal,
  messageToConfirm, actionToConfirm
}) => {
  const { t } = useTranslations();
  return (
    <Modal
      open={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
    >
      <Box sx={style}>
        <StyledDivTitleCard
          id="confirm-modal-title"
          variant="h6"
          component="h2"
          style={{ textAlign: "center" }}
        >
          {t.descrConfirmationEnvoi}
        </StyledDivTitleCard>
        <StyledContentCard
          id="confirm-modal-description"
          sx={{ mt: 2 }}
          style={{ textAlign: "center" }}
        >
          {messageToConfirm}
        </StyledContentCard>
        <Box mt={3} display="flex" justifyContent="space-between">
          <IconButton
            onClick={() => setOpenConfirmationModal(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>

          <BoutonWithoutArrow
            texte={"Annuler"}
            action={() => setOpenConfirmationModal(false)}
          />
          <StyledButtonContinuer
            variant="contained"
            style={{ backgroundColor: EC_COLORS.DARK_BLUE }}
            onClick={() => {
              if (actionToConfirm) actionToConfirm();
              setOpenConfirmationModal(false);
            }}
          >
            {t.comfirmer}
          </StyledButtonContinuer>
        </Box>
      </Box>
    </Modal>
  )
};


const DialogueAttente = ({ openWaitingModal, setOpenWaitingModal}) => {
  const { t } = useTranslations();
  return (
    <Modal
      open={openWaitingModal}
      onClose={() => setOpenWaitingModal(false)}
      aria-labelledby="waiting-modal-title"
      aria-describedby="waiting-modal-description"
    >
      <Box sx={style}>
        <IconButton
          onClick={() => setOpenWaitingModal(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon/>
        </IconButton>
        <StyledDivTitleCard
          id="waiting-modal-title"
          variant="h6"
          component="h2"
          style={{ textAlign: "center" }}
        >
          {t.envoiEnCours}...
        </StyledDivTitleCard>
        <Typography
          id="waiting-modal-description"
          sx={{ mt: 2 }}
          style={{ textAlign: "center" }}
        >
          <CircularProgress/>
        </Typography>
      </Box>
    </Modal>
  );
};

const DialogueResultat = ({ resultTitle, resultMessage, resultSucces, openResultModal, setOpenResultModal }) => {
  return (
    <Modal
      open={openResultModal}
      onClose={() => setOpenResultModal(false)}
      aria-labelledby="result-modal-title"
      aria-describedby="result-modal-description"
    >
      <Box sx={style}>
        <IconButton
          onClick={() => setOpenResultModal(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon/>
        </IconButton>

        <StyledDivTitleCard
          id="waiting-modal-title"
          variant="h6"
          component="h2"
        >
          {resultTitle}
        </StyledDivTitleCard>
        <StyledContentCard id="result-modal-description">
          <div style={{ textAlign: "center", marginBottom: "1.25rem" }}>
            { resultSucces ? (
              <StyledCheckRoundedIcon
                sx={{
                  backgroundColor: EC_COLORS.DEEP_GREEN,
                  borderRadius: "50%",
                  width: "5.375rem",
                  height: "5.375rem",
                }}
              />
            ) : (
              <StyledClearSharpIconError
                sx={{
                  backgroundColor: EC_COLORS.DEEP_GREEN,
                  borderRadius: "50%",
                  width: "5.375rem",
                  height: "5.375rem",
                }}
              />
            )}
          </div>
          {resultMessage}
        </StyledContentCard>
      </Box>
    </Modal>
  )
};

const CommercialCard = ({ styles, isMobile }) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const { detailsClient, currentContact} = useSelector((state) => state.espaceClient);
  const [imageSrc, setImageSrc] = useState("");
  const [actionToConfirm, setActionToConfirm] = useState(null);
  const [messageToConfirm, setMessageToConfirm] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openWaitingModal, setOpenWaitingModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [resultSucces, setResultSucces] = useState(false);
  const [resultTitle, setResultTitle] = useState("");


  useEffect(() => {
    if (
      detailsClient &&
      detailsClient.commercialPrenom &&
      detailsClient.commercialNom
    ) {
      const prenom = detailsClient.commercialPrenom;
      const nom = detailsClient.commercialNom;

      // On prend les initiales du prénom et du nom pour générer le nom de fichier
      const initials = prenom.charAt(0) + nom.charAt(0);

      // Générer le nom de l'image en fonction du format (Desktop ou Mobile)
      const format = window.innerWidth > 768 ? "Desktop" : "Mobile";
      const imageName = `${initials}_${format}.png`;

      // Importer toutes les images
      const images = importAll();

      // Vérifier si l'image spécifique existe, sinon utiliser l'image par défaut
      if (images[ imageName ]) {
        setImageSrc(images[ imageName ]);
      } else {
        const defaultImage = `DEFAULT_${format}.png`;
        setImageSrc(images[ defaultImage ]);
      }
    }
  }, [detailsClient, window.innerWidth]); // FIXME pourquoi mettre innerWidth ?

  const handleProgrammerVisite = () => {
    setOpenWaitingModal(true);
    dispatch(sendMailProgrammerVisite({ contactById: currentContact.item}))
      .unwrap()
      .then((_) => {
        setOpenWaitingModal(false);
        setResultMessage(t.messageResultatEnvoiMailVisite);
        setResultSucces(true);
        setResultTitle(t.titleMessageResultat);
        setOpenResultModal(true);
      })
      .catch(() => {
        setOpenWaitingModal(false);
        setResultMessage(t.erreurEnvoiMail);
        setResultSucces(false);
        setOpenResultModal(true);
      });
  };

  const handleEtreRappele = () => {
    setOpenWaitingModal(true);
    dispatch(sendMailEtreRappeler({ contactById: currentContact.item}))
      .unwrap()
      .then(() => {
        setOpenWaitingModal(false);
        setResultMessage(t.messageResultatEtreRappele);
        setResultSucces(true);
        setResultTitle(t.titleMessageResultatEtreRappele);
        setOpenResultModal(true);
      })
      .catch(() => {
        setOpenWaitingModal(false);
        setResultMessage(t.erreurEnvoiMail);
        setResultSucces(false);
        setOpenResultModal(true);
      });
  };

  const handleConfirmationModal = (action) => {
    if (currentContact.item === null) {
      setOpenWaitingModal(false);
      setResultMessage(t.messageAucunContact);
      setResultSucces(false);
      setResultTitle(t.titleMessageResultat);
      setOpenResultModal(true);
      return;
    }
    setActionToConfirm(() => {
      if (action === "visite") {
        setMessageToConfirm(t.messageToConfirmVisite);
        return handleProgrammerVisite;
      }
      if (action === "rappel") {
        setMessageToConfirm(t.messageToConfirmRappel);
        return handleEtreRappele;
      }
      return null;
    });
    setOpenConfirmationModal(true);
  };

  const styleSpecial = isMobile ? {} : {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const styleImage = isMobile ? styles.mobileImage : styles.desktopImage ;

  return (
    <Card
      id="commercial-card"
      className={`${styles.cardContainer}`}
      style={{
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        order: 5,
        borderRadius: "8px",
      }}
    >
      <CardContent className={styles.textContainer} style={{ padding: "24px" }} >
        <StyledDivTitleCard style={styleSpecial} >
          {t.genTitleCardCialProximp}
        </StyledDivTitleCard>
        <StyledDivTitleSubCard>
          {`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
        </StyledDivTitleSubCard>
        <StyledContentCard>
          {t.telCommercial} : {detailsClient.commercialTelephone}
        </StyledContentCard>
        <StyledContentCard style={styleSpecial} >
          {t.emailCommercial} : {detailsClient.commercialEmail}
        </StyledContentCard>
        <StyledDivBoutonCialProximite
          style={{
            display: "flex", // Flexbox pour gérer la mise en page
            flexDirection: "column", // Les boutons sont empilés verticalement
            width: "100%", // Le conteneur prend toute la largeur
            gap: "10px", // Un espace entre les boutons (facultatif)
          }}
        >
          <BoutonCialProx texte={t.programmerVisite} action={() => handleConfirmationModal("visite")} isFirstButton={true} />
          <BoutonCialProx texte={t.etreRappele} action={() => handleConfirmationModal("rappel")} isFirstButton={false} />
        </StyledDivBoutonCialProximite>
      </CardContent>

      <CardMedia
        component="img"
        alt={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
        src={imageSrc}
        title={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
        className={styleImage}
      />

      <DialogueConfirmation
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        messageToConfirm={messageToConfirm}
        actionToConfirm={actionToConfirm}
      />

      <DialogueAttente
        openWaitingModal={openWaitingModal}
        setOpenWaitingModal={setOpenWaitingModal}
      />

      <DialogueResultat
        resultTitle={resultTitle}
        resultMessage={resultMessage}
        resultSucces={resultSucces}
        openResultModal={openResultModal}
        setOpenResultModal={setOpenResultModal}
      />

    </Card>
  )
}

export default CommercialCard;

import { useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";

import { BoutonAccueilEC } from "../../components/espaceClient/BoutonAccueilEC";
import { GuestRoutes } from "../../../../constantes/routes/GuestRoutes";
import { StringToRouteInNavigate } from "../../../../helper/StringParser";
import { EC_COLORS } from "../../espaceClient/common/EcColor";
import { importAll } from "../../../../helper/ImportAll";

import { StyledContentActus, StyledDivTitleCardReverse } from "../../styledComponents/StyledTextEspaceClient";

const ReactualisationCard = ({ title, content, buttonLabel, refOffre = null, refCommande = null} ) => {

  const handleReactualiser = () => {

    const params = new URLSearchParams();
    if (refOffre !== null)
      params.append('offre', refOffre);
    if (refCommande !== null)
      params.append('commande', refCommande);

    let url = StringToRouteInNavigate(GuestRoutes.RECHERCHER);
    // Naviguer vers une nouvelle URL avec les paramètres
    if (refOffre !== null || refCommande !== null)
      url = url + '?' + params.toString();


    if (window.AUTOTEST) {
      // pour les auto-tests, ne pas ouvrir de nouvel onglet
      window.location.assign(url);
    }
    else {
      // ouvrir un nouvel onglet
      window.open(url, "_blank");
    }
  };

  return (
    <Card
      id="configurateur-card"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        backgroundColor: EC_COLORS.DEEP_BLUE,
        flexWrap: "wrap",
        marginTop: "2rem",
      }}
    >
      <img
        src={importAll()["RCModeles-Configurateur.png"]}
        alt="RC Modèles Configurateur"
        style={{
          height: "100%",
          width: "100%",
          maxWidth: "225px",
          objectFit: "fill",
        }}
      />
      <CardContent style={{ flex: "1", minWidth: "300px" }}>
        <StyledDivTitleCardReverse>
          {title}
        </StyledDivTitleCardReverse>

        <StyledContentActus style={{ fontSize: "calc(8px + 1vw)", color: "#fff" }} >
          {content}
        </StyledContentActus>

        <BoutonAccueilEC texte={buttonLabel} action={handleReactualiser} backgroundColor={'dark'} />
      </CardContent>
    </Card>
  );
}

export { ReactualisationCard };

import { useEffect, useState } from "react";

import BoutonRetourListeArticle from "./BoutonRetourListeArticle";
import {
  ARTICLE,
  DEMANDE_DE_PRIX,
  OFFRE_DE_PRIX,
} from "../../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { useLocation, useSearchParams } from "react-router-dom";
import { dataCleaner } from "../../../../../helper/DataCleaner";
import { EXTREMITE_IDENTIQUE } from "../../../../../constantes/symbols/SymbolsS01";
import { genererFormulaire } from "../../../components/generateFormulary/CasesGeneration";
import { formulaireExtremiteDroite, formulaireExtremiteGauche } from "../../../ChampsFormulaires/S01/recapitulatif/ChampsRecapitulatif";
import { genererChampRecapitulatifHorsConfigurateur } from "../../../components/generateFormulary/methods/ResumeGenerate";
import { DessinTechnique } from "../../../components/DessinTechnique";
import { LoadingErrorMessage } from "../../../components/LoadingMessageComponents.jsx";
import { importAllSpec } from "../../../../../helper/ImportAll";
import { StyledSpanSubtitlesRecap } from "../../../styledComponents/StyledSpan";
import { arrayProductFamilly } from "../../../../../constantes/symbols/SymbolsServicesCommon";
import { afficherOnglets, afficherOngletsMobile } from "../../../components/espaceClient/OngletsArticle";

import {
  StyledDivDessinRecap,
  StyledDivDessinRecapIframe,
  StyledDivDessinRecapIframeScaleS01,
  StyledDivFiligranneRecapEC,
  StyledDivRecapExtremiteTitre,
  StyledDivRecapGeneEC,
  StyledDivRecapInt,
  StyledDivRecapitulatifEC,
  StyledDivSectionRecapEC,
  StyledDivSectionsRowRecap,
  StyledDivSubtitlesRecap,
} from "../../../styledComponents/StyledDiv";
import { StyledPaperBodyEC } from "../../../styledComponents/StyledPaper";
import { StyledH1VuesEC } from "../../../styledComponents/StyledTextEspaceClient";
import { StyledHrEspaceClient } from "../../../styledComponents/StyledDivEspaceClient";

export const ArticleS01 = ({
  state: {
    booleanIdentite,
    formik,
    styles,
    styles: { sousTitreRecap, sousTitreExtremitesRecap },
    t,
    isArticleByIdSet,
  },
  action: { dispatch, setArticleId, handleRetour, setIsArticleByIdSet },
  isFrom,
  nom,
}) => {
  // FIXME longueur codée en dur
  const [isOngletDesktopFormat, setIsOngletDesktopFormat] = useState(window.innerWidth >= 768);

  const [params] = useSearchParams();
  const from = [DEMANDE_DE_PRIX, OFFRE_DE_PRIX, ARTICLE].includes(isFrom);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const [iframe, setIframe] = useState();
  const [formulaire, setFormulaire] = useState(
    dataCleaner(formik.values ? formik.values : formik)
  );

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setIsOngletDesktopFormat(window.innerWidth >= 768);
  }, [window.innerWidth]);

  useEffect(() => {
    setIframe(parseInt(query.get("iframe")) === 1);
  }, []);

  useEffect(() => {
    setArticleId(params.get("art"));
  }, [booleanIdentite, setArticleId]);


  const afficherExtremite = (t) => {
    switch (parseInt(formulaire[EXTREMITE_IDENTIQUE.description])) {
      case 0:
        return (
          <>
            <StyledDivRecapExtremiteTitre className={sousTitreExtremitesRecap}>
              {t.titreExtremiteGauche}
            </StyledDivRecapExtremiteTitre>
            {genererFormulaire(formulaireExtremiteGauche(t), formulaire, t)}
            {genererChampRecapitulatifHorsConfigurateur(formulaire, 4)}
            <div></div>
            <StyledDivRecapExtremiteTitre className={sousTitreExtremitesRecap}>
              {t.titreExtremiteDroite}
            </StyledDivRecapExtremiteTitre>
            {genererFormulaire(formulaireExtremiteDroite(t), formulaire, t)}
            {genererChampRecapitulatifHorsConfigurateur(formulaire, 5)}
          </>
        );
      default:
        return (
          <>
            <StyledDivRecapExtremiteTitre className={sousTitreExtremitesRecap}>
              {t.titreExtremites}
            </StyledDivRecapExtremiteTitre>
            {genererFormulaire(formulaireExtremiteDroite(t), formulaire, t)}
            {genererChampRecapitulatifHorsConfigurateur(formulaire, 5)}
          </>
        );
    }
  };

  const afficherDessinTechnique = () => {
    return <DessinTechnique formik={formulaire} styles={styles} t={t} />;
  };

  if (!isArticleByIdSet) {
    return <LoadingErrorMessage t={t} />;
  }

  const displayDessinTechnique = () => {
    return iframe ? (
      <StyledDivDessinRecapIframe name={nom}>
        <StyledDivDessinRecapIframeScaleS01>
          {afficherDessinTechnique()}
        </StyledDivDessinRecapIframeScaleS01>
      </StyledDivDessinRecapIframe>
    ) : (
      <StyledDivDessinRecap>{afficherDessinTechnique()}</StyledDivDessinRecap>
    );
  };


  const isFamilleCodeInArray = (familleCode) => {
    return arrayProductFamilly(t).some((item) => item.code === familleCode);
  };

  const afficherRecap = () => {
    return (
      <>
        {iframe ? displayDessinTechnique() : null}
        <StyledPaperBodyEC elevation={0}>
          <StyledDivRecapitulatifEC>
            <StyledDivRecapInt>
              <StyledDivFiligranneRecapEC
                style={{
                  backgroundImage:
                    "url(" +
                    importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                    ")",
                  zIndex: "0",
                }}
                className={styles.logoFiligraneEC}
              />

              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>

                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.reference}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC>
                    <div>
                      {t.codeRCMP} :{" "}
                      <span>
                        {formulaire?.["infosArticleEC"]?.[0]["articleCode"]}
                      </span>
                    </div>
                    {formulaire["infosArticleEC"]?.[0]?.[
                      "articleReferenceClient"
                    ] === "" ? null : (
                      <div>
                        {t.titleCodeClient} :{" "}
                        <span>
                          {
                            formulaire?.["infosArticleEC"]?.[0][
                              "articleReferenceClient"
                            ]
                          }
                        </span>
                      </div>
                    )}

                    <div>
                      {t.titleNomArticle} :{" "}
                      <span>
                        {
                          formulaire["infosArticleEC"]?.[0]?.[
                            "articleDesignation"
                          ]
                        }
                      </span>
                    </div>
                    <div>
                      {t.titleFamille} :{" "}
                      <span>
                        {arrayProductFamilly(t).find(
                          (e) =>
                            e.code ===
                            formulaire?.["infosArticleEC"]?.[0][
                              "familleCode"
                            ].substring(0, 3)
                        )?.name ??
                          formulaire?.["infosArticleEC"]?.[0][
                            "familleCode"
                          ].substring(0, 3)}
                      </span>
                    </div>
                  </StyledDivRecapGeneEC>
                </StyledDivSectionRecapEC>
                <StyledDivSectionRecapEC>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.descrArticle}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGeneEC
                    dangerouslySetInnerHTML={{
                      __html:
                        formulaire?.["infosArticleEC"]?.[0][
                          "articleDescription"
                          ],
                    }}
                  />
                </StyledDivSectionRecapEC>

              </StyledDivSectionsRowRecap>
            </StyledDivRecapInt>
          </StyledDivRecapitulatifEC>
        </StyledPaperBodyEC>
      </>
    );
  };

  return (
    <div name={nom} style={{ padding: "1rem" }}>
      <BoutonRetourListeArticle/>
      {formulaire || formulaire.infosArticleEC === undefined ? (
        <div>
          <div>
            <StyledH1VuesEC>
              {t.detailsArticle} :{" "}
              {arrayProductFamilly(t).find(
                (e) =>
                  e.code ===
                  formulaire?.["infosArticleEC"]?.[0]["familleCode"].substring(
                    0,
                    3
                  )
              )?.name ?? formulaire["designation"]}
            </StyledH1VuesEC>
            <StyledHrEspaceClient></StyledHrEspaceClient>
          </div>

          {isOngletDesktopFormat
            ? afficherOnglets(
                t,
                formulaire,
                selectedTab,
                setSelectedTab,
                afficherRecap()
              )
            : afficherOngletsMobile(
                t,
                formulaire,
                selectedTab,
                setSelectedTab,
                afficherRecap()
              )}
        </div>
      ) : (
        <LoadingErrorMessage t={t}/>
      )}
    </div>
  );
};

export const serviceCleaner = (service) => {
  if(service) {
    service = JSON.parse(atob(service));
    return Object.fromEntries(
      Object.entries(service).map((element) => {
        if (element[0] === "horsConfigurateur" || element[0] === "affichage") {

          return element;
        }
        if (typeof element[1] === "object" && !Array.isArray(element[1])) {
          return [
            element[0],
            Object.entries(element[1]).find(
              (e) => e[1].length !== 0 && e[1] !== ""
            )[1],
          ];
        }
        return element;
      })
    );
  }
};

export const langForService = (lang, thunkAPI) => {
  const resolvedLang = lang?.target?.value || thunkAPI.getState().i18n.lang;
  if (resolvedLang === "GB") {
    return "EN";
  }
  return resolvedLang;
};

export const langJsonFormatter = (lang, thunkAPI, reponse) => {
  const resolvedLang = langForService(lang, thunkAPI);
  const data = reponse.data
    .sort((curr, next) => {
      return curr[resolvedLang].localeCompare(next[resolvedLang]);
    })
    .map((element) => {
      return {
        code: element.IDSylob,
        reference: element.cle,
        name: element[resolvedLang]?element[resolvedLang]:element['FR'],
      };
    });
  return data;
};

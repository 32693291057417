import { useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";

import { BoutonAccueilEC } from "../../components/espaceClient/BoutonAccueilEC";
import { LocalStorage } from "../../../../constantes/globalName/LocalStorage";
import { useChoixContact } from '../../../../redux/vue/components/hook/useChoixContact.js';
import { GuestRoutes } from "../../../../constantes/routes/GuestRoutes";
import { StringToRouteInNavigate } from "../../../../helper/StringParser";
import { EC_COLORS } from "../../espaceClient/common/EcColor";
import { importAll } from "../../../../helper/ImportAll";
import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  TELEPHONE,
  FONCTION,
  NOM,
  PAYS,
  PRENOM,
  SECTEUR,
  SOCIETE,
} from "../../../../constantes/symbols/SymbolsCommon";

import { StyledContentActus, StyledDivTitleCardReverse } from "../../styledComponents/StyledTextEspaceClient";

const ReactualisationCard = ({ title, content, buttonLabel} ) => {
  const { contact: choixContact } = useChoixContact();
  const { paysList, fonctionsList, secteursList, currentContact } = useSelector((state) => state.espaceClient);
  const detailsContact = currentContact.item;

  const handleActualiser = () => {

    /* FIXME code à mutualiser avec commande
    if(offreById && offreById[0]){
      localStorage.setItem(LocalStorage.OFFRE_CLIENT, offreById[0]['offreCode']);
    }
    */

    if (detailsContact[0] && choixContact === null) {
      // Fonction pour mapper sur fonctionList et rechercher la valeur correspondante
      const fonction = fonctionsList.find( (item) => item.name === detailsContact[0].fonction );
      const secteur = secteursList.find( (item) => item.reference === detailsContact["infosClient"].codeSecteurActivite );
      const pays = paysList.find( (item) => item.reference === detailsContact["infosClient"].codePays );

      localStorage.setItem(
        LocalStorage.INFORMATIONS_CLIENT,
        btoa(
          JSON.stringify({
            [SOCIETE.description]: detailsContact["infosClient"].raisonSociale,
            [PAYS.description]: pays,
            [CODE_POSTAL.description]: detailsContact["infosClient"].codePostal,
            [SECTEUR.description]: secteur,
            [NOM.description]: detailsContact[0].nom,
            [PRENOM.description]: detailsContact[0].prenom,
            [FONCTION.description]: fonction,
            [ADRESSE_MAIL.description]: detailsContact[0].email,
            [TELEPHONE.description]: detailsContact[0].telephone,
          })
        )
      );
    }
    const url = StringToRouteInNavigate(GuestRoutes.RECHERCHER);
    window.open(url, "_blank");
  };

  return (
    <Card
      id="configurateur-card"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        backgroundColor: EC_COLORS.DEEP_BLUE,
        flexWrap: "wrap",
        marginTop: "2rem",
      }}
    >
      <img
        src={importAll()["RCModeles-Configurateur.png"]}
        alt="RC Modèles Configurateur"
        style={{
          height: "100%",
          width: "100%",
          maxWidth: "225px",
          objectFit: "fill",
        }}
      />
      <CardContent style={{ flex: "1", minWidth: "300px" }}>
        <StyledDivTitleCardReverse>
          {title}
        </StyledDivTitleCardReverse>

        <StyledContentActus style={{ fontSize: "calc(8px + 1vw)", color: "#fff" }} >
          {content}
        </StyledContentActus>

        <BoutonAccueilEC texte={buttonLabel} action={handleActualiser} backgroundColor={'dark'} />
      </CardContent>
    </Card>
  );
}

export { ReactualisationCard };

import { Paper } from "@mui/material";

const LoadingErrorMessage = ({t, errorMessage=null}) => {
  const message = errorMessage ? `${t.status_error} (${errorMessage}) `: t.status_error;
  return <Paper>{message}</Paper>;
};

const LoadingMessage = ({t}) => {
  return <Paper>{t.status_pending}</Paper>;
};

export { LoadingErrorMessage, LoadingMessage };

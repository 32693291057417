import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge } from "@mui/material";

import { ListeArticleByOffre } from "./ListeArticleByOffre";
import { useTranslations } from "../../components/hook/useTranslations";
import { LoadingMessage, LoadingErrorMessage } from "../../components/LoadingMessageComponents.jsx";
import { useEffect, useState } from "react";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { ApiStatus } from '../../../../constantes/ApiStatus';

import { StyledSpanTitleVueDetailEC } from "../../styledComponents/StyledSpan";
import { StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { StyledPaperBodyEC } from "../../styledComponents/StyledPaper";
import {
  StyledContentVueDetailsEC,
  StyledH1VuesEC,
  StyledTitleVueDetailsEC,
} from "../../styledComponents/StyledTextEspaceClient";
import {
  StyledDivSectionsRowRecap,
  StyledDivSectionVueDetailOrangeEC,
  StyledDivVueDetailsEC,
  StyledDivVueDetailsGeneOrangeEC,
} from "../../styledComponents/StyledDiv";

const DetailsOffre = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslations();

  const { familleArticleList, currentOffre } = useSelector((state) => state.espaceClient);

  const [tableauOffres, setTableauOffres] = useState([]);
  const offreById = currentOffre.item;

  const navigateToCommande = (commandeId) => {
    if (commandeId) {
      navigate(ClientRoutes.VUE_COMMANDE + commandeId);
    }
  };

  const getBadgeColor = (reponse) => {
    switch (reponse) {
      case "Attente":
        return { backgroundColor: "#7f7f7f" }; // Orange pour "Attente"
      case "Commandée":
        return { backgroundColor: "#4ea72e" }; // Vert pour "Commandée"
      case "Refusée":
        return { backgroundColor: "#e97132" }; // Rouge pour "Refusée"
      default:
        return { backgroundColor: "#7f7f7f" }; // Gris par défaut
    }
  };

  const getBadgeText = (reponse, t) => {
    switch (reponse) {
      case "Attente":
        return t.enAttente;
      case "Commandée":
        return t.commandee;
      case "Refusée":
        return t.refusee;
      default:
        return reponse;
    }
  };

  const DisplayBadge = ({ offreById, t }) => (
    <Badge
      style={{
        wordBreak: "break-all",
        color: "white",
        padding: "0.2rem",
        borderRadius: "0.2rem",
        ...getBadgeColor(offreById["reponse"]),
      }}
    >
      {getBadgeText(offreById["reponse"], t)}
    </Badge>
  );

  useEffect(() => {
    if (offreById) {
      const offresArray = Object.values(offreById)
        .filter((value) => typeof value === "object" && value !== null)
        .sort((a, b) => a.ligne - b.ligne);
      setTableauOffres(offresArray);
    }
  }, [offreById, location]);

  const stateUsedForRender = {
    booleanIdentite: false,
    offreById,
    //isOffreByIdSet,
    tableauOffres,
    familleArticleList,
  };

  const actionUsedForRender = {
    setTableauOffres,
  };

  if (currentOffre.apiStatus === ApiStatus.IDLE)
    return <LoadingMessage t={t} />;

  if (currentOffre.apiStatus === ApiStatus.ERROR)
    // TODO améliorer le rendu
    return <LoadingErrorMessage t={t} errorMessage={currentOffre.apiError}/>;

  return (
    <div style={{ padding: "1rem" }}>
      {offreById && offreById[0] !== undefined ? (
        <>
          <StyledH1VuesEC>
            {t.detailsOffre} : {offreById[0]["offreCode"]}
          </StyledH1VuesEC>
          <StyledHrEspaceClient></StyledHrEspaceClient>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC>
              <StyledSpanTitleVueDetailEC>
                {t.informationsGenerales}
              </StyledSpanTitleVueDetailEC>
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneOrangeEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.reference} : </span>

                      <StyledContentVueDetailsEC>
                        {offreById["reference"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.dateCreation} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {offreById["dateCreation"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.contact} : </span>

                      <StyledContentVueDetailsEC>
                        {offreById["contact"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneOrangeEC>
                </StyledDivSectionVueDetailOrangeEC>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneOrangeEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.commande} : </span>

                      {offreById["commandeCode"] !== "" ? (
                        <StyledContentVueDetailsEC
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            navigateToCommande(offreById["commandeId"]);
                          }}
                        >
                          {offreById["commandeCode"]}
                        </StyledContentVueDetailsEC>
                      ) : (
                        <StyledContentVueDetailsEC>
                          {t.pasDeCommande}
                        </StyledContentVueDetailsEC>
                      )}
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>
                        {t.statutPublication} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {offreById["publ"].includes("Vrai") ? (
                          <Badge
                            style={{
                              wordBreak: "break-all",
                              backgroundColor: "#4ea72e",
                              color: "white",
                              padding: "0.2rem",
                              borderRadius: "0.2rem",
                            }}
                          >
                            {t.envoyee}
                          </Badge>
                        ) : (
                          <Badge
                            style={{
                              wordBreak: "break-all",
                              backgroundColor: "#7f7f7f",
                              color: "white",
                              padding: "0.2rem",
                              borderRadius: "0.2rem",
                            }}
                          >
                            {t.enCours}
                          </Badge>
                        )}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC>
                      <span style={{ width: "200px" }}>{t.reponse} : </span>

                      <StyledContentVueDetailsEC>
                        <DisplayBadge offreById={offreById} t={t} />
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneOrangeEC>
                </StyledDivSectionVueDetailOrangeEC>
              </StyledDivSectionsRowRecap>
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC>
              <StyledSpanTitleVueDetailEC>
                {t.titleTableauDetailsOffre}
              </StyledSpanTitleVueDetailEC>

              <ListeArticleByOffre
                state={stateUsedForRender}
                actions={actionUsedForRender}
                style={{ marginTop: "1rem" }}
              />
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
        </>
      ) : (
        <LoadingErrorMessage t={t} />
      )}
    </div>
  );
};

export { DetailsOffre };

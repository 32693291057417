//Librairies de fonctionnement
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useOutletContext } from "react-router-dom";
/*-----------!!! ATTENTION Il existe @material-ui et @mui. !!!-----------------
 * @material-ui est une ancienne version (<V5) de @mui et peut renvoyer des erreurs
 * de type findDomnodes ces erreurs n'affectent pas nécessairement le comportement
 * du code mais mettent à mal le strict-mode de react (vérification continue des
 * références objet pour éviter les boucles infinies). => On peut donc considérer
 * @material-ui comme deprecié (j'ai passé trop de temps à déduire ca 😢)
 */
import { Grid } from "@mui/material";

import { genererFormulaire } from "./components/generateFormulary/CasesGeneration";
import { formulaireContact } from "./ChampsFormulaires/ContactChamps";
import { initialValuesContact } from "./ChampsFormulaires/Initialisation/ContactInitialValues";
import { ValidContact } from "./ChampsFormulaires/validationsFormulaires/ValidContact";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "./components/hook/useTranslations";
import { getAllSecteurs } from "../slice/SecteurReducer";
import { getAllFonctions } from "../slice/FonctionReducer";
import { getAllPays } from "../slice/PaysReducer";
import {
  StyledDivButton,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
  StyledDivH1ContactRecherche,
  StyledDivSticky,
} from "./styledComponents/StyledDiv";
import { importAllSpec } from "../../helper/ImportAll";
import { LoadingErrorMessage } from "./components/LoadingMessageComponents.jsx";
import {
  resetIsFormularySent,
  sendFormulaireContact,
} from "../slice/FormulaireReducer";
import { WaitingDbInsert } from "./components/WaitingDbInsert";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "./styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "./styledComponents/StyledIcon";
import { StyledPContact } from "./styledComponents/StyledP";
import { Confirmation } from "./formulaires/Confirmation";
import { ErrorDbInsert } from "./formulaires/ErrorDbInsert";
import { CONTACT } from "../../constantes/symbols/SymbolsResumeDisplayedFrom";

export const Contact = () => {
  const [styles, t, booleanIdentite] = useOutletContext();
  const [step, setStep] = useState(1);
  const [formulaire, setFormulaire] = useState("");
  const [upToDateSecteursList, setUpToDateSecteursList] = useState([]);
  const [upToDateFonctionsList, setUpToDateFonctionsList] = useState([]);
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [isAllowedToQuit, setIsAllowedToQuit] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { lang } = useTranslations();
  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);
  const { isFormularySent } = useSelector((state) => state.formulaire);

  const cssButtonPrec = styles.cssButtonPrec;
  const cssButton = styles.cssButton;
  const cssPContact = styles.cssPContact;
  const sectionFormSansBg = styles.sectionFormSansBg;

  useEffect(() => {
    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());
  }, [dispatch]);

  useEffect(() => {
    if (!secteursList) {
      return;
    }
    setUpToDateSecteursList(secteursList);
  }, [secteursList]);

  useEffect(() => {
    if (!fonctionsList) {
      return;
    }
    setUpToDateFonctionsList(fonctionsList);
  }, [fonctionsList]);

  useEffect(() => {
    if (!paysList) {
      return;
    }
    setUpToDatePaysList(paysList);
  }, [paysList]);

  const handleSubmit = (form) => {
    form.langue = lang;
    form.marque = booleanIdentite ? "MP" : "RC";
    form.timestamp = Date.now();
    dispatch(sendFormulaireContact(form));
    setStep(step + 1);
  };

  const handleRetour = () => {
    navigate(GuestRoutes.ACCUEIL);
  };

  const formik = useFormik({
    initialValues: { ...initialValuesContact(formulaire) },
    validationSchema: ValidContact(
      upToDateSecteursList,
      upToDateFonctionsList,
      upToDatePaysList,
      t
    ),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const resetFormularySentState = () => {
    dispatch(resetIsFormularySent());
  };

  const resetFormulary = () => {
    setFormulaire({});
  };

  const state = {
    styles,
    t,
    booleanIdentite,
    formik,
  };

  const action = {
    setEtape: setStep,
    resetFormularySentState,
    resetFormulary,
    setIsAllowedToQuit,
  };

  if (!upToDateFonctionsList || !upToDateSecteursList || !upToDatePaysList) {
    return <LoadingErrorMessage t={t} />;
  } else {
    if (isFormularySent.status !== 200) {
      return (
        <ErrorDbInsert
          etape={step}
          setEtape={setStep}
          t={t}
          styles={styles}
          resetFormularySentState={resetFormularySentState}
          resetFormulary={resetFormulary}
        />
      );
    }
    if (isFormularySent.isBackendOk) {
      return <Confirmation state={state} action={action} from={CONTACT} />;
    }
    if (step === 1) {
      return (
        <StyledDivFormAndImageContainer>
          <StyledDivFormContainer>
            <StyledDivH1ContactRecherche>
              <h1>{t.genAccueilH2DeposezUneDemande}</h1>
            </StyledDivH1ContactRecherche>
            <StyledPContact className={cssPContact}>
              {t.descrPageContact}
            </StyledPContact>
            <form onSubmit={formik.handleSubmit}>
              <Grid container direction="column">
                <div className={sectionFormSansBg}>
                  {
                    /* Generating the form with the fields of the form. */
                    genererFormulaire(
                      formulaireContact(
                        upToDatePaysList,
                        upToDateSecteursList,
                        upToDateFonctionsList,
                        styles,
                        t
                      ),
                      formik
                    )
                  }
                </div>
                <Grid item>
                  <StyledDivButton>
                    <StyledButtonPrec
                      className={cssButtonPrec}
                      variant="outlined"
                      onClick={handleRetour}
                      startIcon={<StyledArrowBackOutlinedIcon />}
                    />
                    <StyledButtonContinuer
                      className={cssButton}
                      variant="contained"
                      type="submit"
                    >
                      {t.bouttonEnvoyer}
                    </StyledButtonContinuer>
                  </StyledDivButton>
                </Grid>
              </Grid>
            </form>
          </StyledDivFormContainer>
          <StyledDivSticky>
            <img
              className="alone"
              src={importAllSpec(booleanIdentite)["LandingPageImage.jpg"]}
              alt="TODO: Define alt"
            />
          </StyledDivSticky>
        </StyledDivFormAndImageContainer>
      );
    } else {
      return <WaitingDbInsert state={state} from={CONTACT} />;
    }
  }
};

import { FAMILLE_PROTECTION_INDUSTRIELLE } from "../../../../../constantes/symbols/SymbolsCommon";
import { arrayProductFamilly } from "../../../../../constantes/symbols/SymbolsServicesCommon";
import { ApiStatus } from '../../../../../constantes/ApiStatus';
import { LoadingMessage, LoadingErrorMessage } from "../../../components/LoadingMessageComponents.jsx";
import BoutonRetourListeArticle from './BoutonRetourListeArticle.jsx';

import { ArticleS01 } from "./ArticleS01";
import { ArticleS04 } from "./S04/ArticleS04";
import { ArticleS05 } from "./S05/ArticleS05";
import { ArticleS06 } from "./S06/ArticleS06";
import { ArticleS08 } from "./S08/ArticleS08";

export const controllerDisplayResumeArticleEC = (state, action, isFrom) => {

  const currentArticle = state.currentArticle;
  if (currentArticle.apiStatus === ApiStatus.IDLE)
    return <LoadingMessage t={state.t} />;

  if (currentArticle.apiStatus === ApiStatus.ERROR)
    // TODO améliorer le rendu
    return <>
      <LoadingErrorMessage t={state.t} errorMessage={currentArticle.apiError}/>
      <BoutonRetourListeArticle/>
    </>;

  if (state.formik === null)
    return <></>;

  const productFamilyList = arrayProductFamilly().map(e => e.code);

  // FIXME : codage du case en dur : 0=S01, 1=S01_1, 2=S04, 3=S04-06, 4=S05, 5=S08
  switch (state.formik[FAMILLE_PROTECTION_INDUSTRIELLE.description]) {
    case productFamilyList[2]:
      return renderS04(state, action, isFrom);
    case productFamilyList[4]:
      return renderS05(state, action, isFrom);
    case productFamilyList[5]:
      return renderS08(state, action, isFrom);
    case productFamilyList[3]:
      return renderS06(state, action, isFrom);
    default:
      return renderS01(state, action, isFrom);
  }
};

const renderS01 = (state, action, isFrom) => {
  return <ArticleS01 state={state} action={action} isFrom={isFrom} />;
};

const renderS04 = (state, action, isFrom) => {
  return <ArticleS04 state={state} action={action} isFrom={isFrom} />;
};

const renderS05 = (state, action, isFrom) => {
  return <ArticleS05 state={state} action={action} isFrom={isFrom} />;
};

const renderS06 = (state, action, isFrom) => {
  return <ArticleS06 state={state} action={action} isFrom={isFrom} />;
};

const renderS08 = (state, action, isFrom) => {
  return <ArticleS08 state={state} action={action} isFrom={isFrom} />;
};


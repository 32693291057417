//Librairies de fonctionnement
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

//Reducers
import {
  connexion,
  rafraichissement,
} from "../slice/AuthentificationReducer";
import { clearMessage } from "../slice/MessageReducer";

//Constantes et elements redondants
import { CollaborateurRoutes } from "../../constantes/routes/CollaborateurRoutes";
import { StringToRouteInNavigate } from "../../helper/StringParser";

//Frontend elements
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Box from "@mui/material/Box";


import { ValidAuthentification } from "./ChampsFormulaires/validationsFormulaires/ValidAuthentification";

//CSS
import { useStyles } from "./css/GeneralBackoffice";
import {
  StyledDivConnexion,
  StyledDivConnexionParent,
  StyledDivButtonConnexion,
  StyledDivLogos,
} from "./styledComponents/StyledDivBackoffice";
import { StyledButtonConnexion } from "./styledComponents/StyledButtonBackoffice";
import { StyledH1Connexion } from "./styledComponents/StyledTextBackoffice";
import {
  StyledImgLogoRC,
  StyledImgLogoMoov,
} from "./styledComponents/StyledImgBackoffice";

import { importAll } from "../../helper/ImportAll";
import { useTranslations } from "./components/hook/useTranslations";
import { useConfiguration } from "./components/hook/useConfiguration";

import { GuestRoutes } from "../../constantes/routes/GuestRoutes";

export const AuthentificationForm = ({ history }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { parameters } = useConfiguration();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.authentification);
  const { message } = useSelector((state) => state.message);

  const css = useStyles();

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      return;
    }
    dispatch(clearMessage(null));
    dispatch(rafraichissement())
      .unwrap()
      .then(() => {
        navigate(StringToRouteInNavigate(CollaborateurRoutes.PROFIL));
      })
      .catch(() => {});
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isLoggedIn) {
      const previousPage = history.at(-2);
      if (
        previousPage !== undefined &&
        previousPage.pathname !== "/" + GuestRoutes.AUTHENTIFICATION
      ) {
        const previousUrl = previousPage.pathname + previousPage.search;
        navigate(previousUrl);
      } else {
        navigate(StringToRouteInNavigate(CollaborateurRoutes.PROFIL));
      }
    }
  }, [isLoggedIn]);

  const handleConnexion = (formData) => {
    setLoading(true);
    dispatch(connexion(formData))
      .unwrap()
      .then(() => {})
      .catch(() => {
        setLoading(false);
      });
  };


  const TogglePassword = () => {
    setShowPassword(!showPassword);
  };

  //Initialisation des champs formulaire
  const valeursInitiales = {
    email: "",
    motDePasse: "",
  };

  //Initialisation du formulaire
  const formik = useFormik({
    initialValues: { ...valeursInitiales },
    validationSchema: ValidAuthentification(t),
    onSubmit: (values) => {
      handleConnexion(values);
    },
  });

  return (
    <StyledDivConnexionParent
      style={{
        background:
          "linear-gradient(0deg, rgba(72 92 150 / 50%), rgba(72 92 150 / 50%)), url(" +
          parameters.HOME_BACKGROUND_IMAGE +
          ")",
      }}
    >
      <StyledDivConnexion>
        <StyledDivLogos>
          <StyledImgLogoMoov src={importAll()["LogoMoovCouleur.png"]} />
          <StyledImgLogoRC src={importAll()["LogoRCCouleur.png"]} />
        </StyledDivLogos>
        <StyledH1Connexion>Connexion</StyledH1Connexion>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            <Grid item>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  className={css.textfield}
                  id="email"
                  name="email"
                  label={t.authLblEmail}
                  value={formik.values.email}
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  error={Boolean(formik.errors.email)}
                  helperText={formik.errors.email}
                  InputProps={{
                    className: css.textfieldProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlinedIcon
                          className={css.iconConnexion}
                        />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ className: css.textfieldLabelProps }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  className={css.textfield}
                  id="motDePasse"
                  name="motDePasse"
                  label={t.authLblPassword}
                  value={formik.values.motDePasse}
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type={showPassword ? "text" : "password"}
                  error={Boolean(formik.errors.motDePasse)}
                  helperText={formik.errors.motDePasse}
                  InputProps={{
                    className: css.textfieldProps,
                    endAdornment: (
                      <IconButton onClick={TogglePassword}>
                        {showPassword ? (
                          <RemoveRedEyeOutlinedIcon sx={{ fontSize: 24 }} />
                        ) : (
                          <VisibilityOffOutlinedIcon sx={{ fontSize: 24 }} />
                        )}
                      </IconButton>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon className={css.iconConnexion} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ className: css.textfieldLabelProps }}
                />
              </Box>
            </Grid>
            {/* <Grid item>
                                <StyledFormControlLabelSouvenir control={<Checkbox size="small" />} label='Se souvenir de moi'/>
                            </Grid> */}
          </Grid>
          <StyledDivButtonConnexion>
            {/* <StyledButtonMdp variant="text" onClick={handleForgetPassword}>
                                Mot de passe oublié ?
                            </StyledButtonMdp> */}

            <StyledButtonConnexion type="submit" disabled={loading}>
              {t.authBtConnexion}
            </StyledButtonConnexion>
          </StyledDivButtonConnexion>
        </form>
        <div>{message}</div>
      </StyledDivConnexion>
    </StyledDivConnexionParent>
  );
};

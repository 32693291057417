import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  Badge,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { EC_COLORS, getBgColorFromStatus } from "../common/EcColor";

import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
  StyledDivSearchBar,
} from "../../styledComponents/StyledDivBackoffice";
import { StyledTextfieldSearchDdpRose } from "../../styledComponents/StyledTextfieldBackoffice";


export const CommandeItem = ({
  id,
  offrePrixCode,
  reference,
  dateCreation,
  ligne,
  contact,
  commandeCode,
  action,
  commandeId,
  livr,
  fact,
  totalHT,
  offrePrixId,
  state: { t },
  searchBarAction: {
    setSearchBarContentCodeOffre,
    setSearchBarContentReference,
    setSearchBarContentDateCreation,
    setSearchBarContentContact,
    setSearchBarContentCodeCommande,
    setSearchBarContentTotalHT,
    setSearchBarContentEtatLivr,
    setSearchBarContentEtatFact,
  },
  sortAction: {
    setSortedCodeOffre,
    setSortedReference,
    setSortedDateCreation,
    setSortedLigne,
    setSortedCodeCommande,
    setSortedContact,
    setSortedTotalHT,
    setSortedEtatLivr,
    setSortedEtatFact,
  },
}) => {
  const navigate = useNavigate();

  const columnSize = 1; // const [columnSize, setColumnSize] = useState(1);

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  const noClassNameMobileAndTablette =
    "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  const handleChange = (event, setterSearchBar) => {
    setterSearchBar(event.target.value);
  };

  const navigateToOffre = (offreId) => {
    if (offreId)
      navigate(ClientRoutes.VUE_OFFRE + offreId);
  };

  const navigateToCommande = (commandeId) => {
    if (commandeId)
      navigate(ClientRoutes.VUE_COMMANDE + commandeId);
  };

  const DisplayAdornment = ({ md, style }) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={md ? md : columnSize}
        xs={4}
        className={style + " itemDdp action"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        <IconButton style={{ padding: 0 }} onClick={() => navigateToCommande(commandeId)} >
          <div
            style={{
              padding: 6,
              borderRadius: 4,
              overflow: "hidden",
              border: "2px #1C243C solid",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              // display: "inline-flex",
            }}
          >
            <div
              style={{
                color: "#1C243C",
                fontSize: 18,
                fontFamily: "Dosis",
                fontWeight: "600",
              }}
            >
              {t.voirPlus}
            </div>
          </div>
        </IconButton>
      </Grid>
    );
  };

  const displaySearchBar = (setValue) => {
    if (setValue) {
      return (
        <div>
          <StyledDivSearchBar>
            <StyledTextfieldSearchDdpRose
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event, setValue)}
              InputProps={{
                endAdornment: (
                  <SearchIcon md={{ color: EC_COLORS.ORANGE, fontSize: 10 }} />
                ),
              }}
            />
          </StyledDivSearchBar>
        </div>
      );
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={`${style} columnHeaderEC ${
          searchBarStateSetter ? "" : "hasSearch"
        }`}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
          // marginLeft={name === "Action" ? "-0.75rem" : "0"}
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
            {displaySortArrows(sortStateSetter)}
          </Stack>
          <Stack>{displaySearchBar(searchBarStateSetter)}</Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style, texte) => {
    const displayName = texte ? name + " " + texte : name;
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: displayName }}
        />
      </Grid>
    );
  };

  const displayItemOffre = (offrePrixId, name, md, sm, style) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {offrePrixId ? (
          // Si offrePrixId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={ () => navigateToOffre(offrePrixId)}
          />
        ) : (
          // Si offrePrixId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  const displayCommandeCode = (commandeId, name, md, sm, style) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {commandeId ? (
          // Si commandeId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={ () => navigateToCommande(commandeId) }
          />
        ) : (
          // Si commandeId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  const displayEtatLivrEtFact = (name, md, sm, style, t) => {
    let reponse;
    switch (name) {
      case "Non commencée":
        reponse = t.nonCommencee;
        break;
      case "Terminé":
        reponse = t.terminee;
        break;
      case "Partielle":
        reponse = t.partielle;
        break;
      default:
        reponse = name;
    };

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Badge
          style={{
            backgroundColor: getBgColorFromStatus(name),
            color: "white",
            padding: "0.5rem",
            borderRadius: "0.5rem",
            wordBreak: "break-all",
            display: 'inline-block', // pour tronquer le texte trop long
            width: '80%',
            overflow: 'hidden', // Cache le débordement de texte
            whiteSpace: 'nowrap', // Empêche le texte de passer à la ligne
            textOverflow: 'ellipsis' // Ajoute des points de suspension
          }}
        >
          {reponse}
        </Badge>
      </Grid>
    );
  };

  const displayItemAction = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return <DisplayAdornment md={md} style={style} />;
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : { height: "100px" }}
    >
      {/* colonne code commande */}
      {id === 0
        ? displayItemHeader(
            commandeCode,
            setSearchBarContentCodeCommande,
            setSortedCodeCommande,
            1.5,
            1.5,
            4,
            noClassNameMobile
          )
        : displayCommandeCode(
            commandeId,
            commandeCode,
            1.5,
            1.5,
            noClassNameMobile
          )}

      {/* colonne date création */}
      {id === 0
        ? displayItemHeader(
            dateCreation,
            setSearchBarContentDateCreation,
            setSortedDateCreation,
            1.25,
            1.25,
            4,
            noClassNameMobile
          )
        : displayItem(dateCreation, 1.25, 1.25, noClassNameMobile)}

      {/* colonne référence */}
      {id === 0
        ? displayItemHeader(
            reference,
            setSearchBarContentReference,
            setSortedReference,
            1,
            1,
            8,
            alwaysDisplayed
          )
        : displayItem(reference, 1, 1, alwaysDisplayed)}

      {/* colonne code offre */}
      {id === 0
        ? displayItemHeader(
            offrePrixCode,
            setSearchBarContentCodeOffre,
            setSortedCodeOffre,
            1.25,
            1.25,
            4,
            noClassNameMobileAndTablette
          )
        : displayItemOffre(
            offrePrixId,
            offrePrixCode,
            1.25,
            1.25,
            noClassNameMobileAndTablette
          )}

      {/* colonne contact */}
      {id === 0
        ? displayItemHeader(
            contact,
            setSearchBarContentContact,
            setSortedContact,
            1.5,
            1.5,
            8,
            noClassNameMobileAndTablette
          )
        : displayItem(contact, 1.5, 1.5, noClassNameMobileAndTablette)}

      {/* colonne nombre de lignes */}
      {id === 0
        ? displayItemHeader(
            ligne,
            null,
            setSortedLigne,
            1,
            1,
            null,
            noClassNameMobile
          )
        : displayItem(ligne, 1, 1, noClassNameMobile)}

      {/* colonne Total HT */}
      {id === 0
        ? displayItemHeader(
            totalHT,
            setSearchBarContentTotalHT,
            setSortedTotalHT,
            1,
            1,
            8,
            noClassNameMobileAndTablette
          )
        : displayItem(totalHT, 1, 1, noClassNameMobileAndTablette, "€")}

      {/* colonne État livraison */}
      {id === 0
        ? displayItemHeader(
            livr,
            setSearchBarContentEtatLivr,
            setSortedEtatLivr,
            1.25,
            1.25,
            4,
            noClassNameMobileAndTablette
          )
        : displayEtatLivrEtFact(
            livr,
            1.25,
            1.25,
            noClassNameMobileAndTablette,
            t
          )}

      {/* colonne État facturation */}
      {id === 0
        ? displayItemHeader(
            fact,
            setSearchBarContentEtatFact,
            setSortedEtatFact,
            1.25,
            1.25,
            4,
            noClassNameMobileAndTablette
          )
        : displayEtatLivrEtFact(
            fact,
            1.25,
            1.25,
            noClassNameMobileAndTablette,
            t
          )}

      {/* colonne action */}
      {id === 0
        ? displayItemHeader(action, null, null, 1, 1, 4, "")
        : displayItemAction(action, null, null, 1, 1, 4, "")}
    </Grid>
  );
};

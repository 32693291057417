import { Grid } from "@mui/material";
import { importAllSpec } from "../../../../helper/ImportAll";

import useInfosClient from '../../components/hook/useInfosClient.js';
import { LoadingErrorMessage } from "../../components/LoadingMessageComponents.jsx";
import { formulaireCoordonnees } from "../../ChampsFormulaires/S01/etape8/ChampsCoordonnees";
import { DessinTechnique } from "../../components/DessinTechnique";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";

import { StyledButtonPrec } from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import {
  StyledDivFiligranne,
  StyledDivActiveStepTitle,
  StyledDivButtonToRecap,
  StyledDivDessin,
  StyledDivFormAndImageContainer,
  StyledDivFormContainer,
} from "../../styledComponents/StyledDiv";
import { StyledButtonToRecap } from "../../styledComponents/StyledButton";
import { useEffect } from "react";
import { SOCIETE } from "../../enum/champs/ChampsCommunFormulairesEnum";
import {
  ADRESSE_MAIL,
  CODE_POSTAL,
  FONCTION,
  NOM,
  PAYS,
  PRENOM,
  SECTEUR,
  TELEPHONE,
} from "../../../../constantes/symbols/SymbolsCommon";

export const StepContactInfo = ({
  state: {
    booleanIdentite,
    formik,
    styles,
    styles: {
      cssFontFamily,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    upToDateSecteursList,
    upToDateFonctionsList,
    upToDatePaysList,
  },
  action: { handleRetour },
  nom,
}) => {

  const { updateInfosClient } = useInfosClient();

  useEffect(() => {
    updateInfosClient({
      societe: formik.values[SOCIETE.description],
      pays: formik.values[PAYS.description],
      code_postal: formik.values[CODE_POSTAL.description],
      secteur: formik.values[SECTEUR.description],
      nom: formik.values[NOM.description],
      prenom: formik.values[PRENOM.description],
      fonction: formik.values[FONCTION.description],
      adresse_mail: formik.values[ADRESSE_MAIL.description],
      telephone: formik.values[TELEPHONE.description],
    });
  }, [
    formik.values[SOCIETE.description],
    formik.values[PAYS.description],
    formik.values[CODE_POSTAL.description],
    formik.values[SECTEUR.description],
    formik.values[NOM.description],
    formik.values[PRENOM.description],
    formik.values[FONCTION.description],
    formik.values[ADRESSE_MAIL.description],
    formik.values[TELEPHONE.description],
  ]);

  const afficherDessinTechnique = () => {
    return <DessinTechnique formik={formik.values} styles={styles} t={t} isFrom={"Configurateur"}/>;
  };

  if (
    !upToDateFonctionsList ||
    upToDateFonctionsList.length === 0 ||
    !upToDateSecteursList ||
    upToDateSecteursList.length === 0 ||
    !upToDatePaysList ||
    upToDatePaysList.length === 0
  ) {
    return <LoadingErrorMessage t={t} />;
  } else {
    return (
      <StyledDivFormAndImageContainer>
        <StyledDivFormContainer>
          <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
            <h2>{nom}</h2>
          </StyledDivActiveStepTitle>
          <StyledP className={cssFontFamily}>
            {t.genConfigurateurBodyPourFinaliserDemande}
          </StyledP>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
              {genererFormulaire(
                formulaireCoordonnees(
                  upToDatePaysList,
                  upToDateSecteursList,
                  upToDateFonctionsList,
                  styles,
                  t
                ),
                formik
              )}

              <Grid item>
                <StyledDivButtonToRecap>
                  <StyledButtonPrec
                    className={cssButtonPrec}
                    onClick={handleRetour}
                    variant="outlined"
                    startIcon={<StyledArrowBackOutlinedIcon />}
                  />
                  <StyledButtonToRecap
                    className={'cssButton'}
                    variant="contained"
                    type="submit"
                  >
                    {t.bouttonContinuerVersRecap}
                  </StyledButtonToRecap>
                </StyledDivButtonToRecap>
              </Grid>
            </Grid>
          </form>
        </StyledDivFormContainer>

        <StyledSticky>
          <StyledDivDessin>
            {afficherDessinTechnique()}
            <StyledDivFiligranne
              style={{
                backgroundImage:
                  "url(" +
                  importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                  ")",
              }}
              className={logoFiligraneDessin}
            />
          </StyledDivDessin>
        </StyledSticky>
      </StyledDivFormAndImageContainer>
    );
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";
import { AuthentificationActionType } from "../../constantes/actionType/AuthentificationActionType";
import authentificationService from "../service/AuthentificationService";
import { loadCurrentClient } from "./EspaceClientReducer";

const accessToken = authentificationService.getAccessToken();
const accessTokenEC = authentificationService.getAccessTokenEC();
const clientId = authentificationService.getClientId();

const connexionEC = createAsyncThunk(
  AuthentificationActionType.CONNEXION_EC,
  async ({ clientCode, clientPassword }, thunkAPI) => {
    try {
      const dispatch = thunkAPI.dispatch;

      // 1ère étape : faire la connexion et attendre la mise à jour du state authentification
      const { clientId, token } = await dispatch(connexionInterneEC({ clientCode, clientPassword })).unwrap();

      // 2ème étape : déclencher les mises à jour dépendantes
      await dispatch(loadCurrentClient({forcage:true})).unwrap();

      dispatch(setMessage(null));
      return { clientId, token };
    } catch (error) {
      setMessage({ message: "Accès non autorisé", type: "error" })
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

/**
 * Thunk interne appelé uniquement par le thunk «connexionEC».
 */
const connexionInterneEC = createAsyncThunk(
  'auth/_connexionInterneEC ',
  async ({ clientCode, clientPassword }, thunkAPI) => {
    try {
      return await authentificationService.connexionEC(clientCode, clientPassword);
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const connexion = createAsyncThunk(
  AuthentificationActionType.CONNEXION,
  async ({ email, motDePasse }, thunkAPI) => {
    try {
      const response = await authentificationService.connexion( email, motDePasse);
      const token = JSON.parse(atob(response.data.accessToken.split(".")[1]));
      thunkAPI.dispatch(setMessage(null));
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const rafraichissement = createAsyncThunk(
  AuthentificationActionType.RAFRAICHISSEMENT,
  async (_, thunkAPI) => {
    try {
      const accessToken = { [LocalStorage.ACCESS_TOKEN]: localStorage.getItem( LocalStorage.ACCESS_TOKEN) };
      const response = await authentificationService.rafraichissement( accessToken);
      const token = JSON.parse(atob(response.data.accessToken.split(".")[1]));
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const rafraichissementEC = createAsyncThunk(
  AuthentificationActionType.RAFRAICHISSEMENT_EC,
  async (_, thunkAPI) => {
    try {
      const { token } = await authentificationService.rafraichissementEC();
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const deconnexion = createAsyncThunk(
  AuthentificationActionType.DECONNEXION,
  async () => {
    authentificationService.deconnexion();
  }
);

const deconnexionEC = createAsyncThunk(
  AuthentificationActionType.DECONNEXION_EC,
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;

    // 1ère étape : faire la déconnexion et attendre la mise à jour du state authentification
    await dispatch(deconnexionInterneEC());

    // 2ème étape : remettre à zéro les données de l'espace client (réinitialisation car clientId = null)
    dispatch(loadCurrentClient({ forcage: true}));

    dispatch(setMessage(null));
  }
);

/**
 * Thunk interne appelé uniquement par le thunk «connexionEC».
 */
const deconnexionInterneEC = createAsyncThunk(
  'auth/_deconnexionInterneEC ',
  async (_, thunkAPI) => {
    try {
      authentificationService.deconnexionEC();
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);



const initialState = {
  isLoggedIn: !!(accessToken || accessTokenEC),
  accessToken: accessToken,
  accessTokenEC: accessTokenEC,
  clientId : clientId,
};

const authentificationSlice = createSlice({
  name: AuthentificationActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      // thunk connexion
      .addCase(connexion.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessToken = action.payload.token;
      })
      .addCase(connexion.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.accessToken = null;
      })
      // thunk connexionEC
      // FIXME state déjà mis à jour par connexionInterneEC ci-dessous
      .addCase(connexionEC.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.clientId = action.payload.clientId;
        state.accessTokenEC = action.payload.token;
      })
      .addCase(connexionEC.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      })
      // thunk connexionInterneEC
      .addCase(connexionInterneEC.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.clientId = action.payload.clientId;
        state.accessTokenEC = action.payload.token;
      })
      .addCase(connexionInterneEC.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      })
      // thunk rafraichissement
      .addCase(rafraichissement.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessToken = action.payload.token;
      })
      .addCase(rafraichissement.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.accessToken = null;
      })
      // thunk rafraichissementEC
      .addCase(rafraichissementEC.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessTokenEC = action.payload.token;
      })
      .addCase(rafraichissementEC.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      })
      // thunk deconnexion
      .addCase(deconnexion.fulfilled, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessToken = null;
      })
      // thunk deconnexionEC
      // FIXME state déjà mis à jour par deconnexionInterneEC ci-dessous
      .addCase(deconnexionEC.fulfilled, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      })
      .addCase(deconnexionEC.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      })
      // thunk deconnexionInterneEC
      .addCase(deconnexionInterneEC.fulfilled, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      });
  },
});

const { reducer } = authentificationSlice;

export {
  connexion, deconnexion, rafraichissement,
  connexionEC, deconnexionEC, rafraichissementEC
};
export default reducer;

import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const StyledButtonContinuer = styled(Button)({
    fontFamily: 'Dosis',
    fontSize: '1.125em',
    textAlign: 'center',
    fontWeight: '600',
    textTransform: 'none',
    margin: '1em',
    padding: '0',
    borderRadius: '8px',
    width: '7.625em',
    height: '2.6em',
    lineHeight: '1em',
    '@media (max-width: 600px)':{
        fontSize: '1em',
        lineHeight:'1em',
    }
});
StyledButtonContinuer.defaultProps = {
  id: 'boutonContinuer'
};

export const StyledButtonHome = styled(Button)({
    margin: '0 0 1em 0',
    fontSize: '1em',
    width: '10em',
    height: '2.25em',
    fontFamily: 'Dosis',
    textAlign: 'center',
    fontWeight: '600',
    textTransform: 'none',
    padding: '0',
    borderRadius: '8px',
    lineHeight: '1em',
    '@media (max-width: 600px)':{
        fontSize: '1em',
        lineHeight:'1em',
    }
});

export const StyledButtonPrec = styled(Button)({
    height: '2.6em',
    width: '3.5em',
    borderRadius: '8px',
    backgroundColor: 'white',
    '&.MuiButton-root': {
        minWidth: '3.5em',
    }
});

export const StyledButtonToRecap = styled(Button)({
    fontFamily: 'Dosis',
    fontSize: '1.125em',
    textAlign: 'center',
    fontWeight: '600',
    textTransform: 'none',
    margin: '1em',
    padding: '0 0.5em',
    borderRadius: '8px',
    height: '2.6em',
    lineHeight: '1em',
    width: '50%',
    minWidth: '200px',
    '@media (max-width: 600px)':{
        fontSize: '1em',
        lineHeight:'1em',
    }
});
StyledButtonToRecap.defaultProps = {
  id: 'boutonContinuer'
};

export const StyledButtonAjouterQuantite = styled(Button)({
    lineHeight: '1.1em',
    fontSize: '0.875em',
    textAlign: 'left',
    justifyContent: 'left',
    width: '100%',
    maxWidth: '250px',
    height: 'auto',
    minHeight: '40px',
    margin: '1em 0',
    padding: '0.5em',
    fontWeight: '600',
    textTransform: 'none',
    borderRadius: '8px',
    backgroundColor: '#2A375C',
    '&:hover': {
        backgroundColor: '#2A375C',
    },
    '@media (max-width: 600px)':{
        fontSize: '1em',
        lineHeight:'1em',
        width: '100%',
    }
});

export const StyledButtonRetirerQuantite = styled(Button)({
    color: '#2A375C',
    border: '1px solid #2A375C',
    lineHeight: '1.1em',
    fontSize: '0.875em',
    textAlign: 'left',
    justifyContent: 'left',
    width: '100%',
    maxWidth: '250px',
    height: 'auto',
    minHeight: '40px',
    margin: '1em 0',
    padding: '0.5em',
    fontWeight: '600',
    textTransform: 'none',
    borderRadius: '8px',
    backgroundColor: '#F7F8FF',
    '&:hover': {
        backgroundColor: '#F7F8FF',
    },
    '@media (max-width: 600px)':{
        fontSize: '1em',
        lineHeight:'1em',
        width: '100%',
    }
});

export const StyledButtonRetirerFichier = styled(Button)({
    width: 'auto',
    textAlign: 'center',
    color: '#2A375C',
    border: '1px solid #2A375C',
    lineHeight: '1.1em',
    fontSize: '0.875em',
    height: 'auto',
    margin: '1em 0',
    padding: '0.5em',
    fontWeight: '600',
    textTransform: 'none',
    borderRadius: '8px',
    backgroundColor: '#F7F8FF',
    '&:hover': {
        backgroundColor: '#F7F8FF',
    },
    '@media (max-width: 600px)':{
        fontSize: '1em',
        lineHeight:'1em',
        width: '100%',
    }
});

// bouton de validation de la DDP dans le récapitulatif
export const StyledButtonRecap = styled(Button)({
    fontSize: '1.5em',
    fontWeight: '600',
    width: 'auto',
    padding: '0 1.1em',
    fontFamily: 'Dosis',
    textAlign: 'center',
    textTransform: 'none',
    margin: '1em',
    borderRadius: '8px',
    height: '2.6em',
    lineHeight: '1em',
    '@media (max-width: 600px)':{
        lineHeight: '1em',
        padding: '0.2em 1.1em',
        fontSize:'1em',
    }
});
StyledButtonRecap.defaultProps = {
  id: 'boutonValider'
};

export const StyledButtonTelechargerRecap = styled(Button)({
    fontSize: '1.2em',
    fontWeight: '600',
    width: 'auto',
    padding: '0 1.1em',
    fontFamily: 'Dosis',
    textAlign: 'center',
    textTransform: 'none',
    margin: '0 0 1em 0',
    borderRadius: '8px',
    height: '2.6em',
    lineHeight: '1em',
    '@media (max-width: 600px)':{
        lineHeight: '1em',
        padding: '0.2em 1.1em',
        fontSize:'1em',
    }
});

export const StyledButtonPageConfirmation = styled(Button)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '9em',
    height: '2.6em',
    padding: '0',
    fontSize: '1.125em',
    borderRadius: '8px',
    textTransform: 'none',
    fontFamily: 'Dosis',
    lineHeight: '1.1em',
});

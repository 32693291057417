import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";

import { getAllAgressions } from "../slice/AgressionsReducer";
import { getAllMarqueMachine, getAllTypeMachine } from "../slice/MachinesReducer";
import { getOdpById } from "../slice/FormulaireReducer";
import { setMessage } from "../slice/MessageReducer";
import { getAllSecteurs } from "../slice/SecteurReducer";
import { getAllFonctions } from "../slice/FonctionReducer";
import { getAllPays } from "../slice/PaysReducer";
import { LoadingErrorMessage } from "./components/LoadingMessageComponents.jsx";
import { controllerResumeDisplay } from "./backoffice/ControllerResumeDisplay";
import { OFFRE_DE_PRIX } from "../../constantes/symbols/SymbolsResumeDisplayedFrom";
import ErrorBoundary from "./components/ErrorBoundary";

export const OffreDePrix = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const [reference, setReference] = useState(null);

  const [upToDateSecteursList, setUpToDateSecteursList] = useState([]);
  const [upToDateFonctionsList, setUpToDateFonctionsList] = useState([]);
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [upToDateAgressionsList, setUpToDateAgressionsList] = useState([]);
  const [upToDateTypeMachineList, setUpToDateTypeMachineList] = useState([]);
  const [upToDateMarqueMachineList, setUpToDateMarqueMachineList] = useState([]);

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);
  const { agressionsList } = useSelector((state) => state.agressions);
  const { isFormularySent } = useSelector((state) => state.formulaire);
  const { typeMachineList, marqueMachineList } = useSelector(
    (state) => state.machines
  );
  const { formikList } = useSelector((state) => state.formulaire);

  const [
    styles,
    t,
    setUpToDateReferenceByIdList,
    upToDateReferenceByIdList,
    setSelectedPage,
  ] = useOutletContext();

  useEffect(() => {
    const odp = params.get(OFFRE_DE_PRIX.description);
    if (!odp) {
      return;
    }
    dispatch(getOdpById(odp));
  }, [dispatch, params]);

  useEffect(() => {
    if (!formikList) {
      return;
    }
    setUpToDateReferenceByIdList(formikList);
  }, [formikList]);

  useEffect(() => {
    dispatch(getAllAgressions());
    dispatch(getAllTypeMachine());
    dispatch(getAllMarqueMachine());
    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());
  }, [dispatch]);

  useEffect(() => {
    setSelectedPage(1);
  }, []);

  useEffect(() => {
    if (!agressionsList) {
      return;
    }
    setUpToDateAgressionsList(agressionsList);
  }, [agressionsList]);

  useEffect(() => {
    if (!typeMachineList) {
      return;
    }
    setUpToDateTypeMachineList(typeMachineList);
  }, [typeMachineList]);

  useEffect(() => {
    if (!marqueMachineList) {
      return;
    }
    setUpToDateMarqueMachineList(marqueMachineList);
  }, [marqueMachineList]);

  useEffect(() => {
    if (!secteursList) {
      return;
    }
    setUpToDateSecteursList(secteursList);
  }, [secteursList]);

  useEffect(() => {
    if (!fonctionsList) {
      return;
    }
    setUpToDateFonctionsList(fonctionsList);
  }, [fonctionsList]);

  useEffect(() => {
    if (!paysList) {
      return;
    }
    setUpToDatePaysList(paysList);
  }, [paysList]);

  const resetMessage = () => {
    dispatch(setMessage(null));
  };

  const stateUsedForRender = {
    booleanIdentite: false,
    formik: upToDateReferenceByIdList,
    styles,
    t,
    isFormularySent,
    upToDateAgressionsList,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateFonctionsList,
    upToDateSecteursList,
    upToDatePaysList,
  };

  const actionUsedForRender = {
    dispatch,
    setReference,
    setUpToDateReferenceByIdList,
  };

  if (!upToDateReferenceByIdList) {
    return <LoadingErrorMessage t={t} />;
  } else {
    return (
      <ErrorBoundary>
        <div>
          {controllerResumeDisplay(
            stateUsedForRender,
            actionUsedForRender,
            OFFRE_DE_PRIX
          )}
        </div>
      </ErrorBoundary>
    );
  }
};

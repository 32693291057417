import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  Badge,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { useTranslations } from '../../components/hook/useTranslations.js';
import { StyledTextfieldSearchDdp } from "../../styledComponents/StyledTextfieldBackoffice";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { EC_COLORS, getBgColorFromStatus } from "../common/EcColor";

import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
  StyledDivSearchBar,
} from "../../styledComponents/StyledDivBackoffice";

export const OffreItem = ({
  id,
  offrePrixCode,
  offreRefClient,
  dateCreation,
  ligne,
  contact,
  reponse,
  publ,
  commandeCode,
  action,
  offrePrixId,
  commandeId,
  searchBarAction: {
    setSearchBarContentCodeOffre,
    setSearchBarContentReference,
    setSearchBarContentDateCreation,
    setSearchBarContentContact,
    setSearchBarContentCodeCommande,
    setSearchBarContentReponse,
    setSearchBarContentEtat,
  },
  sortAction: {
    setSortedCodeOffre,
    setSortedReference,
    setSortedDateCreation,
    setSortedLigne,
    setSortedCodeCommande,
    setSortedContact,
    setSortedReponse,
    setSortedEtat,
  },
}) => {
  const { t } = useTranslations();
  const { clientId } = useAuthentification();
  const navigate = useNavigate();

  const columnSize = 1; // const [columnSize, setColumnSize] = useState(1);

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const noClassNameMobileAndTablette =
    "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  const handleChange = (event, setterSearchBar) => {
    setterSearchBar(event.target.value);
  };

  const navigateToCommande = (commandeId) => {
    if (commandeId) {
      navigate(ClientRoutes.VUE_COMMANDE + commandeId);
    }
  };

  const navigateToOffre = (offreId) => {
    if (offreId)
      navigate(ClientRoutes.VUE_OFFRE + offreId);
  };

  const DisplayAdornment = ({ md, style, xs }) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={md ? md : columnSize}
        className={style + " itemDdp action"}
        xs={xs ? xs : columnSize}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        {publ.includes("Vrai") ? (
          <IconButton style={{ padding: 0 }} onClick={() => navigateToOffre(offrePrixId)} >
            <div
              style={{
                padding: 6,
                borderRadius: 4,
                overflow: "hidden",
                border: "2px #1C243C solid",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 6,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "#1C243C",
                  fontSize: 18,
                  fontFamily: "Dosis",
                  fontWeight: "600",
                }}
              >
                {t.voirPlus}
              </div>
            </div>
          </IconButton>
        ) : null}
      </Grid>
    );
  };

  const displaySearchBar = (setValue) => {
    if (setValue) {
      return (
        <div>
          <StyledDivSearchBar>
            <StyledTextfieldSearchDdp
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event, setValue)}
              InputProps={{
                endAdornment: (
                  <SearchIcon md={{ color: EC_COLORS.ORANGE, fontSize: 10 }} />
                ),
              }}
            />
          </StyledDivSearchBar>
        </div>
      );
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={`${style} columnHeaderEC ${
          searchBarStateSetter ? "" : "hasSearch"
        }`}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
          // marginLeft={name === "Action" ? "-0.75rem" : "0"}
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
            {displaySortArrows(sortStateSetter)}
          </Stack>
          <Stack>{displaySearchBar(searchBarStateSetter)}</Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </Grid>
    );
  };

  const displayItemCommande = (commandeId, name, md, sm, style) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {commandeId ? (
          // Si commandeId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={() => navigateToCommande(commandeId)} // Ne pas invoquer directement la fonction
          />
        ) : (
          // Si commandeId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  const displayOffreCode = (offreId, name, md, sm, style) => {


    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {offreId && publ.includes("Vrai") ? (
          // Si offreId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={() => navigateToOffre(offreId)}
          />
        ) : (
          // Si offreId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  const displayEtat = (name, md, sm, style, t) => {
    // Vérifier si le mot "Vrai" est contenu dans la variable name
    const etat = name.includes("Vrai") ? t.envoyee : t.enCours;

    const bgcolorEtat = name.includes("Vrai") ? EC_COLORS.GREEN : EC_COLORS.GREY;

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Badge
          style={{
            wordBreak: "break-all",
            backgroundColor: bgcolorEtat,
            color: "white",
            padding: "0.5rem",
            borderRadius: "0.5rem",
          }}
        >
          {etat}
        </Badge>
      </Grid>
    );
  };

  const displayReponse = (name, md, sm, style, t) => {
    // Vérifier si le mot name est "Attente", "Commandée" ou "Refusée"
    const reponse =
      name === "Attente"
        ? t.enAttente
        : name === "Commandée"
        ? t.commandee
        : name === "Refusée"
        ? t.refusee
        : name;

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Badge
          style={{
            wordBreak: "break-all",
            backgroundColor: getBgColorFromStatus(name),
            color: "white",
            padding: "0.5rem",
            borderRadius: "0.5rem",
          }}
        >
          {reponse}
        </Badge>
      </Grid>
    );
  };

  const displayItemAction = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return <DisplayAdornment md={md} style={style} xs={xs} />;
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : { height: "100px" }}
    >
      {/*Code Offre*/}
      {id === 0
        ? displayItemHeader(
            offrePrixCode,
            setSearchBarContentCodeOffre,
            setSortedCodeOffre,
            1.5,
            1.5,
            8,
            alwaysDisplayed
          )
        : displayOffreCode(
            offrePrixId,
            offrePrixCode,
            1.5,
            1.5,
            alwaysDisplayed
          )}
      {/*Date création*/}
      {id === 0
        ? displayItemHeader(
          dateCreation,
          setSearchBarContentDateCreation,
          setSortedDateCreation,
          1,
          1,
          4,
          noClassNameMobile
        )
        : displayItem(dateCreation, 1, 1, noClassNameMobile)}
      {/*Référence*/}
      {id === 0
        ? displayItemHeader(
            offreRefClient,
            setSearchBarContentReference,
            setSortedReference,
            2,
            2,
            8,
            noClassNameMobile
          )
        : displayItem(offreRefClient, 2, 2, noClassNameMobile)}
      {/*Contact*/}
      {id === 0
        ? displayItemHeader(
          contact,
          setSearchBarContentContact,
          setSortedContact,
          1.5,
          1.5,
          8,
          noClassNameMobileAndTablette
        )
        : displayItem(contact, 1.5, 1.5, noClassNameMobileAndTablette)}
      {/*Nombre de ligne*/}
      {id === 0
        ? displayItemHeader(
            ligne,
            null,
            setSortedLigne,
            1,
            1,
            null,
            noClassNameMobile
          )
        : displayItem(ligne, 1, 1, noClassNameMobile)}
      {/*Etat*/}
      {id === 0
        ? displayItemHeader(
            publ,
            setSearchBarContentEtat,
            setSortedEtat,
            1,
            1,
            null,
            noClassNameMobile
          )
        : displayEtat(publ, 1, 1, noClassNameMobile, t)}
      {/*Réponse*/}
      {id === 0
        ? displayItemHeader(
            reponse,
            setSearchBarContentReponse,
            setSortedReponse,
            1.5,
            1.5,
            null,
            noClassNameMobile
          )
        : displayReponse(reponse, 1.5, 1.5, noClassNameMobile, t)}

      {/*Code commande*/}
      {id === 0
        ? displayItemHeader(
            commandeCode,
            setSearchBarContentCodeCommande,
            setSortedCodeCommande,
            1.5,
            1.5,
            4,
            noClassNameMobileAndTablette
          )
        : displayItemCommande(
            commandeId,
            commandeCode,
            1.5,
            1.5,
            noClassNameMobileAndTablette
          )}
      {/*Action*/}
      {id === 0
        ? displayItemHeader(action, null, null, 1, 1, 4, "")
        : displayItemAction(action, null, null, 1, 1, 4, "")}
    </Grid>
  );
};

import { useEffect, useState } from 'react';

import { fetchJsonFromServer } from '../../helper/fetchUtil.js';
import { Serveur } from '../../constantes/services/Serveur';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';

const BilanTraductions = () => {

  const listLocalLang = require.context('../../languages/', false, /\.(json)$/);
  const [ bilan, setBilan ] = useState(null);

  useEffect( async () => {
    const traductionsLocales = await getJsonFromFileByLang('fr');

    const listeTraductionsSylob = await fetchJsonFromServer(Serveur.URL_TRADUCTIONS);
    // Exemple : {
    //   Code: "TRAD000001",
    //   "Code site": "languageName",
    //   Famille: "Général",
    //   FR: "Français",
    //   EN: "English",
    //   DE: "Deutsch",
    //   ES: "Español",
    //   IT: "Italiano"
    // }

    const tabBilan = [];
    for ( const  [codeSite, traductionLocale]  of Object.entries(traductionsLocales)) {
      //const traductionLocale = traductionsLocales[cle];
      if (typeof(traductionLocale) !== 'string')
        continue;

      const listeEquivalencesSylob =  listeTraductionsSylob.filter( traductionSylob => traductionSylob['Code site'] === codeSite );
      const equivalenceSylob = listeEquivalencesSylob.length !== 0;

      if (equivalenceSylob)
        continue;

      const bilan = [ codeSite, equivalenceSylob, traductionLocale ];
      tabBilan.push(bilan);
    }
    setBilan(tabBilan);
  }, []);

  const getJsonFromFileByLang = (lang) => {
    lang = lang.toLowerCase()
    return listLocalLang
      .keys()
      .filter((fileName) => { // filename: «./fr.json»
        return fileName.replace('./', '').split('.')[0]===lang;
      })
      .map((fileName) => {
        return listLocalLang(fileName);
      })
      .find((_elem, index) => index === 0);
  }


  return (
    <Paper>
      <h1>Bilan des traductions</h1>
      { bilan === null ? (
          <p>Analyse en cours...</p>
        ):(
          <>
          <p>Nombre de traductions absentes côté Sylob : { bilan.length }</p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Identifiant</TableCell>
                <TableCell>Gibraltaz</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { bilan.map( ([codeSite, _equivalenceSylob, traductionLocale]) =>
                <TableRow key={'tr_' + codeSite}>
                  <TableCell>{codeSite}</TableCell>
                  <TableCell>{traductionLocale}</TableCell>
                </TableRow>
             )}
            </TableBody>
          </Table>
          </>
        )
      }
    </Paper>
  );

};

export default BilanTraductions;

import { useSelector } from "react-redux";

import { Card, CardContent } from "@mui/material";
import { EC_COLORS } from "../../espaceClient/common/EcColor";

import { useChoixContact } from '../hook/useChoixContact.js';
import { useTranslations } from '../hook/useTranslations.js';
import useInfosClient from '../hook/useInfosClient.js';
import { StyledDivTitleCardReverse, StyledContentActus } from "../../styledComponents/StyledTextEspaceClient";
import { StringToRouteInNavigate } from "../../../../helper/StringParser";
import { GuestRoutes } from "../../../../constantes/routes/GuestRoutes";
import { BoutonAccueilEC } from "../../components/espaceClient/BoutonAccueilEC";
import { importAll } from "../../../../helper/ImportAll";

const ConfigurateurCard = ({ styles }) => {
  const { t } = useTranslations();
  const { updateInfosClient } = useInfosClient();

  const { contact: choixContact } = useChoixContact();
  const { currentContact } = useSelector((state) => state.espaceClient);

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);

  const handleConfigurer = () => {
    if (currentContact.item[ 0 ] && choixContact === null) {
      // Fonction pour mapper sur fonctionList et rechercher la valeur correspondante
      const fonction = fonctionsList.find(
        (item) => item.name === currentContact.item[ 0 ].fonction
      );

      const secteur = secteursList.find(
        (item) =>
          item.reference === currentContact.item[ "infosClient" ].codeSecteurActivite
      );

      const pays = paysList.find(
        (item) => item.reference === currentContact.item[ "infosClient" ].codePays
      );

      updateInfosClient({
        societe: currentContact.item[ "infosClient" ].raisonSociale,
        pays: pays,
        code_postal: currentContact.item[ "infosClient" ].codePostal,
        secteur: secteur,
        nom: currentContact.item[ 0 ].nom,
        prenom: currentContact.item[ 0 ].prenom,
        fonction: fonction,
        adresse_mail: currentContact.item[ 0 ].email,
        telephone: currentContact.item[ 0 ].telephone,
      });
    }

    const url = StringToRouteInNavigate(GuestRoutes.CONFIGURATEUR);
    window.open(url, "_blank");
  };


  return (
    <Card
      id="configurateur-card"
      className={styles.jeConfigureCard}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        backgroundColor: EC_COLORS.DEEP_BLUE,
        flexWrap: "wrap",
        borderRadius: "8px",
      }}
    >
      <img
        className={styles.mobileImageConfigure}
        src={importAll()[ "RCModeles-Configurateur.png" ]}
        alt="RC Modèles Configurateur"
      />
      <CardContent
        style={{ flex: "1", minWidth: "300px", padding: "24px" }}
      >
        <StyledDivTitleCardReverse>
          {t.genTitleJeConfigure}
        </StyledDivTitleCardReverse>

        <StyledContentActus
          style={{ fontSize: "1.25rem", color: "white" }}
        >
          {t.genDescrJeConfigure}
        </StyledContentActus>

        <BoutonAccueilEC
          texte={t.genAffJeConfigure}
          action={handleConfigurer}
          backgroundColor={'dark'}
        />

      </CardContent>
    </Card>
  )
}

export default ConfigurateurCard;

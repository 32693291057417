export const defineSortedDates = (sortedDownOrUp, dateKey) => {
  const comparator = sortedDownOrUp === "up"
    ? (a, b) => parseDate(a[dateKey]) - parseDate(b[dateKey])
    : (a, b) => parseDate(b[dateKey]) - parseDate(a[dateKey]);

  return (list) => list.sort(comparator);
};

const parseDate = (dateString) => {
  const [day, month, year] = dateString.split("/");
  return new Date(year, month - 1, day);
};

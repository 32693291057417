import { Grid } from "@mui/material";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
  StyledDivPhotoPasDessin,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import { importAll, importAllSpec } from "../../../../helper/ImportAll";
import {
  formulaireTypeSection,
  formulaireSection,
  formulaireLongueurX,
  formulaireSectionSymetrique,
  formulaireLongueurC,
  formulaireLongueurD,
  formulaireLongueurE,
  formulaireLongueurF,
  formulaireLongueurG,
  formulaireLongueurH,
  formulaireFormeQuelconque,
  formulaireToile,
  formulaireLongueurB,
} from "../../ChampsFormulaires/S04/etape4/ChampsConstruction";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  S04_STEP4_arrayFormeCorpsD,
  S04_STEP4_arrayFormeCorpsE,
  S04_STEP4_arrayFormeCorpsF,
  S04_STEP4_arrayFormeCorpsSymA,
  S04_STEP4_arrayFormeCorpsSymB,
  S04_STEP4_arrayFormeCorpsSymC,
  S04_STEP4_arrayFormeCorpsSymD,
  S04_STEP4_arrayFormeCorpsSymE,
  S04_STEP4_arrayFormeCorpsSymF,
  S04_STEP4_arrayToile,
  S04_STEP4_arrayTypeCorps,
} from "../../../../constantes/symbols/SymbolsServicesS04";
import {
  TYPE_SECTION,
  SECTION_SYMETRIQUE,
  S04_ECAILLES,
} from "../../../../constantes/symbols/SymbolsS04";
import { DessinTechnique } from "../../components/DessinTechnique";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../constantes/symbols/HelperSymbolsServices";
import { findFormeCorpsFromTypeSection } from "../../../../helper/displayCasesFormulary";
import { useEffect } from "react";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";
import { nativeLang } from '../../../../config/i18nConfig.js';

export const StepS04Build = ({
  state: {
    booleanIdentite,
    etape,
    styles,
    styles: {
      cssFontFamily,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const translation = useTranslations();
  const lang = translation.lang;

  const formeCorps = findFormeCorpsFromTypeSection(formik.values);

  useEffect(() => {
    formik.values = Object.assign({}, formik.values, {
      [S04_ECAILLES.description]: [],
    });
  }, [formeCorps]);

  const typeSection = formik.values[TYPE_SECTION.description];
  const sectionSymetrique = equalsBooleanOrStringOrNumber(
    formik.values[SECTION_SYMETRIQUE.description]
  );

  const afficherFormulaireTypeSection = (formik) => {
    switch (true) {
      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 0):
        return (
          <>
            {genererFormulaire(
              formulaireSection(
                formik,
                S04_STEP4_arrayFormeCorpsSymA(t),
                styles,
                t,
                t.SQ0037_Q00350
              ),
              formik
            )}
            {genererFormulaire(formulaireLongueurX(styles, t), formik)}
          </>
        );
      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 1):
        return (
          <>
            {genererFormulaire(
              formulaireSection(
                formik,
                S04_STEP4_arrayFormeCorpsSymB(t),
                styles,
                t,
                t.SQ0037_Q00360
              ),
              formik
            )}
            {genererFormulaire(formulaireLongueurB(styles, t), formik)}
            {genererFormulaire(formulaireLongueurX(styles, t), formik)}
          </>
        );
      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 2):
        return (
          <>
            {genererFormulaire(
              formulaireSection(
                formik,
                S04_STEP4_arrayFormeCorpsSymC(t),
                styles,
                t,
                t.SQ0037_Q00370
              ),
              formik
            )}
            {genererFormulaire(formulaireLongueurB(styles, t), formik)}
            {genererFormulaire(formulaireLongueurX(styles, t), formik)}
          </>
        );
      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 3):
        switch (true) {
          case !!sectionSymetrique:
            switch (true) {
              //si symétrique ET option D1S selectionnée
              case equalsCodeOrLabel(
                formeCorps,
                S04_STEP4_arrayFormeCorpsSymD(),
                0
              ):
                return (
                  <>
                    {genererFormulaire(
                      formulaireSection(
                        formik,
                        S04_STEP4_arrayFormeCorpsSymD(t),
                        styles,
                        t,
                        t.SQ0037_Q00290
                      ),
                      formik
                    )}
                    {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurX(styles, t), formik)}
                  </>
                );
              //si symétrique ET option D3S selectionnée
              case equalsCodeOrLabel(
                formeCorps,
                S04_STEP4_arrayFormeCorpsSymD(),
                1
              ):
                return (
                  <>
                    {genererFormulaire(
                      formulaireSection(
                        formik,
                        S04_STEP4_arrayFormeCorpsSymD(t),
                        styles,
                        t,
                        t.SQ0037_Q00290
                      ),
                      formik
                    )}
                    {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurX(styles, t), formik)}
                  </>
                );
              default:
                return null;
            }
          case !sectionSymetrique:
            switch (true) {
              //si non symétrique ET option D1 selectionnée
              case equalsCodeOrLabel(
                formeCorps,
                S04_STEP4_arrayFormeCorpsD(),
                0
              ):
                return (
                  <>
                    {genererFormulaire(
                      formulaireSection(
                        formik,
                        S04_STEP4_arrayFormeCorpsD(t),
                        styles,
                        t,
                        t.SQ0037_Q00300
                      ),
                      formik
                    )}
                    {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurX(styles, t), formik)}
                  </>
                );
              //si non symétrique ET option D2 selectionnée
              case equalsCodeOrLabel(
                formeCorps,
                S04_STEP4_arrayFormeCorpsD(),
                1
              ):
                return (
                  <>
                    {genererFormulaire(
                      formulaireSection(
                        formik,
                        S04_STEP4_arrayFormeCorpsD(t),
                        styles,
                        t,
                        t.SQ0037_Q00300
                      ),
                      formik
                    )}
                    {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurD(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurX(styles, t), formik)}
                  </>
                );
              //si non symétrique ET option D3 selectionnée
              case equalsCodeOrLabel(
                formeCorps,
                S04_STEP4_arrayFormeCorpsD(),
                2
              ):
                return (
                  <>
                    {genererFormulaire(
                      formulaireSection(
                        formik,
                        S04_STEP4_arrayFormeCorpsD(t),
                        styles,
                        t,
                        t.SQ0037_Q00300
                      ),
                      formik
                    )}
                    {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurD(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurE(styles, t), formik)}
                    {genererFormulaire(formulaireLongueurX(styles, t), formik)}
                  </>
                );
              default:
                return null;
            }
          default:
            return null;
        }

      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4):
        if (!!sectionSymetrique) {
          //partie symétrique
          if (
            equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymE(), 1)
          ) {
            //si symétrique et option E2S sélectionnée
            return (
              <>
                {genererFormulaire(
                  formulaireSection(
                    formik,
                    S04_STEP4_arrayFormeCorpsSymE(t),
                    styles,
                    t,
                    t.SQ0037_Q00310
                  ),
                  formik
                )}
                {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                {genererFormulaire(formulaireLongueurD(styles, t), formik)}
                {genererFormulaire(formulaireLongueurE(styles, t), formik)}
                {genererFormulaire(formulaireLongueurX(styles, t), formik)}
              </>
            );
          } else {
            //si symétrique et option E2S non sélectionnée
            return (
              <>
                {genererFormulaire(
                  formulaireSection(
                    formik,
                    S04_STEP4_arrayFormeCorpsSymE(t),
                    styles,
                    t,
                    t.SQ0037_Q00310
                  ),
                  formik
                )}
                {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                {genererFormulaire(formulaireLongueurX(styles, t), formik)}
              </>
            );
          }
        } else {
          //partie non symétrique
          if (equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsE(), 1)) {
            //si non symétrique et option E2 sélectionnée
            return (
              <>
                {genererFormulaire(
                  formulaireSection(
                    formik,
                    S04_STEP4_arrayFormeCorpsE(t),
                    styles,
                    t,
                    t.SQ0037_Q00320
                  ),
                  formik
                )}
                {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                {genererFormulaire(formulaireLongueurD(styles, t), formik)}
                {genererFormulaire(formulaireLongueurE(styles, t), formik)}
                {genererFormulaire(formulaireLongueurF(styles, t), formik)}
                {genererFormulaire(formulaireLongueurX(styles, t), formik)}
              </>
            );
          } else {
            //si symétrique et option E2S non sélectionnée
            return (
              <>
                {genererFormulaire(
                  formulaireSection(
                    formik,
                    S04_STEP4_arrayFormeCorpsE(t),
                    styles,
                    t,
                    t.SQ0037_Q00320
                  ),
                  formik
                )}
                {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                {genererFormulaire(formulaireLongueurD(styles, t), formik)}
                {genererFormulaire(formulaireLongueurX(styles, t), formik)}
              </>
            );
          }
        }

      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 5):
        if (!!sectionSymetrique) {
          //partie symétrique
          if (
            equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsSymF(), 1)
          ) {
            //si symétrique et option F2S sélectionnée
            return (
              <>
                {genererFormulaire(
                  formulaireSection(
                    formik,
                    S04_STEP4_arrayFormeCorpsSymF(t),
                    styles,
                    t,
                    t.SQ0037_Q00330
                  ),
                  formik
                )}
                {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                {genererFormulaire(formulaireLongueurD(styles, t), formik)}
                {genererFormulaire(formulaireLongueurX(styles, t), formik)}
              </>
            );
          } else {
            //si symétrique et option F2S non sélectionnée
            return (
              <>
                {genererFormulaire(
                  formulaireSection(
                    formik,
                    S04_STEP4_arrayFormeCorpsSymF(t),
                    styles,
                    t,
                    t.SQ0037_Q00330
                  ),
                  formik
                )}
                {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                {genererFormulaire(formulaireLongueurX(styles, t), formik)}
              </>
            );
          }
        } else {
          //partie non symétrique
          if (equalsCodeOrLabel(formeCorps, S04_STEP4_arrayFormeCorpsF(), 1)) {
            //si non symétrique et option F2 sélectionnée
            return (
              <>
                {genererFormulaire(
                  formulaireSection(
                    formik,
                    S04_STEP4_arrayFormeCorpsF(t),
                    styles,
                    t,
                    t.SQ0037_Q00340
                  ),
                  formik
                )}
                {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                {genererFormulaire(formulaireLongueurD(styles, t), formik)}
                {genererFormulaire(formulaireLongueurE(styles, t), formik)}
                {genererFormulaire(formulaireLongueurF(styles, t), formik)}
                {genererFormulaire(formulaireLongueurG(styles, t), formik)}
                {genererFormulaire(formulaireLongueurH(styles, t), formik)}
                {genererFormulaire(formulaireLongueurX(styles, t), formik)}
              </>
            );
          } else {
            //si symétrique et option F2 non sélectionnée
            return (
              <>
                {genererFormulaire(
                  formulaireSection(
                    formik,
                    S04_STEP4_arrayFormeCorpsF(t),
                    styles,
                    t,
                    t.SQ0037_Q00340
                  ),
                  formik
                )}
                {genererFormulaire(formulaireLongueurB(styles, t), formik)}
                {genererFormulaire(formulaireLongueurC(styles, t), formik)}
                {genererFormulaire(formulaireLongueurD(styles, t), formik)}
                {genererFormulaire(formulaireLongueurE(styles, t), formik)}
                {genererFormulaire(formulaireLongueurF(styles, t), formik)}
                {genererFormulaire(formulaireLongueurX(styles, t), formik)}
              </>
            );
          }
        }

      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 6):
        return (
          <>{genererFormulaire(formulaireFormeQuelconque(styles, t), formik)}</>
        );

      default:
        return null;
    }
  };

  const affichageSectionSymetrique = (formik) => {
    switch (true) {
      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 3):
        return (
          <>
            {genererFormulaire(formulaireSectionSymetrique(styles, t), formik)}
          </>
        );

      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 4):
        return (
          <>
            {genererFormulaire(formulaireSectionSymetrique(styles, t), formik)}
          </>
        );
      case equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 5):
        return (
          <>
            {genererFormulaire(formulaireSectionSymetrique(styles, t), formik)}
          </>
        );
      default:
        return null;
    }
  };

  const afficherDessinTechnique = () => {
    if (equalsCodeOrLabel(typeSection, S04_STEP4_arrayTypeCorps(), 6)) {
      return (
        <StyledDivPhotoPasDessin
          style={{
            backgroundImage:
              "url(" + importAll()["GrandePhotosSouffletPlisse.jpg"] + ")",
          }}
        />
      );
    } else {
      return (
        <DessinTechnique styles={styles} formik={formik.values} etape={etape} t={t}/>
      );
    }
  };

  const afficherSOSetForm = () => {
    if (lang !== nativeLang)
      return <></>;
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyConstruction}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(formulaireTypeSection(styles, t), formik)}
            {affichageSectionSymetrique(formik)}
            {afficherFormulaireTypeSection(formik)}
            {genererFormulaire(formulaireToile(S04_STEP4_arrayToile(t), styles, t), formik)}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={'cssButton'}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};

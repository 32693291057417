export const ServiceIcon = ({ currentColor }) => {

  return (
    <svg width="40" height="40" viewBox="0 0 511.986 511.986">
      <g>
        <path
          d="M401.665 90.306v-64.72a91.24 91.24 0 0 1 19.906 15.224c3.883 3.931 10.216 3.968 14.142.085 3.929-3.882 3.967-10.213.085-14.142C424.353 15.169 410.276 6.122 395.087.59c-6.42-2.337-13.422 2.577-13.422 9.396v75.387l-24.43 18.731-24.43-18.731V9.986c0-6.82-7.001-11.732-13.422-9.396-43.418 15.814-72.589 57.515-72.589 103.767 0 42.386 23.956 80.549 61.595 99.084l-.552 15.939a33.578 33.578 0 0 0-14.992-3.543c-14.538 0-27.456 9.231-32.145 22.971a9.86 9.86 0 0 0-.391 1.532l-12.649 73.436a19.778 19.778 0 0 1-14.351 15.768v-11.261c0-5.522-4.478-10-10-10h-66.62L41.748 264.418c-5.157-1.966-10.938.618-12.908 5.777-1.969 5.16.617 10.939 5.777 12.908l110.23 42.065v132.861H82.786c-5.522 0-10 4.478-10 10s4.478 10 10 10H223.311c5.522 0 10-4.478 10-10v-12.863h66.369l-1.608 46.475c-.19 5.52 4.129 10.148 9.648 10.34.118.004.235.006.352.006 5.363 0 9.801-4.253 9.988-9.654l10.55-304.88a9.999 9.999 0 0 0-6.146-9.575c-33.817-14.101-55.669-46.885-55.669-83.521 0-32.884 18.003-62.958 46.011-78.788v64.738c0 3.11 1.447 6.043 3.915 7.936l34.43 26.399a10.002 10.002 0 0 0 12.17 0l34.43-26.399a10.006 10.006 0 0 0 3.914-7.937zM213.311 458.029h-48.463V328.281h48.463zm20.005-108.136c17.116-2.218 31.044-15.293 34.055-32.72l12.498-72.558c2.391-6.059 8.169-8.78 12.978-8.78 2.737 0 5.409.826 7.73 2.394 3.978 2.68 6.266 7.149 6.124 11.958l-6.452 184.979h-66.933z"
          style={{ fill: currentColor, opacity: 1 }}
        ></path>
        <path
          d="M477.601 389.542a30.539 30.539 0 0 0-5.421-17.399c7.077-5.632 11.626-14.311 11.626-24.038s-4.549-18.406-11.626-24.038a30.538 30.538 0 0 0 5.421-17.398c0-16.938-13.78-30.719-30.718-30.719H408.59l-2.509-72.512c37.038-18.263 60.973-55.799 61.566-97.482.079-5.522-4.334-10.063-9.856-10.142-5.532-.105-10.063 4.334-10.142 9.856-.514 36.058-22.354 68.325-55.642 82.206a10.001 10.001 0 0 0-6.146 9.575l2.814 81.342c-10.755 5.019-17.823 15.992-17.823 27.875 0 7.614 2.91 15.094 8.045 20.715-5.149 5.623-8.045 13.098-8.045 20.722 0 7.581 2.893 15.138 8.053 20.711-5.153 5.623-8.053 13.101-8.053 20.726 0 7.615 2.91 15.089 8.05 20.707-5.364 5.854-8.253 13.679-8.028 21.608.405 13.907 10.52 26.14 24.106 29.126l1.431 41.349a10 10 0 0 0 19.988-.692l-1.382-39.944h22.864c16.938 0 30.719-13.78 30.719-30.718 0-5.86-1.651-11.34-4.51-16.004 8.147-5.53 13.511-14.866 13.511-25.432zm-30.718 10.717h-45.23c-.026 0-.051-.004-.077-.004-5.911 0-10.72-4.804-10.72-10.709 0-5.897 4.928-10.687 10.778-10.724h45.25c5.91 0 10.718 4.809 10.718 10.719s-4.809 10.718-10.719 10.718zm6.205-62.873c5.91 0 10.718 4.809 10.718 10.719s-4.808 10.718-10.718 10.718h-51.517c-5.833 0-10.716-4.884-10.716-10.717 0-5.823 4.886-10.72 10.716-10.72zm4.513-30.717c0 5.91-4.808 10.718-10.718 10.718h-45.312c-5.829 0-10.716-4.894-10.716-10.721 0-5.822 4.889-10.716 10.716-10.716h45.312c5.91 0 10.718 4.808 10.718 10.719zm-19.72 135.027h-36.306c-5.911 0-10.72-4.809-10.72-10.72 0-5.826 4.889-10.717 10.716-10.717h36.31c5.91 0 10.719 4.809 10.719 10.719s-4.809 10.718-10.719 10.718zM441.056 70.305c2.095 5.034 8.015 7.499 13.06 5.41 5.033-2.084 7.493-8.027 5.41-13.06-2.085-5.036-8.027-7.488-13.06-5.41-5.03 2.078-7.507 8.032-5.41 13.06zM47.415 464.206c-2.065-5.043-8.036-7.49-13.06-5.41-5.033 2.084-7.495 8.027-5.41 13.061 2.085 5.033 8.031 7.487 13.061 5.41 5.026-2.077 7.504-8.038 5.409-13.061z"
          style={{ fill: currentColor, opacity: 1 }}
        ></path>
      </g>
    </svg>
  );
};

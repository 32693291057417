import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useConfiguration } from "../../redux/vue/components/hook/useConfiguration";
import { useTranslations } from "../../redux/vue/components/hook/useTranslations";
import { getAllFonctions } from "../../redux/slice/FonctionReducer";
import { getAllSecteurs} from "../../redux/slice/SecteurReducer";
import { getAllPays} from "../../redux/slice/PaysReducer";
import { WindowWidthProvider } from '../../context/WindowWidthContext';

const CUSTOM_LINK_ID = 'custom_style';

const MasterWrapper = ({ children, setTrackingId }) => {

  const dispatch = useDispatch();
  const { loading: configLoading, parameters } = useConfiguration();
  const { loading: translationLoading } = useTranslations();

  useEffect( () => {
    if (parameters !== null) {

      // définition du titre du site
      const siteTitle = parameters.SITE_TITLE;
      if (siteTitle !== undefined)
        document.title = siteTitle;

      // définition du tracking ID Google Analytics
      const trackingId = parameters.TRACKING_ID;
      if (trackingId !== undefined && trackingId.length > 0)
        setTrackingId(trackingId);

      // chargement du fichier styles personnalisés du site
      const cssFile = parameters.CSS;
      if (cssFile !== undefined && cssFile.length > 0) {
        loadCustomStyle(cssFile);
      }
    }
  }, [parameters]);


  useEffect( () => {
    return () => {
      // au démontage du composant, enlever la balise de styles personnalisés
      loadCustomStyle(null);
    };
  }, []);


  useEffect( () => {
    if (configLoading || translationLoading)
      return;
    // charger les listes utilisées dans toutes les parties du site
    // (espace public, formulaire rechercher, espace client, etc)
    dispatch(getAllFonctions());
    dispatch(getAllSecteurs());
    dispatch(getAllPays());
  }, [ configLoading, translationLoading ]);


  const loadCustomStyle = (cssFile) => {
    // supprimer l'ancienne balise style si elle existe
    let linkEl = document.querySelector(CUSTOM_LINK_ID);
    if (linkEl !== null)
      document.head.removeChild(linkEl);
    // quitter s'il n'y pas de fichier style à monter (démontage)
    if (cssFile === null)
      return;
    // chargement du fichier style si demandé
    const urlStyle = `/css/${cssFile}`;
    console.log("Chargement styles", urlStyle );
    linkEl = document.createElement('link');
    linkEl.id = CUSTOM_LINK_ID;
    linkEl.rel = 'stylesheet';
    linkEl.href = urlStyle;
    document.head.appendChild(linkEl);
  }

  // attendre la fin du chargement
  if (configLoading || translationLoading)
    return <div>Loading...</div>;

  return (
    <WindowWidthProvider>
      {children}
    </WindowWidthProvider>
  );
}

export default MasterWrapper;

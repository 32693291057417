import * as Yup from "yup";
import {
  NDDP,
  CAP,
  QUANTITEE_SOUHAITEE_CODE_ARTICLE,
  ARTICLES,
  NUM_OFFRE,
} from "../RechercherChamps";
import {
  CP,
  DEMANDE,
  EMAIL,
  FONCTION,
  NOM,
  PRENOM,
  PAYS,
  SECTEUR,
  SOCIETE,
  TEL,
} from "../../enum/champs/ChampsCommunFormulairesEnum";

const otherwiseSchema = Yup.mixed().optional();
const phoneRegExp = /^\+|0(?:[0-9] ?){6,14}[0-9]$/;

/* A validation schema for the form. */
export const ValidRecherche = (secteursList, fonctionsList, paysList, t) => {
  return Yup.object().shape(
    {
      [ARTICLES.description]: Yup.mixed().when(
        [[NDDP.description].toString(), [NUM_OFFRE.description].toString()],
        {
          is: (_nddp, _numOffre) => true,
          then: Yup.array()
            .of(
              Yup.object().shape({
                [QUANTITEE_SOUHAITEE_CODE_ARTICLE.description]:
                  Yup.number()
                    .transform((value) => (isNaN(value) ? -1 : value)) // hack car NaN est considéré comme un nombre
                    .positive()
                    .min(1, t.quantiteSupA0),
                [CAP.description]: Yup.string(t.demandeDescription),
              })
            )
            .test(
              "testOneElementIsSet",
              () => {
                return t.articlesRechercher;
              },
              (articlesList) => {
                return articlesList.filter(
                  (elem) => ![elem[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description], elem[CAP.description]].includes("")
                  && ![elem[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description], elem[CAP.description]].includes(undefined)
                ).length > 0}
            ),
          otherwise: otherwiseSchema,
        }
      ),
      [NDDP.description]: Yup.mixed().when(
        [[ARTICLES.description].toString(), [NUM_OFFRE.description].toString()],
        {
          is: (articlesList, numOffre) =>
            articlesList.filter(
              (elem) => ![elem[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description], elem[CAP.description]].includes("")
              && ![elem[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description], elem[CAP.description]].includes(undefined)
            ).length === 0 && !numOffre,
          then: Yup.string(t.demandeDescription).required(t.nddpRechercher),
          otherwise: otherwiseSchema,
        }
      ),
      [NUM_OFFRE.description]: Yup.mixed().when(
        [[ARTICLES.description].toString(), [NDDP.description].toString()],
        {
          is: (articlesList, nddp) =>
            articlesList.filter(
              (elem) =>
              ![elem[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description], elem[CAP.description]].includes("")
              && ![elem[QUANTITEE_SOUHAITEE_CODE_ARTICLE.description], elem[CAP.description]].includes(undefined)
            ).length === 0 && !nddp,
          then: Yup.string(t.demandeDescription).required(t.numOffreRechercher),
          otherwise: otherwiseSchema,
        }
      ),
      [DEMANDE.description]: Yup.string(t.demandeDescription).min(
        4,
        t.champPlusDe4Caractere
      ),
      [SOCIETE.description]: Yup.string(t.demandeDescription).required(
        t.renseignerChamp
      ),
      [CP.description]: Yup.string(t.demandeDescription)
        .min(4, t.champPlusDe4Caractere)
        .max(20, t.champMoinsDe20Caractere)
        .required(t.renseignerChamp),
      [NOM.description]: Yup.string(t.demandeDescription)
        .min(4, t.champPlusDe4Caractere)
        .max(20, t.champMoinsDe20Caractere)
        .required(t.renseignerChamp),
      [PRENOM.description]: Yup.string(t.demandeDescription)
        .min(4, t.champPlusDe4Caractere)
        .max(20, t.champMoinsDe20Caractere)
        .required(t.renseignerChamp),
      [EMAIL.description]: Yup.string(t.demandeDescription)
        .min(4, t.champPlusDe4Caractere)
        .email(t.emailNonValide)
        .required(t.renseignerChamp),
      [TEL.description]: Yup.string(t.demandeDescription)
        .matches(phoneRegExp, t.numeroInvalide)
        .required(t.renseignerChamp),
      [SECTEUR.description]: Yup.mixed()
        .test(
          "SecteurActiviteInList",
          () => {
            return t.selectionSecteurActivite;
          },
          (selectedSecteur) =>
            secteursList
              .map((value) => value.name)
              .includes(selectedSecteur?.name)
        )
        .required(t.renseignerChamp),
      [FONCTION.description]: Yup.mixed()
        .test(
          "FonctionInList",
          () => {
            return t.selectionFonction;
          },
          (selectedFonction) => {
            if (selectedFonction === null || selectedFonction === undefined) {
              return true;
            }
            return fonctionsList
              .map((value) => value.name)
              .includes(selectedFonction?.name);
          }
        ),
      [PAYS.description]: Yup.mixed()
        .test(
          "PaysInList",
          () => {
            return t.selectionPays;
          },
          (selectedPays) =>
            paysList.map((value) => value.name).includes(selectedPays?.name)
        )
        .required(t.renseignerChamp),
    },
    [
      [[ARTICLES.description], [NDDP.description]],
      [[ARTICLES.description], [NUM_OFFRE.description]],
      [[NDDP.description], [NUM_OFFRE.description]],
    ]
  );
};

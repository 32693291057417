import { Button } from "@mui/material";
import { IcOutlineSos } from "./IconSOS";
import { ChevronDown, ChevronUp } from "./Chevron";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StringToRouteInNavigate } from "../../../helper/StringParser";
import { GuestRoutes } from "../../../constantes/routes/GuestRoutes";

export const SOSComponent = ({ styles }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const navigate = useNavigate();

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const handleCallback = () => {
    const formulaireFPS = localStorage.getItem("formulaireFPS");
    if (formulaireFPS) {
      // Enregistrer cette valeur dans le sessionStorage
      sessionStorage.setItem("formulaireFPS", formulaireFPS);
    }
    window.open(StringToRouteInNavigate(GuestRoutes.SOS), '_blank');
  };

  const buttonStyle = {
    padding: collapseOpen ? "1em 1em 0 1em" : "1em",
  };

  return (
    <>
      <Button
        id="collapseButton"
        onMouseEnter={() => setCollapseOpen(true)}
        onMouseLeave={() => setCollapseOpen(false)}
        onClick={toggleCollapse}
        variant="contained"
        style={buttonStyle}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IcOutlineSos styles={styles} />
            <span>Nos experts vous rappellent</span>
          </div>
          {collapseOpen ? (
            <ChevronDown styles={styles} />
          ) : (
            <ChevronUp styles={styles} />
          )}
        </div>
        {collapseOpen && (
          <div id="collapseContent">
            <p>
              Nos experts vous rappellent rapidement pour vous accompagner dans
              votre configuration.
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation(); // Empêche la propagation de l'événement au collapseButton
                handleCallback(); // Exécute le callback pour "Je souhaite être rappelé"
              }}
              className={'cssButton'}
              style={{ width: buttonStyle.width }} // Utilisation de la largeur du bouton principal
            >
              Je souhaite être rappelé
            </Button>
          </div>
        )}
      </Button>
    </>
  );
};

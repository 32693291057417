const BoutonAccueilEC = ({ texte, action, backgroundColor }) => {
  // Déterminer la couleur du texte en fonction de la couleur de fond
  const textColor = backgroundColor === "dark" ? "white" : "#1C243C";

  return (
    <button
      style={{
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 4,
        border: `2px ${textColor} solid`,
        marginTop: "1.25rem",
        backgroundColor: "transparent",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        onClick={action}
        style={{
          color: textColor,
          fontSize: 18,
          fontFamily: "Dosis",
          fontWeight: "600",
        }}
      >
        {texte}
      </div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginLeft: "0.375rem", minWidth: "1.5rem" }}
      >
        <path
          d="M1.96869 8.99978C1.96869 9.22356 2.05759 9.43817 2.21582 9.59641C2.37406 9.75464 2.58867 9.84353 2.81244 9.84353H13.1484L9.52588 13.4653C9.36737 13.6238 9.27833 13.8388 9.27833 14.063C9.27833 14.2872 9.36737 14.5021 9.52588 14.6606C9.68439 14.8192 9.89937 14.9082 10.1235 14.9082C10.3477 14.9082 10.5627 14.8192 10.7212 14.6606L15.7837 9.59814C15.8624 9.51976 15.9248 9.42661 15.9674 9.32405C16.0099 9.22149 16.0319 9.11154 16.0319 9.00049C16.0319 8.88944 16.0099 8.77948 15.9674 8.67692C15.9248 8.57436 15.8624 8.48122 15.7837 8.40283L10.7212 3.34033C10.6427 3.26185 10.5495 3.19959 10.447 3.15711C10.3444 3.11464 10.2345 3.09277 10.1235 3.09277C10.0125 3.09277 9.90264 3.11464 9.80009 3.15711C9.69754 3.19959 9.60437 3.26185 9.52588 3.34033C9.4474 3.41882 9.38514 3.51199 9.34266 3.61454C9.30019 3.71708 9.27833 3.82699 9.27833 3.93799C9.27833 4.04898 9.30019 4.15889 9.34266 4.26144C9.38514 4.36398 9.4474 4.45716 9.52588 4.53564L13.1484 8.15603H2.81244C2.58867 8.15603 2.37406 8.24493 2.21582 8.40316C2.05759 8.5614 1.96869 8.77601 1.96869 8.99978Z"
          fill={textColor}
        />
      </svg>
    </button>
  );
};

const BoutonRetourEC = ({ texte, action, backgroundColor }) => {
  // Déterminer la couleur du texte en fonction de la couleur de fond
  const textColor = backgroundColor === "dark" ? "white" : "#1C243C";

  return (
    <button
      style={{
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 4,
        border: `2px ${textColor} solid`,
        marginTop: "1.25rem",
        backgroundColor: "transparent",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: "0.375rem", minWidth: "1.5rem" }}
      >
        <path
          d="M16.0314 9C16.0314 9.22377 15.9425 9.43839 15.7843 9.59662C15.6261 9.75485 15.4115 9.84375 15.1877 9.84375H4.85174L8.47424 13.4655C8.63275 13.6241 8.7218 13.839 8.7218 14.0632C8.7218 14.2874 8.63275 14.5023 8.47424 14.6609C8.31573 14.8194 8.10075 14.9084 7.87658 14.9084C7.65242 14.9084 7.43744 14.8194 7.27893 14.6609L2.21643 9.59836C2.13777 9.51997 2.07535 9.42683 2.03277 9.32427C1.99018 9.22171 1.96826 9.11175 1.96826 9.0007C1.96826 8.88965 1.99018 8.77969 2.03277 8.67713C2.07535 8.57458 2.13777 8.48143 2.21643 8.40304L7.27893 3.34054C7.35741 3.26206 7.45059 3.1998 7.55313 3.15732C7.65568 3.11485 7.76559 3.09299 7.87658 3.09299C7.98758 3.09299 8.09749 3.11485 8.20003 3.15732C8.30258 3.1998 8.39575 3.26206 8.47424 3.34054C8.55272 3.41903 8.61498 3.51221 8.65746 3.61475C8.69993 3.7173 8.7218 3.82721 8.7218 3.9382C8.7218 4.0492 8.69993 4.1591 8.65746 4.26165C8.61498 4.3642 8.55272 4.45737 8.47424 4.53586L4.85174 8.15625H15.1877C15.4115 8.15625 15.6261 8.24514 15.7843 8.40338C15.9425 8.56161 16.0314 8.77622 16.0314 9Z"
          fill={textColor}
        />
      </svg>
      <div
        onClick={action}
        style={{
          color: textColor,
          fontSize: 18,
          fontFamily: "Dosis",
          fontWeight: "600",
        }}
      >
        {texte}
      </div>
    </button>
  );
};

const BoutonConfirmEC = ({ texte, action, backgroundColor }) => {
  // Déterminer la couleur du texte en fonction de la couleur de fond
  const textColor = backgroundColor === "dark" ? "white" : "#1C243C";

  return (
    <button
      style={{
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 4,
        border: `2px ${textColor} solid`,
        display: "inline-flex",
        marginTop: "1.25rem",
        backgroundColor: "transparent",
        cursor: "pointer",
        alignItems: "end",
      }}
    >
      <div
        onClick={action}
        style={{
          color: textColor,
          fontSize: 18,
          fontFamily: "Dosis",
          fontWeight: "600",
        }}
      >
        {texte}
      </div>
    </button>
  );
};

const BoutonWithoutArrow = ({ texte, action }) => {
  return (
    <button
      style={{
        fontSize: "1.125em",
        textAlign: "center",
        fontWeight: "600",
        textTransform: "none",
        margin: "1em",
        width: "7.625em",
        borderRadius: 4,
        border: `2px #1C243C solid`,
        marginTop: "1.25rem",
        backgroundColor: "transparent",
        cursor: "pointer",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        onClick={action}
        style={{
          color: "#1C243C",
          fontSize: 18,
          fontFamily: "Dosis",
          fontWeight: "600",
        }}
      >
        {texte}
      </div>
    </button>
  );
};

const BoutonCialProx = ({ texte, action, isFirstButton }) => {
  return (
    <button
      onClick={action}
      style={{
        fontSize: "1.125em",
        textAlign: "center",
        fontWeight: "600",
        textTransform: "none",
        width: "100%",
        borderRadius: 4,
        border: `2px #1C243C solid`,
        marginTop: isFirstButton ? "1.25rem" : "0",  // Applique la marge uniquement sur le premier bouton
        backgroundColor: "transparent",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        padding: "0.375rem 0.875rem",
      }}
    >
      <div
        style={{
          color: "#1C243C",
          fontSize: 18,
          fontFamily: "Dosis",
          fontWeight: "600",
        }}
      >
        {texte}
      </div>
    </button>
  );
};

export {
  BoutonWithoutArrow,
  BoutonCialProx,
  BoutonConfirmEC,
  BoutonRetourEC,
  BoutonAccueilEC
};

/*
 * ListeArticle :
 * Composant JSX - tableau des articles
 * Utilisé par : VueArticle.js
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Pagination } from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import { ArticleItem } from "./ArticleItem";
import { apiStatusMessageForList } from "../../../../helper/apiStatusHelper.js";

import { StyledDivPagination } from "../../styledComponents/StyledDivBackoffice";
import { StyledGridLignesTabRoseEC } from "../../styledComponents/StyledGridBackoffice";

const ListeArticle = ({ actions, state }) => {
  const { t } = useTranslations();
  const { articleList, familleArticleList } = useSelector((state) => state.espaceClient);

  const [listMessage, setListMessage] = useState(null);
  const [searchBarContentCodeClient, setSearchBarContentCodeClient] = useState();
  const [searchBarContentCodeFPS, setSearchBarContentCodeFPS] = useState();
  const [searchBarContentNomArticle, setSearchBarContentNomArticle] = useState();
  const [searchBarContentModeleMachine, setSearchBarContentModeleMachine] = useState();
  const [searchBarContentEmplacementPiece, setSearchBarContentEmplacementPiece] = useState();
  const [searchBarContentFamille, setSearchBarContentFamille] = useState();
  const [sortedDate, setSortedDate] = useState("down");
  const [sortedCodeClient, setSortedCodeClient] = useState();
  const [sortedCodeFPS, setSortedCodeFPS] = useState();
  const [sortedNomArticle, setSortedNomArticle] = useState();
  const [sortedModeleMachine, setSortedModeleMachine] = useState();
  const [sortedFamille, setSortedFamille] = useState();
  const [sortedEmplacementPiece, setSortedEmplacementPiece] = useState();

  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  const defineFilteredList = (searchBarContent, key) => {
    return articleList.list.filter((item) => {
      if (searchBarContent === undefined) {
        return item;
      } else {
        if (item[key].toLowerCase().includes(searchBarContent.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      }
    });
  };

  const defineFilteredFamilleCode = (searchBarContent) => {
    return articleList.list.filter((item) => {
      if (!searchBarContent) {
        return item;
      } else {
        const familleCode = item["familleCode"];
        const familleArticle = familleArticleList.find(
          (famille) => famille.code === familleCode
        );

        if (familleArticle) {
          // FIXME langues codées en dur
          const keys = ["FR", "EN", "DE", "IT", "ES"];
          for (let key of keys) {
            if (
              familleArticle[key] &&
              familleArticle[key]
                .toLowerCase()
                .includes(searchBarContent.toLowerCase())
            ) {
              return item;
            }
          }
        }
        return null;
      }
    });
  };

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...articleList.list].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...articleList.list].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    setListMessage(apiStatusMessageForList(articleList));
  }, [articleList.list, articleList.apiStatus]);

  useEffect(() => {
    if (! articleList.list)
      return;
    setFilteredList(defineFilteredList(searchBarContentCodeClient, "articleReferenceClient"));
  }, [searchBarContentCodeClient, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    setFilteredList(defineFilteredList(searchBarContentCodeFPS, "articleCode"));
  }, [searchBarContentCodeFPS, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    setFilteredList(defineFilteredList(searchBarContentNomArticle, "articleDesignation"));
  }, [searchBarContentNomArticle, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    setFilteredList(defineFilteredFamilleCode(searchBarContentFamille));
  }, [searchBarContentFamille, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    setFilteredList(defineFilteredList(searchBarContentModeleMachine, "modeleMachine"));
  }, [searchBarContentModeleMachine, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    setFilteredList(defineFilteredList(searchBarContentEmplacementPiece, "emplacementPiece"));
  }, [searchBarContentEmplacementPiece, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    defineSortedList(sortedCodeClient, "articleReferenceClient");
  }, [sortedCodeClient, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    defineSortedList(sortedCodeFPS, "articleCode");
  }, [sortedCodeFPS, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    defineSortedList(sortedNomArticle, "articleDesignation");
  }, [sortedNomArticle, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    defineSortedList(sortedFamille, "familleCode");
  }, [sortedFamille, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    defineSortedList(sortedModeleMachine, "modeleMachine");
  }, [sortedModeleMachine, articleList.list]);

  useEffect(() => {
    if (!articleList.list)
      return;
    defineSortedList(sortedEmplacementPiece, "emplacementPiece");
  }, [sortedEmplacementPiece, articleList.list]);

  useEffect(() => {
    if (!filteredList)
      return;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (_, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeArticle = pagination.currentData.map((item) => {
    return (
      <ArticleItem
        key={item.articleId}
        id={item.articleId}
        state={state}
        {...item}
        searchBarAction={{
          setSearchBarContentCodeClient,
          setSearchBarContentCodeFPS,
          setSearchBarContentNomArticle,
          setSearchBarContentModeleMachine,
          setSearchBarContentEmplacementPiece,
          setSearchBarContentFamille,
        }}
        sortAction={{
          setSortedDate,
          setSortedCodeClient,
          setSortedCodeFPS,
          setSortedNomArticle,
          setSortedModeleMachine,
          setSortedFamille,
          setSortedEmplacementPiece,
        }}
        modale={actions}
      />
    );
  });

  return (
    <div>
      <StyledGridLignesTabRoseEC
        container
        style={{
          boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        }}
      >
        <ArticleItem
          key={0}
          id={0}
          action={t.titleAction}
          articleReferenceClient={t.titleCodeClient}
          articleCode={t.titleCodeFPS}
          articleDesignation={t.titleNomArticle}
          familleCode={t.titleFamille}
          modeleMachine={t.titleModeleMachine}
          emplacementPiece={t.emplacementPiece}
          nbOffrePrix={t.titleOffrePrix}
          nbCommande={t.titleCommande}
          articleId={"articleId"}
          articleEtatValidation={"articleEtatValidation"}
          state={state}
          searchBarAction={{
            setSearchBarContentCodeClient,
            setSearchBarContentCodeFPS,
            setSearchBarContentNomArticle,
            setSearchBarContentModeleMachine,
            setSearchBarContentEmplacementPiece,
            setSearchBarContentFamille,
          }}
          sortAction={{
            setSortedDate,
            setSortedCodeClient,
            setSortedCodeFPS,
            setSortedNomArticle,
            setSortedModeleMachine,
            setSortedFamille,
            setSortedEmplacementPiece,
          }}
          modale={actions}
        />
        { listMessage !== null ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1rem",
            }}
          >
            <Grid container item direction="row" className={"row"}>
              { listMessage }
            </Grid>
          </div>
        ) : (
          afficherListeArticle
        )}
      </StyledGridLignesTabRoseEC>
      <StyledDivPagination>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPagination>
    </div>
  );
};

export { ListeArticle };

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useTranslations } from "../../components/hook/useTranslations";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
  StyledDivSearchBar,
} from "../../styledComponents/StyledDivBackoffice";
import {
  StyledTextfieldSearchDdpRose,
} from "../../styledComponents/StyledTextfieldBackoffice";

const ArticleItem = ({
  id,
  articleReferenceClient,
  articleCode,
  articleDesignation,
  modeleMachine,
  emplacementPiece,
  familleCode,
  nbOffrePrix,
  nbCommande,
  action,
  articleId,
  articleEtatValidation,
  searchBarAction: {
    setSearchBarContentCodeClient,
    setSearchBarContentCodeFPS,
    setSearchBarContentNomArticle,
    setSearchBarContentModeleMachine,
    setSearchBarContentEmplacementPiece,
    setSearchBarContentFamille,
  },
  sortAction: {
    setSortedCodeClient,
    setSortedCodeFPS,
    setSortedNomArticle,
    setSortedModeleMachine,
    setSortedFamille,
    setSortedEmplacementPiece,
  },
}) => {
  const { t } = useTranslations();
  const { familleArticleList } = useSelector((state) => state.espaceClient);

  const navigate = useNavigate();
  const [columnSize, setColumnSize] = useState(1);
  const translation = useTranslations();
  const lang = translation.lang;

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  const noClassNameMobileAndTablette =
    "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  const handleChange = (event, setterSearchBar) => {
    setterSearchBar(event.target.value);
  };

  const navigateToArticle = (articleId) => {
    if (articleId)
      navigate(ClientRoutes.VUE_ARTICLE + articleId);
  };

  const DisplayAdornment = ({ md, style, xs }) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={md ? md : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " itemDdp action"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingLeft: "0",
        }}
      >
        {articleEtatValidation !== "100" || articleEtatValidation !== "900" ? (
          <IconButton style={{ padding: 0 }} onClick={() => navigateToArticle(articleId)} >
            <div
              style={{
                padding: 6,
                borderRadius: 4,
                overflow: "hidden",
                border: "2px #1C243C solid",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 6,
                marginLeft: "0.5rem",
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "#1C243C",
                  fontSize: 18,
                  fontFamily: "Dosis",
                  fontWeight: "600",
                }}
              >
                {t.voirPlus}
              </div>
            </div>
          </IconButton>
        ) : null}
      </Grid>
    );
  };

  const displaySearchBar = (setValue) => {
    if (setValue) {
      return (
        <div>
          <StyledDivSearchBar>
            <StyledTextfieldSearchDdpRose
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event, setValue)}
              InputProps={{
                endAdornment: (
                  <SearchIcon md={{ color: "#EC8812", fontSize: 10 }} />
                ),
              }}
            />
          </StyledDivSearchBar>
        </div>
      );
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (id !== articleCode && setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " columnHeaderEC"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
          // marginLeft={name === "Action" ? "-0.75rem" : "0"}
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
            {displaySortArrows(sortStateSetter)}
          </Stack>
          <Stack>{displaySearchBar(searchBarStateSetter)}</Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style, xs) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : 8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </Grid>
    );
  };

  const displayItemAction = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return <DisplayAdornment md={md} xs={xs} style={style} />;
  };

  const displayFamillArticle = (array, field, md, sm, style) => {

    const getTranslation = (code) => {
      const entry = array.find((e) => e.code === code);
      return entry?.[lang] ?? code;
    };

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={0}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{
            wordBreak: "break-word",
            justify: "center",
            alignItems: "center",
          }}
        >
          { getTranslation(field) }
        </div>
      </Grid>
    );
  };

  const displayArticleCode = (articleId, name, md, sm, style) => {

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {articleId ? (
          // Si articleId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={() => navigateToArticle(articleId)}
          />
        ) : (
          // Si articleId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : { height: "100px" }}
    >
      {id === 0
        ? displayItemHeader(
            articleCode,
            setSearchBarContentCodeFPS,
            setSortedCodeFPS,
            1.25,
            1.25,
            8,
            alwaysDisplayed
          )
        : displayArticleCode(
            articleId,
            articleCode,
            1.25,
            1.25,
            alwaysDisplayed
          )}
      {id === 0
        ? displayItemHeader(
            articleReferenceClient,
            setSearchBarContentCodeClient,
            setSortedCodeClient,
            1.25,
            1.25,
            2,
            noClassNameMobile
          )
        : displayItem(articleReferenceClient, 1.25, 1.25, noClassNameMobile, 4)}
      {id === 0
        ? displayItemHeader(
            articleDesignation,
            setSearchBarContentNomArticle,
            setSortedNomArticle,
            1.5,
            1.5,
            4,
            noClassNameMobile
          )
        : displayItem(articleDesignation, 1.5, 1.5, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            familleCode,
            setSearchBarContentFamille,
            setSortedFamille,
            1.5,
            1.5,
            null,
            noClassNameMobile
          )
        : displayFamillArticle(
            familleArticleList,
            familleCode,
            1.5,
            1.5,
            noClassNameMobile
          )}
      {id === 0
        ? displayItemHeader(
            modeleMachine,
            setSearchBarContentModeleMachine,
            setSortedModeleMachine,
            2,
            2,
            8,
            noClassNameMobileAndTablette
          )
        : displayItem(modeleMachine, 2, 2, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(
            emplacementPiece,
            setSearchBarContentEmplacementPiece,
            setSortedEmplacementPiece,
            1.75,
            1.75,
            4,
            noClassNameMobileAndTablette
          )
        : displayItem(
            emplacementPiece,
            1.75,
            1.75,
            noClassNameMobileAndTablette
          )}
      {id === 0
        ? displayItemHeader(
            nbOffrePrix,
            null,
            null,
            0.75,
            0.75,
            4,
            noClassNameMobileAndTablette
          )
        : displayItem(
            nbOffrePrix === "0" ? "-" : nbOffrePrix,
            0.75,
            0.75,
            noClassNameMobileAndTablette
          )}
      {id === 0
        ? displayItemHeader(
            nbCommande,
            null,
            null,
            0.9,
            0.9,
            4,
            noClassNameMobileAndTablette
          )
        : displayItem(
            nbCommande === "0" ? "-" : nbCommande,
            0.9,
            0.9,
            noClassNameMobileAndTablette
          )}
      {id === 0
        ? displayItemHeader(action, null, null, 1, 1, 4, "")
        : displayItemAction(action, null, null, 1, 1, 4, "")}
    </Grid>
  );
};

export { ArticleItem };

export const NCIcon = ({ currentColor }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 512 512">
      <g>
        <path
          d="M451.473 459.93H60.527c-21.847 0-41.41-11.293-52.336-30.215-10.921-18.918-10.921-41.512 0-60.43l195.477-338.57C214.59 11.793 234.152.5 256 .5c21.844 0 41.41 11.293 52.332 30.215l81.543 141.234c2.762 4.781 1.121 10.899-3.66 13.66-4.781 2.758-10.899 1.121-13.66-3.66L291.012 40.715C283.707 28.055 270.617 20.5 256 20.5s-27.707 7.555-35.016 20.215L25.512 379.285c-7.309 12.66-7.309 27.774 0 40.43 7.308 12.66 20.394 20.219 35.015 20.219h390.946c14.617 0 27.707-7.559 35.015-20.22 7.309-12.655 7.309-27.769 0-40.429l-68.933-119.394c-2.762-4.782-1.121-10.899 3.66-13.66 4.785-2.762 10.898-1.122 13.66 3.66l68.934 119.394c10.921 18.922 10.921 41.512 0 60.43-10.926 18.922-30.489 30.215-52.336 30.215zm0 0"
          style={{ fill: currentColor, opacity: 1 }}
        ></path>
        <path
          d="M403.719 225.926c-2.64 0-5.211-1.07-7.082-2.93a10.08 10.08 0 0 1-2.93-7.07c0-2.63 1.07-5.207 2.93-7.07a10.103 10.103 0 0 1 7.082-2.93c2.629 0 5.199 1.07 7.058 2.93a10.053 10.053 0 0 1 2.93 7.07c0 2.629-1.062 5.21-2.93 7.07a10.052 10.052 0 0 1-7.058 2.93zM256 375.816c-18.746 0-33.996-15.25-33.996-33.996 0-18.746 15.25-33.996 33.996-33.996s33.996 15.25 33.996 33.996c0 18.746-15.25 33.996-33.996 33.996zm0-47.996c-7.719 0-14 6.282-14 14s6.281 14 14 14 14-6.28 14-14-6.281-14-14-14zM256 282.129c-18.746 0-33.996-15.25-33.996-34V163.94c0-18.75 15.25-34 33.996-34s33.996 15.25 33.996 34v84.188c0 18.75-15.25 34-33.996 34zm0-132.188c-7.719 0-14 6.278-14 14v84.188c0 7.723 6.281 14 14 14s14-6.277 14-14V163.94c0-7.722-6.281-14-14-14zm0 0"
          style={{ fill: currentColor, opacity: 1 }}
        ></path>
      </g>
    </svg>
  );
};

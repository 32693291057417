import { Grid, InputAdornment } from "@mui/material";
import {
  StyledDivFormSection,
  StyledDivTextAndTextfieldAlign,
} from "../../../styledComponents/StyledDiv";
import { StyledTextfield } from "../../../styledComponents/StyledTextfield";
import { HelperText } from "../../HelperText";
import { OUTLINED, ROW } from "../commonParameters/CommonParameters";

const genererChampStyledTextfield = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label ? champ.label : ""}
          variant={OUTLINED.description}
          value={formik.values[champ.nom.description]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={champ.disabled ? champ.disabled : false}
          error={Boolean(
            formik.touched[champ.nom.description] &&
              formik.errors[champ.nom.description]
          )}
          helperText={
            formik.touched[champ.nom.description]
              ? formik.errors[champ.nom.description]
              : null
          }
          InputProps={{
            className: champ.textInformation?.cssIcon,
            endAdornment: champ.textInformation?.text ? (
              <InputAdornment position="end">
                <HelperText
                  name={champ.nom.description}
                  textInformation={champ.textInformation}
                />
              </InputAdornment>
            ) : null,
            startAdornment: null,
          }}
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampStyledTextfieldReturnArray = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description + champ.positionInArray}
    >
      <Grid item>
        <StyledTextfield
          type={champ.dataType}
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label}
          variant={OUTLINED.description}
          value={formik.values[champ.nom.description][champ.positionInArray]}
          onChange={(event) => {
            const newArray = formik.values[champ.nom.description].map(
              (value, key) =>
                key === champ.positionInArray ? event.target.value : value
            );
            formik.setFieldValue(champ.nom.description, newArray);
          }}
          onBlur={formik.handleBlur}
          disabled={champ.disabled ? champ.disabled : false}
          error={Boolean(
            formik.touched[champ?.nom.description] &&
              formik.errors[champ?.nom.description] !== undefined &&
              formik.errors[champ?.nom.description][champ?.positionInArray]
          )}
          helperText={
            formik.touched[champ?.nom.description] &&
            formik.errors[champ?.nom.description] !== undefined &&
            formik.errors[champ?.nom.description][champ?.positionInArray]
              ? formik.errors[champ?.nom.description][champ?.positionInArray]
              : null
          }
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};


const genererChampStyledTextfieldReturnArrayMulti = (champ, formik) => {
  const fieldValue = champ.value.description; // nom du tableau de valeurs
  const fieldIndex = champ.positionInArray; // indice de la valeur (objet) dans le tableau
  const fieldName  = champ.nom.description; // nom de la propriété de la valeur
  const fieldId = `${fieldValue}_${fieldIndex}_${fieldName}`;
  const hasChanged = formik.touched[fieldName];
  //const hasValue = Object.keys(formik.values[fieldValue]).includes( (fieldIndex).toString());

  let fieldError = null;
  if (hasChanged) {
    fieldError = formik.errors[fieldValue] ?? null;
    if (fieldError !== null) {
      // l'erreur est soit un string soit un Array un slot par instance du champ :
      // - le slot est vide s'il n'y a pas d'erreur pour ce champ
      // - le slot contient un objet dont la propriété est le nom du champ
      //   et sa valeur est le message d'erreur
      if (fieldError instanceof Array) {
        fieldError = fieldError[fieldIndex];
        if (fieldError instanceof Object) {
          fieldError = fieldError[fieldName];
          if (fieldError === undefined) // l'erreur concerne un autre champ
            fieldError = null;
        }
      }
      if (fieldError !== null && typeof(fieldError) !== 'string')
        fieldError = "Unknown error"; // simple précaution
    }
  }

  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={fieldId}
    >
      <Grid item>
        <StyledTextfield
          type={champ.dataType}
          className={champ.cssTextfield}
          id={fieldId}
          name={fieldName}
          label={champ.label}
          variant={OUTLINED.description}
          value={ formik.values[fieldValue][fieldIndex][fieldName] }
          onChange={(event) => {
            const newArray = formik.values[fieldValue].map(
              (value, index) =>
                index === fieldIndex
                  ? { ...value, [fieldName]: event.target.value }
                  : { ...value }
            );
            formik.setFieldValue(fieldValue, newArray);
          }}
          onBlur={formik.handleBlur}
          disabled={champ.disabled ? champ.disabled : false}
          error={fieldError !== null}
          helperText={fieldError}
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampTextAndTextfieldAlign = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledDivTextAndTextfieldAlign>
          <div className={champ.cssFontFamily} id={champ.nom.description}>
            {champ.label}
          </div>
          <StyledTextfield
            className={champ.cssTextfield}
            name={champ.nom.description}
            variant={OUTLINED.description}
            value={formik.values[champ.nom.description]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched[champ.nom.description] &&
                formik.errors[champ.nom.description]
            )}
            helperText={
              formik.touched[champ.nom.description]
                ? formik.errors[champ.nom.description]
                : null
            }
            size="small"
          />
        </StyledDivTextAndTextfieldAlign>
      </Grid>
    </StyledDivFormSection>
  );
};

/**
 * Generate a text field multiline attribute for a given field
 * @param champ - The field to be rendered.
 * @param formik - The formik object that contains the form data and the methods to update it.
 * @returns A grid item with a text field.
 */
const genererChampStyledTextfieldZone = (champ, formik) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label}
          multiline
          rows={ROW.description}
          variant={OUTLINED.description}
          value={formik.values[champ.nom.description]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          helperText={
            formik.touched[champ.nom.description]
              ? formik.errors[champ.nom.description]
              : null
          }
        />
      </Grid>
    </StyledDivFormSection>
  );
};

const genererChampStyledTextfieldWithoutFormik = (champ, object, _key) => {
  return (
    <StyledDivFormSection
      className={champ.cssMarginFormSection}
      key={champ.nom.description}
    >
      <Grid item>
        <StyledTextfield
          className={champ.cssTextfield}
          id={champ.nom.description}
          name={champ.nom.description}
          label={champ.label ? champ.label : ""}
          variant={OUTLINED.description}
          value={champ.value !== undefined && champ.value !== null ? champ.value : ""}
          onChange={object.handleChange}
          onBlur={object.handleBlur}
          disabled={champ.disabled ? champ.disabled : false}
          InputProps={{
            className: champ.textInformation?.cssIcon,
            endAdornment: champ.textInformation?.text ? (
              <InputAdornment position="end">
                <HelperText
                  name={champ.nom.description}
                  textInformation={champ.textInformation}
                />
              </InputAdornment>
            ) : null,
            startAdornment: null,
          }}
          size="small"
        />
      </Grid>
    </StyledDivFormSection>
  );
};

export {
  genererChampStyledTextfield,
  genererChampStyledTextfieldReturnArray,
  genererChampTextAndTextfieldAlign,
  genererChampStyledTextfieldZone,
  genererChampStyledTextfieldReturnArrayMulti,
  genererChampStyledTextfieldWithoutFormik
};

import { Button, Collapse, Grid } from "@mui/material";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
  StyledDivPhotoPasDessin,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import { importAll, importAllSpec } from "../../../../helper/ImportAll";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import { DessinTechnique } from "../../components/DessinTechnique";
import { findFormeCorpsFromTypeSection } from "../../../../helper/displayCasesFormulary";
import { useEffect, useState } from "react";
import { formulaireToileS06 } from "../../ChampsFormulaires/S04_06/etape4/ChampsConstructionS06";
import { S06_STEP4_arrayToile } from "../../../../constantes/symbols/SymbolsServicesS06";
import { IcOutlineSos } from "../../components/IconSOS";
import { ChevronDown, ChevronUp } from "../../components/Chevron";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";
import { nativeLang } from '../../../../config/i18nConfig.js';

export const StepS06Build = ({
  state: {
    booleanIdentite,
    etape,
    styles,
    styles: {
      cssFontFamily,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const translation = useTranslations();
  const lang = translation.lang;

  const afficherDessinTechnique = () => {
    return (
      <>
        <DessinTechnique
          styles={styles}
          formik={formik.values}
          etape={etape}
          t={t}
          isFrom={"Configurateur"}
        />
      </>
    );
  };

  const afficherSOSetForm = () => {
    if (lang !== nativeLang)
      return <></>;
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyConstruction}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(
              formulaireToileS06(S06_STEP4_arrayToile(t), styles, t),
              formik
            )}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={'cssButton'}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
